
import React from 'react';

import {Dropdown} from 'primereact/dropdown';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {CircularProgress} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTable} from '@fortawesome/free-solid-svg-icons'

export default function TablePrevisualization(props) {


    function onEditorValueChange(propsValue, value) {
        let updatedColumnFormats = [...propsValue.value];
        updatedColumnFormats[propsValue.rowIndex][propsValue.field] = value;
        props.setColumnFormats(updatedColumnFormats)
    }

    function formatEditor (props) {
        let formats = [
            {label: 'String', value: 'String'},
            {label: 'Integer', value: 'Integer'},
            {label: 'Float', value: 'Float'},
            {label: 'Date', value: 'Date'}
        ];
        
        return (
            <Dropdown value={props.value[props.rowIndex].brand} options={formats} style={{fontSize:'10px'}}
                    onChange={(e) => onEditorValueChange(props, e.value)} style={{width:'100%'}} placeholder="Select a Format"/>
        );
    }

    if(props.parsingData === true){
        return(
            <>
            <section style={{textAlign:'center', border:'0px solid ', borderRadius:'20px',  width:'300px' , paddingTop:'20px', paddingBottom:'20px', paddingRight:'10px', paddingLeft:'10px', display:'flex', flexDirection:'column', justifyContent:'center', margin:'auto'}}>
            <p style={{paddingBottom:'10px', fontWeight:'bold', color:'#606060'}}><span style={{display:'block'}}>Loading your data,</span> <span>please wait...</span></p>
            <section style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <FontAwesomeIcon icon={faTable} style={{marginRight:'8', alignSelf:'center', color:'#66B2FF'}}/>
            <CircularProgress size='1em' style={{alignSelf:'center'}} />
            </section>
            </section>
            </> 
        )
    }
    else{
    return(
        <DataTable value={props.columnFormats}
                        editable={true} 
                        style={{algin:'center',width:'100%'}}
                        scrollable={true} scrollHeight="200px">
                            <Column field="position" header="Position"
                            style={{
                                background:'transparent',
                                borderColor: 'transparent', 
                                fontSize:'12px',
                                textAlign: 'right',
                                width:'20%'
                                }}  />
                            <Column field="name" header="Name"
                            style={{
                                background:'transparent',
                                borderColor: 'transparent', 
                                fontSize:'12px',
                                textAlign: 'right',
                                width:'40%'
                                }}  />
                            <Column field="type" header="Format"
                            editor={formatEditor}
                            style={{
                                background:'transparent',
                                borderColor: 'transparent', 
                                fontSize:'12px',
                                textAlign: 'right',
                                width:'40%'
                                }}  />
                            
                        </DataTable>
    )
                            }
}