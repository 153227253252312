
import React from 'react'

import FormRuleFormat from './FormRuleFormat'
import FormRuleNull from './FormRuleNull'
import FormRegularExp from './FormRegularExp'
import FormRuleDuplicated from './FormRuleDuplicated'
import FormRuleValueRange from './FormRuleValueRange'
import FormRuleCheckBetweenLoads from './FormRuleCheckBetweenLoads'
import FormTimeliness from './FormTimeliness'
import FormMasterData from './FormMasterData'
import FormAdHoc from './FormAdHoc'


/*
import FormRuleCheckBetweenLoads from './FormRuleCheckBetweenLoads'
import FormRuleDuplicated from './FormRuleDuplicated'
import FormRuleValueRange from './FormRuleValueRange'
import FormTimeliness from './FormTimeliness'
*/


export function selectSpecificForm(type, commonRule, datasource, setSpecific, editableRule, specific){

    console.log ('type', type)

    switch(type){
        case 'statistics':
            return(
                <FormRuleFormat commonRule={commonRule} columns = {datasource.dataset.columns} setSpecific={setSpecific} editableRule ={editableRule}
                specific={specific}/>
            )
            
        case 'null':
            return(
                <FormRuleNull commonRule={commonRule}  setSpecific={setSpecific} editableRule ={editableRule} specific={specific}/>
            )
            
        case 'exp':
            return(
                <FormRegularExp commonRule={commonRule}  setSpecific={setSpecific} editableRule ={editableRule} specific={specific}/>
            )
        case 'duplicated':
            return(
                <FormRuleDuplicated commonRule={commonRule}  setSpecific={setSpecific} editableRule ={editableRule} specific={specific}/>
                )
            
        case 'ranges':
            return(
                <FormRuleValueRange commonRule={commonRule}  setSpecific={setSpecific} editableRule ={editableRule}
                datasetColumns = {datasource.dataset.columns} specific={specific}/>
            )
        case 'checkBetweenLoads':
            return(
                <FormRuleCheckBetweenLoads commonRule={commonRule} columns = {datasource.dataset.columns} 
                setSpecific={setSpecific} editableRule ={editableRule} specific={specific} />
                )

        case 'dvd':
                    return(
                        <FormRuleCheckBetweenLoads commonRule={commonRule} columns = {datasource.dataset.columns} 
                        setSpecific={setSpecific} editableRule ={editableRule} specific={specific} />
                        )
            
        case 'time':
            return(
                <FormTimeliness commonRule={commonRule}  setSpecific={setSpecific} editableRule ={editableRule} specific={specific} />
            )
        case 'masterData':
                return(
                    <FormMasterData commonRule={commonRule}  setSpecific={setSpecific} editableRule ={editableRule} specific={specific} />
                )
        case 'adHoc':
                    return(
                        <FormAdHoc commonRule={commonRule}  setSpecific={setSpecific} editableRule ={editableRule} specific={specific} />
        )
        default:
            return(
                <FormRuleFormat commonRule={commonRule} columns = {datasource.dataset.columns}
                 setSpecific={setSpecific} editableRule ={editableRule} specific={specific} />
            )
       
       
    }
}