import React from 'react';
import {makeStyles,TextField,Grid ,Typography} from '@material-ui/core';
import classNames from 'classnames';
import {Button,Checkbox,FormControlLabel} from  '@material-ui/core';

import { styles, QualityTheme } from '../../../../../../../../style/CustomTheme'
import {getDataFromProperties} from './SpecificRuleHelper'
import trackEvent from '../../../../../../../../trackEvent'


export default function FormAdHoc(props){

    const classes = makeStyles(styles(QualityTheme));

    const [query,setQuery] = React.useState(getDataFromProperties(props.editableRule, props.specific,'query',''))

    function createSpecific(){
        //creamos el objeto de regla especifica
        const specific = {
            query: query
        }
        props.setSpecific(specific)
       
    }
    
    React.useEffect(() => {
        createSpecific() 
    }, [query]);


    return(
        <div style={{marginTop:'5px'}}>
           
            <div style={{width:'50%'}}>
                <TextField
                    fullWidth={true}
                    id="queryTextField"
                    label="SQL Expression"
                    InputLabelProps={{ shrink: true, style:{ fontSize:'12px'} }}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    value={query} 
                    onChange={event =>{setQuery(event.target.value); }}
                />
            </div>
            
          </div>
    )
}
