
import quality from '../apis/quality';
import {
    EDIT_PROJECT

    } from './types'
    import {axiosHeader,dispatchInfo, dispatchError} from '../components/common/axios/axiosHelper';


export const addQualityPipe = (idProject,formValues)=> async dispatch => {
    var returnValue = {result:'undefined'}
    await quality.post(`/qualityPoint/${idProject}`, formValues,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`QualityPoint ${formValues.name} created`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue = {result:'ko',error:error}

      });
    return returnValue
}
export const editQualityPipe = (idProject,formValues)=> async dispatch => {
    var returnValue = {result:'undefined'}
   await quality.put(`/qualityPoint/${idProject}`, formValues,{
    headers: axiosHeader()
  }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`QualityPoint ${formValues.name} modified`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue

}

export const deleteQualityPipe = (idProject,pipeId)=> async dispatch => {
    var returnValue = {result:'undefined'}
    await quality.delete(`/qualityPoint/${idProject}/${pipeId}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`QualityPoint deleted`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue

}