import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StyledMenu } from "../common/StyleMenu";
import { MenuItem } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSearch, faBell } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@material-ui/core/styles";
import { AqtivaDialogDataRepository } from "../quality/DataRepository/EntityDataRepository/AqtivaDialogDataRepository";
import { AllNotifications } from "./AllNotifications";
import * as actions from "./../../actions";
import {clickOnNotification, markAsRead} from "./NotificationsHelper"

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function NotificationsMenu(props) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const user = useSelector((store) => store.user);
  const unreadNotifications = useSelector((store) =>
    Object.values(store.notifications)
  );

  const [dialog, setDialog] = React.useState(false);

  function truncate(strShort,strLong, n, useWordBoundary) {
    if((strShort===undefined || strShort===null) && (strLong===undefined || strLong===null)){
      return str
    }
    let str = (strShort===undefined || strShort===null)? strLong : strShort
    if (str.length <= n) {
      return str;
    }
    const subString = str.substr(0, n - 1);
    return (
      (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + "..."
    );
  }
      //HE SACADO ESTAS DOS FUNCIONES A UN HELPER, PORQUE SE USABAN TAMBIÉN EN OTRO COMPONENTE Y QUERÍA EVITAR REPETIR CÓDIGO
/*   function markAsRead(notification) {
    dispatch(actions.markAsReadNotifications(notification._id, user.id));
  }

  function clickOnNotification(notification) {
    if(notification && notification.params && notification.params.simulationId){
      if (notification.notificationType === "simulation") {
        props.history.push(`/aqtiva/dashboard/${notification.params.simulationId}/simulation`);
      }
      markAsRead(notification);
    }
    if(notification && notification.params && notification.params.rulesDTO){
      if (notification.notificationType === "ruleRecommender") {
        props.history.push(`/aqtiva/project/${notification.params.projectId}/${notification.params.qpId}/${notification.params.ruleDTOId}/true`);
      }
      markAsRead(notification);
    }

  }
    
  } */
  const notificationsItems = unreadNotifications.map((notification, key) => {
    return (
      <StyledMenuItem key={key}>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={9}>
            <Tooltip title = {notification.longDescription}>
              <Typography style={theme.notifications.mainItem}>
                {truncate(notification.shortDescription, notification.longDescription, 60)}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
          <Tooltip title="Mark as read">
            <Button
              style={{
                color: "white",
                width: "5px",
                marginRight:"10px"
              }}
              onClick={() => markAsRead(notification, user, dispatch)}
            >
              <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
            </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
          <Tooltip title="Go to detail">
            <Button
              style={{
                color: "white",
                width: "5px",
                marginRight: "-10px",
                marginLeft: "15px",
              }}
              onClick={() => clickOnNotification(notification, props.history, user, dispatch)}
            >
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
            </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </StyledMenuItem>
    );
  });

  //¿Sacamos esto a otro componente?
  const showNotificationsOrDefaultMessage = () => {
    if (
      unreadNotifications &&
      unreadNotifications.length > 0 &&
      notificationsItems
    ) {
      return (
          <div>
            {unreadNotifications &&
              unreadNotifications.length > 0 &&
              notificationsItems}
          </div>
      );
    } else {
      return (
          <Typography
            variant="subtitle2"
            style={theme.notifications.notificationDefaultText}
          >
            You don't have new notifications
          </Typography>
      );
    }
  };

  return (
    <>
      <div>
        <StyledMenu
          id="menu-appbar"
          anchorEl={props.anchorElNotification}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          open={props.openNotifications}
          onClose={props.handleCloseNotifications}
          style={{ minWidth: "500px" }}
        >
          <div style={{ textAlign: "center" }}>
            <Typography variant="subtitle2" style={theme.notifications.title}>
              Notifications
            </Typography>
          </div>
          <Divider
            variant="inset"
            component="li"
            style={{ backgroundColor: "#FFFFFF", margin: "1%" }}
          />
          {showNotificationsOrDefaultMessage()}
          <Divider
            variant="inset"
            component="li"
            style={{ backgroundColor: "#FFFFFF", margin: "1%" }}
          />

          <Grid container spacing={1} style={{ width: "100%" }}>
            <Grid item xs={12}>
              <Button
                style={theme.notifications.notificationsButton}
                onClick={() => setDialog(true)}
              >
                <Typography
                  variant="subtitle2"
                  style={theme.notifications.notificationsButton}
                >
                  View all notifications
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </StyledMenu>
      </div>
      <AqtivaDialogDataRepository
        dialog={dialog}
        setDialog={setDialog}
        title="All notifications"
        titleIcon={faBell}
        showConfirmButton={false}
        confirmCallback={(event) => console.log("I am clicking here")}
      >
        <AllNotifications history={props.history} />
      </AqtivaDialogDataRepository>
    </>
  );
}
