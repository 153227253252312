import React from 'react';
import QualityDetailProjectTable from './QualityDetailProjectTable';
import ExecutionConditionRule from '../QualityDetailProject/Dialogs/DialogCreateRule/ExecutionConditionRule';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function QualityDetailProject(props) {



    return (
        <div >

            <DndProvider backend={HTML5Backend}>
                <QualityDetailProjectTable project={props.project}
                    editQualityPipe={props.editQualityPipe}
                    editDatasource={props.editDatasource}
                    editRule={props.editRule}
                    setSelectedItem={props.setSelectedItem}
                    deleteItem={props.deleteItem}
                    addDatasource={props.addDatasource}
                    addFolder={props.addFolder}
                    addRule={props.addRule}
                    onClickOpenDialogSimulation={props.onClickOpenDialogSimulation}
                    qualityConfiguration={props.qualityConfiguration}
                    searchText={props.searchText}
                    handleRuleRecommender={props.handleRuleRecommender}
                    selectedEnvironment={props.selectedEnvironment}

                />
                </DndProvider>
        </div>

    );
}