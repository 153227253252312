import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Typography,
  CircularProgress,
  Card,
  CardContent,
} from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#474b51",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#DAEAF3",
    },
  },
}))(TableRow);

function createRow(User, Environment, Event) {
  return { User, Environment, Event };
}

export default function TableTotalEventRules(props) {
  const classes = useStyles();
  const [tableRows, setTableRows] = React.useState([]);
  const spaces= "   "

  React.useEffect(() => {
    const rows = [];
    //UserName - environment - total
    if (
      props.tableTotalEventRulesData &&
      props.tableTotalEventRulesData.tableDataUsers
    ) {
      props.tableTotalEventRulesData.tableDataUsers.map((data) => {
        rows.push(
          createRow(
            `${data.userName}`,
            data.environment,
            numberWithCommas(data.totalEvents)
          )
        );
      });
    }
    rows.push(createRow(<b>Subtotal by user</b>));
    //Username - subtotal
    //AÑADIR ESPACIOS PARA INDENTAR AQUÍ
    if (
      props.tableTotalEventRulesData &&
      props.tableTotalEventRulesData.tableDataSubTotalUser
    ) {
      props.tableTotalEventRulesData.tableDataSubTotalUser.map((data) => {
        rows.push(
          createRow(
            `Subtotal ${data.userName}`,
            "",
            numberWithCommas(data.totalEvents)
          )
        );
      });
    }
    rows.push(createRow("", <b>Subtotal by environment</b>));
    //Environment - subtotal
    if (
      props.tableTotalEventRulesData &&
      props.tableTotalEventRulesData.tableDataSubTotalEnvironment
    ) {
      props.tableTotalEventRulesData.tableDataSubTotalEnvironment.map(
        (data) => { if (data.environment === "null"){
          rows.push(
            createRow(
              "",
              `Subtotal undefined environment `,
              numberWithCommas(data.totalEvents)
            )
          );
        }else {
          rows.push(
            createRow(
              "",
              `Subtotal ${data.environment} `,
              numberWithCommas(data.totalEvents)
            )
          );
        }

        }
      );
    }
    //Total event rules
    if (
      props.tableTotalEventRulesData &&
      props.tableTotalEventRulesData.totalEvents
    ) {
      rows.push(
        createRow(
          "",
          <b>Total Event Rules</b>,
          numberWithCommas(props.tableTotalEventRulesData.totalEvents)
        )
      );
    }
    setTableRows(rows);
  }, [props.tableTotalEventRulesData]);

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  }

  //LOADING
  if (!props.tableTotalEventRulesData.tableDataUsers) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress size={50} style={{ margin: "40px" }} />
        <Typography variant="subtitle2" style={{ marginLeft: "10px" }}>
          Fetching data
        </Typography>
        <Typography variant="caption" style={{ marginTop: "10px" }}>
          Please wait...{" "}
        </Typography>
      </div>
    );
  }
  /* console.log('row.User-->',tableRows) */
  return (
    <div
      style={{
        maxWidth: "1200px",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "auto",
        marginTop: "40px",
        marginBottom: "50px",
      }}
    >
      <Card style={{ minWidth: "900px", borderRadius: "10px" }}>
        <CardContent>
          <Typography variant="h6" style={{margin:"10px", textAlign:"center"}}>Total event rules table</Typography>
          <TableContainer style={{ maxWidth: "800px", borderRadius: "10px", margin:"auto" }}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">User</StyledTableCell>
                  <StyledTableCell align="left">Environment</StyledTableCell>
                  <StyledTableCell align="left">Event Rules</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows &&
                  tableRows.length > 0 &&
                  tableRows.map((row) => (
                    
                    <StyledTableRow key={row.desc}>
                      <TableCell
                        padding={"none"}
                        align={row.User.toString().includes('ubtotal') ? 'justify' : 'left'}
                        key={row.User}
                        style={{ paddingLeft: row.User.toString().includes('ubtotal') ? '40px' : '10px'}}
                      >
                        {row.User}
                      </TableCell>
                      <TableCell
                        padding={"none"}
                        align={row.environment && row.Environment.toString().includes('ubtotal') ? 'justify' : 'left'}
                        key={row.Environment}
                        style={{ paddingLeft: row.Environment && row.Environment.toString().includes('ubtotal') ? '40px' : '10px'}}
                      >
                        {row.Environment}
                      </TableCell>
                      <TableCell
                        padding={"none"}
                        align="right"
                        key={row.Event}
                        style={{ paddingRight: "10px" }}
                      >
                        {row.Event}
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
}
