import React from 'react'
import { Typography, Grid, Tooltip } from '@material-ui/core'
import { faHome, faChartPie } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { faChartBar } from '@fortawesome/free-regular-svg-icons'
import { faTools } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'

import { useTheme } from '@material-ui/core/styles';


function routeChange(props, route) {
    let path = route;
    props.history.push(path);
}

export default function PathNavigation(props) {

    const theme = useTheme();

    const variantText = 'subtitle1'

    const arrayPath = props.arrayPath ? props.arrayPath : []


    return (
        <div style={theme.title}>
            <Grid container spacing={0} alignItems="center">
                <Grid item xs={4}>
                    <div style={theme.elementAdjacent}>
                        <FontAwesomeIcon icon={faHome} style={{ color: '#FFFFFF', fontSize: 18, marginTop: '5px', marginRight: '0.5%', cursor: 'pointer' }}
                            onClick={event => routeChange(props, '/home')} />

                        <Typography variant={variantText} >
                            /
        </Typography>

                        {arrayPath.map(path => (
                            <div key={path} style={theme.elementAdjacent}>
                                <Typography variant={variantText} style={{ cursor: 'pointer' }}
                                    onClick={event => routeChange(props, path.route)}> {path.text}</Typography>
                                <Typography variant={variantText} >/</Typography>
                            </div>
                        ))}
                        <Typography variant={variantText} >
                            {props.title}
                        </Typography>
                    </div>
                </Grid>

                    <FontAwesomeIcon icon={faHome} style={theme.titleIcon} 
                    onClick={event => routeChange(props, '/home')}/>
                    <FontAwesomeIcon icon={faChartPie} style={theme.titleIcon}
                    onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/governance`)}/>
                    <FontAwesomeIcon icon={faChartBar} style={theme.titleIcon}
                    onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/dashboard`)}/>
                    <FontAwesomeIcon icon={faPlayCircle} style={theme.titleIcon} 
                    onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/simulations`)}/>
                {(props.user && (props.user.role==='ADMIN' || props.user.role === 'SUPER_ADMIN' )) &&
                    <FontAwesomeIcon icon={faTools} style={theme.titleIcon} 
                    onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/configuration`)}/>
                }
                    <FontAwesomeIcon icon={faDatabase} style={theme.titleIcon}
                    onClick={event => routeChange(props, `/${process.env.REACT_APP_PREFIX}/datasets`)}/>




            </Grid>
        </div>


    )
}