import React from 'react';
import AppBarTemplate from './../../template/AppBarTemplate'
import Parallax from "./../../common/Parallax/Parallax.js";
import QualityConfiguration from './QualityConfiguration'
import * as pathHelper from './../../template/PathHelper'


import { useSelector, useDispatch } from 'react-redux'
import * as actions  from './../../../actions'



export default function QualityConfigurationPage(props) {

    

    const dispatch = useDispatch();
    const user = useSelector(store => store.user)

    function getUsers(){
        dispatch(actions.fetchUser()  )
    }

    React.useEffect(() => {
        getUsers()
    },[])

    return (
        <div >
            <AppBarTemplate history={props.history} title={'Configuration'}  arrayPath ={pathHelper.getPathArray('QualityConfiguration')} />
           
            <div style={{'padding':'2%'}}>
                <QualityConfiguration currentUser ={user}>
                    
                </QualityConfiguration>
            
            </div>
        </div>

    );
}