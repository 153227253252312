import React from 'react';
import AppBarTemplate from '../../../template/AppBarTemplate'
import Parallax from "../../../common/Parallax/Parallax.js";
import QualityDetailProjectToolBar from './QualityDetailProjectToolBar';
import QualityDetailProject from './QualityDetailProject';
import DialogSelectWorkItem from './QualityDetailProject/Dialogs/DialogSelectWorkItem'
import DialogCreateQualityPoint from './QualityDetailProject/Dialogs/DialogCreateQualityPoint'
import DialogCreateDatasource from './QualityDetailProject/Dialogs/DialogCreateDataSource'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'

import ConfirmDialog from './../../../common/ConfirmDialog'
import DialogCreateFolder from './QualityDetailProject/Dialogs/DialogCreateFolder';
import DialogCreateRule from './QualityDetailProject/Dialogs/DialogCreateRule'
import DialogSimulation from './QualityDetailProject/Dialogs/DialogSimulation'
import DialogCreateStreamSimulation from './QualityDetailProject/Dialogs/DialogCreateStreamSimulation'


import * as projectHelper from './QualityDetailProject/QualityProjectHelper'
import * as pathHelper from './../../../template/PathHelper'
import { FETCH_ERROR } from './../../../../actions/types'

import DialogRecommendedRules from './../../QualityRuleRecomPage/Dialogs/DialogRecommendedRules'
import DialogEditRecommendedRules from './../../QualityRuleRecomPage/Dialogs/DialogEditRecommendedRules'

import ReactGA from 'react-ga';

import trackEvent from './../../../../trackEvent'

import { AqtivaDialogDataRepository } from './../../DataRepository/EntityDataRepository/AqtivaDialogDataRepository'
import { AqtivaDialogDataRepositoryRefactor } from './../../DataRepositoryRefactor/EntityDataRepository/AqtivaDialogDataRepository'
import { TabsBar } from './../../DataRepository/Tabs/index'
import { TabsBarRefactor } from './../../DataRepositoryRefactor/Tabs/index'
import { faDatabase } from "@fortawesome/free-solid-svg-icons";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//ReactGA.pageview('quality/project/');

export default function QualityProjectPage(props) {




    const dispatch = useDispatch();
    var projects = useSelector(store => Object.values(store.projects))
    var qualityConfigurationSelector = useSelector(store => (store.qualityConfiguration))
    const allNotifications = useSelector(store => (store.allNotifications))
    const [qualityConfiguration, setQualityConfiguration] = React.useState({})

    const user = useSelector(store => store.user)



    const [openSelectWI, setOpenSelectWI] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState()
    const [selectedTypeItem, setSelectedTypeItem] = React.useState()
    const [openQualityPointDialog, setOpenQualityPointDialog] = React.useState(false)
    const [openDatasourceDialog, setOpenDatasourceDialog] = React.useState(false)
    const [openCreateFolderDialog, setOpenCreateFolderDialog] = React.useState(false)
    const [openCreateRuleDialog, setOpenCreateRuleDialog] = React.useState(false)
    const [openSimulationDialog, setOpenSimulationDialog] = React.useState(false)
    const [openSimulationDialogStream, setOpenSimulationDialogStream] = React.useState(false)
    const [openEditRecommendedRulesDialog, setOpenEditRecommendedRulesDialog] = React.useState(false)
    const [runId, setRunId] = React.useState()

    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState()
    const [selectedDatasource, setSelectedDatasource] = React.useState()
    const [selectedFolder, setSelectedFolder] = React.useState();


    const [title, setTitle] = React.useState('')
    const [openConfirmDialogDeleteQP, setConfirmDialogDeleteQP] = React.useState(false)
    const [openConfirmDialogDeleteDS, setConfirmDialogDeleteDS] = React.useState(false)
    const [openConfirmDialogDeleteFolder, setConfirmDialogDeleteFolder] = React.useState(false)
    const [openConfirmDeleteRule, setConfirmDeleteRule] = React.useState(false)
    const [showDialogRuleRecommender, setShowDialogRuleRecommender] = React.useState(false)

    const [searchText, setSearchText] = React.useState("")

    const [ruleRecommenderQPId, setRuleREcommenderQPId] = React.useState()

    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')

    const [openExportDialog, setOpenExportDialog] = React.useState(false)

    //const [ruleDTOId, setRuleDTOId] = React.useState()

    // we extract params from the path in case we're redirected from a notification
    const [qpId, setQpId] = React.useState((props.match && props.match.params && props.match.params.qpId) ? props.match.params.qpId : undefined)
    const [notificationId, setNotificationId] = React.useState((props.match && props.match.params && props.match.params.notificationid) ? props.match.params.notificationId : undefined)

    //const paramRuleDTOId = (props.match && props.match.params && props.match.params.ruleDTOId) ? props.match.params.ruleDTOId : undefined
    const [paramRecommenderShowNotification, setParamRecommenderShowNotification] =
        React.useState((props.match && props.match.params && props.match.params.recommenderShow) ? props.match.params.recommenderShow : undefined)
    const [recommenderShowNotification, setRecommenderShowNotification] = React.useState(paramRecommenderShowNotification == 'true')
    const [datasetFromParams, setDatasetFromParams] = React.useState((props.match && props.match.params && props.match.params.dataformat) ? props.match.params.dataformat : undefined)


    React.useEffect(() => {
        setQpId((props.match && props.match.params && props.match.params.qpId) ? props.match.params.qpId : undefined)
        setParamRecommenderShowNotification((props.match && props.match.params && props.match.params.recommenderShow) ? props.match.params.recommenderShow : undefined)
        setRecommenderShowNotification(((props.match && props.match.params && props.match.params.recommenderShow) ? props.match.params.recommenderShow : undefined) == 'true')
        const notificationId = (props.match && props.match.params && props.match.params.notificationId) ? props.match.params.notificationId : undefined

        if (notificationId) {
            setDatasetFromParams(allNotifications[notificationId] && allNotifications[notificationId].params ?
                allNotifications[notificationId].params.dataformat : undefined)
        }

    }, [props.match, allNotifications]);






    React.useEffect(() => {
        setQualityConfiguration(qualityConfigurationSelector)
    }, [qualityConfigurationSelector]);


    React.useEffect(() => {
        if (user && user.id) {
            dispatch(actions.fetchConfigurationByUserId(user.id))

        }

    }, [user]);

    React.useEffect(() => {

        if (qpId && recommenderShowNotification) {
            setProjectValuesFromNotification(qpId, recommenderShowNotification)
        }
    }, [qpId, recommenderShowNotification]);



    const confirmDialogDeleteQP = () => {
        const project = getProject(searchText)

        dispatch(actions.deleteQualityPipe(project._id, selectedItem._id))
        setConfirmDialogDeleteQP(false)

    }
    const cancelDialogDeleteQP = () => {
        setConfirmDialogDeleteQP(false)
    }

    const confirmDialogDeleteDatasource = () => {
        const project = getProject(searchText)
        const qualityPoint = projectHelper.findQualityPointFromDatasourceId(project, selectedItem._id)

        dispatch(actions.deleteDatasource(project._id, qualityPoint._id, selectedItem._id))
        setConfirmDialogDeleteDS(false)
        setSelectedItem(undefined)

    }
    const cancelDialogDeleteDatasource = () => {
        setConfirmDialogDeleteDS(false)
    }

    const confirmDialogDeleteFolder = () => {
        const project = getProject(searchText)

        dispatch(actions.deleteFolder(project._id, selectedItem.datasourceId, selectedItem.level, selectedItem.name, selectedItem.parentName))
        setConfirmDialogDeleteFolder(false)

    }
    const cancelDialogDeleteFolder = () => {
        setConfirmDialogDeleteFolder(false)
    }

    const confirmDialogDeleteRule = () => {
        const project = getProject(searchText)
        const ds = projectHelper.findDatasourceFromRuleId(project, selectedItem._id)
        const qp = projectHelper.findQualityPointFromRuleId(project, selectedItem._id)
        if (ds === null || qp === null) {
            //TODO ERROR
            console.error('error test')
        }
        else {

            dispatch(actions.deleteRule(project._id, qp._id, ds._id, selectedItem._id))
        }

        setConfirmDeleteRule(false)
    }
    const cancelDialogDeleteRule = () => {
        setConfirmDeleteRule(false)
    }


    React.useEffect(() => {
        if (user && user.id) {
            dispatch(actions.fetchProjects(user.id))
        }

    }, [user])


    React.useEffect(() => {
        dispatch(actions.fetchUser())
    }, [])

    React.useEffect(() => {

        if (projects) {
            let project = getProject(searchText)
            if (project) {
                setTitle(project.name)
            }


        }
        //  

    }, [projects]);

    function getProject(searchText) {
        var projectTmp = projects.filter(data => data._id === props.match.params.projectId)
        const originalProject = (projectTmp === undefined) ? undefined : projectTmp[0]
        var project = Object.assign({}, originalProject);
        if (project === undefined) {
            return undefined
        }
        if (searchText === "" || searchText === undefined) {
            return project
        }
        else {
            return project
        }
    }

    function setSelectedItemAndOpen(value, type) {

        setSelectedItem(value)
        setSelectedTypeItem(type)
        if (type === 'Qualitypoint') {
            //abrimos dialogo de quality point en modo edicion
            setOpenQualityPointDialog(true)
        }
        else if (type === 'Datasource') {
            //abrimos dialogo de quality point en modo edicion
            const selectedQualityPoint = projectHelper.findQualityPointFromDatasourceId(getProject(searchText), value._id)
            setSelectedQualityPoint(selectedQualityPoint)
            setOpenDatasourceDialog(true)
        }

        else if (type === 'Rule') {
            //abrimos dialogo de quality point en modo edicion
            const selectedQualityPoint = projectHelper.findQualityPointFromRuleId(getProject(searchText), value._id)
            const selectedDatasource = projectHelper.findDatasourceFromRuleId(getProject(searchText), value._id)
            setSelectedQualityPoint(selectedQualityPoint)
            setSelectedDatasource(selectedDatasource)
            setOpenCreateRuleDialog(true)
        }
        else if (type === 'Folder') {
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(getProject(searchText), value.datasourceId)
            const datasource = projectHelper.findDatasourceFromDatasourceId(getProject(searchText), value.datasourceId)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(datasource)
            setSelectedItem(value)
            setSelectedTypeItem(type)
            setOpenCreateFolderDialog(true)
        }
        else {
            dispatch({
                type: FETCH_ERROR,
                payload: `No Item found for type ${type}`
            });
        }


    }

    function deleteItem(value, type) {

        setSelectedItem(value)
        setSelectedTypeItem(type)
        if (type === 'Qualitypoint') {
            //abrimos dialogo de confirmación de borrado
            setConfirmDialogDeleteQP(true)
        }
        if (type === 'Datasource') {
            //abrimos dialogo de confirmación de borrado
            setConfirmDialogDeleteDS(true)
        }
        if (type === 'Folder') {
            //abrimos dialogo de confirmación de borrado
            setConfirmDialogDeleteFolder(true)

        }
        if (type === 'Rule') {
            //abrimos dialogo de confirmación de borrado
            setConfirmDeleteRule(true)
        }


    }



    function clearSelectedItem() {
        //setSelectedItem(undefined)
        //setSelectedTypeItem(undefined)
    }

    function editQualityPipe(projectId, qualityPoint) {
        dispatch(actions.editQualityPipe(projectId, qualityPoint))
    }

    function editDatasource(projectId, qualitypointId, datasource, datasourceId) {
        dispatch(actions.editDatasource(projectId, qualitypointId, datasource, datasourceId))
    }

    function editRule(projectId, qualitypointId, datasourceId, rule) {
        dispatch(actions.editRule(projectId, qualitypointId, datasourceId, rule))
    }

    const updateSearchText = (tx) => {
        setSearchText(tx)
    }
    function addDatasource(value, type) {
        setSelectedQualityPoint(value)
        setSelectedItem(undefined)
        setSelectedTypeItem(type)

        if (type === 'Qualitypoint') {
            //abrimos dialogo de quality point en modo edicion
            setOpenDatasourceDialog(true)
        }
    }

    function handleRuleRecommender(value, type) {
        setRuleREcommenderQPId(value._id)
        setShowDialogRuleRecommender(true)
    }

    function addFolder(value, type) {

        setSelectedItem(value)
        setSelectedTypeItem(type)
        setOpenCreateFolderDialog(true)
    }

    function addRule(value, type) {

        if (type === 'Datasource') {
            //abrimos dialogo de quality point en modo edicion
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(getProject(searchText), value._id)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateRuleDialog(true)
        }

        if (type === 'Folder') {
            const qualityPoint = projectHelper.findQualityPointFromDatasourceId(getProject(searchText), value.datasourceId)
            const datasource = projectHelper.findDatasourceFromDatasourceId(getProject(searchText), value.datasourceId)
            setSelectedQualityPoint(qualityPoint)
            setSelectedDatasource(datasource)
            setSelectedFolder(value)
            setSelectedItem(undefined)
            setSelectedTypeItem(type)
            setOpenCreateRuleDialog(true)
        }
    }

    function onClickOpenDialogSimulation(data) {
        trackEvent('Detail Project Page', 'Open Dialog Simulation');
        setSelectedQualityPoint(data)
        if (data && data.runningType !== 'streaming_lambda') {
            trackEvent('Detail Project Page', 'Open Dialog Simulation Batch');
            setOpenSimulationDialog(true)
        }
        else {
            trackEvent('Detail Project Page', 'Open Dialog Simulation Stream');
            setOpenSimulationDialogStream(true)
        }


    }

    function onClickSimulate() {

    }

    function onClickExport() {
        setOpenExportDialog(true)
    }
    function onClickCloseSimulation() {
        setOpenSimulationDialog(false)
    }

    function onClickCloseSimulationStream() {
        setOpenSimulationDialogStream(false)
    }


    function setOpenCreateRuleDialogCallback(event) {
        setSelectedQualityPoint(undefined)
        setSelectedDatasource(undefined)
        setSelectedItem(undefined)
        setOpenCreateRuleDialog(event)
    }




    function setOpenCreateFolderDialogCallback(open) {
        setSelectedQualityPoint(undefined)
        setSelectedDatasource(undefined)
        setSelectedItem(undefined)
        setOpenCreateFolderDialog(open)
    }

    function setOpenDatasourceDialogCallback(open) {
        setSelectedQualityPoint(undefined)
        setSelectedDatasource(undefined)
        setSelectedItem(undefined)
        setOpenDatasourceDialog(open)
    }

    function setOpenQualityPointDialogCallback(open) {
        setSelectedQualityPoint(undefined)
        setSelectedDatasource(undefined)
        setSelectedItem(undefined)
        setOpenQualityPointDialog(open)
    }

    // here we set the values extracted from the path to their corresponding fields.
    //this function is called in the third useEffect.
    function setProjectValuesFromNotification(qpId, recommenderShowNotification, paramRuleDTOId) {
        setShowDialogRuleRecommender(recommenderShowNotification)
        setRuleREcommenderQPId(qpId)
        //setRuleDTOId(paramRuleDTOId)
    }




    function addDialogs() {
        return (
            <div>
                {openExportDialog === true && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true' &&
                    <AqtivaDialogDataRepository
                        dialog={openExportDialog}
                        setDialog={setOpenExportDialog}
                        title="Data Repository"
                        titleIcon={faDatabase}
                        confirmCallback={(event) => console.log("I am clicking here")}

                    >
                        <TabsBar user={user} selectedProjectId={getProject(searchText)._id} />
                    </AqtivaDialogDataRepository>}

                {openExportDialog === true && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true' && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'false' &&
                    <AqtivaDialogDataRepositoryRefactor
                        dialog={openExportDialog}
                        setDialog={setOpenExportDialog}
                        title="Data Repository"
                        titleIcon={faDatabase}
                        confirmCallback={(event) => console.log("I am clicking here")}

                    >
                        <TabsBarRefactor user={user} selectedProjectId={getProject(searchText)._id} />
                    </AqtivaDialogDataRepositoryRefactor>}

                {showDialogRuleRecommender &&
                    <DialogRecommendedRules
                        open={showDialogRuleRecommender} setClose={setShowDialogRuleRecommender}
                        openEditRecommendedRulesDialog={setOpenEditRecommendedRulesDialog}
                        userId={user.id}
                        configuration={qualityConfiguration}
                        setRunId={setRunId}
                        projectId={getProject(searchText) ? getProject(searchText)._id : -1}
                        qpId={ruleRecommenderQPId}
                        project={getProject(searchText)}
                        recommenderShowNotification={recommenderShowNotification}
                        datasetFromParams={datasetFromParams}
                        history={props.history}
                    //ruleDTOId={ruleDTOId}
                    />}

                {openConfirmDialogDeleteQP && <ConfirmDialog
                    id='confirmDialogDelete'
                    confirmAction={confirmDialogDeleteQP}
                    cancelAction={cancelDialogDeleteQP}
                    title={`Confirm Delete Quality point ${selectedItem.name}`}
                    open={openConfirmDialogDeleteQP}
                />}

                {openConfirmDialogDeleteFolder && <ConfirmDialog
                    id='confirmDialogDeleteFolder'
                    confirmAction={confirmDialogDeleteFolder}
                    cancelAction={cancelDialogDeleteFolder}
                    title={`Confirm Delete Folder ${selectedItem.name}`}
                    open={openConfirmDialogDeleteFolder}
                />}

                {openConfirmDialogDeleteDS && <ConfirmDialog
                    id='confirmDialogDeleteFDS'
                    confirmAction={confirmDialogDeleteDatasource}
                    cancelAction={cancelDialogDeleteDatasource}
                    title={`Confirm Delete Datasource ${selectedItem.name}`}
                    open={openConfirmDialogDeleteDS}
                />}

                {openConfirmDeleteRule && <ConfirmDialog
                    id='confirmDialogDeleteRule'
                    confirmAction={confirmDialogDeleteRule}
                    cancelAction={cancelDialogDeleteRule}
                    title={`Confirm Delete Rule ${selectedItem.name}`}
                    open={openConfirmDeleteRule}
                />}
                <DialogSelectWorkItem open={openSelectWI} setClose={setOpenSelectWI}
                    openQPDialog={setOpenQualityPointDialog}
                    openDSDialog={setOpenDatasourceDialogCallback}
                    openRuleDialog={setOpenCreateRuleDialogCallback}
                />
                {openQualityPointDialog && <DialogCreateQualityPoint
                    configuration={qualityConfigurationSelector}
                    open={openQualityPointDialog} setClose={setOpenQualityPointDialogCallback} idProject={props.match.params.projectId}
                    qualityPoint={selectedItem} clearSelectedItem={clearSelectedItem} />}
                {openDatasourceDialog && <DialogCreateDatasource open={openDatasourceDialog}
                    setClose={setOpenDatasourceDialogCallback}
                    project={getProject(searchText)}
                    configuration={qualityConfigurationSelector}
                    datasource={selectedItem} qualityPoint={selectedQualityPoint} clearSelectedItem={clearSelectedItem} />}
                {openCreateFolderDialog && <DialogCreateFolder parent={selectedItem} parentType={selectedTypeItem} project={getProject(searchText)} datasource={selectedDatasource}
                    open={openCreateFolderDialog} setClose={setOpenCreateFolderDialogCallback} folder={selectedTypeItem === 'Folder' ? selectedItem : undefined}
                />}
                {openCreateRuleDialog && <DialogCreateRule rule={selectedItem} project={getProject(searchText)}
                    open={openCreateRuleDialog} setClose={setOpenCreateRuleDialog}
                    datasource={selectedDatasource}
                    qualityPoint={selectedQualityPoint}
                    folder={selectedFolder}
                />}
                {openSimulationDialogStream && <DialogCreateStreamSimulation
                    userId={user.id}
                    configuration={qualityConfigurationSelector}
                    history={props.history}
                    visible={openSimulationDialogStream}
                    setVisible={setOpenSimulationDialogStream}
                    onClickSimulate={onClickSimulate}
                    onClose={onClickCloseSimulationStream}
                    onHide={onClickCloseSimulationStream}
                    selectedQualityPoint={selectedQualityPoint}
                    project={getProject(searchText)}
                    currentUser={user}
                />}
                {openSimulationDialog && <DialogSimulation
                    userId={user.id}
                    configuration={qualityConfigurationSelector}
                    history={props.history}
                    visible={openSimulationDialog}
                    onClickSimulate={onClickSimulate}
                    onClose={onClickCloseSimulation}
                    onHide={onClickCloseSimulation}
                    selectedQualityPoint={selectedQualityPoint}
                    project={getProject(searchText)}
                    currentUser={user} />}
            </div>
        )
    }
    return (
        <div  >
            <AppBarTemplate history={props.history} title={` Project ${title}`} history={props.history} arrayPath={pathHelper.getPathArray('ProjectDetail')} />

            <div style={{ 'padding': '10px' }}>
                {addDialogs()}
                <div style={{ marginTop: '0px' }}>


                    <QualityDetailProjectToolBar openWIDialog={setOpenSelectWI}
                        openRuleRecommenderDialog={setShowDialogRuleRecommender}
                        updateText={updateSearchText}
                        qualityConfiguration={qualityConfiguration}
                        project={getProject(searchText) === undefined ? undefined : getProject(searchText)}
                        history={props.history}
                        setSelectedEnvironment={setSelectedEnvironment}
                        user={user}
                        onClickExport={onClickExport} />
                </div>
                <QualityDetailProject project={getProject(searchText) === undefined ? undefined : getProject(searchText)}
                    setSelectedItem={setSelectedItemAndOpen}
                    editQualityPipe={editQualityPipe}
                    editDatasource={editDatasource}
                    editRule={editRule}
                    addDatasource={addDatasource}
                    addFolder={addFolder}
                    addRule={addRule}
                    deleteItem={deleteItem}
                    onClickOpenDialogSimulation={onClickOpenDialogSimulation}
                    qualityConfiguration={qualityConfiguration}
                    handleRuleRecommender={handleRuleRecommender}
                    searchText={searchText}
                    selectedEnvironment={selectedEnvironment}
                />
            </div>
        </div>

    );
}