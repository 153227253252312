import React from "react";
import AqtivaDialog from "../../../../../../common/AqtivaDialog";
import { faHubspot } from "@fortawesome/free-brands-svg-icons";
import {  makeStyles } from "@material-ui/core"
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { styles, QualityTheme } from './../../../../../../../style/CustomTheme'

import TopicTable from './TopicTable'


export default function DialogViewDetailTopic(props) {
    const theme = useTheme();
    const dispatch = useDispatch()
    const classes = makeStyles(styles(QualityTheme));

    const configuration = useSelector(store => (store.qualityConfiguration))
    const user = useSelector((store) => store.user);

   
    return (
        <AqtivaDialog
            visible={props.visible}
            maxWidth={"lg"}
            title={"Stream Topic Inspection"}
            titleIcon={faHubspot}
            confirmText={"Simulate"}
            cancelText={"Close"}
            showConfirmButton={false}
            confirmCallback={(event) => console.log(event)}
            cancelCallback={(event) => props.close(false)}
        >
            <TopicTable
            endPoint = {props.endPoint}
            entityPath = {props.entityPath}
            sak = {props.sak}
            sakName = {props.sakName}
            />
        </AqtivaDialog>
    );
}
