import React from "react";
import { useInterval } from './../../../../components/common/useInterval'
import { lighten, makeStyles } from '@material-ui/core/styles';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTable from './EnhancedTable';
import LoadingComponent from './LoadingComponent';
import { Paper, CircularProgress, Grid,Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../actions'
import { invertArray, isEmpty } from './utils'
import { useLocation } from "react-router-dom"

import * as recommenderUtils from './../../../utils/utilsRecommender'

/* This table component is composed by three subcomponents:
-EnhancedTableHead where define columns names and we set display sort values
-EnhancedTableToolbar where the "delete column" and "go back" logic resides
-EnhancedTable, which is the main component where data is displayed and where the "delete rule" and pagination logic resides
*/


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  }
}));

export default function TableRecommendations(props) {
  const dispatch = useDispatch();
  const location = useLocation()

  let ruleRecommendation = useSelector(store => store.fetchJobOutput)

  const { configuration, runId, rules, setRules, recommenderShowNotification } = props;
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [histDisplay, setHistDisplay] = React.useState([])
  const [currentDisplay, setCurrentDisplay] = React.useState([])
  const [stopInterval, setStopInterval] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(undefined)

  React.useEffect(() => {
    if (recommenderShowNotification === true) {
      const ruleDTOFromNotification = location.state.rulesDTONotification
      const dataSetNotification = location.state.dataSet
      if (!isEmpty(ruleDTOFromNotification) && ruleDTOFromNotification) {

        let res = processResult(ruleDTOFromNotification, true)
        if(res===false){
          setStopInterval(true)
          if(ruleRecommendation && ruleRecommendation.data && ruleRecommendation.data.result){
            setErrorMessage(ruleRecommendation.data.result.toString())
          }
          setErrorMessage('An error occurred while executing the recommendation. Please try again')
          
        }
      }

    }
  }, [])


  useInterval( () => {
    if (stopInterval==false && recommenderShowNotification !== true && runId != undefined) {
    dispatch(
      actions.getJobRunOutput(runId, configuration.environmentConfigurationList[0].databricksInstance, 
        configuration.environmentConfigurationList[0].databricksToken)).then(ruleRecommendation=>{
      if (!isEmpty(ruleRecommendation) && ruleRecommendation && ruleRecommendation.data && ruleRecommendation.data.result != null && setStopInterval) {
        // try to parse result. if not , assume it is an error

        let res = processResult(ruleRecommendation)
        if(res===false){
          setStopInterval(true)
          setErrorMessage(ruleRecommendation.data.result.toString())

        }

      }
      if (ruleRecommendation && ruleRecommendation.data && ruleRecommendation.data.result != null) {
        setStopInterval(true)
      }
    }
      )
      
      
    }
  }
    //How often (ms) we check if rulerecommenderjob is done
    , 3000)

  function processResult(ruleRecommendation, complete) {
    try {
      
      let result = complete && complete ===true ? ruleRecommendation : ruleRecommendation.data.result.toString()
      result = result.replace(new RegExp("reg-exp", "g"), 'reg_exp')
      result = result.replaceAll('inf', '"inf"')
      result = JSON.parse(result)
      result = recommenderUtils.filterRecommenderData(result)
      // set currentDisplay and histdisplay
      let { columns, dataset } = result
      let modifiedData = histDisplay.slice()
      let datasetColumn = {
        column: 'COMPLETE_DATASET',
        rules: [
          { rule: 'schema_rule' }
        ]
      }
      columns.unshift(datasetColumn)

      modifiedData.push(datasetColumn)
      modifiedData.push(columns)
      modifiedData = modifiedData.filter(Boolean);
      setHistDisplay(modifiedData)
      setCurrentDisplay(columns)
      //set  rules array
      if (columns.length > 0) {
        let invertedValue = invertArray(columns)
        setRules(invertedValue)
      }
      return true
    }
    catch (error) {
     console.info("error processing json result")
     console.error(error)
    }
    return false
    
    
  }




  return (
    <div className={classes.root}>
      {errorMessage ===undefined && currentDisplay && currentDisplay.length > 0 && <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} selected={selected} setSelected={setSelected} histDisplay={histDisplay} setHistDisplay={setHistDisplay} currentDisplay={currentDisplay} setCurrentDisplay={setCurrentDisplay} rules={rules} setRules={setRules} />

        <EnhancedTable ruleRecommendation={ruleRecommendation} selected={selected} setSelected={setSelected} histDisplay={histDisplay} setHistDisplay={setHistDisplay} currentDisplay={currentDisplay} setCurrentDisplay={setCurrentDisplay} rules={rules} setRules={setRules} />
      </Paper>}
      {errorMessage ===undefined && currentDisplay && currentDisplay.length < 1 &&
        <LoadingComponent />
      }
      {errorMessage !== undefined && 
        <div>
          <Typography variant='subtitle2'> An error occurred in the recommender execution </Typography>
          <Typography variant='caption' style={{ color: '#ab2e27' }}> {errorMessage}</Typography>
        </div>
      }
    </div >
  );
}


