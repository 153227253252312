
import quality from '../apis/quality';
import qualityLocalHost from '../apis/qualityLocalHost';
import {
    EDIT_PROJECT,
    } from './types'
    import {axiosHeader,dispatchInfo, dispatchError} from '../components/common/axios/axiosHelper';



export const deleteRule = (idProject,idQualitypoint,idDatasource,idRule) => async dispatch => {
    var returnValue = {result:'undefined'}
    await quality.delete(`/rule/${idProject}/${idQualitypoint}/${idDatasource}/${idRule}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Rule deleted`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue = {result:'ko',error:error}
      });
      return returnValue

}


export const addRule = (idProject,idQualitypoint,idDatasource,rule)=> async dispatch => {
    var returnValue = {result:'undefined'}
    await quality.post(`/rule/${idProject}/${idQualitypoint}/${idDatasource}`, rule,{
        headers: axiosHeader()
      }).then(function (response) {
         dispatch( {
             type : EDIT_PROJECT,
             payload: response.data
         });
         dispatchInfo(dispatch,`Rule ${rule.name} created`)
        
         returnValue = {result:'ok'}
     })
     .catch(function (error) {
        dispatchError(dispatch,error)
         
         returnValue = {result:'ko',error:error}
       });
       return returnValue
 
 }


 
export const addRuleFormatDefaultDS = (idProject,idQualitypoint,idDatasource)=> async dispatch => {
    var returnValue = {result:'undefined'}
    await quality.post(`/rule/defaultStatistics/${idProject}/${idQualitypoint}/${idDatasource}`,{
        headers: axiosHeader()
      }).then(function (response) {
         dispatch( {
             type : EDIT_PROJECT,
             payload: response.data
         });
         returnValue = {result:'ok'}
     })
     .catch(function (error) {
        dispatchError(dispatch,error)
         
         returnValue = {result:'ko',error:error}
       });
       return returnValue

 }
 
 export const editRule = (idProject,idQualitypoint,idDatasource,rule)=> async dispatch => {

    var returnValue = {result:'undefined'}
    await quality.put(`/rule/${idProject}/${idQualitypoint}/${idDatasource}`, rule,{
        headers: axiosHeader()
      }).then(function (response) {
         dispatch( {
             type : EDIT_PROJECT,
             payload: response.data
         });
         dispatchInfo(dispatch,`Rule ${rule.name} modified`)
        
         returnValue = {result:'ok'}
     })
     .catch(function (error) {
        dispatchError(dispatch,error)
        
         returnValue = {result:'ko',error:error}
       });
       return returnValue

 }

 export const editRulesPriority = (idProject,idQualitypoint,idDatasource,rules)=> async dispatch => {

  var returnValue = {result:'undefined'}
  await quality.put(`/rule/priority/${idProject}/${idQualitypoint}/${idDatasource}`, rules,{
      headers: axiosHeader()
    }).then(function (response) {
       dispatch( {
           type : EDIT_PROJECT,
           payload: response.data
       });
       dispatchInfo(dispatch,`Rules priority modified`)
      
       returnValue = {result:'ok'}
   })
   .catch(function (error) {
      dispatchError(dispatch,error)
      
       returnValue = {result:'ko',error:error}
     });
     return returnValue

}
 
 
 export const inheritRulesFromDatasource = (idProject,qualityPointId,datasourceId)=> async dispatch => {
    var returnValue = {result:'undefined'}
    await quality.post(`/datasource/inherit/${idProject}/${qualityPointId}/${datasourceId}`,null,{
        headers: axiosHeader()
      }).then(function (response) {
         dispatch( {
             type : EDIT_PROJECT,
             payload: response.data
         });
         returnValue = {result:'ok'}
     })
     .catch(function (error) {
        dispatchError(dispatch,error)
         
         returnValue = {result:'ko',error:error}
       });
       return returnValue

 }
 

 

 export const resetDvdKpiRule = (projectId,ruleId,environment) => async (dispatch, getState) => {

    var returnValue = {}
    await quality.post(`/rule/kpi/reset/project/${projectId}/rule/${ruleId}/environment/${environment}`, null,{
      headers: axiosHeader()
    }).then(function (response) {
        dispatchInfo(dispatch,`Rule KPIs deleted`)
      returnValue = response.data
    })
      .catch(function (error) {
        dispatchError(dispatch, error)
  
        returnValue = { result: 'ko', error: error }
      });
  
  
    return returnValue
  }
  