
import React from 'react'
import {Button,Grid} from '@material-ui/core'
import {
    faDownload
  } from "@fortawesome/free-solid-svg-icons";
  import { useTheme } from '@material-ui/core/styles';
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ImportButton(props) {

    const theme = useTheme();

    return(
       <div>
           <Button  onClick = {props.onClick} style={theme.buttons.buttonGlow}
           startIcon={
            <FontAwesomeIcon
              icon={faDownload}
              style={{ fontSize: 'calc(8px + 0.4vw)' }}
              fontSize="small"
            />
          }>
               <div style={theme.common.text.buttonText}>
                     Data Repository
               </div>
              
                   
                </Button>
       </div>
    )

}