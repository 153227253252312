import React from 'react';
import {useSelector,useDispatch} from 'react-redux'

import {
    FormControl, Grid, Dialog, DialogTitle, Typography, DialogContent,Select,Input,MenuItem,Chip,
    DialogActions, Button, InputAdornment, LinearProgress, FormControlLabel, Switch,Icon,InputLabel
} from '@material-ui/core'

import CustomInput from "./../../../common/CustomInput/CustomInput.js";


import { useTheme } from '@material-ui/core/styles';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Link from '@material-ui/core/Link';


export default function DialogInfoEnvironmentPreparation(props) {


  
    const theme = useTheme();
    const dispatch = useDispatch()


    


    return (
        
        <Dialog
                aria-labelledby="create-license-dialog"
                open={props.visible}
                fullWidth={true}
                maxWidth={'md'}

            >
                <DialogTitle style={theme.dialogs.dialogTitle}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item>
                            <FontAwesomeIcon icon={faInfoCircle} style={theme.dialogs.dialogTitleAwesomeIcon} />

                        </Grid>
                        <Grid item >
                            <Typography  style={theme.dialogs.dialogTitleText}>
                                {`Environment Preparation Info`}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{marginRight:'5%'}}> 
                        <Typography variant='subtitle1'>Databricks Preparation</Typography>
                        <Typography variant='subtitle2' style={{marginLeft:'3%'}}>Access Token</Typography>
                        <div><Typography variant='caption'style={{marginLeft:'5%'}}>In order to Aqtiva to comunicate with Databricks a user access token needs to be generated</Typography>
                        </div>
                        <div><Typography variant='caption' style={{marginLeft:'5%'}}>It is recommended to create the token without time expiration limit to avoid Aqtiva communication issues in the future</Typography>
                        </div>
                        <Typography variant='subtitle2'style={{marginLeft:'3%'}}>Simulation Notebook</Typography>
                        <div><Typography variant='caption' style={{marginLeft:'5%'}}>In order to Aqtiva be able to simulate quality jobs in the cluster, the simulation notebook must be set at one location in the cluster</Typography>
                        </div>
                        <div><Typography variant='caption' style={{marginLeft:'5%'}}>The notebook can be downloaded from 
                        <Link href={process.env.REACT_APP_URL_DOWNLOAD_NOTEBOOK_SIMULATION}>
                            here
                        </Link>
                         </Typography>
                        </div>
                        <div><Typography variant='caption' style={{marginLeft:'5%'}}>Place the notebook at the desired location and set the location in the configuration property: Databricks Simulation Notebook Path </Typography>
                        </div>
                        <Typography variant='subtitle2'style={{marginLeft:'3%'}}>Automatic Deployment</Typography>
                        <div><Typography variant='caption' style={{marginLeft:'5%'}}>Aqtiva is able to automatically deploy quality validation notebooks preconfigured based on your Quality Points</Typography>
                        </div>
                        <div><Typography variant='caption' style={{marginLeft:'5%'}}>Set a parent folder where the notebooks will be deployed in the property: Databricks Deployment Notebook Path </Typography>
                        </div>
                        <div><Typography variant='caption' style={{marginLeft:'5%'}}>Aqtiva will deploy Quality Points with the structure projectId/QualityPoinNameNotebook </Typography>
                        </div>
                        <Typography variant='subtitle2'style={{marginLeft:'3%'}}>Spark Library</Typography>
                        <div><Typography variant='caption' style={{marginLeft:'5%'}}>Aqtiva needs to find Aqtiva Spark library in the cluster. It can be downloaded from  
                        <Link href={process.env.REACT_APP_URL_DOWNLOAD_SPARK_LIBRARY} >
                            here
                        </Link>
                        </Typography>
                        </div>

                        <Typography variant='subtitle1' style={{marginTop:'1%'}}>BlobStorage Preparation</Typography>
                        <Typography variant='subtitle2' style={{marginLeft:'3%'}}>Keys</Typography>
                        <div><Typography variant='caption'style={{marginLeft:'5%'}}>Aqtiva needs a SAS Access Key in order to use the Blob Storage from staging area to store samples files uploaded trough FrontEnd</Typography>
                        </div>
                        <div><Typography variant='caption'style={{marginLeft:'5%'}}>Aqtiva needs an Access Key for the Databricks notebooks to access data storaged in the Blob Storage</Typography>
                        </div>
                        <Typography variant='subtitle2' style={{marginLeft:'3%'}}>Mandatory Structured</Typography>
                        <div><Typography variant='caption'style={{marginLeft:'5%'}}>Aqtiva needs a container with the name notebook-base with the base code to generate deployments.</Typography>
                        </div>
                        <div><Typography variant='caption'style={{marginLeft:'5%'}}>Within the container it only needs the base notebook in the path databricks/AQTIVA_EXECUTION_NOTEBOOK_BASE.scala' that can be found 
                        <Link href={process.env.REACT_APP_URL_DOWNLOAD_NOTEBOOK_BASE}>
                            here
                        </Link>
                        </Typography>
                        </div>
                    </div>

                

                </DialogContent>
                <DialogActions>
                <div>
                   
                    
                    <Button id='CancelButton' variant='outlined'  style={theme.buttons.buttonCancel} 
                    onClick={event=>props.onClose()}>
                        Cancel
                    </Button>
                </div>   
                </DialogActions>
            </Dialog>


    );
}