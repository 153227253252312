import React from 'react'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopy'
import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import StarBorderIcon from '@material-ui/icons/StarBorder';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons'

import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'




const getTableRowActions = (rowData, editProject, deleteProject, copyProject, anchorEl, setAnchorEl, onCloseShareMenu, onShareForUser, license, currentUser) => {


  return (
    <div>

      <Grid>
        <IconButton size='small' onClick={event => copyProject(rowData)}>
          <CopyIcon />
        </IconButton>
        <IconButton size='small' onClick={event => onShareForUser(rowData, event)} >
          <ShareIcon />
        </IconButton>

        <IconButton size='small' onClick={event => deleteProject(rowData)}>
          <DeleteIcon />
        </IconButton>
        <IconButton size='small' onClick={event => editProject(rowData)}>
          <EditIcon />
        </IconButton>
      </Grid>
    </div>

  );
}


const getProjectIcon = (rowData) => {

  
    return (
      <div style={{display: 'flex',justifyContent: 'left' }}>
          <Tooltip title={(rowData.description && rowData.description !== null) ? rowData.description : ''} >
            <Link href={`/${process.env.REACT_APP_PREFIX}/project/${rowData._id}`} >
              {rowData.name}
            </Link>
          </Tooltip>
        </div>
    );
  
}

const getEnvironment = (rowData) => {

  if (rowData.environment === "AWS") {
    return (
      <div style={{display: 'flex',justifyContent: 'left' }}>
      <FontAwesomeIcon icon={faCloud} style={{ color: '#76b3d6',height: '18px', width: '18px' ,marginRight:'5px'}}/>
      Cloud
      </div>
    );
  }
  else if (rowData.environment === "Azure") {
    return (
      <div style={{display: 'flex',justifyContent: 'left' }}>
          <FontAwesomeIcon icon={faCloud} style={{ color: '#76b3d6',height: '18px', width: '18px' ,marginRight:'5px'}}/>
          Cloud
        </div>
    );
  }
  else if (rowData.environment === "On-premise") {
    return (
      <div style={{display: 'flex',justifyContent: 'left' }}>
          <StarBorderIcon style={{ color: '#76b3d6',height: '15px', width: '15px' ,marginRight:'5px'}} />
          {rowData.environment}
          </div>

    );
  }
  else {
    return (
      <div style={{display: 'flex',justifyContent: 'left' }}>
          <FontAwesomeIcon icon={faCloud} style={{ color: '#76b3d6',height: '18px', width: '18px' ,marginRight:'5px'}}/>
          <Typography>
            <Tooltip title={(rowData.description && rowData.description !== null) ? rowData.description : ''} >
              <Link href={`/${process.env.REACT_APP_PREFIX}/project/${rowData._id}`} variant="body2">
                {rowData.name}
              </Link>
            </Tooltip>
          </Typography>
      </div>
    );
  }
}

function getUserInfo(project, license) {

  let userId = project.userId
  if (!license || !license.users) {
    return userId
  }
  let user = license.users.filter(data => data.id === userId)
  if (user && user[0]) {
    return user[0].name
  }
  return userId
}

export function getColumns(editProject, deleteProject, copyProject, anchorEl, setAnchorEl, license, onCloseShareMenu, onShareForUser, currentUser) {
  return ([
    {
      title: 'Name', field: 'name', render: (rowData) => { return getProjectIcon(rowData) },
      cellStyle: {
        fontSize: '13px',
        fontWeight: 'bold',
        borderBottomStyle: 'solid',
        borderBottomColor: '#76b3d6',
        borderBottomWidth: '1px'
      }
    },
    { title: 'Environment', field: 'environment',render: (rowData) => { return getEnvironment(rowData) }},
    { title: 'Owner', field: 'userId', render: (rowData) => { return getUserInfo(rowData, license) } },
    {
      title: 'Actions', field: 'no_field_to_match', render: (rowData) => {
        return getTableRowActions(rowData, editProject, deleteProject, copyProject, anchorEl, setAnchorEl
          , onCloseShareMenu, onShareForUser, license, currentUser)
      }
    }
  ]
  )
}

export const customLocation = {
  body: {
    emptyDataSourceMessage: 'No hay datos que mostrar'
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar'
  },
  pagination: {
    labelRowsSelect: 'registros',
    labelDisplayedRows: '{from}-{to} de {count} regs.',
    firstTooltip: 'Primero',
    previousTooltip: 'Anterior',
    nextTooltip: 'Siguiente',
    lastTooltip: 'Ultimo'
  }

}