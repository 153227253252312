import React from 'react'
import {
    Grid, FormControl, InputLabel, NativeSelect, Typography, Select, Chip, InputAdornment, Icon, makeStyles, MenuItem,
    Card, CardContent, Input, Button
} from '@material-ui/core'
import CustomInput from "../../../../../../common/CustomInput/CustomInput.js";
import QualityItemSelector from './../../../../../../common/QualityItemSelector'
import AllOutIcon from '@material-ui/icons/AllOut';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import AdvanceConfigurationQuality from './../../../../../../common/AdvanceConfigurationQuality'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap } from '@fortawesome/free-solid-svg-icons'
import { faColumns } from '@fortawesome/free-solid-svg-icons'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'

import * as helper from './CreateRuleHelper'
import DialogCreateCombinedColumn from '../DialogCreateCombinedColumn/index.js';
import trackEvent from './../../../../../../../trackEvent'

import * as ruleUtils from './../../../../../../utils/utilsRuleCreation'

export function convertRuleTypes (ruleType){
    
    if(ruleType==='dvd'){
        return 'checkBetweenLoads'
    }
    if(ruleType==='dup'){
        return 'duplicated'
    }
    if(ruleType==='cell_based'){
        return 'ranges'
    }
    if(ruleType==='cell_based'){
        return 'ranges'
    }

    return ruleType
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14
    },
    elementAdjacent: {
        display: 'flex',
        justifyContent: 'left'
    },
    chipContainerRule: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function BasicRuleDataForm(props) {

    const classes = useStyles();


   


    /*
    * VARIABLES DE ESTADO
    */
    const [editable, setEditable] = React.useState(props.rule ? true : false)    
    const [name, setName] = React.useState(props.rule ? props.rule.name : (props.commonRule && props.commonRule.common ? props.commonRule.common.name : ''))
    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState(props.commonRule && props.commonRule.common ? props.commonRule.common.qualityPoint :props.qualityPoint)
    const [selectedDatasource, setSelectedDatasource] = React.useState(props.commonRule && props.commonRule.common ? props.commonRule.common.datasource :props.datasource)
    const selectedFolderFromDS = (selectedDatasource && props.rule && props.rule.folder) ? selectedDatasource.folders.filter(x => x.url === props.rule.folder.url) : {}
    const [selectedFolder, setSelectedFolder] = React.useState((selectedFolderFromDS && selectedFolderFromDS.length > 0) ? selectedFolderFromDS[0] : (props.folder ? props.folder : undefined))

    const [ruleType, setRuleType] = React.useState(editable ? convertRuleTypes(props.rule.type) : (props.commonRule && props.commonRule.common ? convertRuleTypes(props.commonRule.common.type): 'format'))
    const [selectedDimension, setSelectedDimension] = React.useState(editable ? props.rule.dimension : (props.commonRule && props.commonRule.common ? props.commonRule.common.dimension : helper.getDimensionFromRuleType('statistics')))

    const [thError, setThError] = React.useState(editable ? props.rule.errorTh : (props.commonRule  && props.commonRule.common? props.commonRule.common.thError : (props.datasource ? props.datasource.errorTh : {})))
    const [thWarning, setThWarning] = React.useState(editable ? props.rule.warningTh : (props.commonRule  && props.commonRule.common? props.commonRule.common.thWarning : (props.datasource ? props.datasource.warningTh : {})))
    
    const [wrongRegisterAction, setWrongRegisterAction] = React.useState(editable ? props.rule.wrongRegisterActionType : (props.commonRule  && props.commonRule.common && props.commonRule.common.exclude_wrong_regs_next_rule
         ? (props.commonRule.common.exclude_wrong_regs_next_rule.includes('discard') ? props.commonRule.common.exclude_wrong_regs_next_rule.slice(0, -7) : props.commonRule.common.exclude_wrong_regs_next_rule ): (props.datasource ? props.datasource.wrongRegisterActionType : '')))
    const [outputMode, setOutputMode] = React.useState(editable ? props.rule.wrongRegisterActionType : (props.commonRule  && props.commonRule.common &&
        props.commonRule.common.output_control? props.commonRule.common.output_control    : (props.datasource ? props.datasource.controlModeType : '')))
  



    const [selectedColumns, setSelectedColumns] = React.useState(editable ? getSingleColumns(props.rule.columns) : (props.commonRule  && props.commonRule.common ? getSingleColumnSelected(props.commonRule.common) : []))
    const [selectedCombinedColumns, setSelectedCombinedColumns] = React.useState(editable ? getCombinedColumns(props.rule.columns) : (props.commonRule  && props.commonRule.common ? getCombinedColumnSelected(props.commonRule.common) : []))
    const [source, setSource] = React.useState()
    const [errorTypes, setErrorTypes] = React.useState();
    const [errorMsgs, setErrorMsgs] = React.useState();
    const [hasErrors, setHasErrors] = React.useState();


    const [showDialogCreateCombinedColumn, setShowDialogCreateCombinedColumn] = React.useState(false)

    React.useEffect(() => {
        if(selectedDatasource){
            setThError(selectedDatasource ? selectedDatasource.errorTh : {})
            setThWarning(selectedDatasource ? selectedDatasource.warningTh : {})
            setWrongRegisterAction(selectedDatasource ? selectedDatasource.wrongRegisterActionType : '')
            setOutputMode(selectedDatasource? selectedDatasource.controlModeType : '')
        }
        

    }, [selectedDatasource]);

    React.useEffect(() => {
        if(selectedQualityPoint){
            setThError(selectedQualityPoint ? selectedQualityPoint.errorTh : {})
            setThWarning(selectedQualityPoint ? selectedQualityPoint.warningTh : {})
            setWrongRegisterAction(selectedQualityPoint ? selectedQualityPoint.wrongRegisterActionType : '')
            setOutputMode(selectedQualityPoint? selectedQualityPoint.controlModeType : '')
        }
        

    }, [selectedQualityPoint]);

    function getSingleColumnSelected(common){
        var returnColumns = []

        if(common && common.selected_columns ){
            returnColumns = getSingleColumns(common.selected_columns)
        }
        
        return returnColumns

    }
    function getCombinedColumnSelected(common){

        var returnColumns = []

        if(common && common.selected_columns ){
            returnColumns = getCombinedColumns(common.selected_columns)
        }

        return returnColumns
        
    }

    


    function getSingleColumns(columns){
        if(columns===null || columns===undefined){
            return []
        }
        return columns.filter(x=>x.cols.length===1)
    }
    function getCombinedColumns(columns){
        if(columns===null || columns===undefined){
            return []
        }
        return columns.filter(x=>x.cols.length>1)
    }
    

    React.useEffect(() => {
        
        createCommonRule()
    }, [name, selectedQualityPoint, selectedDatasource, selectedFolder, ruleType, selectedDimension, thError, thWarning, wrongRegisterAction, outputMode, selectedColumns,selectedCombinedColumns]);

    React.useEffect(() => {
        if(props.datasource){
            setThError(editable ? props.rule.errorTh : (props.datasource ? props.datasource.errorTh : {}))
            setThWarning(editable ? props.rule.warningTh : (props.datasource ? props.datasource.warningTh : {}))
            setWrongRegisterAction(editable ? props.rule.wrongRegisterActionType : (props.datasource ? props.datasource.wrongRegisterActionType : ''))
            setOutputMode(editable ? props.rule.controlModeType : (props.datasource ? props.datasource.controlModeType : ''))
        }
        

    }, [props.datasource]);



    /*
    * ERROR HANDLE FUNCTIONS
    */
    function isError(name) {
        if (!errorTypes) {
            return false
        }
        return errorTypes.includes(name)
    }

    function getErrorMessage(name) {
        var idx = errorTypes.indexOf(name);
        if (idx === -1) {
            return ''
        }
        else {
            return errorMsgs[idx]
        }
    }
    // actualizamos el source de columnas cada vez que se cambie el dataset
    React.useEffect(() => {

        if (selectedDatasource && selectedDatasource.dataset) {
            var sourceTmp = selectedDatasource.dataset.columns.slice()

            //buscamos si hay alguna columna selecionada de antelación y lo quitamos de source
            if(props.rule && props.rule.columns && props.rule.columns.length >0){
                for (var i = 0; i < sourceTmp.length; i++) {
                    for (var j = 0; j < getSingleColumns(props.rule.columns).length; j++) {
                        if (sourceTmp[i] && getSingleColumns(props.rule.columns)[j].cols[0] === sourceTmp[i].name) {
                            sourceTmp.splice(i, 1);
                            i--;
                        }
                    }
                }
            }            

            setSource(sourceTmp)
        }

    }, [selectedDatasource, props.rule]);

    function isEmpty (obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    function createCommonRule() {
        //TODO: poner prioridad
        //TODO: defiir bien type y subtype

        var selectedColumns_ = selectedColumns
        if(isEmpty(selectedColumns_) && props.commonRule && props.commonRule.common){
            selectedColumns_ = getSingleColumnSelected(props.commonRule.common)
        }
        var selectedCombinedColumns_ = selectedCombinedColumns
        if(isEmpty(selectedCombinedColumns_) && props.commonRule && props.commonRule.common){
            selectedCombinedColumns_ = getCombinedColumnSelected(props.commonRule.common)
        }


        const commonRule = helper.createCommonRule(name, 0, ruleType, ruleType, outputMode, wrongRegisterAction,
            selectedDimension, thError, thWarning, selectedColumns_.concat(selectedCombinedColumns_),selectedQualityPoint, selectedDatasource, selectedFolder)
        props.setCommonRule(commonRule)
        const { hasErrors, arrayErrorTypes, arrayErrorMsgs } = helper.validateCommonRule(commonRule)
        setHasErrors(hasErrors)
        setErrorTypes(arrayErrorTypes)
        setErrorMsgs(arrayErrorMsgs)

    }
    /*
    * ON CHANGE CALLBACKS
    */

    function onChangeQPCallback(event) {

        const qp = event.target.value

        setSelectedQualityPoint(qp)
        setThError(qp.errorTh)
        setThWarning(qp.warningTh )
        setWrongRegisterAction(qp.wrongRegisterActionType )
        setOutputMode(qp.controlModeType)

    }
    function onChangeDSCallback(event) {

        const ds = event.target.value

        setSelectedDatasource(ds)
        setThError(ds.errorTh)
        setThWarning(ds.warningTh )
        setWrongRegisterAction(ds.wrongRegisterActionType )
        setOutputMode(ds.controlModeType)

    }
    function onChangeFolderCallback(event) {

        setSelectedFolder(event.target.value)
    }
    const onChangeTextField = (event) => {

        setName(event.target.value)
    }
    const onChangeDimensionCallback = (event) => {

        setSelectedDimension(event.target.value)
    }

    

    const onChangeRuleTypeCallback = (event) => {
        setRuleType(event.target.value)
        setSelectedDimension(helper.getDimensionFromRuleType(event.target.value))
        props.setRuleType(event.target.value)
    }
    //function to update control elmenets
    function setControlObj(data) {
        setWrongRegisterAction(data.wrongRegisterActionType)
        setOutputMode(data.controlModeType)
    }

    function getControlObj() {
        return { wrongRegisterAction: wrongRegisterAction, outputMode: outputMode }


    }


    function handleSelectColumn(e) {

        for (var i = 0; i < source.length; i++) {
            for (var j = 0; j < e.target.value.length; j++) {
                if (e.target.value[j].position === source[i].position) {
                    source.splice(i, 1);
                    i--;
                }
            }
        }
        

        const tmpColumns = (e.target.value.map(x=>{
            if(x.hasOwnProperty('position')){
                var x2 = {
                    positionTmp: x.position,
                    id: Math.random().toString(36).substr(2, 9),
                    cols : [x.name],
                    joinOperation : 'null'
                    }
                    return x2
            }
            else{
                return x
            }
           
        }))

        setSelectedColumns(tmpColumns)
       
        setSource(source)

    }

    function compareByPosition( a, b ) {
        if ( a.position < b.position ){
          return -1;
        }
        if ( a.position > b.position ){
          return 1;
        }
        return 0;
      }

      function arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
      
        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.
      
        for (var i = 0; i < a.length; ++i) {
          if (a[i] !== b[i]) return false;
        }
        return true;
      }

    function handleCloseChipCallback(value) {
        var selectedColTemp = selectedColumns.slice()
        
        var selectedColTempCombined = selectedCombinedColumns.slice()
        for (var i = 0; i < selectedColumns.length; i++) {
            if (value.cols.length===1 && (value.cols[0] === selectedColumns[i].cols[0]))  {
                selectedColTemp.splice(i, 1);
                break
            }

        }


        for (var i = 0; i < selectedCombinedColumns.length; i++) {
            if(arraysEqual(value.cols, selectedCombinedColumns[i].cols)){
                selectedColTempCombined.splice(i, 1);
                break
            }

        }
        var sourceTemp = source;

        var originalFormatValue = undefined

        for (var i = 0; i < selectedDatasource.dataset.columns.length; i++) {
            if (value.cols.length === 1 && (value.cols[0] === selectedDatasource.dataset.columns[i].name)) {
                originalFormatValue = selectedDatasource.dataset.columns[i]
                break
            }

        }
        if(originalFormatValue){
            sourceTemp.push(originalFormatValue)
            sourceTemp.sort(compareByPosition)
            setSource(sourceTemp)

        }
        

        setSelectedColumns(selectedColTemp)
        setSelectedCombinedColumns(selectedColTempCombined)
        
    }


    function onAddCombinedColumn(column){
        if(column){
            var tmpColumns = Object.assign([], selectedCombinedColumns);
            tmpColumns.push(column)
            setSelectedCombinedColumns(tmpColumns)
        }
    }




    return (
        <div style={{ marginBottom: '2%' }}>
            <DialogCreateCombinedColumn visible={showDialogCreateCombinedColumn}
                selectedDatasource={selectedDatasource}
                ruleType={ruleType}
                onClose={event=>setShowDialogCreateCombinedColumn(false)}
                onAddCombinedColumn={onAddCombinedColumn} />
            <Grid container spacing={0}>
                <Grid item xs={3}>

                    <Card elevation={12}
                        style={{ width: '90%', minHeight: '100%' }}>

                        <CardContent style={{ marginBottom: '-10%' }}>
                            <div className={classes.elementAdjacent}>
                                <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#76b3d6', fontSize: 15 }} />
                                <Typography variant='subtitle2' style={{ marginLeft: '2%', marginBottom: '2%' }}> Rule Selection</Typography>
                            </div>

                        </CardContent>
                        <div style={{ marginLeft: '10%' }}>
                            <CustomInput
                                labelText="Rule Name"
                                id="ProjectName"
                                formControlProps={{
                                    fullWidth: false,

                                    style: { width: '80%' }
                                }}

                                inputProps={{
                                    type: "text",
                                    onChange: onChangeTextField,
                                    value: name,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon className="fas fa-signature" />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div style={{ marginLeft: '10%' }}>
                            {hasErrors && isError('name') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('name')}</Typography>}
                        </div>

                        <div style={{ marginLeft: '10%' }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="typeWarnTh-selection-helper">Rule Type</InputLabel>
                                <NativeSelect
                                    style={{
                                        width: '80%',
                                        fontSize: 14,

                                    }}
                                    value={ruleType}
                                    onChange={event => onChangeRuleTypeCallback(event)}
                                >
                                    {selectedQualityPoint===undefined || (selectedQualityPoint.runningType===undefined || selectedQualityPoint.runningType!=='streaming_lambda') ? ruleUtils.ruleTypesBatch.map(
                                        (event) => (<option key={event.value} value={event.value}>
                                        {event.label}
                                    </option>))
                                    :
                                    ruleUtils.ruleTypesLambda.map(
                                        (event) => (<option key={event.value} value={event.value}>
                                        {event.label}
                                    </option>))
                                    }
                                </NativeSelect>
                            </FormControl>
                            <div style={{ marginLeft: '10%' }}>
                                {hasErrors && isError('ruleType') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('ruleType')}</Typography>}
                            </div>
                        </div>
                        <div style={{ marginLeft: '10%' }}>
                            <FormControl className={classes.formControl}>

                                <Typography variant='caption'>Quality Dimension</Typography>
                                <Select
                                    style={{ width: '80%' }}
                                    value={selectedDimension}
                                    onChange={onChangeDimensionCallback}
                                    inputProps={{
                                        name: 'Rule Dimension',
                                        id: 'dimension-selection',

                                    }}
                                    
                                >
                                    <MenuItem key='integrity' value='integrity'><div className={classes.elementAdjacent}>
                                        <CompareArrowsIcon style={{ fontSize: '18px', color: '#4697c4' }} />
                                        <Typography variant='body2'>Integrity</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='veracity' value='veracity'><div className={classes.elementAdjacent}>
                                        <CheckCircleOutlineIcon style={{ fontSize: '18px', color: '#4697c4' }} />
                                        <Typography variant='body2'>Veracity</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='unicity' value='unicity'><div className={classes.elementAdjacent}>
                                        <AllOutIcon style={{ fontSize: '18px', color: '#4697c4' }} />
                                        <Typography variant='body2'>Unicity</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='timeliness' value='timeliness'><div className={classes.elementAdjacent}>
                                        <AvTimerIcon style={{ fontSize: '18px', color: '#4697c4' }} />
                                        <Typography variant='body2'>Timeliness</Typography></div>
                                    </MenuItem>
                                    <MenuItem key='format' value='format'><div className={classes.elementAdjacent}>
                                        <AvTimerIcon style={{ fontSize: '18px', color: '#4697c4' }} />
                                        <Typography variant='body2'>Format</Typography></div>
                                    </MenuItem>

                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginLeft: '10%' }}>
                            {hasErrors && isError('dimension') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('dimension')}</Typography>}
                        </div>
                    </Card>


                </Grid>
                <Grid item xs={3} >
                    <Card elevation={12}
                        style={{ width: '90%', minHeight: '100%' }}>

                        <CardContent style={{ marginBottom: '-10%' }}>
                            <div className={classes.elementAdjacent}>
                                <FontAwesomeIcon icon={faSitemap} style={{ color: '#76b3d6', fontSize: 15 }} />
                                <Typography variant='subtitle2' style={{ marginLeft: '2%', marginBottom: '2%' }}> Hierarchy Selection</Typography>
                            </div>

                        </CardContent>
                        <div style={{ marginLeft: '10%' }}>
                            <QualityItemSelector
                                title={'Select a Quality Point'} type='QualityPoint' value={selectedQualityPoint} onChange={onChangeQPCallback}
                                itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints : [])}
                                itemId={'_id'} itemValue={'name'}
                            />
                            <div>
                                {hasErrors && isError('selectedQualityPoint') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('selectedQualityPoint')}</Typography>}
                            </div>
                        </div>

                        <div style={{ marginLeft: '10%' }}>
                            <QualityItemSelector
                                title={'Select a Datasource'} type='Datasource' value={selectedDatasource} onChange={onChangeDSCallback}
                                itemsArray={((selectedQualityPoint && selectedQualityPoint.datasources) ? selectedQualityPoint.datasources : [])}
                                itemId={'_id'} itemValue={'name'}
                            />
                            <div >
                                {hasErrors && isError('selectedDatasource') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('selectedDatasource')}</Typography>}
                            </div>
                        </div>

                        <div style={{ marginLeft: '10%' }}>
                            <QualityItemSelector
                                title={'Select a Folder'} type='Folder' value={selectedFolder} onChange={onChangeFolderCallback}
                                itemsArray={((selectedDatasource && selectedDatasource.folders
                                ) ? helper.generateFolderArray(selectedDatasource.folders) : [])}
                                itemId={'uri'} itemValue={'name'}
                            />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={6} >
                    <Card elevation={12}
                        style={{ width: '90%', minHeight: '100%' ,overflowY: 'scroll',flexGrow: 1 }}>

                        <CardContent style={{ marginBottom: '-10%', }}>
                        <div >
                            <div className={classes.elementAdjacent}>
                                <FontAwesomeIcon icon={faColumns} style={{ color: '#76b3d6', fontSize: 15 }} />
                                <Typography variant='subtitle2' style={{ marginLeft: '2%' }}> Columns Selection</Typography>
                            </div>
                            <div>
                                <Typography variant='body2' style={{ marginTop: '2%' }}> Available Columns</Typography>
                                <Select
                                    multiple
                                    disabled={helper.isSelectedColumnDisabled(ruleType,selectedColumns)}
                                    style={{ width: '380px' }}
                                    value={selectedColumns}
                                    onChange={handleSelectColumn}
                                    input={<Input id="select-multiple-checkbox" />}

                                >
                                    {selectedDatasource && selectedDatasource.dataset && source && helper.getSourceForRule(ruleType, source).sort(helper.compareColumns).map(e => (
                                        <MenuItem key={e.position} value={e} >
                                            {`#${e.position} (${e.type}) ${e.name}`}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </div>
                            {!helper.isSelectedColumnDisabled(ruleType) &&
                                <div style={{ marginTop: '5%' }}>
                                    <Button id='CancelButton' variant='outlined' color='primary'
                                        onClick={event => { 
                                            trackEvent('CreateRuleDialog','Add Combined Column');
                                            setShowDialogCreateCombinedColumn(true) }}>
                                        Add Combined Column
                                                </Button>
                                </div>
                            }

                            <div>
                                <Typography variant='caption' > {helper.isSelectedColumnDisabledMessage(ruleType, source)}</Typography>
                            </div>
                            <Typography variant='body2' style={{ marginTop: '5%' }}> Selected Columns</Typography>
                            <div className={classes.chipContainerRule}>

                                {selectedColumns && selectedColumns.concat(selectedCombinedColumns).map(e => {
                                    return (
                                        <div>
                                            <Chip
                                                label={e.cols.join(',')}
                                                key={e.id}
                                                onDelete={event => handleCloseChipCallback(e)}

                                            />
                                        </div>
                                    )

                                }

                                )}
                            </div>
                            <div style={{ marginLeft: '10%' }}>
                                {hasErrors && isError('selectedColumns') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('selectedColumns')}</Typography>}
                            </div>
                            
                        </div>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>

            <div style={{ marginTop: '1%', width: '95%' }}>
                <AdvanceConfigurationQuality
                    setThError={setThError} setThWarning={setThWarning} setControlObj={setControlObj}
                    thError={thError}
                    thWarning={thWarning}
                    controlObj={getControlObj()} />
            </div>
        </div>
    )
}