import React from 'react';
import AppBarTemplate from './../../template/AppBarTemplate'
import Parallax from "./../../common/Parallax/Parallax.js";
import * as pathHelper from './../../template/PathHelper'
import LicenseTable from './LicenseTable'
import DialogCreateLicense from './DialogCreateLicense';
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { useTheme } from '@material-ui/core/styles';


import trackEvent from './../../../trackEvent'



export default function SuperAdminLicenseManagementPage(props) {

    
    const theme = useTheme();


    const [visibleDialogCreateLicense, setVisibleDialogCreateLicense] = React.useState(false)
    const [update,setUpdate] = React.useState(0)
   

    
    return (
        <div >
            <AppBarTemplate history={props.history} title={'Management'}  arrayPath ={pathHelper.getPathArray('Management')} />
            <Button  onClick = {event => {setVisibleDialogCreateLicense(true); trackEvent('Management', 'Create License Dialog')}} style={theme.buttons.buttonRegular}
                startIcon={<AddIcon />} 
                 >
                    New License
                        
                </Button>
            <LicenseTable update = {update}/>
            <DialogCreateLicense visible={visibleDialogCreateLicense} 
            onClose={event =>{setVisibleDialogCreateLicense(false); setUpdate(update+1)}}/>
           
        </div>

    );
}