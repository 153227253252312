import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import Link from "@material-ui/core/Link";
import trackEvent from './../../../../../trackEvent'
 
//Este componente gestiona el renderizado de iconos y nombres de archivos y carpeta en función del tipo de estos.
export function CellTemplate(
  node,
  iconType1,
  iconType2,
  onClickOpenDialogSimulation,
  fullProjectView
) { 
  const icon =
    node.data.type === "Folder"
      ? { type: iconType1, color: "#4697c4" }
      : { type: iconType2, color: "#474b51" };

  const onClickOpenDialog = onClickOpenDialogSimulation
    ? onClickOpenDialogSimulation
    : () => {};
  if (fullProjectView === true && node.data.type !== "Folder") {
    return (
      <>
        {<FontAwesomeIcon
          icon={icon.type}
          onClick={(event) => {onClickOpenDialog(node.data, event);  trackEvent('DataRepository','Cell Template Open Dialog Folder')}}
          style={{ color: icon.color, marginRight: "5px", fontSize: "15px", paddingBottom:"1px" }}
        />}
        <span>
          {/* <Link href={`/aqtiva/project/${node.data.projectId}`}> */}
            
            {node.data.name.length > 22 ? node.data.name.substr(0, 20) + "..." : node.data.name}
     {/*      </Link> */}
        </span>
      </>
    );
  } else {
    return (
      <>
        <FontAwesomeIcon
          icon={icon.type}
          onClick={(event) => {onClickOpenDialog(node.data, event);  trackEvent('DataRepository','Cell Template Open Dialog Other Folder')}}
          style={{ color: icon.color, marginRight: "5px", fontSize: "15px" }}
        />
        <span>{node.data.name}</span>
      </>
    );
  }
}
