import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Toolbar, Typography, IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import RestoreIcon from '@material-ui/icons/Restore'
import { lighten, makeStyles } from '@material-ui/core/styles';
import { invertArray } from './../utils'





function EnhancedTableToolbar(props) {


  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }));

  const classes = useToolbarStyles();
  const { numSelected, selected, setSelected, histDisplay, setHistDisplay, currentDisplay, setCurrentDisplay, rules, setRules } = props;

  // delete all selected columns from the recommended rules object
  const handleDeleteSelected = (selected) => () => {
    const newState = JSON.parse(JSON.stringify(currentDisplay))
    for (let i = 0; i < newState.length; i++) {
      for (let j = 0; j < selected.length; j++) {
        if (newState[i].column === selected[j]) {
          newState.splice(i, 1)
        }
      }
    }
    let newData = JSON.parse(JSON.stringify(histDisplay))
    newData.push(newState)
    setCurrentDisplay(newState)
    setHistDisplay(newData)
    setSelected([])
    let inverted = invertArray(newState)
    setRules(inverted)
  }

  //revert changes to the recommended rules object by switching to the previous state and deleting the last one
  function handleGoBack() {
    var tmpHistDisplay = histDisplay.slice()

    const index = tmpHistDisplay.length - 2
    setHistDisplay(tmpHistDisplay)
    if (index > 0) {
      setCurrentDisplay(tmpHistDisplay[index])
      tmpHistDisplay.pop()
      setHistDisplay(tmpHistDisplay)
      let inverted = invertArray(tmpHistDisplay[index])
      setRules(inverted)
    }
    else {
      setCurrentDisplay(histDisplay[0])
      var tmpHist = []
      tmpHist.push(histDisplay[0])
      setHistDisplay(tmpHist)
      // updated new rules Array
      let inverted = invertArray(histDisplay[0])
      setRules(inverted)
    }
  }


  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Recommended quality rules
          </Typography>
        )}
      <Tooltip title="Go Back">
        <IconButton aria-label="Go Back">
          <span>
            <RestoreIcon onClick={handleGoBack} />
          </span>
        </IconButton>
      </Tooltip>
      {numSelected > 0 ? (
        <Tooltip title="Delete selected columns">
          <IconButton aria-label="delete">
            <span>
              <DeleteIcon onClick={handleDeleteSelected(selected)} />
            </span>
          </IconButton>
        </Tooltip>
      ) : (
          <Tooltip title="No column selected">
            <span>
              <IconButton disabled aria-label="No column selected">
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
    </Toolbar>
  );
};



EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};




export default EnhancedTableToolbar