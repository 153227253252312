import React from 'react';
import {
    AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer,Area, Brush, BarChart, Bar
} from 'recharts';


export default function ChartBarsRules(props) {

  const processData=(data)=>{
    if(data && data.length>0){

      const result = data.reduce((acc, d) => {
        const found = acc.find(a => a.ruleId === d.ruleId);
        //const value = { name: d.name, val: d.value };
        if (!found) {
          //acc.push(...value);
          acc.push(d) // not found, so need to add data property
        }
        else {
              found.numIn=found.numIn+d.numIn 
              found.numOut=found.numOut+d.numOut 
              found.numOk=found.numOk+d.numOk 
              found.numKo=found.numKo+d.numKo
        }
        return acc;
      }, []);
      return result
    }
    else{
      return data
    }
  }


     
    return (
        <div style={{height:props.height}}>
            {processData(props.data) &&
                <ResponsiveContainer width='100%'>
                    <BarChart
                            width={500}
                            height={400}
                            data={processData(props.data)} 
                            margin={{
                            top: 0, right: 0, left: 0, bottom: 30,
                            }}
                            barSize={30}
                        >

                    <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="ruleName" tick={{fontSize: 9}} angle={-25} textAnchor="end" interval={0} width={20}/>
                            <YAxis tick={{fontSize: 9}} />
                            <Tooltip />
                            <Bar type="monotone" dataKey="numOk" stackId="1" stroke="#108521" fill="#108521AA" >
                                <Tooltip/>
                            </Bar>
                            <Bar type="monotone" dataKey="numKo" stackId="1" stroke="#F3410D" fill="#F3410DAA" >
                            <Tooltip/>
                            </Bar>
                            
                        </BarChart>
                            
                </ResponsiveContainer>}

        </div>
    )
}
