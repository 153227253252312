import React from 'react';
import MaterialTable from 'material-table'
import * as actions  from './../../../actions'
import {useSelector,useDispatch} from 'react-redux'


export default function LicenseTable(props) {

    const dispatch = useDispatch();
    const [data, setData] = React.useState([])


    const getLicenses = async () => {

    const data =await dispatch(actions.fetchAll())
    
    return data
    }

    React.useEffect(() => {
        getLicenses().then(response=>setData(response))
        
    }, []);


    React.useEffect(() => {
        getLicenses().then(response=>setData(response))
        
    }, props.update);

    const columns = [
        {title: 'Name', field: 'licenseName'},
        {title: 'Main User', render: rowData => ((rowData.userId && rowData.users) ?  rowData.users.find(x=>x.id===rowData.userId) ?
        rowData.users.find(x=>x.id===rowData.userId).email :'' : '')},
        {title: 'Api Key', field: 'apiKey'},
        {title: 'Init Date', field: 'initDate' },
        {title: 'End Date', field: 'endDate' },
        {title: 'Num Max Users', field: 'numMaxUsers' },
        {title: 'Added Users',  render: rowData => (rowData.users ?  rowData.users.length : 0)},
        {title: 'Lambda Activated',  render: rowData => (rowData.lambdaActive ?  rowData.lambdaActive : false)}
        
    ]

    return (
        <MaterialTable
                        title = {' '}
                        columns = {columns}
                        data={data}
                        options={{
                            filtering: false,
                            exportButton: false,  
                            search: true,
                            toolbar: false,
                            grouping: false,
                            cellStyle:{
                                fontSize:'12px',
                                padding:'5px'
                            },
                        }}
                    />


    );
}