import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { QualityTheme,styles } from './../../../style/CustomTheme';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from  '@material-ui/core/TextField';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import SearchIcon from  '@material-ui/icons/Search';

import { connect } from 'react-redux';
import { fetchDataFormats,deleteUnusedDataformats,fetchUser } from '../../../actions';
import {
    withRouter
} from 'react-router-dom'

import DatasetViewDialog from './DatasetViewDialog'

class QualityDatasetRepository extends Component {

    constructor(props) {
        super(props);

        

        this.state = {
            showDialogDatasetDetail:false,
            selectedDataset:undefined,
            selected : []
        }

        
    }
    componentDidMount (){


        this.props.fetchUser()

        if(!this.isEmpty(this.props.currentUser)){
            this.props.fetchDataFormats(this.props.currentUser)
        }
        
        
    }

    componentDidUpdate(oldProps){
       if(this.isEmpty(oldProps.currentUser) && !this.isEmpty(this.props.currentUser)){
            this.props.fetchDataFormats(this.props.currentUser)
        }
        
    }

    isEmpty = (obj) =>{
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    clean() {
        this.setState({
            selected : []
        })
    }

    pad(n) {
        return n<10 ? '0'+n : n
    }


    onDeleteUnused = () =>{
        this.props.deleteUnusedDataformats(this.props.currentUser)
    }

    onClose = ()=>{
        this.props.onHide();
        this.clean();
    }

    
    

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = this.props.dataformats.map(n => n._id);
          this.setState({selected:newSelecteds});
          return;
        }
        this.setState({selected:[]});
      }

    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
      }

      handleClick(event, id) {
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(this.state.selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
          newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1),
          );
        }
        this.setState({selected: newSelected})
      }

      compareDatasets=( a, b )=> {
        if ( a.creationDate > b.creationDate ){
          return -1;
        }
        if ( a.creationDate < b.creationDate ){
          return 1;
        }
        return 0;
      }

      onCloseDetailDialog = () =>{
        this.setState({showDialogDatasetDetail:false})
      }

     isItemSelected=(id) =>{
         return (this.state.selected.indexOf(id) !== -1)
        };

        viewDatasetDetails=(id)=>{
            
            this.setState({selectedDataset: (this.props.dataformats ? this.props.dataformats.find(x=>x._id === id) : undefined),showDialogDatasetDetail:true})
        }
    render() {
        const { classes } = this.props;
       
        return (
            <MuiThemeProvider theme={QualityTheme}>
                <DatasetViewDialog show={this.state.showDialogDatasetDetail} 
                dataset={this.state.selectedDataset}
                onClose = {this.onCloseDetailDialog}/>
                
                        <Grid container spacing={0} style={{ width: '1000px' }}>
                            
                        </Grid>
                        
                  
                        <Table className={classes.table}  style={{width:'900px'}}>
                            <TableHead>
                                <TableRow>
                                <TableCell padding="checkbox"> 
                                <Checkbox onClick={this.handleSelectAllClick} color='primary' />
                                </TableCell>   
                                    <TableCell align="left">Timestamp</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Number of Columns</TableCell>
                                    <TableCell align="left"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.props.dataformats && !this.isEmpty(this.props.dataformats) &&
                             this.props.dataformats.sort(this.compareDatasets).map(row => (
                                <TableRow key={row._id}
                                hover
                                 onClick={event => this.handleClick(event, row._id)}>
                                <TableCell padding="checkbox">
                                    <Checkbox checked={this.isItemSelected(row._id)} color='primary' />
                                 </TableCell>
                                    <TableCell component="th" scope="row" align="left">
                                        {row.creationDate ? row.creationDate.substring(0,16) : ''}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{ row.columns ? row.columns.length : 0}</TableCell>
                                    <TableCell padding="checkbox">
                                    <Fab size="small" color="primary" aria-label="Detail" className={classes.fab}
                                    onClick={() => { this.viewDatasetDetails(row._id) }}
                                    >
                                        <SearchIcon    />
                                    </Fab>
                                 </TableCell>
                                
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                   
                    <Button   variant="contained" style={{minWidth:'20%'}} 
                        onClick={this.onDeleteUnused} color="primary">
                            Delete Unused
                        </Button>
                        
                       
                      
               
            </MuiThemeProvider>
        );
    }
}
QualityDatasetRepository.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        dataformats: Object.values(state.dataformats),
        currentUser: (state.user)

    };
}


export default withStyles(styles)(withRouter((connect(mapStateToProps, { fetchUser,fetchDataFormats,deleteUnusedDataformats  })(QualityDatasetRepository))));