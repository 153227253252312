import React from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';


export default function DialogCreateDataSource(props) {
    return(
        <div>
            <DataTable value={props.dataformat.columns}
                        editable={false}
                        style={{algin:'center',width:'100%'}}
                        scrollable={true} scrollHeight="200px">
                            <Column field="position" header="Position"
                            style={{
                                background:'transparent',
                                borderColor: 'transparent', 
                                fontSize:'12px',
                                textAlign: 'right',
                                width:'20%'
                                }}  />
                            <Column field="name" header="Name"
                            style={{
                                background:'transparent',
                                borderColor: 'transparent', 
                                fontSize:'12px',
                                textAlign: 'right',
                                width:'40%'
                                }}  />
                            <Column field="type" header="Format"
                            style={{
                                background:'transparent',
                                borderColor: 'transparent', 
                                fontSize:'12px',
                                textAlign: 'right',
                                width:'40%'
                                }}  />
                            
                        </DataTable>
        </div>
    )
}
