import React, { Component } from 'react';
import { ACCESS_TOKEN,USER } from './../../Constants';
import { Redirect } from 'react-router-dom'
import quality from '../../apis/qualityLogin';
import {axiosHeaderToken} from './../common/axios/axiosHelper';
import {
    withRouter
  } from 'react-router-dom'

class OAuth2RedirectHandler extends Component {
    
  
    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    

     render() {  
        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');
        
        if(token) {
            
            localStorage.setItem(ACCESS_TOKEN, token);
           
            
            //obtenemos la información de usuario
            
              quality.get('/user/me',{
                headers: axiosHeaderToken(token)
              }).then(response => {
                  localStorage.setItem(USER, JSON.stringify(response.data))
                  this.props.setAuthenticated(true)
                    this.props.history.push('/home')
                  }
                  )
              .catch(error=>{ console.log(error)})
            
            
              return  <Redirect to={{
                pathname: "/home",
                state: { from: this.props.location }
            }}/>; ; 
            
        } else {
            localStorage.setItem(ACCESS_TOKEN, undefined);
            return <Redirect to={{
                pathname: "/login",
                state: { 
                    from: this.props.location,
                    error: error 
                }
            }}/>; 
        }
    }
}

export default withRouter(OAuth2RedirectHandler);