import React from 'react'
import {Button,LinearProgress, Typography} from '@material-ui/core'
import { BlobServiceClient } from '@azure/storage-blob';

import { useTheme } from '@material-ui/core/styles';

import * as BlobStorageService from './../services/BlobStorageService'

import trackEvent from './../../../../trackEvent'


export default function InputFile(props){
  const theme = useTheme();


    var inputFileRef = React.useRef(null);

    const [uploading, setUploading] = React.useState(false)
    const [size, setSize] = React.useState(0)

    function uploadFiles (files, props) {
      
        files && files.length >0 && uploadFile(files[0],props);
    }
    

    const showFileDialog = () =>{
      inputFileRef.current && inputFileRef.current.click()

    }
     



    

    function buildConnectionString (options)  {
      
        return (
          `BlobEndpoint=${options.storageUri};` +
          `SharedAccessSignature=${options.storageAccessToken}`
        );
      };

     function buildClient(options) {
        return BlobServiceClient.fromConnectionString(
          buildConnectionString(options)
        );
      }

    


    

     function uploadFile( file, props) {
       
       setSize(file.size/1024/1024)
          setUploading(true)
          BlobStorageService.uploadToBlobStorage(file, props.folder,
            props.storageAccountName,props.containerName,props.sasToken,
            {
              blobHTTPHeaders: {
                blobContentType: file.type
              }
            }).then(response=>{
              setUploading(false)
                props.onUpload(response)
            })
           
        
      }

     
    return(
        <div>
      <input
        style={{ display: 'none' }}
        accept=".csv"
        ref={inputFileRef}
        type="file"
        multiple={false}
        onChange={e => uploadFiles(e.target.files,props)}
        onClick={(event)=> { 
          event.target.value = null
     }}
      />
      {uploading && 
      <div>
        <LinearProgress  style={{width:'65%'}}/>
        {size<20 && <Typography style={{width:'65%'}} variant={'subtitle2'}> {`Uploading File with size ${Math.round(size * 100) /100} MB`} </Typography>}
        {size>=20 && <Typography style={{width:'65%'}} variant={'subtitle2'}> {`Uploading File with size ${Math.round(size * 100) /100 } MB. It may take some time depending on your internet connection`} </Typography>}
      </div>
        }
      <Button variant='outlined' style={theme.buttons.buttonRegular} onClick={() => {trackEvent('InputFile','Upload File Button'); showFileDialog()}} >
        {props.buttonText}
      </Button>
    </div>
    )
}