import React from 'react';
import AppBarTemplate from './../../template/AppBarTemplate'
import Parallax from "./../../common/Parallax/Parallax.js";
import * as pathHelper from './../../template/PathHelper'


import { useDispatch } from 'react-redux'
import * as actions  from './../../../actions'
import QualityDatasetRepository from './QualityDatasetRepository';
import CustomTabs from './../../common/CustomTabs/CustomTabs'

import { loadCSS } from 'fg-loadcss';
import Face from "@material-ui/icons/Face";
import SyncAlt from "@material-ui/icons/SyncAlt";
import QualityMasterDataRepository from './QualtiyMasterDataRepository';

export default function QualityDatasetRepositoryPage(props) {

    const tabSelected = 0

    React.useEffect(() => {
        loadCSS(
          'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
          document.querySelector('#font-awesome-css'),
        );
      }, []);
    

   
    return (
        <div >
            <AppBarTemplate history={props.history} title={'Configuration'} history={props.history}  arrayPath ={pathHelper.getPathArray('QualityDatasetRepositoryPage')} />

            <div>
            <CustomTabs
                headerColor="primary"
                value={tabSelected}
                tabs={[
                    {
                      tabName: "Dataset Repository",
                      tabIcon: Face,
                      tabContent: (
                        <QualityDatasetRepository/>
                      )
                    },
                    {
                      tabName: "Master Data Repository",
                      tabIcon: SyncAlt,
                      tabContent: (
                       <QualityMasterDataRepository/>
                      )
                    }
                ]}/>
                
                
        </div>
            
           
        </div>

    );
}