import React from 'react';
import {makeStyles,TextField,Grid ,Typography,InputLabel, Select,Input,IconButton,MenuItem,Chip} from '@material-ui/core';
import classNames from 'classnames';
import Button from  '@material-ui/core/Button';

import { styles, QualityTheme } from '../../../../../../../../style/CustomTheme'
import {getDataFromProperties} from './SpecificRuleHelper'
import trackEvent from '../../../../../../../../trackEvent'
import * as actions from '../../../../../../../../actions'

import { useSelector, useDispatch } from 'react-redux'

import { useTheme } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faColumns } from '@fortawesome/free-solid-svg-icons'

import DialogCreateCombinedColumn from './../../DialogCreateCombinedColumn';


import * as helper from './../CreateRuleHelper'

import DialogCreateMasterData from './../../../../../../QualityDatasetRepositoryPage/QualtiyMasterDataRepository/DialogCreateMasterData'




export default function FormMasterData(props){

   
    const classes = makeStyles(styles(QualityTheme));

    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license) 

    const [masterData,setMasterData] = React.useState([])
    const [selectedMasterDataIx,setSelectedMasterDataIx] = React.useState(masterData.length>0 && props.specific ? masterData.findIndex(x=>x._id===props.specific.masterDataId):0)
    const [selectedColumn,setSelectedColumn] = React.useState(props.specific ? JSON.stringify(props.specific.masterDataSelectedColumn): undefined)
    const [selectedCondition,setSelectedCondition] = React.useState(props.specific ? props.specific.masterDataCondition : 'exists')

    const [showDialogDetailMasterData,setShowDialogDetailMasterData ]= React.useState(false)
    const [showDialogCreateMasterData,setShowDialogCreateMasterData ]= React.useState(false)
    const [showDialogCreateCombinedColumn, setShowDialogCreateCombinedColumn] = React.useState(false)

    console.log('selectedColumn',selectedColumn)

    const dispatch= useDispatch()
    const theme = useTheme();


    function createRuleColumn(column){
       return {
            cols:[column.name],
            joinOperation: 'concat'
        }
    }
    function createSpecific(){
        //creamos el objeto de regla especifica
        const specific = {
            masterDataId: masterData.length>0 ? masterData[selectedMasterDataIx]._id: undefined,
            masterDataSelectedColumn: selectedColumn ?(selectedColumn.id ? (selectedColumn): createRuleColumn(JSON.parse(selectedColumn))) : undefined,
            masterDataCondition: selectedCondition,
            fileName: masterData.length>0 ? masterData[selectedMasterDataIx].blobStorageDTO.blob_fileName: undefined,
            masterDataSeparator : masterData.length>0 ? masterData[selectedMasterDataIx].separator: undefined,
        }
        props.setSpecific(specific)
       
    }

    function setDefaultSpecific(data){
       
        //creamos el objeto de regla especifica
        const specific = {
            masterDataId: data.length>0 ? data[0]._id: undefined,
            masterDataSelectedColumn: data.length>0 && data[0] ? data[0].columns[0]: undefined,
            masterDataCondition: selectedCondition,
            fileName: masterData.length>0 ? masterData[selectedMasterDataIx].blobStorageDTO.blob_fileName: undefined,
        }
        props.setSpecific(specific)
       
    }

    function getMasterData(){
        dispatch(actions.getDataRepositoryForUserAndLicense(user.id,license._id)).then(response=>{
            
            if(response.data){
                setMasterData(response.data) 
            }
            if(props.editableRule && response && response.data && response.data.length>0){
                console.log('aquiii')
                console.log(props.editableRule)
                const ix = response.data.findIndex(x=>x._id===props.editableRule.masterDataId)

                //obtain master
                let master = response.data[ix]

                //look for column 
                let col = master.columns.filter(x=>x.name===props.editableRule.masterDataSelectedColumn.cols[0])
                if(col.length>0){
                    console.log('column', col[0])
                    setSelectedColumn(JSON.stringify(col[0]))
                }
                    

                setSelectedMasterDataIx(ix)
                
                setSelectedCondition(props.editableRule.masterDataCondition)
            }
            else{
                if(response.data){
                    setDefaultSpecific(response.data)
                }
                
            }
            
        })
    }

    React.useEffect(() => {
        getMasterData()
    }, []);

    React.useEffect(() => {
        if(props.editableRule && masterData && masterData.length>0){
            setSelectedMasterDataIx(masterData.findIndex(x=>x._id===props.editableRule.masterDataId))
            setSelectedColumn(JSON.stringify(props.editableRule.masterDataSelectedColumn))
            setSelectedCondition(props.editableRule.masterDataCondition)
        }
    }, [props.editableRule]);

   

    React.useEffect(() => {
       createSpecific()
    }, [selectedMasterDataIx,selectedColumn,selectedCondition]);
    
  
    function onAddCombinedColumn(column){
        if(column){
            
            
            setSelectedColumn(column)
        }
    }

    function handleCloseChipCallback(value){
       setSelectedColumn(undefined)
    }


    function getNameFromColumn (column){
        if(column.name){
            return column.name
        }
        else{
            return column.cols.join(',')
        }
    }

    return(
        <div >
            <DialogCreateCombinedColumn visible={showDialogCreateCombinedColumn}
                masterData={masterData[selectedMasterDataIx]}
                ruleType={'master'}
                onClose={event=>setShowDialogCreateCombinedColumn(false)}
                onAddCombinedColumn={onAddCombinedColumn} />
            <DialogCreateMasterData open={showDialogCreateMasterData} onClose={event=>{getMasterData(); setShowDialogCreateMasterData(false)}}
            licenseId = {license._id} userId={user.id} />

            <DialogCreateMasterData open={showDialogDetailMasterData} onClose={event=>setShowDialogDetailMasterData(false)}
            licenseId = {license._id} userId={user.id}  masterData={masterData[selectedMasterDataIx]}/>


           <Grid container spacing={3} >
           <Grid item xs={12}>

            <InputLabel htmlFor="typeWarnTh-selection-helper" style={theme.inputLabel}>Master Data Privacity</InputLabel>
            <div style={theme.elementAdjacent}>
                <Select
                    native
                    value={selectedMasterDataIx}
                    style={{
                        width: '40%',
                        fontSize: 14,

                    }}
                    onChange={event => { setSelectedMasterDataIx((event.target.value));setSelectedColumn(undefined) }}
                    input={<Input id="select-multiple-checkbox" />}
                >
                    {masterData && masterData.length>0 && masterData.map((data,index) =>
                        <option value={index}>{`${data.name} (${data.description})`}</option>
                        )}
                    

                </Select>
                <IconButton aria-label="delete" variant='outlined' onClick={event=>{setShowDialogDetailMasterData(true);
                trackEvent('CreateRuleSpecific','MasterData show detail');
            }}>
                    <FontAwesomeIcon icon={faSearch} style={theme.icons.iconAqtivaActionBig} />
                </IconButton>
                <IconButton aria-label="delete" variant='outlined' onClick={event=>{setShowDialogCreateMasterData(true);
                trackEvent('CreateRuleSpecific','MasterData show detail');
                }}>
                    <FontAwesomeIcon icon={faPlus} style={theme.icons.iconAqtivaActionBig} />
                </IconButton>
            </div>
            </Grid>
            <Grid item xs={12}>
            <div >
                            <div className={classes.elementAdjacent}>
                                <FontAwesomeIcon icon={faColumns} style={{ color: '#76b3d6', fontSize: 15 }} />
                                <Typography variant='subtitle2' style={{ marginLeft: '2%' }}> Columns Selection</Typography>
                            </div>
                            <div>
                           <InputLabel htmlFor="typeWarnTh-selection-helper" style={theme.inputLabel}>Available Columns</InputLabel>
                                <Select
                                    disabled={selectedColumn}
                                    native
                                    style={{
                                        width: '40%',
                                        fontSize: 14,
                
                                    }}
                                    value={selectedColumn}
                                    onChange={event=>{setSelectedColumn(event.target.value);}}
                                    input={<Input id="select-multiple-checkbox" />}

                                >
                                    <option key={'undefined'} value={undefined}>None</option>
                                    {masterData && masterData.length>0 && masterData[selectedMasterDataIx].columns.map((e,index) => (
                                        <option key={e.position} value={JSON.stringify(e)}>{`#${e.position} (${e.type}) ${e.name}`}</option>

                                       
                                    ))}
                                </Select>
                            
                                <Typography variant='body2' style={{ marginTop: '2%' }}> Selected Columns</Typography>
                                <div className={classes.chipContainerRule}>

                                    {selectedColumn &&
                                            <div>
                                                <Chip
                                                    label={selectedColumn.id ? getNameFromColumn(selectedColumn) : getNameFromColumn(JSON.parse(selectedColumn))}
                                                    key={selectedColumn.id ? selectedColumn.id : JSON.parse(selectedColumn).id}
                                                    onDelete={event => handleCloseChipCallback(selectedColumn)}

                                                />
                                            </div>
}               
                                        </div>
                                      

                                    

                                   

                            </div>
                            {!selectedColumn &&
                            <div style={{ marginTop: '2%' }}>
                                    <Button id='CancelButton' variant='outlined' color='primary'
                                        onClick={event => { 
                                            trackEvent('CreateRuleSpecific','MasterData Create Combined Column');
                                            setShowDialogCreateCombinedColumn(true) }}>
                                        Add Combined Column
                                                </Button>
                                </div>
                            }
                            {selectedColumn &&
                             <Typography variant='caption' > Only one column allowed</Typography>
                            }
                            
             </div>
            </Grid>
           
            </Grid>
        </div>
    )
}
