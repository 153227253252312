import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Tooltip,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faDatabase,
  faProjectDiagram,
  faFileAlt,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import TotalScoreChart from "./TotalScoreChart";
import { useSelector, useDispatch } from "react-redux";

import { AreaChart, Area, XAxis, Brush,YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

export default function KPICard(props) {
  const user = useSelector((store) => store.user);
  const theme = useTheme();
  const [daos, setDaos] = React.useState([]);
  const [daosType, setDaosType] = React.useState([]); 
  const dispatch = useDispatch();

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);


  function consolidateObjectsDao(daos){

    if(daos && daos.length>0){
      const arrayHashmap = daos.reduce((obj, item) => {
        obj[item.entityId] ?  obj[item.entityId]=selectDao(obj[item.entityId], item): (obj[item.entityId] = { ...item });
        return obj;
      }, {});
      const mergedArray = Object.values(arrayHashmap);
      return mergedArray
    }
    return daos
    
  }

  function selectDao(a,b){
    if(a.totalEvents>b.totalEvents){
      if(a.childrenId && a.childrenId.length>0) a.childrenIds.push(...b.childrenIds)
      return a
    }
    if(b.totalEvents<b.totalEvents){
      if(b.childrenId && b.childrenId.length>0) b.childrenIds.push(...a.childrenIds)
      return b
    }
    if(a.rulesDeployed>b.rulesDeployed){
      if(a.childrenId && a.childrenId.length>0)  a.childrenIds.push(...b.childrenIds)
      return a
    }
    if(a.rulesDeployed<b.rulesDeployed){
      if(b.childrenId && b.childrenId.length>0) b.childrenIds.push(...a.childrenIds)
      return b
    }
    if(a.childrenId && a.childrenId.length>0) a.childrenIds.push(...b.childrenIds)
    return a
  }


  React.useEffect(() => {
   
    setDaos(consolidateObjectsDao(props.dataKPI.globalDashboardDAOs));
    setDaosType(props.dataKPI.entityType);
    forceUpdate()
  }, [props.dataKPI, props.showEmpty]);


  if (!daos || daos.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress size={50} style={{ margin: "40px" }} />
        <Typography variant="subtitle2" style={{ marginLeft: "10px" }}>
          Fetching data
        </Typography>
        <Typography variant="caption" style={{ marginTop: "10px" }}>
          Please wait...{" "}
        </Typography>
      </div>
    );
  }


  function go2Detail(dao){
    if(dao.entityType==='project'){
      props.history.push(`/${process.env.REACT_APP_PREFIX}/dashboard/project/${dao.entityId}/monitoring`)
      forceUpdate()
    }
    else if(dao.entityType==='qualityPoint'){
      props.history.push(`/${process.env.REACT_APP_PREFIX}/dashboard/qualitypoint/${dao.entityId}/monitoring`)
      forceUpdate()
    }
    else if(dao.entityType==='datasource'){
      props.history.push(`/${process.env.REACT_APP_PREFIX}/dashboard/datasource/${dao.entityId}/monitoring`)
      forceUpdate()
    }
    
  }
  console.log('showSelectedProjects',props.showSelectedProjects)
  console.log('selectedProjects',props.selectedProjects)
  return (
    <div style={theme.QualityKPIS.container}>
      {daos &&
        (
          
          (props.showEmpty === true && props.showOwnProjects ===false ) ? ((props.showSelectedProjects!==true || props.selectedProjects===undefined) ? daos : daos.filter(x=>x.entityType!=='project' || props.selectedProjects.includes(x.entityId))) :
          (props.showEmpty === true && props.showOwnProjects ===true ) ?  ((props.showSelectedProjects!==true || props.selectedProjects===undefined) ? daos : daos.filter(x=>x.entityType!=='project' || props.selectedProjects.includes(x.entityId))).filter((x) => x.userId === user.id) :
          (props.showEmpty === false && props.showOwnProjects ===false ) ?  ((props.showSelectedProjects!==true || props.selectedProjects===undefined) ? daos : daos.filter(x=>x.entityType!=='project' || props.selectedProjects.includes(x.entityId))).filter((x) => x.globalScore !== -1) :
          ((props.showSelectedProjects===false) ? daos : daos.filter(x=>x.entityType!=='project' || props.selectedProjects.includes(x.entityId))).filter((x) => x.userId === user.id).filter((x) => x.globalScore !== -1)).map((project, index) => (
          <div key={index}>
            <Card
              style={{
                ...theme.QualityKPIS.mainContainer,
                marginRight: "10px",
                marginLeft: "10px",
              }}
              variant="outlined"
            >
              <CardHeader
                style={theme.QualityKPIS.firstDivider}
                action={
                  <Tooltip title="Go to Dashboard">
                    <div>
                      <FontAwesomeIcon
                        icon={faChartBar}
                        onClick={event=>go2Detail(project)}
                        color="grey"
                        style={theme.QualityKPIS.button}
                      />
                    </div>
                  </Tooltip>
                }
                title={
                  <Typography
                    style={{
                      color: "#FFFFFF",
                      marginTop: "-7px",
                      marginBottom: "-5px",
                      fontWeight: "bold",
                    }}
                    variant="subtitle2"
                  >
                    {" "}
                    {project.entityName && project.entityName.length > 27
                      ? project.entityName.substring(0, 27) + "..."
                      : project.entityName}
                  </Typography>
                }
              />
              <CardContent style={{marginTop:'-10px'}}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div>
                      <TotalScoreChart
                        height="100px"
                        /* data={Math.floor(Math.random() * 60) + 40 }  */
                        data={project.globalScore}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{marginTop:'-15px'}}>
                    <div style={theme.QualityKPIS.divider}>
                      <Grid container spacing={2} style={{ width: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          {project.qualityPointsDeployed != null && (
                            <Grid item xs={3}>
                              <Tooltip title="Quality points">
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <FontAwesomeIcon
                                      icon={faProjectDiagram}
                                      color="#4697c4"
                                      style={{ margin: "5px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div style={{ marginTop: "5px" }}>
                                      {project.qualityPointsDeployed}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </Grid>
                          )}
                          {project.datasourcesDeployed != null && (
                            <Grid item xs={3}>
                              <Tooltip title="Data sources">
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <FontAwesomeIcon
                                      icon={faDatabase}
                                      color="#4697c4"
                                      style={{ margin: "5px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div style={{ marginTop: "5px" }}>
                                      {project.datasourcesDeployed}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </Grid>
                          )}
                          {project.rulesDeployed != null && (
                            <Grid item xs={3}>
                              <Tooltip title="Rules">
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <FontAwesomeIcon
                                      icon={faFileAlt}
                                      color="#4697c4"
                                      style={{ margin: "5px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div style={{ marginTop: "5px" }}>
                                      {project.rulesDeployed}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </Grid>
                          )}
                        </div>
                      </Grid>
                      <div style={{marginTop:'20px', marginBottom:'10px'}}>
                      <AreaChart
                        width={240}
                        height={50}
                        data={(project&& project.monitorDAO && project.monitorDAO.percentesTimeSeriasDAO!==undefined && project.monitorDAO.percentesTimeSeriasDAO!==null) ? project.monitorDAO.percentesTimeSeriasDAO : []}
                        
                      >
                        <Area type="monotone" dataKey="percentOKRuleName" stroke="#108521AA" fill="#108521AA" />
                        <Area type="monotone" dataKey="percentKORuleName" stroke="#bf1111AA" fill="#bf1111AA" />
                        
                      </AreaChart>
                      
                    </div>
                    
                      <Typography
                        style={{ ...theme.QualityKPIS.text, marginTop: "10px" }}
                        variant="subtitle2"
                      >
                        Daily events:{" "}
                        {project.dailyEvents > 1000000
                          ? (
                              Math.round(
                                (project.dailyEvents / 1000000) * 100
                              ) / 100
                            ).toString() + " MM"
                          : project.dailyEvents > 1000
                          ? (
                              Math.round((project.dailyEvents / 1000) * 100) /
                              100
                            ).toString() + " K"
                          : project.dailyEvents}
                      </Typography>
                      <Typography
                        style={{...theme.QualityKPIS.text, marginTop:'0px'}}
                        variant="subtitle2"
                      >
                        Total events:{" "}
                        {project.totalEvents > 1000000
                          ? (
                              Math.round(
                                (project.totalEvents / 1000000) * 100
                              ) / 100
                            ).toString() + " MM"
                          : project.totalEvents > 1000
                          ? (
                              Math.round((project.totalEvents / 1000) * 100) /
                              100
                            ).toString() + " K"
                          : project.totalEvents}
                      </Typography>
                      </div>
                  </Grid>
                  {project.childrenIds && project.childrenIds.length > 0 && (
                    <Grid item xs={12} style={{marginTop:'-20px'}}>
                      <div style={{ textAlign: "right" }}>
                        <Tooltip title="Go to next aggregation level">
                          <Button
                            onClick={(event) => {
                              //clear data
                              dispatch({
                                type: 'FETCH_KPIS_GLOBAL',
                                payload: {},
                              });
                              const childrenIds = project.childrenIds.join("-");
                              const path = `/${process.env.REACT_APP_PREFIX}/governance/${project.childEntityType}/${childrenIds}`;
                              props.history.push(path);
                              forceUpdate()
                            }}
                            style={{marginRight:"-30px", marginBottom:"-20px"}}
                          >
                            <FontAwesomeIcon
                              icon={faArrowCircleDown}
                              color="#4697c4"
                              style={{
                                ...theme.QualityKPIS.button,
                                color: "#4697c4",
                                fontSize:"30px",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </div>
        ))}
    </div>
  );
}
