import _ from 'lodash';
import {FETCH_JOB_OUTPUT
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_JOB_OUTPUT:
            return action.payload;
        default:
            return state;
        
    }
}