import React from 'react';

export function updateSelectedStream( listStreams, datasource, streamObjName){
    if(datasource && datasource[streamObjName]){
        let filteredValues = getElementList(listStreams).filter(x=>x.name===datasource[streamObjName].name &&
            x.namespace ===datasource[streamObjName].namespace && x.resourcegroup ===  datasource[streamObjName].resourcegroup)
            if(filteredValues && filteredValues.length>0){
                return filteredValues [0]
            }
    }
    return undefined
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  export function getElementList(stream) {
    var arrayFlat = []
    for (var i = 0; i < stream.length; i++) {
        var rg = stream[i]
        for (var j = 0; j < rg.namespaces.length; j++) {
            var namespace = rg.namespaces[j]
            var elementNS = {
                type: 'namespace',
                name: namespace.name
            }
            arrayFlat.push(elementNS)
            for (var k = 0; k < namespace.eventHubs.length; k++) {
                var eventhub = namespace.eventHubs[k]
                var elementEH = {
                    type: 'eventhub',
                    name: eventhub.name,
                    namespace: namespace.name,
                    resourcegroup : rg.name
                }
                arrayFlat.push(elementEH)
            }
        }
    }
    
    return arrayFlat
}

export function createConsumerTreeView(rawConsumers, setStreams){
    let resourceGroups = rawConsumers.map(x=>x.resourceGroup).filter(onlyUnique)
    let resourceGroupList = []
    for(var i=0;i<resourceGroups.length;i++){
        let namespaces = rawConsumers.filter(x=>x.resourceGroup===resourceGroups[i]).map(x=>x.namespace).filter(onlyUnique)
        let name = resourceGroups[i]
        let namespaceList = []
        
        for(var j=0;j<namespaces.length;j++){
            let namespace = namespaces[j]
            let eventhubs = rawConsumers.filter(x=>x.resourceGroup===resourceGroups[i]).filter(x=>x.namespace === namespace)
            let eventHubList = []
                for(var k=0;k<eventhubs.length;k++){
                    let eventhub = eventhubs[k]
                    let eventHubNamespace = eventhub.namespace
                    let eventHubName = eventhub.name
                    let connectionStrings = eventhub.authorizationRules.map(x=>x.connString)
                     let eh = {
                        eventHubNamespace:eventHubNamespace,
                        name: eventHubName,
                        connectionStrings : connectionStrings
                     }
                     eventHubList.push(eh)

                }
            
            
            let ns = {
                name:namespace,
                resourceGroup: name,
                eventHubs: eventHubList
            }
            namespaceList.push(ns)

        }
        let rg = {
            name:name,
            namespaces: namespaceList
        }
        resourceGroupList.push(rg)

    }
    setStreams(resourceGroupList)

}