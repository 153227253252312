import React from 'react';

import MaterialTable from 'material-table'
import {Typography} from '@material-ui/core'

import {getColumns, customLocation} from './QualityProjectsTableHelper'
import { Paper } from '@material-ui/core';
import {useSelector} from 'react-redux'
import {StyledMenu, StyledMenuItem} from './../../../common/CustomizedMenu'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const initialState = {
    mouseX: null,
    mouseY: null,
  };

export default function QualityProjectsTable(props) {

    var license = useSelector(store => store.license)
    const user = useSelector(store => store.user)

    const [anchorEl, setAnchorEl] = React.useState(null)

    const [state, setState] = React.useState(initialState);

    const [selectedProject2Share, setSelectedProject2Share] = React.useState('')



    const onCloseShareMenu = ()=>{
        setState(initialState)
    }   

    const onShareForUser = (project,event)=>{
        setSelectedProject2Share(project)
        setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        });
    }
    
    const editProject = (project) => {
        props.editProject(project)
    }


    const deleteProject = (rowData) => {
        props.deleteProject(rowData)
    }
    const copyProject = (rowData) => {
        props.copyProject(rowData)
    }

    const shareProject = (project,userId2Share) => {

        props.shareProject(project,userId2Share)
        setState(initialState)
    }


    function getContextShare(){
        return(
            <div>
                <StyledMenu
                        keepMounted
                        open={state.mouseY !== null}
                        onClose={onCloseShareMenu}
                        anchorReference="anchorPosition"
                        anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                        }
                    >
                        {user && license && license.users && license.users.filter(e=>e.email!==user.email).map( (user,index) =>
                         <StyledMenuItem key={index}>
                            
                            <ListItemText primary={user.name} key={index}
                            onClick = {event =>shareProject(selectedProject2Share,user.id)} />
                        </StyledMenuItem>
                        )}
                        
                    </StyledMenu>
            </div>
        )
    }

  
    return (
        
        <div style={props.style}>
            {getContextShare()}
            <MaterialTable
                        components={{
                            Container: props => <Paper {...props} elevation={0}/>
                    }}
                    data={props.rows}
                    columns={getColumns(editProject,deleteProject,copyProject,anchorEl,setAnchorEl,license, onCloseShareMenu,onShareForUser, user)}
                    style={{
                        width: '80%',
                        marginLeft: '10%',
                    }}
                    options={{
                        search: false,
                        paging: (props.rows && props.rows.length>5) ? true :false,
                        toolbar: false,
                        headerStyle:{
                            fontSize:'14px',   
                            fontWeight: 'bold',       
                            backgroundColor: '#FFF',
                            color: '#000',
                            borderBottomStyle: 'solid', 
                            borderBottomColor: '#76b3d6', 
                            borderBottomWidth: '1px'
                        },
                       
                        cellStyle:{
                            fontSize:'12px',   
                            borderBottomStyle: 'solid', 
                            borderBottomColor: '#76b3d6', 
                            borderBottomWidth: '1px'
                        }
                    }}
                />
        </div>
    );

}