import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from './../../../../actions'
import DialogCreateMasterData from './DialogCreateMasterData'
import Button from '@material-ui/core/Button'
import QualityMasterDataRepositoryTable from './QualityMasterDataRepositoryTable'


export default function QualityMasterDataRepository(props) {

    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license)
    const dispatch = useDispatch()

    const [repositories, setRepositories] = React.useState([])

    const [repositoriesChanged, setRepositoriesChanged] = React.useState(false)

    const [showDialogCreateMasterData, setShowDialogCreateMasterData] = React.useState(false)


    React.useEffect(() => {
        if (isEmpty(user)) {
            dispatch(actions.fetchUser())
        }

        if (!isEmpty(user) && !isEmpty(license)) {
            dispatch(actions.getDataRepositoryForUserAndLicense(user.id, license._id)).then(response => {
                if (response.result === 'ok') {
                    setRepositories(response.data)
                }
            })
        }
        if (repositoriesChanged) {
            setRepositoriesChanged(false)
        }
    }, [user, repositoriesChanged]);


    function isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }



    return (
        <div style={{ marginTop: '1%' }}>
            <Button variant="outlined" color="primary" onClick={(event) => setShowDialogCreateMasterData(true)}>
                Add master data
</Button>
            <DialogCreateMasterData setChangedRepo={setRepositoriesChanged} open={showDialogCreateMasterData} onClose={event => setShowDialogCreateMasterData(false)}
                licenseId={license._id} userId={user.id} />
            <div style={{ marginTop: '1%' }}>
                {repositories && <QualityMasterDataRepositoryTable data={repositories} setChangedRepo={setRepositoriesChanged} ></QualityMasterDataRepositoryTable>
                }
            </div>
        </div>
    )
}