import React from 'react';
import { Grid, Divider, Button, Tooltip } from '@material-ui/core'
import AddProjectItemButton from './AddProjectItemButton'
import SimulateButton from './SimulateButton'
import DeployButton from './DeployButton'
import DeployExternalButton from './DeployExternalButton'
import SearchButton from './SearchButton'
import DeployAuditComponent from './DeployAuditComponent'
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecordVinyl, faFileExport } from '@fortawesome/free-solid-svg-icons'
import trackEvent from './../../../../../trackEvent'

import DeployButtonLambda from './DeployButtonLambda'
import * as helper from './detailProjectToolBarHelper'

import * as projectHelper from './../../../../../components/quality/common/projectHelper'
import { useSelector, useDispatch } from 'react-redux'

import * as actions from '../../../../../actions'


export default function QualityDetailProjectToolBar(props) {

    const dispatch = useDispatch();


    const theme = useTheme();
    const license = useSelector(store =>store.license)

    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [ deploymentAudit, setDeploymentAudit] = React.useState(undefined)


    React.useEffect(() => {
        if(license.apiKey && selectedEnvironment){
            dispatch(actions.getDeploymentForProjectAndEnvironment(license.apiKey,props.project._id, selectedEnvironment)).then(response=>
                {
                    if(response.data && response.data.length>0){
                        setDeploymentAudit(true)
                    }
                    else{
                        setDeploymentAudit(false)
                    }
                }
            )
        }
        
    }, [props.project, selectedEnvironment])

    function setSelectedEnvironmentAndParent(value) {
        setSelectedEnvironment(value)
        if (props.setSelectedEnvironment) {
            props.setSelectedEnvironment(value)
        }
    }

    

    return (
        <div>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <div style={theme.elementAdjacent}>
                        <div style={{ width: '120px' }}>
                            <AddProjectItemButton openWIDialog={props.openWIDialog} />
                        </div>

                        
                            <div style={{ width: '120px' }}>
                                <SimulateButton project={props.project}
                                    user={props.user}
                                    history={props.history}
                                    visible ={projectHelper.isProjectSimulable(props.project)===true}
                                    qualityConfiguration={props.qualityConfiguration}
                                />
                            </div>
                        
                        {process.env.REACT_APP_RULE_RECOMMENDER_ALLOWED === 'true' &&
                            <div style={{ width: '180px' }}>
                                <Button onClick={event => { if(projectHelper.isProjectRecommendable(props.project)===true){
                                        trackEvent('DetailProjectToolbar', 'Open Rule Recommender Dialog');
                                        props.openRuleRecommenderDialog(true)
                                    }
                                }}
                                    style={projectHelper.isProjectRecommendable(props.project)===true ? theme.buttons.buttonRegular : theme.buttons.buttonRegularDisabled}
                                    startIcon={<FontAwesomeIcon icon={faRecordVinyl}  style={{ fontSize:15}} />}
                                >
                                    <div style={theme.common.text.buttonTextSmall}>
                                        Rule Recommender
                            </div>
                                </Button>
                            </div>}

                            {(process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true' || process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true') &&
                                <div style={{ width: '150px' }}>
                            <Button  onClick = {event => { if(projectHelper.isProjectExportable(props.project)===true) {props.onClickExport(event);
                             trackEvent('DetailProject', 'Export Project Button')}}}
                                style={projectHelper.isProjectExportable(props.project)===true ? theme.buttons.buttonRegular : theme.buttons.buttonRegularDisabled}
                                startIcon={<FontAwesomeIcon icon={faFileExport} 
                                style={{ fontSize:15}}
                                
                                 />} 
                                >
                                <div style={theme.common.text.buttonTextSmall}>
                                Export Project
                                </div>
                                
                                        
                                </Button>
                                </div>  
                            }
                            
                            
                    </div>
                </Grid>
                <Grid item xs={10}>
                    
                    <div style={theme.elementAdjacent}>
                        {helper.isStreamProject(props.project) && 
                            <div style={{ width: '350px' }}>
                                <DeployButtonLambda qualityConfiguration={props.qualityConfiguration}
                                    project={props.project}
                                    visible={projectHelper.isProjectExportable(props.project)}
                                    setSelectedEnvironment={setSelectedEnvironmentAndParent} />
                            </div>
                        }

                        {!helper.isStreamProject(props.project) && 
                            <div style={{ width: '350px' }}>
                                <DeployButton qualityConfiguration={props.qualityConfiguration}
                                    project={props.project}
                                    visible={projectHelper.isProjectExportable(props.project)}
                                    setSelectedEnvironment={setSelectedEnvironmentAndParent} />
                            </div>
                        }

                        {process.env.REACT_APP_DEPLOY_EXTERNAL && process.env.REACT_APP_DEPLOY_EXTERNAL === 'true' && !helper.isStreamProject(props.project) && 
                        
                            <div style={{ width: '350px' }}>
                                <DeployExternalButton qualityConfiguration={props.qualityConfiguration}
                                visible = {projectHelper.isProjectExportable(props.project)}
                                    project={props.project} />
                            </div>
                        }
                        {projectHelper.isProjectExportable(props.project)===true && 
                            <div style={{ width: '400px', marginTop: '10px' }}>
                                <DeployAuditComponent project={props.project} environment={selectedEnvironment}
                                deploymentAudit = {deploymentAudit} />
                            </div>
                        }
                    </div>
                </Grid>


                <Grid item xs={2}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <SearchButton updateText={props.updateText} />

                    </div>

                </Grid>


            </Grid>
            <Divider />
        </div>
    )
}