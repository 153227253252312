import React from 'react'


export default function FileDataDetailViewRepository(props) {

    return(
        <div>
            FileDataDetailViewRepository
        </div>
    )
}