import _ from 'lodash';
import {SET_DASHBOARD_DATES
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case SET_DASHBOARD_DATES:
            console.log('action payload', action.payload)
            return action.payload
        default:
            return state;
        
    }
}