import React from 'react';
import LoginPage from './components/login/LoginPage';
import {  Route, Switch } from "react-router-dom";
import HomePage from './components/home/HomePage';
import PrivateRoute from './components/common/PrivateRoute';

import OAuth2RedirectHandler from './components/login/OAuth2RedirectHandler'
import { ACCESS_TOKEN,USER } from './Constants';
import LicensePage from './components/admin/LicensePage/LicensePage';
import QualityManagementProjectPage from './components/quality/QualityManagementProjects/QualityManagementProjectsPage'
import QualityDetailProjectPage from './components/quality/project/QualityDetailProjectPage';
import QualityConfigurationPage from './components/quality/QualityConfigurationPage';
import QualitySimulationRepositoryPage from './components/quality/QualitySimulationRepositoryPage';
import QualityDatasetRepositoryPage from './components/quality/QualityDatasetRepositoryPage';
import QualityDashboardPagev2 from './components/quality/QualityDashboardPagev2'
import SuperAdminLicenseManagementPage from './components/quality/SuperAdminLicenseManagementPage';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';

import MuiAlert from '@material-ui/lab/Alert';
import {useSelector} from 'react-redux'
import QualityRepositoryPage from './components/quality/QualityRepositoryPage';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { QualityTheme } from './style/CustomTheme'

import * as Sentry from '@sentry/browser';

import { useTracking } from './useTracking'
import DataRepository from './components/quality/DataRepository';
import DataRepositoryRefactor from './components/quality/DataRepositoryRefactor';
import QualityKpis from './components/quality/QualityKpis';
import QualityKpisDetail from './components/quality/QualityKpis/QualityKPIsDetail';

import QualityConsumption from './components/quality/QualityConsumption'
import * as snackbarContentHelper from './components/common/SnackBarContent/snackbarContentHelper'

import Slide from '@material-ui/core/Slide';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function App() {

  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID)

  

  const [openInfoSnack, setOpenInfoSnack] = React.useState(false);
  const [openErrorSnack, setOpenErrorSnack] = React.useState(false);

  const token = localStorage.getItem(ACCESS_TOKEN)
  

  const [authenticated, setAuthenticated] = React.useState(token ? true : false)

  const errorMessage = useSelector(store => store.errorMessage)
  const infoMessage = useSelector(store => store.infoMessage)


  const [state, setState] = React.useState({
    open: false,
    Transition: Slide,
  });

  React.useEffect(() => {
    
    if(errorMessage!==undefined && errorMessage.msg !==undefined){
      if( (errorMessage.detail !==undefined  
        && errorMessage.detail.includes("static/js")===false)){
          console.log(errorMessage.detail.includes("static/js"))
        }
      else{
        setOpenErrorSnack(true)
      }
      
    }
        
  }, [errorMessage]);


  React.useEffect(() => {
    if(infoMessage!==undefined && infoMessage.msg !==undefined)
      setOpenInfoSnack(true)
  }, [infoMessage]);

  const setAuthenticatedCallback = (value) =>{
    setAuthenticated(true)
  }
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorSnack(false);
  };
  const handleCloseInfo = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenInfoSnack(false);
  };
  
  const vertical = 'top'
  const horizontal = 'right'


  try{
  return (
    <div>
        <MuiThemeProvider theme={QualityTheme}>  

       

        <div>
        <Snackbar open={openInfoSnack} autoHideDuration={3000} onClose={handleCloseInfo} onClick={handleCloseInfo}
        anchorOrigin={{ vertical, horizontal }} style={{marginTop:'-15px', marginRight:'-15px'}}
        TransitionComponent={state.Transition}
        TransitionProps={{direction: 'left'}}>
          {snackbarContentHelper.infoSnackbarContent(infoMessage)}

        
        </Snackbar>
       </div >

       <div>
        <Snackbar open={openErrorSnack} autoHideDuration={3000} onClose={handleCloseError} onClick={handleCloseError}
        anchorOrigin={{ vertical, horizontal }} style={{marginTop:'-15px', marginRight:'-15px'}}
        TransitionComponent={state.Transition}
        TransitionProps={{direction: 'left'}}>
          {snackbarContentHelper.infoSnackbarContentError(errorMessage)}

        
        </Snackbar>
       </div >


      
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/oauth2/redirect" component={() => <OAuth2RedirectHandler setAuthenticated = {setAuthenticated}/>} ></Route> 
      <PrivateRoute path="/datarepository" component={DataRepository} authenticated={authenticated} />
      <PrivateRoute path="/datarepositoryrefactor" component={DataRepositoryRefactor} authenticated={authenticated} />
     {/*  Añado estas dos rutas */}
      <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/governance/:aggregationLevel/:entityIds`} forceRefresh={true} component={QualityKpisDetail} authenticated={authenticated} />
      <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/governance`} forceRefresh={true} component={QualityKpis} authenticated={authenticated}/>
     
      {/* Las rutas nuevas son las de arriba */}
      <PrivateRoute path={`/admin`} component={LicensePage} authenticated={authenticated} />
      <PrivateRoute path={`/admin/license`} component={LicensePage} authenticated={authenticated} />
      <PrivateRoute path={`/home`} component={QualityManagementProjectPage} authenticated={authenticated} />
      <PrivateRoute path={`/management`} component={SuperAdminLicenseManagementPage} authenticated={authenticated} />
      <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/repo`} component={QualityRepositoryPage} authenticated={authenticated} />
      <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/simulations`} component={QualitySimulationRepositoryPage} authenticated={authenticated} />
      <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/datasets`} component={QualityDatasetRepositoryPage} authenticated={authenticated} />
      <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/dashboard/:idSimulation/:idTab`} component={QualityDashboardPagev2} authenticated={authenticated}/>
      <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/dashboard/project/:idProject/:idTab`} component={QualityDashboardPagev2} authenticated={authenticated}/>
      <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/dashboard/qualitypoint/:idQualityPoint/:idTab`} component={QualityDashboardPagev2} authenticated={authenticated}/>
      <PrivateRoute exact path={`/${process.env.REACT_APP_PREFIX}/dashboard/datasource/:idDatasource/:idTab`} component={QualityDashboardPagev2} authenticated={authenticated}/>

      <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/dashboard`} component={QualityDashboardPagev2} authenticated={authenticated}/>
      <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/configuration`} component={QualityConfigurationPage} authenticated={authenticated} />
      <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/project/:projectId/:qpId/:recommenderShow/:notificationId`} component={QualityDetailProjectPage} authenticated={authenticated} />
      <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/project/:projectId`} component={QualityDetailProjectPage} authenticated={authenticated} />
      <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}/consumption`} component={QualityConsumption} authenticated={authenticated}/>
      <PrivateRoute path={`/`} component={QualityManagementProjectPage} authenticated={authenticated} />
      <PrivateRoute path={`/${process.env.REACT_APP_PREFIX}`} component={QualityManagementProjectPage} authenticated={authenticated} />
  </Switch>
  </MuiThemeProvider>
  </div>
  );
  }
  catch( error){
    Sentry.withScope((scope) => {
      const eventId = Sentry.captureException(error);
      
  });
  }
}
