import quality from '../apis/quality';
import {
    FETCH_PROJECTS_DATA_REPOSITORY,
    FETCH_PROJECTS_DATA_REPOSITORY_SHARED,
    EDIT_PROJECT_DATA_REPOSITORY,
    EDIT_PROJECT_DATA_REPOSITORY_SHARED,
    DELETE_PROJECT_DATA_REPOSITORY,
    DELETE_PROJECT_DATA_REPOSITORY_SHARED
    } from './types'
    import {axiosHeader, dispatchError,dispatchInfo} from '../components/common/axios/axiosHelper';
import qualityLocalHost from '../apis/qualityLocalHost';


    
export const fetchProjectDataRepositoryPrivateWorkspace = (userId) => async (dispatch,getState) => {
    
    
    await quality.get(`/data/repository/project/privateworkspace/${userId}`,{
        headers: axiosHeader()
      }).then(function (response) {
            dispatch( {
                type : FETCH_PROJECTS_DATA_REPOSITORY,
                payload: response.data
            });
            
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
          });
    
    
   
};

export const fetchProjectDataRepositorySharedWorkspace = (userId) => async (dispatch,getState) => {
    
    
    await quality.get(`/data/repository/project/sharedworkspace/${userId}`,{
        headers: axiosHeader()
      }).then(function (response) {
             dispatch( {
                type : FETCH_PROJECTS_DATA_REPOSITORY_SHARED,
                payload: response.data
            });
            
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
          });
    
    
   
};


export const addFolderToRepository = (type, name, dtoId, parentId,visibility) => async (dispatch,getState) => {
    await quality
      .post(
        `/data/repository/project/folder/${type}/add/${name}/${dtoId}/${parentId}/${visibility}`,null,
        {
          headers: axiosHeader(),
        }
      )
      .then(function (response) {
          const actionsType = 
              type === 'private' 
                  ? EDIT_PROJECT_DATA_REPOSITORY 
                  : EDIT_PROJECT_DATA_REPOSITORY_SHARED
              dispatch({
                  type: actionsType,
                  payload: response.data,
              });
              dispatchInfo(dispatch, `Folder ${name} was succesfully created`);
      })
      .catch(function (error) {
        dispatchError(dispatch, error);
      });
  };
  
  
  export const addRootFolderToRepository = (type, name, userId, visibility) => async (dispatch,getState) => {
      await quality
        .post(
          `/data/repository/project/folder/${type}/add/root/${name}/${userId}/${visibility}`,undefined,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
            const actionsType = 
                type === 'private' 
                    ? EDIT_PROJECT_DATA_REPOSITORY 
                    : EDIT_PROJECT_DATA_REPOSITORY_SHARED
                dispatch({
                    type: actionsType,
                    payload: response.data,
                });
                dispatchInfo(dispatch, `Folder ${name} succesfully created in root`);
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };
  
  
    export const deleteParentFolderFromRepository = (type, dtoId, folderId) => async (dispatch,getState) => {
      await quality
        .delete(
          `/data/repository/project/folder/delete/${type}/${dtoId}/${folderId}`,          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
          if(dtoId===folderId){
            const actionsType = 
                type === 'private' 
                    ? DELETE_PROJECT_DATA_REPOSITORY 
                    : DELETE_PROJECT_DATA_REPOSITORY_SHARED
                dispatch({
                    type: actionsType,
                    payload: dtoId,
                });
                dispatchInfo(dispatch, 'Root folder deleted');
          }
          else{
            const actionsType = 
            type === 'private' 
                ? EDIT_PROJECT_DATA_REPOSITORY 
                : EDIT_PROJECT_DATA_REPOSITORY_SHARED
            dispatch({
                type: actionsType,
                payload: response.data,
            });
            dispatchInfo(dispatch, 'Folder deleted');
          }
            
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };

  //Nuevo action para enviar archivos a default

     export const deleteFolderFromRepositoryAndSave = (type, dtoId, folderId, defaultId) => async (dispatch,getState) => {
      await quality
        .delete(
           `/data/repository/project/folder/delete/backup/${type}/dtoId/${dtoId}/folderId/${folderId}/defaultId/${defaultId}`,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
          if(dtoId===folderId){
            const actionsType = 
                type === 'private' 
                    ? DELETE_PROJECT_DATA_REPOSITORY 
                    : DELETE_PROJECT_DATA_REPOSITORY_SHARED
                dispatch({
                    type: actionsType,
                    payload: dtoId,
                });
                dispatchInfo(dispatch, 'Root folder deleted');
          }
          else{
            const actionsType = 
            type === 'private' 
                ? EDIT_PROJECT_DATA_REPOSITORY 
                : EDIT_PROJECT_DATA_REPOSITORY_SHARED
            dispatch({
                type: actionsType,
                payload: response.data,
            });
            dispatchInfo(dispatch, 'Folder deleted');
          }
            
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };
 



    export const deleteFileFromRepository = (type, projectId, userId) => async (dispatch,getState) => {
      await quality
        .delete(
          `/data/repository/project/delete/${type}/${projectId}/user/${userId}`,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
          const actionsType = 
          type === 'private' 
              ? EDIT_PROJECT_DATA_REPOSITORY 
              : EDIT_PROJECT_DATA_REPOSITORY_SHARED
                dispatch({
                    type: actionsType,
                    payload: response.data,
                });
                dispatchInfo(dispatch, 'File deleted');
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };




  
    export const exportFileFromRepository = (userId, dtoId, folderId, dtoIdOr,fileIdOr, projectId) => async (dispatch,getState) => {
      //console.log("exportfile", userId, dtoId, folderId, dtoIdOr,fileIdOr, projectId)
      await quality
        .post(
          `/data/repository/project/export/user/${userId}/dto/${dtoId}/folder/${folderId}/project/${projectId}/or/dto/${dtoIdOr}/entity/${fileIdOr}`, undefined,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
                dispatch({
                    type: EDIT_PROJECT_DATA_REPOSITORY_SHARED,
                    payload: response.data,
                });
                dispatchInfo(dispatch, `File succesfully exported`);
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };
  
  
  
    export const importFileToRepository = (userId, dtoIdOr, dtoIdDst,entityIdOr, entityIdDst, projectId) => async (dispatch,getState) => {
      await quality
        .post(
          `/data/repository/project/import/user/${userId}/dto/or/dto/${dtoIdOr}/entity/${entityIdOr}/dst/dto/${dtoIdDst}/entity/${entityIdDst}/project/${projectId}`, undefined,
          {
            headers: axiosHeader(),
          }
        )
        .then(function (response) {
                dispatch({
                    type: EDIT_PROJECT_DATA_REPOSITORY,
                    payload: response.data,
                });
                dispatchInfo(dispatch, `File succesfully imported`);
        })
        .catch(function (error) {
          dispatchError(dispatch, error);
        });
    };