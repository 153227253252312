import React from "react";

import Drag from "./Drag/index";

//Este componente es una especie de wrapper que sirve para englobar las zonas de drag and drop.
//Cuando queramos aplicarlo lo haremos en DataRepositoryTreeTable y le pasaremos por {component} la zona que nos interesa.
export function DragDropTemplate(
  component,
  data,
  modifiedProject,
  project
) {


  if ((data.data.nodeType === 'Rule')) {
    return (
      <Drag data={data} modifiedProject={modifiedProject} project={project}>
        { component}
      </Drag>
    )
  } else {
    return (<span> { component} </span>)
  }
}