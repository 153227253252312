import axios from 'axios';
import {API_MS_URL} from './../Constants'

export default axios.create(
    {
        baseURL: 'http://localhost:8089'
    }
);


