import React from 'react';
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer,LabelList, Brush
} from 'recharts';


export default function ChartBarsRules(props) {
    const renderCustomizedLabel = (props) => {
        const {
          x, y, width, height, value,
        } = props;
        const radius = 9;
      
        return (
          <g>
            <circle cx={'98%'} cy={y+radius-1} r={radius} fill={value==='INFO' ? "#108521AA" : value==='WARNING'? "#d19915": "#F3410DAA"} />
            
          </g>
        );
      };
   
    return (
        <div style={{height:props.height}}>
            {props.data &&
                <ResponsiveContainer width='100%' >
                    <BarChart data={props.data.ruleNamePercentsDAO} layout="vertical" margin={{
                        top: 1, right: 30, left: 20, bottom: 5,
                    }}  >

                        <XAxis type="number" dataKey="percentOKRuleName" tick={{fontSize: 9}}/>
                        <YAxis type="category" dataKey="ruleName"  tick={{fontSize: 10}}
                        />
                        <Tooltip />
                        {props.data.ruleNamePercentsDAO && props.data.ruleNamePercentsDAO.length>4 &&
                            <Brush dataKey="name" height={30} stroke="#8884d8" />
                        }
                        <Bar dataKey="percentOKRuleName" stackId="a" fill="#108521AA"  stroke="#108521AA" > 
                        <LabelList dataKey="percentOKRuleName" position="right" offset={-30} style={{ fill: '#ffffff' }}/>
                        {undefined && <LabelList dataKey="status" content={renderCustomizedLabel} >
                            <Tooltip />
                        </LabelList>}
        

                        </Bar>
                        <Bar dataKey="percentKORuleName" stackId="a" fill= '#F3410DAA' stroke= '#F3410D' /> 
                    </BarChart>

                </ResponsiveContainer>}

        </div>
    )
}
