import React from 'react'

import {makeStyles,Grid,Paper} from '@material-ui/core'

import FileDataDetailViewRepository from './FileDataDetailViewRepository'
import FileDataTreeViewRepository from './FileDataTreeViewRepository'


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));
export default function FileDataRepository(props) {

    const classes = useStyles();


    return(
        <div>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <Paper className={classes.paper}>
                        <FileDataTreeViewRepository/>
                    </Paper>
                </Grid>
                <Grid item xs={7}>
                    <Paper className={classes.paper}>
                    <FileDataDetailViewRepository/>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}