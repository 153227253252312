
import React from 'react';

import { Grid, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, InputAdornment, FormControlLabel, Switch } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSignature } from '@fortawesome/free-solid-svg-icons'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import CustomInput from "../../../../../../common/CustomInput/CustomInput.js";
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../../../../actions'


import TablePrevisualization from './TablePrevisualization'

import './../../../../../../../style/layout.css'

import InputFile from './../../../../../../common/azure-storage/components/InputFile'
import AqtivaDialog from './../../../../../../common/AqtivaDialog'

import { useTheme } from '@material-ui/core/styles';
import trackUser from './../../../../../../../trackEvent';

import { DataRepositoryTreeTable } from './../../../../../DataRepository/DataRepositoryTreeTrable/DataRepositoryTreeTable'
import * as convertDataHelper from './../../../../../DataRepository/EntityDataRepository/Helpers/convertDataHelper'
import * as getterDTO from "./../../../../../DataRepository/EntityDataRepository/Helpers/getterDTOs";

import {
  faShareSquare,
  faUserLock,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

export default function DialogCreateDataSource(props) {


  const theme = useTheme();

  const user = useSelector(store => store.user)

  const dispatch = useDispatch();

  //variables de estado
  const [error, setError] = React.useState({})
  const [selectedFormatName, setSelectedFormatName] = React.useState(null)
  const [selectedFormat, setSelectedFormat] = React.useState(null)
  const [fileUploadName, setFileUploadName] = React.useState(null)
  const [separator, setSeparator] = React.useState('automatic')
  const [colFormats, setColFormats] = React.useState([])
  const [checkAutomaticSeparator, setCheckAutomaticSeparator] = React.useState(true)

  const [defaultFolderId, setDefaultFolderId] = React.useState(" ");
  const [expandedKeys, setExpandedKeys] = React.useState();
  const [expandedKeysShared, setExpandedKeysShared] = React.useState();
  const [selectedDtoId, setSelectedDtoId] = React.useState(undefined)

  const [selectedItem, setSelectedItem] = React.useState({ itemId: "", type: "" }); // selectedItem del private
  const [selectedItemShared, setSelectedItemShared] = React.useState({
    itemId: "",
    type: "",
  });

  //error component state
  const [errorTypes, setErrorTypes] = React.useState();
  const [errorMsgs, setErrorMsgs] = React.useState();
  const [hasErrors, setHasErrors] = React.useState();

  const entityDataRepo = useSelector((store) =>
    Object.values(store["datasetBatchDataRepository"])
  );

  React.useEffect(() => {
    validate()
    if (selectedItem) {
      setSelectedDtoId(getterDTO.getDTOIdFromFolder(entityDataRepo, selectedItem.itemId))
    }

  }, [selectedItem])

  React.useEffect(() => {
    if (user && user.id) {

      dispatch(
        actions.fetchDataSetBatchPrivateWorkspace(
          user.id
        )
      );
    }
  }, [user]);


  React.useEffect(() => {
    let defaultFolderIdNumber = entityDataRepo[0] && entityDataRepo[0]._id
    setDefaultFolderId(defaultFolderIdNumber)
  }, [entityDataRepo])

  const actionsByType = {
    projectDataRepository: {
      fetchProjectDataRepositorySharedWorkspace:
        actions.fetchProjectDataRepositorySharedWorkspace,
      fetchProjectDataRepositoryPrivateWorkspace:
        actions.fetchProjectDataRepositoryPrivateWorkspace,
      deleteParentFolderFromRepository: actions.deleteParentFolderFromRepository,
      deleteFolderFromRepositoryAndSave: actions.deleteFolderFromRepositoryAndSave,
      deleteFileFromRepository: actions.deleteFileFromRepository,
      addFolderToRepository: actions.addFolderToRepository,
      addRootFolderToRepository: actions.addRootFolderToRepository,
      exportFileFromRepository: actions.exportFileFromRepository,
      importFileToRepository: actions.importFileToRepository,
    },
    datasets: {
      fetchProjectDataRepositorySharedWorkspace:
        actions.fetchDataSetBatchSharedWorkspace,
      fetchProjectDataRepositoryPrivateWorkspace:
        actions.fetchDataSetBatchPrivateWorkspace,
      deleteParentFolderFromRepository:
        actions.deleteParentFolderFromRepositoryDataSetBatch,
      deleteFolderFromRepositoryAndSave: actions.deleteParentFolderFromRepositoryAndSaveyDataSetBatch,
      deleteFileFromRepository: actions.deleteFileFromRepositoryDataSetBatch,
      addFolderToRepository: actions.addFolderToRepositoryDataSetBatch,
      addRootFolderToRepository: actions.addRootFolderToRepositoryDataSetBatch,
      exportFileFromRepository: actions.exportFileFromRepositoryDataSetBatch,
      importFileToRepository: actions.importFileToRepositoryDataSetBatch,
    },
    rules: {
      fetchProjectDataRepositorySharedWorkspace:
        actions.fetchRulesDataRepositorySharedWorkspace,
      fetchProjectDataRepositoryPrivateWorkspace:
        actions.fetchRulestDataRepositoryPrivateWorkspace,
      deleteParentFolderFromRepository:
        actions.deleteParentFolderFromRepositoryRules,
      deleteFileFromRepository: actions.deleteFileFromRepositoryRules,

      addFolderToRepository: actions.addFolderToRepositoryRules,
      deleteFolderFromRepositoryAndSave: actions.deleteFolderFromRepositoryAndSaveRules,
      addRootFolderToRepository: actions.addRootFolderToRepositoryRules,
      exportFileFromRepository: actions.exportFileFromRepositoryRules,
      importFileToRepository: actions.importFileToRepositoryRules,
    },
  };




  function onChangeName(event) {
    setSelectedFormatName(event.target.value)
  }
  function onChangeSeparator(event) {
    setSeparator(event.target.value)
  }



  async function onUpload(fileName) {
    setFileUploadName(fileName)

    var sep = separator
    if (sep === ";") {
      sep = "semicolon"
    }
    else if (sep === "|") {
      sep = "pipe"
    }


    const errors = validate()
    setError(errors)

    const response = await dispatch(actions.parseDataFormatBlob(sep, fileName, user.id))

    setColFormats(response.data.columns)




  }


  function saveDataformatCallback(event) {
    const validateResult = validate()
    setError(validateResult)

    let dtoId = selectedDtoId
    let folderId = selectedItem.itemId === selectedDtoId ? 'null' : selectedItem.itemId

    if ((Object.keys(validateResult).length === 0)) {
      if (((process.env.REACT_APP_DATA_REPOSITORY_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true') || (process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true'))) {
        dispatch(actions.addDataFormatAndRegister(colFormats, selectedFormatName, user.id, dtoId, folderId)).then(response =>
          dispatch(actions.fetchDataSetBatchPrivateWorkspace(user.id)))
      }
      else {
        dispatch(actions.addDataFormat(colFormats, selectedFormatName, user.id))
      }

      props.setClose(false)
    }
  }

  function validate() {
    var errors = {};


    if (!selectedFormatName || selectedFormatName === null) {
      errors.selectedFormatName = 'You must provide a File name';

    }
    if (!separator || separator === null) {
      errors.separator = 'You must select a Separator';

    }

    if (((process.env.REACT_APP_DATA_REPOSITORY_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true') || (process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true')) &&
      selectedItem.type !== "Folder") {
      errors.folder = 'A folder needs to be selected in Private Workspace. You have selected a file.'

    }
    setError(errors)

    return errors;
  }

  function setColFormatsCallback(newFormat) {
    setColFormats(newFormat)
  }


  return (
    <AqtivaDialog visible={props.open}
      fullWidth={true}
      maxWidth={'lg'}
      title={'Create Dataformat'}
      titleIcon={faTable}
      confirmText={'Create'}
      cancelText={'Close'}
      confirmCallback={saveDataformatCallback}
      cancelCallback={event => props.setClose(false)}
    >



      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <CustomInput
              labelText="DataFormat Name"
              id="dataformat_name_id"
              formControlProps={{
                fullWidth: false,
                style: { width: '60%' }
              }}

              inputProps={{
                type: "text",
                onChange: onChangeName,
                value: selectedFormatName,
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faSignature} style={{ fontSize: 20 }} />

                  </InputAdornment>
                )
              }}
            />
            {error && error.selectedFormatName &&
              <div className="ui error message" style={theme.errors.errorText}>
                <div className="header" >{error.selectedFormatName}</div>
              </div>}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={checkAutomaticSeparator}
                  onChange={event => {
                    setCheckAutomaticSeparator(event.target.checked);
                    setSeparator('automatic')
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={<Typography variant='body2'> Automatic Separator Estimation</Typography>}
            />
          </Grid>

          {!checkAutomaticSeparator &&
            <Grid item xs={12}>
              <CustomInput
                labelText="Separator"
                id="separator_id"
                formControlProps={{
                  fullWidth: false,
                  style: { width: '30%' }
                }}

                inputProps={{
                  type: "text",
                  onChange: onChangeSeparator,
                  value: separator,
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faCode} style={{ fontSize: 20 }} />
                    </InputAdornment>
                  )
                }}
              />
              {error && error.separator &&
                <div className="ui error message" style={{ width: '300px', fontSize: '10px' }}>
                  <div className="header" >{error.separator}</div>
                </div>}
            </Grid>
          }


          <Grid item xs={12}>
            <div style={{ marginTop: '5%' }}>
              <InputFile onUpload={onUpload} folder={'dataformatTmp'}
                storageAccountName={props.configuration ? props.configuration.storageAccountName : 'sainputdemoquality'}
                containerName={props.configuration ? props.configuration.containerName : 'unit-test'}
                sasToken={props.configuration ? props.configuration.sasToken : '?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-09-11T14:57:13Z&st=2020-06-22T06:57:13Z&spr=https&sig=f18IZ5vTdx%2FkwLGFjAEhpRZ%2FBZkS%2B3d5Zrv5c1xZZfc%3D'}
                buttonText={'Upload File'} />
            </div>


          </Grid>
          {((process.env.REACT_APP_DATA_REPOSITORY_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true') || (process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true')) &&
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              <div >
                {error && error.folder &&
                  <div className="ui error message" style={theme.errors.errorText}>
                    <div className="header" >{error.folder}</div>
                  </div>} </div>
              <div style={{ marginBottom: '20px' }}>
                <Typography variant='caption' >Data Repository Folder Selection</Typography>
              </div>
              <DataRepositoryTreeTable
                actions={actionsByType.datasets}
                enableContextMenu={false}
                showTitle={false}
                type="private"
                dtoData={entityDataRepo}
                defaultFolderId={defaultFolderId}
                treeData={convertDataHelper.parseEntityDataRepositoryToTree(
                  entityDataRepo
                )}
                iconTitle={faUserLock}
                title={`Select Folder in Private Workspace`}
                titleStyle={{ color: "#45b6fe", margin: 0 }}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                setShowDialogs={{ export: undefined, new: undefined }}
                showDialogs={{ new: undefined }}
                expandedKeys={expandedKeys}
                setExpandedKeys={setExpandedKeys}
                user={user}
              />
            </Grid>
          }



        </Grid>
        <Grid item xs={6}>
          <TablePrevisualization
            columnFormats={colFormats}
            setColumnFormats={setColFormatsCallback}
          />
        </Grid>
      </Grid>
    </AqtivaDialog>

  )

}