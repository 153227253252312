import {combineReducers} from 'redux';
import projectsReducer from './projectsReducer';
import dataformatsReducer from './dataformatsReducer';
import configurationReducer from './configurationReducer';
import recordsReducer from './recordsReducer';
import recordsIntervalReducer from './recordsIntervalReducer';
import simulationsReducer from './simulationsReducer';
import simulationReducer from './simulationReducer';
import errorReducer from './errorReducer';
import licenseReducer from './licenseReducer'
import userReducer from './userReducer'
import infoReducer from './infoReducer'
import ruleRecommenderReducer from './ruleRecommenderReducer'
import fetchJobOutputReducer from './fetchJobOutputReducer'
import streamConfigurationReducer from './streamConfigurationReducer'
import projectDataRepositoryReducer from './projectDataRepositoryReducer';
import projectDataRepositorySharedReducer from './projectDataRepositorySharedReducer';
import datasetBatchDataRepositoryReducer from './datasetBatchDataRepositoryReducer';
import datasetBatchDataRepositorySharedReducer from './datasetBatchDataRepositorySharedReducer';
import rulesDataRepositoryReducer from './rulesDataRepositoryReducer';
import rulesDataRepositorySharedReducer from './rulesDataRepositorySharedReducer'

import notificationsMenuReducer from './notificationsMenuReducer';
import allNotificationsMenuReducer from './allNotificationsMenuReducer';
import KPISReducer from './KPISReducer'
import TotalRecordsReducer from './TotalRecordsReducer'
import TotalRecordsTableReducer from './TotalRecordsTableReducer'
import dashboardTimeReducer from './dashboardTimeReducer'

export default combineReducers(
    {
    projects : projectsReducer,
    dataformats : dataformatsReducer,
    qualityConfiguration : configurationReducer,
    records: recordsReducer,
    recordsInterval: recordsIntervalReducer,
    simulations: simulationsReducer,
    actualSimulation: simulationReducer,
    errorMessage: errorReducer,
    infoMessage: infoReducer,
    user:userReducer,
    license: licenseReducer,
    streamConfiguration: streamConfigurationReducer,
    ruleRecommender: ruleRecommenderReducer,
    fetchJobOutput: fetchJobOutputReducer,
    projectDataRepository: projectDataRepositoryReducer,
    projectDataRepositoryShared: projectDataRepositorySharedReducer,
    datasetBatchDataRepository:datasetBatchDataRepositoryReducer,
    datasetBatchDataRepositoryShared:datasetBatchDataRepositorySharedReducer,
    rulesDataRepository:rulesDataRepositoryReducer,
    rulesDataRepositoryShared:rulesDataRepositorySharedReducer,
    notifications: notificationsMenuReducer,
    allNotifications: allNotificationsMenuReducer,
    KPIS: KPISReducer,
    totalRecords: TotalRecordsReducer,
    totalRecordsTable: TotalRecordsTableReducer,
    dashboardDates: dashboardTimeReducer

}
);