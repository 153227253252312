
import React from 'react';
import {
    Grid, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, InputAdornment, Icon,
    Tabs, Tab, FormControlLabel, Switch, Tooltip
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faSearch } from '@fortawesome/free-solid-svg-icons'
import CustomInput from "../../../../../../common/CustomInput/CustomInput.js";
import PreviewDataformat from './PreviewDataformat'
import AdvanceConfigurationQuality from '../../../../../../common/AdvanceConfigurationQuality'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../../../../actions'

import QualityItemSelector from '../../../../../../common/QualityItemSelector'
import DialogCreateDataFormat from '../DialogCreateDataFormat'
import { QualityTheme } from '../../../../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';

import { faHubspot } from '@fortawesome/free-brands-svg-icons'
import { faFileUpload, faFileImport } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@material-ui/core/styles';
import trackEvent from './../../../../../../../trackEvent'
import SelectHierarchyEhubs from '../../../../../../common/SelectHierarchyEhubs/index.js';
import { LoadingComponent } from './LoadingComponent.js/index.js';
import AqtivaDialog from '../../../../../../common/AqtivaDialog/index.js';
import DialogViewDetailTopic from './../../Dialogs/DialogViewDetailTopic'
import { AqtivaDialogDataRepository } from './../../../../../DataRepository/EntityDataRepository/AqtivaDialogDataRepository'
import { TabsBar } from './../../../../../DataRepository/Tabs'
import * as sseApi from './../../../../../../../apis/sseApi';

import * as datasourceHelper from './CreaterDataSourceHelper'

export default function DialogCreateDataSource(props) {

    const theme = useTheme();
    const dispatch = useDispatch();

    /******* STORE OBJECTS ********/
    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license)
    const dataformats = useSelector(store => Object.values(store.dataformats))
    var qualityConfigurationSelector = useSelector(store => (store.qualityConfiguration))

    /******* CONTROL VARIABLES ********/
    const activateStream = (license && (license.lambdaActivate === true || !license.lambdaActivate))//variable to activate stream capabilities
    const [tabValue, setTabValue] = React.useState(props.datasource && props.datasource.inputConnector ? 1 : 0)
    const [editable, setEditable] = React.useState(props.datasource ? true : false)
    const [loadingPreviewData, setLoadingPreviewData] = React.useState(false)
    const [noDataStreamFound, setNoDataStreamFound] = React.useState(false)
    const [datasourceType, setDatasourceType] = React.useState(props.datasource && props.datasource.inputConnector ? 'REAL_TIME_LAMBDA' : 'BATCH')

    /******* DIALOG CONTROL VARIABLES ********/
    const [showDialogCreateDataformat, setShowDialogCreateDataformat] = React.useState(false)
    const [openDialogDataRepository, setOpenDialogDataRepository] = React.useState(false)

    /******* FORM VARIABLES  ********/
    const [name, setName] = React.useState(props.datasource ? props.datasource.name : '')
    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState('')
    const [selectedDataFormat, setSelectedDataFormat] = React.useState('')
    const [thError, setThError] = React.useState(props.datasource ? props.datasource.errorTh : (props.qualityPoint ? props.qualityPoint.errorTh : {}))
    const [thWarning, setThWarning] = React.useState(props.datasource ? props.datasource.warningTh : (props.qualityPoint ? props.qualityPoint.warningTh : {}))
    const [wrongRegisterAction, setWrongRegisterAction] = React.useState(props.datasource ? props.datasource.wrongRegisterActionType : (props.qualityPoint ? props.qualityPoint.wrongRegisterActionType : ''))
    const [outputMode, setOutputMode] = React.useState(props.datasource ? props.datasource.controlModeType : (props.qualityPoint ? props.qualityPoint.controlModeType : ''))

    const [selectedStreamDataFormat, setSelectedStreamDataFormat] = React.useState(props.datasource && props.datasource.inputConnector ? props.datasource.inputConnector : undefined)
    const [selectedOutputStreamDataFormat, setSelectedOutputStreamDataFormat] = React.useState(props.datasource && props.datasource.inputConnector ? props.datasource.outputConnector : undefined)
    const [selectedOutputOKStreamDataFormat, setSelectedOutputOKStreamDataFormat] = React.useState(props.datasource && props.datasource.outputOKConnector ? props.datasource.outputOKConnector : undefined)
    const [selectedOutputKOStreamDataFormat, setSelectedOutputKOStreamDataFormat] = React.useState(props.datasource && props.datasource.outputKOConnector ? props.datasource.outputKOConnector : undefined)

    const [selectedStreamDataFormatConnStr, setSelectedStreamDataFormatConnStr] = React.useState()
    const [selectedOutputStreamDataFormatConnStr, setSelectedOutputStreamDataFormatConnStr] = React.useState()
    const [selectedOutputOKStreamDataFormatConnStr, setSelectedOutputOKStreamDataFormatConnStr] = React.useState()
    const [selectedOutputKOStreamDataFormatConnStr, setSelectedOutputKOStreamDataFormatConnStr] = React.useState()

    const [streamEndPoint, setStreamEndPoint] = React.useState()
    const [streamEntityPath, setStreamEntityPath] = React.useState()
    const [streamSak, setStreamSak] = React.useState()
    const [streamSakName, setStreamSakName] = React.useState()


    /******* ERROR VARIABLES ********/
    const [errorTypes, setErrorTypes] = React.useState();
    const [errorMsgs, setErrorMsgs] = React.useState();
    const [hasErrors, setHasErrors] = React.useState()

    const [checkSplitOutput, setCheckSplitOutput] = React.useState(true)
    const [visibleDetailStream, setVisibleDetailStream] = React.useState(false)

    /******* EVENT DATA VARIABLES ********/
    const [rawConsumers, setRawConsumers] = React.useState([]) // info obtain from ehubs to be parsed to tree structured
    const [streams, setStreams] = React.useState([]) // info obtain from ehubs to be parsed to tree structured
    React.useEffect(() => {
      console.log('selectedStreamDataFormat',selectedStreamDataFormat)
    }, [selectedStreamDataFormat]);

    /******* INIT FUNCTION ********/
    React.useEffect(() => {
        sseApi.sseReceiver(process.env.REACT_APP_BACK_HOST+'/azure/eventhub/list/simple', onOpen, onMessage, onClose)
        setSelectedDataFormat(props.datasource && dataformats && dataformats.length > 0 ? dataformats.filter(x => x.name === props.datasource.dataset.name)[0] : '')

    }, []);

    /******* UPDATE EFFECTS ********/

    React.useEffect(() => { 
        datasourceHelper.createConsumerTreeView(rawConsumers, setStreams)
        
    }, [rawConsumers])

    

    React.useEffect(() => { 
        if (user && user.id){
             dispatch(actions.fetchConfigurationByUserId(user.id)) 
             dispatch(actions.fetchDataFormats(user))
        }
    
    }, [user]);

    React.useEffect(() => {
        if (props.datasource && dataformats.length > 0 && selectedDataFormat === '') {
            setSelectedDataFormat(dataformats.filter(x => x.name === props.datasource.dataset.name)[0])
        }
    }, [dataformats]);

    //set editable elements if qualityPoint is found in props
    React.useEffect(() => {

        setEditable(props.datasource ? true : false)
        setName(props.datasource ? props.datasource.name : '')
        setThError(props.datasource ? props.datasource.errorTh : (props.qualityPoint ? props.qualityPoint.errorTh : {}))
        setThWarning(props.datasource ? props.datasource.warningTh : (props.qualityPoint ? props.qualityPoint.warningTh : {}))
        setWrongRegisterAction(props.datasource ? props.datasource.wrongRegisterActionType : (props.qualityPoint ? props.qualityPoint.wrongRegisterActionType : ''))
        setOutputMode(props.datasource ? props.datasource.controlModeType : (props.qualityPoint ? props.qualityPoint.controlModeType : ''))

        if (props.datasource && props.datasource.dataset) {
            if (props.datasource) {
                if (dataformats.filter(x => x.name === props.datasource.dataset.name)[0]) {
                    setSelectedDataFormat(dataformats.filter(x => x.name === props.datasource.dataset.name)[0])
                }

            }
        }


    }, [props.datasource]);

    React.useEffect(() => {validate()}, [selectedDataFormat, selectedQualityPoint]);

    React.useEffect(() => {
        setSelectedQualityPoint(props.qualityPoint)
        setThError(props.datasource ? props.datasource.errorTh : (props.qualityPoint ? props.qualityPoint.errorTh : undefined))
        setThWarning(props.datasource ? props.datasource.warningTh : (props.qualityPoint ? props.qualityPoint.warningTh : undefined))
        setWrongRegisterAction(props.datasource ? props.datasource.wrongRegisterActionType : (props.qualityPoint ? props.qualityPoint.wrongRegisterActionType : undefined))
        setOutputMode(props.datasource ? props.datasource.controlModeType : (props.qualityPoint ? props.qualityPoint.controlModeType : undefined))
    }, [props.qualityPoint]);


    React.useEffect(() => {
        setThError(selectedQualityPoint ? selectedQualityPoint.errorTh : undefined)
        setThWarning(selectedQualityPoint ? selectedQualityPoint.warningTh : undefined)
        setWrongRegisterAction(selectedQualityPoint ? selectedQualityPoint.wrongRegisterActionType : undefined)
        setOutputMode(selectedQualityPoint ? selectedQualityPoint.controlModeType : undefined)
    }, [selectedQualityPoint]);


    //helper functions
    function onMessage(event) {
        const eventData = JSON.parse(event.data);
        setRawConsumers(rawConsumers => [...rawConsumers, eventData]);
    }
    function onOpen(event) {
        console.log('open', event)
    }

   
    function onClose(event) {
        
        setSelectedStreamDataFormat(props.datasource && props.datasource.inputConnector ? 
            {
            type: 'eventhub',
            name: props.datasource.inputConnector.name,
            namespace: props.datasource.inputConnector.namespace,
            resourcegroup : props.datasource.inputConnector.resourcegroup
        } : undefined)
        console.log('error', event)
    }

    function reset() {
        setName('')
        setThError({})
        setThWarning({})
        setWrongRegisterAction('')
        setOutputMode('')
        setShowDialogCreateDataformat(false)
        setErrorTypes(undefined)
        setErrorMsgs(undefined)
        setDatasourceType('BATCH')
        setCheckSplitOutput(true)
    }


    
    /******* UPDATE CALLBACKS ********/
    function handleTabChange(event, newValue) {
        setTabValue(newValue)
    }

    //function to update control elmenets
    function setControlObj(data) {
        setWrongRegisterAction(data.wrongRegisterActionType)
        setOutputMode(data.controlModeType)
    }

    const onChangeTextField = (event) => {
        setName(event.target.value)
        validate()
    }

    //callback to save Datasource
    const createDatasourceV1 = () => {
        const datasource = {
            name: name,
            active: true,
            userId: user.id,
            errorTh: thError,
            warningTh: thWarning,
            controlModeType: outputMode,
            wrongRegisterActionType: wrongRegisterAction,
            dataset: selectedDataFormat,
            type: datasourceType

        }
        dispatch(actions.addDatasourceV1(props.project._id, selectedQualityPoint._id, datasource))

    }

    //callback to save QualityPoint
    async function createDatasourceV2() {

        var dataformatStream = Object.assign({}, selectedDataFormat)
        dataformatStream.format = 'json'
        dataformatStream.name = selectedStreamDataFormat.name
        const azureCredentials = qualityConfigurationSelector.azureCredentials


        const datasource = {
            name: name,
            active: true,
            userId: user.id,
            errorTh: thError,
            warningTh: thWarning,
            controlModeType: outputMode,
            wrongRegisterActionType: wrongRegisterAction,
            dataset: dataformatStream,
            datasourceType: datasourceType,
            inputConnector: await createConnector(selectedStreamDataFormat, selectedStreamDataFormatConnStr, azureCredentials),
            outputDefaultConnector: await createConnector(selectedOutputStreamDataFormat, selectedOutputStreamDataFormatConnStr, azureCredentials),
            outputOKConnector: await createConnector(selectedOutputOKStreamDataFormat, selectedOutputOKStreamDataFormatConnStr, azureCredentials),
            outputKOConnector: await createConnector(selectedOutputKOStreamDataFormat, selectedOutputKOStreamDataFormatConnStr, azureCredentials)

        }

        dispatch(actions.addDatasourceV1(props.project._id, selectedQualityPoint._id, datasource))

    }

    async function createConnection(stream, azureCredentials) {
        var result = undefined
        await dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            stream.resourcegroup, stream.namespace, stream.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    result = response.data.connectionStrings[0]
                }

            }).catch(error => {
                result = undefined
            })

        return result
    }

    async function createConnector(stream, connStr, azureCredentials) {

        if (!stream) {
            return undefined
        }

        const connector = {
            type: 'event_hubs',
            name: stream.name,
            namespace: stream.namespace,
            resourcegroup: stream.resourcegroup,
            conn_str: connStr ? connStr : await createConnection(stream, azureCredentials)

        }
        return connector
    }

    //callback to save QualityPoint
    const modifyDatasource = () => {
        var newDatasource = Object.assign(props.datasource)
        newDatasource.name = name
        newDatasource.active = true
        newDatasource.userId = user.id
        newDatasource.errorTh = thError
        newDatasource.warningTh = thWarning
        newDatasource.controlModeType = outputMode
        newDatasource.wrongRegisterActionType = wrongRegisterAction
        newDatasource.dataset = selectedDataFormat

        dispatch(actions.editDatasource(props.project._id, selectedQualityPoint._id, newDatasource))

    }

    //wrapper to save QualityPoint
    const saveItem = () => {
        const { hasErrors, arrayErrorTypes, arrayErrorMsgs } = validate()
        if (!hasErrors) {


            if (tabValue === 1) {
                //es stream, uso v2
                createDatasourceV2()
                props.setClose(false)
                props.clearSelectedItem()
            }
            else {
                //es batch, uso v1
                createDatasourceV1()
                props.setClose(false)
                props.clearSelectedItem()
            }

            reset()


        }
    }

    //wrapper to edit QualityPoint
    const editItem = () => {
        const { hasErrors, arrayErrorTypes, arrayErrorMsgs } = validate()
        if (!hasErrors) {
            modifyDatasource()
            props.setClose(false)
            props.clearSelectedItem()
        }
    }

    function onChangeQPCallback(event) {

        setSelectedQualityPoint(event.target.value)
        validate()
    }

    function onChangeDFCallback(event) {
        setDatasourceType('BATCH')
        setSelectedDataFormat(event.target.value)
        validate()
    }
    function onChangeStreamDFCallback(event) {
        setSelectedStreamDataFormat(event.target.value)
        setLoadingPreviewData(true)
        const data = event.target.value
        const azureCredentials = qualityConfigurationSelector.azureCredentials

        dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            data.resourcegroup, data.namespace, data.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    setSelectedStreamDataFormatConnStr(response.data.connectionStrings[0])
                    listenEventHubs(response.data.connectionStrings[0])
                    validate()
                }

            }
            )


    }
    function onChangeOutputStreamDFCallback(event) {
        setSelectedOutputStreamDataFormat(event.target.value)
        validate()
        const azureCredentials = qualityConfigurationSelector.azureCredentials
        const data = event.target.value
        dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            data.resourcegroup, data.namespace, data.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    setSelectedOutputStreamDataFormatConnStr(response.data.connectionStrings[0])

                }

            })
    }
    function onChangeOutputOKStreamDFCallback(event) {
        setSelectedOutputOKStreamDataFormat(event.target.value)
        const azureCredentials = qualityConfigurationSelector.azureCredentials
        const data = event.target.value
        dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            data.resourcegroup, data.namespace, data.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    setSelectedOutputOKStreamDataFormatConnStr(response.data.connectionStrings[0])

                }

            })
        validate()
    }
    function onChangeOutputKOStreamDFCallback(event) {
        setSelectedOutputKOStreamDataFormat(event.target.value)
        const azureCredentials = qualityConfigurationSelector.azureCredentials
        const data = event.target.value
        dispatch(actions.getStreamEventHubDetails(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription,
            data.resourcegroup, data.namespace, data.name)).then(response => {
                if (response.data && response.data.connectionStrings && response.data.connectionStrings.length > 0) {
                    setSelectedOutputKOStreamDataFormatConnStr(response.data.connectionStrings[0])

                }

            })
        validate()
    }



    function getControlObj() {return { wrongRegisterAction: wrongRegisterAction, outputMode: outputMode }}

    /*
    * ERROR FORM HANDLER FUNCTIONS
    */
    function isError(name) {
        if (!errorTypes) {
            return false
        }
        return errorTypes.includes(name)
    }

    function getErrorMessage(name) {
        var idx = errorTypes.indexOf(name);
        if (idx === -1) {
            return ''
        }
        else {
            return errorMsgs[idx]
        }
    }

    function validate() {
        var hasErrors = false
        const arrayErrorTypes = []
        const arrayErrorMsgs = []


        if (!name) {
            hasErrors = true
            arrayErrorTypes.push('name')
            arrayErrorMsgs.push('A name must be provided')
        }
        if (!selectedQualityPoint) {
            hasErrors = true
            arrayErrorTypes.push('selectedQualityPoint')
            arrayErrorMsgs.push('A Qualit Point must be selected')
        }
        if (tabValue === 0 && !selectedDataFormat) {
            hasErrors = true
            arrayErrorTypes.push('selectedDataFormat')
            arrayErrorMsgs.push('A Dataformat must be provided')
        }


        setHasErrors(hasErrors)
        setErrorTypes(arrayErrorTypes)
        setErrorMsgs(arrayErrorMsgs)

        return { hasErrors, arrayErrorTypes, arrayErrorMsgs };

    }



    function listenEventHubs(stream) {
        setDatasourceType('REAL_TIME_LAMBDA')
        let endPoint = stream.split(';')[0].split('Endpoint=')[1].split("/").join("_()_")
        let entityPath = stream.split(';')[3].split('EntityPath=')[1].split("/").join("_()_")
        let sak = stream.split(';')[2].split('SharedAccessKey=')[1].split("/").join("_()_")
        let sakName = stream.split(';')[1].split('SharedAccessKeyName=')[1].split("/").join("_()_")

        setStreamEndPoint(endPoint)
        setStreamEntityPath(entityPath)
        setStreamSak(sak)
        setStreamSakName(sakName)

        setSelectedDataFormat(undefined)



        dispatch(actions.getStatisticsFromStream(user.id, endPoint, entityPath, sakName, sak)).then(response => {
            if (response && response.data) {
                if (response.result === 'ok') {
                    setSelectedDataFormat(response.data)
                    setLoadingPreviewData(false)
                    setNoDataStreamFound(false)
                }
                else {
                    setLoadingPreviewData(false)
                    setNoDataStreamFound(true)
                }

            }

        }
        )


    }

    function onCloseDialogRepository(value) {
        dispatch(actions.fetchDataFormats(user))
        setOpenDialogDataRepository(value)
    }


    return (
        <AqtivaDialog visible={props.open}
            fullWidth={true}
            maxWidth={'md'}
            title={editable ? 'Edit Datasource' : 'Create Datasource'}
            editable={editable}
            titleIcon={faDatabase}
            confirmText={'Save'}
            cancelText={'Cancel'}
            editText={'Edit'}
            confirmCallback={saveItem}
            cancelCallback={event => {
                reset()
                props.setClose(false)
            }}
            editCallback={editItem}
        >
            {openDialogDataRepository && <AqtivaDialogDataRepository
                dialog={openDialogDataRepository}
                setDialog={onCloseDialogRepository}
                title="Data Repository"
                showConfirmButton={false}

                titleIcon={faDatabase}
                confirmCallback={(event) => console.log("I am clicking here")}
            >
                <TabsBar user={user} defaultValue={1} />
            </AqtivaDialogDataRepository>}

            <DialogViewDetailTopic visible={visibleDetailStream} close={setVisibleDetailStream}
                endPoint={streamEndPoint}
                entityPath={streamEntityPath}
                sak={streamSak}
                sakName={streamSakName}
            />

            {activateStream && process.env.REACT_APP_AFUNCTION_ALLOWED === 'true' &&
                <Tabs value={tabValue} onChange={handleTabChange}
                    variant="scrollable" scrollButtons="off"
                    style={{ width: '100%', marginTop: '-10px' }}>
                    <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                        <FontAwesomeIcon icon={faFileUpload} style={{ marginRight: '5%', marginTop: '10%', color: '#76b3d6', fontSize: 15 }} />
                        <Typography variant='subtitle2' style={{ marginTop: '5px' }}>
                            {`Batch`}</Typography> </div>}
                    />
                    <Tab label={<div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                        <FontAwesomeIcon icon={faHubspot} style={{ marginRight: '%', marginTop: '10%', color: '#76b3d6', fontSize: 15 }} />
                        <Typography variant='subtitle2' style={{ marginTop: '5px' }}>
                            {`Stream `}</Typography> </div>} />
                </Tabs>
            }

            {showDialogCreateDataformat &&
                <DialogCreateDataFormat
                    configuration={props.configuration}
                    open={showDialogCreateDataformat}
                    setClose={setShowDialogCreateDataformat} />
            }
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <div>
                            <CustomInput
                                labelText="Datasource Name"
                                id="ProjectName"
                                formControlProps={{
                                    fullWidth: false,
                                    style: { width: '80%' }
                                }}

                                inputProps={{
                                    type: "text",
                                    onChange: onChangeTextField,
                                    value: name,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon className="fas fa-signature" />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <div >
                                {hasErrors && isError('name') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('name')}</Typography>}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>


                            <QualityItemSelector
                                title={'Select a Quality Point'} type='QualityPoint' value={selectedQualityPoint} onChange={onChangeQPCallback}
                                itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints : [])}
                                itemId={'_id'} itemValue={'name'}
                            />
                            <div >
                                {hasErrors && isError('selectedQualityPoint') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('selectedQualityPoint')}</Typography>}
                            </div>
                        </div>
                    </Grid>
                    {tabValue === 0 &&
                        <div>
                            <Grid item xs={12}>

                                <div>
                                    <div style={theme.elementAdjacent}>

                                        <div style={{ width: '90%' }}>
                                            <QualityItemSelector
                                                title={'Select a Data Format'} type='DataFormat' value={selectedDataFormat} onChange={onChangeDFCallback}
                                                itemsArray={dataformats}
                                                itemId={'_id'} itemValue={'name'}
                                            />
                                        </div>
                                        <Tooltip title='Import DataFormat from Data Repository'>
                                            <div>
                                                <FontAwesomeIcon icon={faFileImport}
                                                    style={{ color: '#4697c4', fontSize: '20px', marginTop: '30px', marginLeft: '-60px', cursor: 'pointer' }}
                                                    onClick={event => {
                                                        trackEvent('DialogCreateDataSource', 'Data Repository')
                                                        setOpenDialogDataRepository(true)
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div >
                                        {hasErrors && isError('selectedDataFormat') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('selectedDataFormat')}</Typography>}
                                    </div>
                                </div>





                            </Grid>


                            <Grid item xs={10}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button variant='outlined' style={theme.buttons.buttonConfirm}
                                            onClick={event => {
                                                trackEvent('DialogCreateDataSource', 'Add Dataformat')
                                                setShowDialogCreateDataformat(true)
                                            }}>
                                            <i className="icon plus"></i>
                                            Add Dataformat
                                </Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </div>
                    }
                    {tabValue === 1 &&
                        <div>
                            <Grid item xs={12}>
                                <div style={theme.elementAdjacent}>
                                    <div style={{ width: '90%' }}>
                                        <SelectHierarchyEhubs title={'Select a Stream Source'}
                                            streams={streams}
                                            value={selectedStreamDataFormat} onChange={onChangeStreamDFCallback} />
                                        <div  >
                                            {hasErrors && isError('selectedDataFormat') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('selectedDataFormat')}</Typography>}
                                        </div>
                                    </div>
                                    <div style={{ width: '20px' }}>
                                        <FontAwesomeIcon icon={faSearch} onClick={event => setVisibleDetailStream(true)}
                                            style={{ color: '#4697c4', fontSize: '15px', marginTop: '40px', marginLeft: '-60px', cursor: 'pointer' }} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checkSplitOutput}
                                            onChange={event => {
                                                setCheckSplitOutput(event.target.checked);
                                            }}
                                            name="checkSplitOutput"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant='body2'> Automatic Separator Estimation</Typography>}
                                />
                            </Grid>
                            {checkSplitOutput &&
                                <div>
                                    <Grid item xs={12}>
                                        <SelectHierarchyEhubs title={'Select an Output Stream for Ok Data'}
                                            streams={streams}
                                            value={selectedOutputOKStreamDataFormat}
                                            onChange={onChangeOutputOKStreamDFCallback}
                                            enableCreation={true} />
                                        <div  >
                                            {hasErrors && isError('selectedOutputOKDataFormat') &&
                                                <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('selectedOutputOKDataFormat')}</Typography>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectHierarchyEhubs title={'Select an Output Stream for KO Data'}
                                            streams={streams}
                                            value={selectedOutputKOStreamDataFormat}
                                            onChange={onChangeOutputKOStreamDFCallback}
                                            enableCreation={true} />

                                        <div  >
                                            {hasErrors && isError('selectedOutputKODataFormat') &&
                                                <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('selectedOutputKODataFormat')}</Typography>}
                                        </div>
                                    </Grid>
                                </div>

                            }
                            {!checkSplitOutput &&
                                <Grid item xs={12}>
                                    <QualityItemSelector
                                        title={'Select an Output Stream '} type='StreamDataformat' value={selectedOutputStreamDataFormat}
                                        onChange={onChangeOutputStreamDFCallback}
                                        itemsArray={qualityConfigurationSelector && qualityConfigurationSelector.eventHubs ? qualityConfigurationSelector.eventHubs : []}
                                        itemId={'entityPath'} itemValue={'entityPath'}
                                    />
                                    <div  >
                                        {hasErrors && isError('selectedOutputDataFormat') &&
                                            <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('selectedOutputDataFormat')}</Typography>}
                                    </div>
                                </Grid>
                            }
                        </div>
                    }


                </Grid>

                {loadingPreviewData && <Grid item xs={6} >
                    <div style={{ marginTop: '10%' }}>
                        <LoadingComponent />
                    </div>
                </Grid>}

                {noDataStreamFound && <Grid item xs={6} >
                    <div style={{ marginTop: '10%' }}>
                        <LoadingComponent noData={true} />
                    </div>
                </Grid>}

                {!loadingPreviewData && <Grid item xs={6} >
                    <div style={{ marginTop: '10%' }}>
                        {selectedDataFormat && <PreviewDataformat dataformat={selectedDataFormat} />}
                    </div>
                </Grid>}


            </Grid>
            <AdvanceConfigurationQuality setThError={setThError} setThWarning={setThWarning} setControlObj={setControlObj}
                thError={thError}
                thWarning={thWarning}
                controlObj={getControlObj()} />




        </AqtivaDialog>
    )
}