
import React from 'react';
import { Grid,Dialog,DialogTitle,Typography,DialogContent,DialogActions,Button, InputAdornment,Icon,
    TextField} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import CustomInput from "../../common/CustomInput/CustomInput.js";

import {useSelector,useDispatch} from 'react-redux'
import * as actions  from '../../../actions'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles';

import trackEvent from './../../../trackEvent'

const useStyles = makeStyles((theme) => ({
    elementAdjacent: {
        display: 'flex',
        justifyContent: 'left' 
      },
    
  }));

export default function DialogCreateQualityPoint(props) {

    const classes = useStyles();

    const user = useSelector(store => store.user)
    const dispatch = useDispatch();

    const cellTextSize=3
    const cellValueSize=9

    const [endPoint, setEndPoint] = React.useState('')
    const [entityPath, setEntityPath] = React.useState('')
    const [sakName, setSakName] = React.useState('')
    const [eventHubName, setEventHubName] = React.useState('')
    const [eventHubNames, setEventHubNames] = React.useState([])
    const [sak, setSak] = React.useState('')
    const [eventHubNameSpaceConnStr, setEventHubNameSpaceConnStr] = React.useState('')
    const [eventHubs2Show, setEventHubs2Show]= React.useState([])


   
     

    
    function saveEventHubs(){
        trackEvent('DialogAddEventHub','Save EventHub');

        var jsonObj=[];
        const newData = eventHubNames.forEach(function(val,key){
            var tempObj={
                endPoint: endPoint,
                entityPath: val,
                sakName: sakName,
                sak: sak
            }
            jsonObj.push(tempObj)
        })
        props.updateEventHubsData(jsonObj)
        onClose()
    }

    function onClose(){
        trackEvent('DialogAddEventHub','Close EventHubDialog');

        //TODO: clear variables
        clearVariables()
        props.onClose()
    }

    function clearVariables(){

    }

    const setNewEventHubName = () => {
        if(eventHubName!==''){
                let newArray = eventHubNames
                newArray.push(eventHubName)
                setEventHubNames(newArray)
                setEventHubName('')
                setNewItemsToShow(newArray)
                
            }
            
        
    }

    function parseEventHubNameSpaceConnStr(){
        trackEvent('DialogAddEventHub','Parse');

        var res = eventHubNameSpaceConnStr.split(";");
        if(res.length!==3){
            console.error("Format not valid")
        }
        else{
            const endPointStr = res[0]
            const shareAccessKeyNameStr = res[1]
            const shareAccessKeyStr = res[2]

            setEndPoint(endPointStr.split("=")[1])
            setSakName(shareAccessKeyNameStr.split("=")[1])
            setSak(shareAccessKeyStr.split("=")[1])
        }
    }

    const createChip = (eventHub, index) => {
        return (
          
                <Chip 
                    key={index} 
                    label={eventHub} 
                    style={{backgroundColor: '#76b3d6', color: '#FFF'}}
                    onDelete={() => deleteEventHub(index)}
                />
           
        );
    }
    const deleteEventHub = (index) => {
        const newArray = eventHubNames.filter((currentTag, currentIndex) => {
            return currentIndex !== index
        })
        setEventHubName('')
        setEventHubNames(newArray)
        setNewItemsToShow(newArray)
    }

    const setNewItemsToShow = (array) => {
        let newItemsToShow = []
        array.forEach((currentTag, index) => {
            newItemsToShow.push(createChip(currentTag, index)) 
        })
        setEventHubs2Show(newItemsToShow)
    }

    function handleKeyPressEventHubName(event){
        if(event.key === 'Enter'){
            setNewEventHubName()
          }
    }

 
    return(
        <div>
            <Dialog open={props.open} 
            fullWidth={true}
            maxWidth = {'md'}>

                <DialogTitle style={{backgroundColor: '#474b51'}}>
                    <Grid container spacing={4}>
                        <Grid item>
                                <FontAwesomeIcon icon={faProjectDiagram} style={{color:'#D3D0D3', fontSize:30}} />
                            
                        </Grid>
                        <Grid item style={{marginTop: '1%'}}>
                            <Typography style={{'display': 'flex', 'justifyContent': 'flex-start', marginBottom:'1%', color: '#D3D0D3'}}>
                                Add EventHub
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={cellTextSize}>
                        <Typography variant = 'subtitle2'>EndPoint</Typography>
                    </Grid> 
                    <Grid item xs={cellValueSize}>
                        <Typography variant = 'caption'>{endPoint}</Typography>
                    </Grid> 
                    <Grid item xs={cellTextSize}>
                        <Typography variant = 'subtitle2'>Entity Path (EventHub)</Typography>
                    </Grid> 
                    <Grid item xs={cellValueSize}>
                        <div className={classes.elementAdjacent}>
                        {eventHubs2Show}
                        </div>
                        
                    </Grid> 
                    <Grid item xs={cellTextSize}>
                        <Typography variant = 'subtitle2'>SAK Name</Typography>
                    </Grid> 
                    <Grid item xs={cellValueSize}>
                        <Typography variant = 'caption'>{sakName}</Typography>
                    </Grid> 
                    <Grid item xs={cellTextSize}>
                        <Typography variant = 'subtitle2'>SAK</Typography>
                    </Grid>  
                    <Grid item xs={cellValueSize}>
                        <Typography variant = 'caption'>{sak}</Typography>
                    </Grid> 
                      

                </Grid>

                <Grid container spacing={2} style={{marginTop:'5%'}}>
                    <Grid item xs={6}>
                    <CustomInput
                                labelText="Add EventHub (name)"
                                id="eventHubName"
                                formControlProps={{
                                    fullWidth: false,
                                    style:{width:'100%'}
                                }}
                                
                                inputProps={{
                                    type: "text",
                                    onChange: (event=>setEventHubName(event.target.value)),
                                    onKeyPress: (event => handleKeyPressEventHubName(event)),
                                    value: eventHubName,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />

                            </Grid>
                    <Grid item xs={4} >
                            <IconButton onClick={setNewEventHubName} size='small' variant='contained' style={{marginTop:'10%',backgroundColor: '#76b3d6', color: '#FFF'}}>
                                <AddIcon />
                            </IconButton>
                     </Grid>
                    
                     <Grid item xs={6}>
                            <CustomInput
                                labelText="Add EventHub Namespace Connection String"
                                id="eventHubnamespaceConnStr"
                                formControlProps={{
                                    fullWidth: false,
                                    style:{width:'100%'}
                                }}
                                
                                inputProps={{
                                    type: "text",
                                    onChange: (event=>setEventHubNameSpaceConnStr(event.target.value)),
                                    value: eventHubNameSpaceConnStr,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />
                    </Grid>
                    <Grid item xs={6}>
                        <Button id='parseEHubsNameStr' variant='outlined' style={{marginTop:'5%',color: '#76b3d6', fontWeight: 'bold'}} 
                        onClick={parseEventHubNameSpaceConnStr}>Parse</Button>
                    </Grid>
                </Grid>
                 
                </DialogContent>

                <DialogActions>
                <Button id='SaveButton' variant='outlined' style={{color: '#76b3d6', fontWeight: 'bold'}} onClick={saveEventHubs}>Save</Button>
                <Button id='CancelButton' variant='outlined' style={{color: 'red', fontWeight: 'bold'}} onClick={ onClose}>Cancel</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}