import React from 'react';

import {
        makeStyles, TextField, FormControlLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Typography,
        FormControl, InputLabel, Select, MenuItem, FormHelperText
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { styles, QualityTheme } from './../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';



export default function BackProcessingConfiguration(props) {


        const classes = makeStyles(styles(QualityTheme));

        const [databricksInstance, setDatabricksInstance] = React.useState(props.configuration && props.configuration.databricksInstance ?
                props.configuration.databricksInstance : '')
        const [databricksClusterId, setDatabricksClusterId] = React.useState(props.configuration && props.configuration.databricksClusterId ?
                props.configuration.databricksClusterId : '')
        const [databricksSimulationNotebookPath, setDatabricksSimulationNotebookPath] = React.useState(props.configuration && props.configuration.databricksSimulationNotebookPath ?
                props.configuration.databricksSimulationNotebookPath : '')
        const [databricksRuleRecommenderNotebookPath, setDatabricksRuleRecommenderPath] = React.useState(props.configuration && props.configuration.databricksRuleRecommenderNotebookPath ?
                props.configuration.databricksRuleRecommenderNotebookPath : '')
        const [databricksToken, setDatabricksToken] = React.useState(props.configuration && props.configuration.databricksToken ?
                props.configuration.databricksToken : '')

        const [resourceGroupName, setResourceGroupName] = React.useState(props.configuration && props.configuration.resourceGroupName ?
                props.configuration.resourceGroupName : '')
        const [regionName, setRegionName] = React.useState(props.configuration && props.configuration.regionName ?
                props.configuration.regionName : '')
        const [storageAccountName, setStorageAccountName] = React.useState(props.configuration && props.configuration.storageAccountName ?
                props.configuration.storageAccountName : '')
        const [containerName, setContainerName] = React.useState(props.configuration && props.configuration.containerName ?
                props.configuration.containerName : '')
        const [storageKey, setStorageKey] = React.useState(props.configuration && props.configuration.storageKey ?
                props.configuration.storageKey : '')
        const [sasToken, setSasToken] = React.useState(props.configuration && props.configuration.sasToken ?
                props.configuration.sasToken : '')
        const [databricksDeploymentPath, setDatabricksDeploymentPath] = React.useState(props.configuration && props.configuration.databricksDeploymentPath ?
                props.configuration.databricksDeploymentPath : '')

        const [cloudProvider, setCloudProvider] = React.useState(props.master === true ? (props.configuration.preferedCloudProvider!==undefined) ? 
                props.configuration.preferedCloudProvider: process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
                (props.configuration  && props.configuration.backendType ? props.configuration.backendType : 'Azure'))
    
        const [s3Keyid, setS3KeyId] = React.useState(props.configuration && props.configuration.amazonConfiguration &&
                props.configuration.amazonConfiguration.accessKey ?
                props.configuration.amazonConfiguration.accessKey : '')
        const [s3KeySecret, setS3KeySecret] = React.useState(props.configuration && props.configuration.amazonConfiguration &&
                props.configuration.amazonConfiguration.privateKey ?
                props.configuration.amazonConfiguration.privateKey : '')

        const [s3Region, setS3Region] = React.useState(props.configuration && props.configuration.amazonConfiguration &&
                props.configuration.amazonConfiguration.awsServiceRegion ?
                props.configuration.amazonConfiguration.awsServiceRegion : '')
        const [s3BucketName, setS3BucketName] = React.useState(props.configuration && props.configuration.amazonConfiguration &&
                props.configuration.amazonConfiguration.bucketName ?
                props.configuration.amazonConfiguration.bucketName : '')

        React.useEffect(() => {


                setDatabricksInstance(props.configuration && props.configuration.databricksInstance && props.configuration.databricksInstance !== null ?
                        props.configuration.databricksInstance : (props.parentConfiguration && props.parentConfiguration.databricksInstance ?
                                props.parentConfiguration.databricksInstance : ''))
                setDatabricksClusterId(props.configuration && props.configuration.databricksClusterId && props.configuration.databricksClusterId !== null ?
                        props.configuration.databricksClusterId : (props.parentConfiguration && props.parentConfiguration.databricksClusterId ?
                                props.parentConfiguration.databricksClusterId : ''))
                setDatabricksSimulationNotebookPath(props.configuration && props.configuration.databricksSimulationNotebookPath && props.configuration.databricksSimulationNotebookPath !== null ?
                        props.configuration.databricksSimulationNotebookPath : (props.parentConfiguration && props.parentConfiguration.databricksSimulationNotebookPath ?
                                props.parentConfiguration.databricksSimulationNotebookPath : ''))
                setDatabricksRuleRecommenderPath(props.configuration && props.configuration.databricksRuleRecommenderNotebookPath && props.configuration.databricksRuleRecommenderNotebookPath !== null ?
                        props.configuration.databricksRuleRecommenderNotebookPath : (props.parentConfiguration && props.parentConfiguration.databricksRuleRecommenderNotebookPath ?
                                props.parentConfiguration.databricksRuleRecommenderNotebookPath : ''))

                setResourceGroupName(props.configuration && props.configuration.resourceGroupName && props.configuration.resourceGroupName !== null ?
                        props.configuration.resourceGroupName : (props.parentConfiguration && props.parentConfiguration.resourceGroupName ?
                                props.parentConfiguration.resourceGroupName : ''))
                setRegionName(props.configuration && props.configuration.regionName && props.configuration.regionName !== null ?
                        props.configuration.regionName : (props.parentConfiguration && props.parentConfiguration.regionName ?
                                props.parentConfiguration.regionName : ''))
                setStorageAccountName(props.configuration && props.configuration.storageAccountName && props.configuration.storageAccountName !== null ?
                        props.configuration.storageAccountName : (props.parentConfiguration && props.parentConfiguration.storageAccountName ?
                                props.parentConfiguration.storageAccountName : ''))
                setContainerName(props.configuration && props.configuration.containerName && props.configuration.containerName !== null ?
                        props.configuration.containerName : (props.parentConfiguration && props.parentConfiguration.containerName ?
                                props.parentConfiguration.containerName : ''))
                setStorageKey(props.configuration && props.configuration.storageKey && props.configuration.storageKey !== null ?
                        props.configuration.storageKey : (props.parentConfiguration && props.parentConfiguration.storageKey ?
                                props.parentConfiguration.storageKey : ''))
                setSasToken(props.configuration && props.configuration.sasToken && props.configuration.sasToken !== null ?
                        props.configuration.sasToken : (props.parentConfiguration && props.parentConfiguration.sasToken ?
                                props.parentConfiguration.sasToken : ''))
                setDatabricksToken(props.configuration && props.configuration.databricksToken && props.configuration.databricksToken !== null ?
                        props.configuration.databricksToken : (props.parentConfiguration && props.parentConfiguration.databricksToken ?
                                props.parentConfiguration.databricksToken : ''))
                setDatabricksDeploymentPath((props.configuration && props.configuration.databricksDeploymentPath ?
                        props.configuration.databricksDeploymentPath : ''))

                setS3KeyId(props.configuration && props.configuration.amazonConfiguration &&
                        props.configuration.amazonConfiguration.accessKey ?
                        props.configuration.amazonConfiguration.accessKey : '')
                setS3KeySecret(props.configuration && props.configuration.amazonConfiguration &&
                        props.configuration.amazonConfiguration.privateKey ?
                        props.configuration.amazonConfiguration.privateKey : '')
                setS3Region(props.configuration && props.configuration.amazonConfiguration &&
                        props.configuration.amazonConfiguration.awsServiceRegion ?
                        props.configuration.amazonConfiguration.awsServiceRegion : '')
                setS3BucketName(props.configuration && props.configuration.amazonConfiguration &&
                        props.configuration.amazonConfiguration.bucketName ?
                        props.configuration.amazonConfiguration.bucketName : '')

                setCloudProvider(props.master === true ? (props.configuration.preferedCloudProvider!==undefined) ? 
                props.configuration.preferedCloudProvider: process.env.REACT_APP_CLOUD_PROVIDER_PREFER :
                (props.configuration  && props.configuration.backendType ? props.configuration.backendType : 'Azure'))

        }, [props.configuration, props.parentConfiguration]);

        React.useEffect(() => {
                props.setConfig(createBackProcessingObj())
        }, [databricksClusterId, databricksInstance, databricksSimulationNotebookPath, storageAccountName, containerName, storageKey, databricksToken,
                databricksDeploymentPath, sasToken, regionName, resourceGroupName, databricksRuleRecommenderNotebookPath, s3KeySecret, s3Keyid, s3BucketName, s3Region,cloudProvider]);

        function createBackProcessingObj() {
                if (props.master === true) {
                        var obj = {
                                databricksInstance: databricksInstance,
                                databricksClusterId: databricksClusterId,
                                databricksSimulationNotebookPath: databricksSimulationNotebookPath,
                                databricksRuleRecommenderNotebookPath: databricksRuleRecommenderNotebookPath,
                                resourceGroupName: resourceGroupName,
                                regionName: regionName,
                                storageAccountName: storageAccountName,
                                containerName: containerName,
                                storageKey: storageKey,
                                sasToken: sasToken,
                                databricksToken: databricksToken,
                                databricksDeploymentPath: databricksDeploymentPath,
                                preferedCloudProvider: cloudProvider,
                                amazonConfiguration: {
                                        accessKey: s3Keyid,
                                        privateKey: s3KeySecret,
                                        awsServiceRegion: s3Region,
                                        bucketName:s3BucketName
                                }

                        }
                        return obj
                }
                else {
                        var obj = {
                                databricksInstance: databricksInstance,
                                databricksClusterId: databricksClusterId,
                                databricksSimulationNotebookPath: databricksSimulationNotebookPath,
                                databricksRuleRecommenderNotebookPath: databricksRuleRecommenderNotebookPath,
                                resourceGroupName: resourceGroupName,
                                regionName: regionName,
                                storageAccountName: storageAccountName,
                                containerName: containerName,
                                storageKey: storageKey,
                                sasToken: sasToken,
                                databricksToken: databricksToken,
                                databricksDeploymentPath: databricksDeploymentPath,
                                backendType: cloudProvider,
                                amazonConfiguration: {
                                        accessKey: s3Keyid,
                                        privateKey: s3KeySecret,
                                        awsServiceRegion: s3Region,
                                        bucketName:s3BucketName
                                }

                        }
                        return obj
                }

        }

        return (
                <MuiThemeProvider theme={QualityTheme}>
                        <ExpansionPanel
                                style={{ width: '100%' }}>
                                <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                >
                                        <Grid item xs={1}>
                                                <FontAwesomeIcon icon={faCog} style={{ color: '#76b3d6', fontSize: 20 }} />
                                        </Grid>
                                        <Grid item xs={11}>
                                                <Typography variant='subtitle2'>Backend Processing Configuration</Typography>
                                        </Grid>

                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                        <div>
                                                {props.master === true &&
                                                        <div style={{ marginTop: '-0%', width: '100%', minWidth: '800px' }}>
                                                                <Typography variant="subtitle2"> Cloud Preferred Provider</Typography>
                                                                <FormControl style={{ width: '200px' }}>
                                                                        <InputLabel id="cloud-provider-select-label">Cloud Provider</InputLabel>
                                                                        <Select
                                                                                labelId="cloud-provider-select"
                                                                                id="cloud-provider-select"
                                                                                value={cloudProvider}
                                                                                onChange={event => setCloudProvider(event.target.value)}
                                                                        >

                                                                                <MenuItem value={'Azure'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('./../../../../resources/icons/AzureIcon.svg')} />
                                                                                                Azure
                                                                                        </div>
                                                                                </MenuItem>
                                                                                <MenuItem value={'AWS'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('./../../../../resources/icons/AmazonIcon.svg')} />
                                                                                                AWS
                                                                                        </div>
                                                                                </MenuItem>
                                                                        </Select>

                                                                </FormControl>
                                                        </div>
                                                }

                                                {props.master !== true &&
                                                        <div style={{ marginTop: '-0%', width: '100%', minWidth: '800px' }}>
                                                                <Typography variant="subtitle2"> Cloud Provider</Typography>
                                                                <FormControl style={{ width: '200px' }}>
                                                                        <InputLabel id="cloud-provider-select-label">Cloud Provider</InputLabel>
                                                                        <Select
                                                                                labelId="cloud-provider-select"
                                                                                id="cloud-provider-select"
                                                                                value={cloudProvider}
                                                                                onChange={event => setCloudProvider(event.target.value)}
                                                                        >

                                                                                <MenuItem value={'Azure'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('./../../../../resources/icons/AzureIcon.svg')} />
                                                                                                Azure
                                                                                        </div>
                                                                                </MenuItem>
                                                                                <MenuItem value={'AWS'}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                                <img alt="" style={{ height: '20px', width: '20px', marginRight: '5px' }}
                                                                                                        src={require('./../../../../resources/icons/AmazonIcon.svg')} />
                                                                                                AWS
                                                                                        </div>
                                                                                </MenuItem>
                                                                        </Select>

                                                                </FormControl>
                                                        </div>
                                                }

                                                <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                                                        <Typography variant="subtitle2"> Databricks Cluster Configuration</Typography>
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksInstance"
                                                                                label="Databricks Instance"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksInstance}
                                                                                onChange={(event) => { setDatabricksInstance(event.target.value) }}
                                                                        />} />
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksClusterId"
                                                                                label="Databricks Cluster Id"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksClusterId}
                                                                                onChange={(event) => { setDatabricksClusterId(event.target.value) }}
                                                                        />} />
                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksToken"
                                                                                type="password"
                                                                                label="Databricks Token "
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksToken}
                                                                                onChange={(event) => { setDatabricksToken(event.target.value) }}
                                                                        />} />

                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksSimulationNotebookPath"
                                                                                label="Databricks Simulation Notebook Path"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksSimulationNotebookPath}
                                                                                onChange={(event) => {
                                                                                        setDatabricksSimulationNotebookPath(event.target.value);
                                                                                        props.setConfig(createBackProcessingObj())
                                                                                }}
                                                                        />} />

                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksSimulationNotebookPath"
                                                                                label="Databricks Rule Recommender Notebook Path"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksRuleRecommenderNotebookPath}
                                                                                onChange={(event) => {
                                                                                        setDatabricksRuleRecommenderPath(event.target.value);
                                                                                        props.setConfig(createBackProcessingObj())
                                                                                }}
                                                                        />} />

                                                        <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                control={
                                                                        <TextField style={{ width: '100%' }}
                                                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                id="databricksDeployNotebookPath"
                                                                                label="Databricks Deployment Notebook Path"
                                                                                className={classNames(classes.textField, classes.dense)}
                                                                                margin="dense"
                                                                                value={databricksDeploymentPath}
                                                                                onChange={(event) => {
                                                                                        setDatabricksDeploymentPath(event.target.value);
                                                                                        props.setConfig(createBackProcessingObj())
                                                                                }}
                                                                        />} />

                                                </div>
                                                {(props.master === true || (props.master !== null && cloudProvider === 'AWS')) &&
                                                        <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                                                                <Typography variant="subtitle2"> Amazon S3 Configuration</Typography>

                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="s3Keyid"
                                                                                        label="Key Id"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={s3Keyid}
                                                                                        onChange={(event) => { setS3KeyId(event.target.value) }}
                                                                                />} />

                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="s3KeySecret"
                                                                                        label="Key Secret"
                                                                                        type="password"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={s3KeySecret}
                                                                                        onChange={(event) => { setS3KeySecret(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="s3Region"
                                                                                        label="Region"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={s3Region}
                                                                                        onChange={(event) => { setS3Region(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="s3BucketName"
                                                                                        label="Bucket Name"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={s3BucketName}
                                                                                        onChange={(event) => { setS3BucketName(event.target.value) }}
                                                                                />} />
                                                        </div>
                                                }
                                                {(props.master === true || (props.master !== null && cloudProvider === 'Azure')) &&
                                                        <div style={{ marginTop: '5%', width: '100%', minWidth: '800px' }}>
                                                                <Typography variant="subtitle2"> BlobStorage Configuration</Typography>
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="accountName"
                                                                                        label="BlobStorage Resource Group Name"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={resourceGroupName}
                                                                                        onChange={(event) => { setResourceGroupName(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="accountName"
                                                                                        label="BlobStorage Region Name "
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={regionName}
                                                                                        onChange={(event) => { setRegionName(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="accountName"
                                                                                        label="BlobStorage Account Name "
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={storageAccountName}
                                                                                        onChange={(event) => { setStorageAccountName(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="containerName"
                                                                                        label="BlobStorage Container Name"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        value={containerName}
                                                                                        onChange={(event) => { setContainerName(event.target.value) }}
                                                                                />} />

                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="storageKey"
                                                                                        label="BlobStorage key"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        type="password"
                                                                                        value={storageKey}
                                                                                        onChange={(event) => { setStorageKey(event.target.value) }}
                                                                                />} />
                                                                <FormControlLabel style={{ marginLeft: '5%', width: '50%' }}
                                                                        control={
                                                                                <TextField style={{ width: '100%' }}
                                                                                        InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                                                        id="sasToken"
                                                                                        label="Storage SAS Token"
                                                                                        className={classNames(classes.textField, classes.dense)}
                                                                                        margin="dense"
                                                                                        type="password"
                                                                                        value={sasToken}
                                                                                        onChange={(event) => { setSasToken(event.target.value) }}
                                                                                />} />
                                                        </div>
                                                }
                                        </div>



                                </ExpansionPanelDetails>
                        </ExpansionPanel>
                </MuiThemeProvider>
        )
}