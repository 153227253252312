
import React from 'react'
import { Grid } from '@material-ui/core'
import AddProjectButton from './AddProjectButton'
import AddViewModeSelect from './AddViewModeSelect'
import ImportButton from './ImportButton'
import SearchButton from './SearchButton'
import { Divider } from '@material-ui/core'
import { useTheme } from "@material-ui/core/styles";



export default function QualityMgtProjectTopBar(props) {
    const theme = useTheme();

    return (
        <div style={props.style}>
            <Grid container >
                <Grid item xs={7}>
                    <div style={theme.elementAdjacent}>
                        <AddProjectButton onClick={props.openDialogCallback} />
                        {(process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true' || process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true') &&
                            <ImportButton onClick={props.openDialogImportCallback} />}
                        
                    </div>

                </Grid>

                <Grid item xs={2}>
                    {undefined &&
                        <AddViewModeSelect style={{ marginTop: '-2%', marginLeft: '50%' }} />
                    }
                </Grid>
                <Grid item xs={1}>
                    {undefined &&
                        <ImportButton />}
                </Grid>
                <Grid item xs={2}>
                    <SearchButton style={{ marginTop: '-5%', display: 'flex', justifyContent: 'space-between' }}
                        updateText={props.updateText} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </div>
    )

}