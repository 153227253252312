import React from 'react'
import CustomTabs from './../../common/CustomTabs/CustomTabs'

import { loadCSS } from 'fg-loadcss';



import DeviceHub from "@material-ui/icons/DeviceHub";
import CloudQueue from "@material-ui/icons/CloudQueue";
import Assignment from "@material-ui/icons/Assignment";
import ProjectRepository from './ProjectRepository'
import DataRepository from './DataRepository'
import RuleRepository from './RuleRepository'




export default function QualityRepository(props) {

    const tabSelected = 0

    React.useEffect(() => {
        loadCSS(
          'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
          document.querySelector('#font-awesome-css'),
        );
      }, []);


    return(
        <div>
            <CustomTabs
                headerColor="primary"
                value={tabSelected}
                tabs={[
                    {
                      tabName: "Projects",
                      tabIcon: DeviceHub,
                      tabContent: (
                        <ProjectRepository/>
                      )
                    },
                    {
                      tabName: "Data",
                      tabIcon: CloudQueue,
                      tabContent: (
                       <DataRepository/>
                      )
                    },
                    {
                      tabName: "Rules",
                      tabIcon: Assignment,
                      tabContent: (
                        <RuleRepository/>
                      )
                    }
                ]}/>
                
                
        </div>
    )
    
}