import React from 'react';

import {  QualityTheme } from './../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AdvanceConfigurationQuality from '../../../common/AdvanceConfigurationQuality'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import StreamEventHubConfiguration from './StreamEventHubConfiguration'
import BackProcessingConfiguration from './BackProcessingConfiguration'
import AzureBackendConfiguration from './AzureBackendConfiguration';



export default function QualityDefaults(props) {

    const dispatch = useDispatch();
    var qualityConfigurationSelector = useSelector(store => (store.qualityConfiguration))
    var license = useSelector(store => (store.license))


    const [thError, setThError] = React.useState()
    const [thWarning, setThWarning] = React.useState()
    const [wrongRegisterAction, setWrongRegisterAction] = React.useState()
    const [outputMode, setOutputMode] = React.useState()
    const [qualityConfiguration, setQualityConfiguration] = React.useState({})
    

    

    React.useEffect(() => {
        if(qualityConfigurationSelector && (qualityConfigurationSelector.wrongRegisterOutput===null || !qualityConfigurationSelector.wrongRegisterOutput)){
            qualityConfigurationSelector.wrongRegisterOutput='discard_action'
        }
        if(qualityConfigurationSelector &&  (qualityConfigurationSelector.outputControl===null || !qualityConfigurationSelector.outputControl)){
            qualityConfigurationSelector.outputControl='INFO'
        }
        setQualityConfiguration(qualityConfigurationSelector)
       }, [qualityConfigurationSelector]);

    React.useEffect(() => {
        if(props.currentUser && props.currentUser.id)
        {
            dispatch(actions.fetchConfigurationByUserId(props.currentUser.id))
        }
       
      }, []);

     

    //function to update control elmenets
    function setControlObj(data){
        setWrongRegisterAction(data.wrongRegisterActionType)
        setOutputMode(data.controlModeType)

        var qualityConfig = qualityConfiguration
        qualityConfig.outputControl=data.controlModeType
        qualityConfig.wrongRegisterOutput=data.wrongRegisterActionType
        qualityConfig.apiKeyUrl=process.env.REACT_APP_URL_API_VALIDATION+license.apiKey
        setQualityConfiguration(qualityConfig)

    }
    //function to update control elmenets
    function setThErrorData(data){
        var qualityConfig = qualityConfiguration
        qualityConfig.thErrorAction=data.action
        qualityConfig.thErrorType=data.type
        qualityConfig.thErrorValue=data.value
        qualityConfig.apiKeyUrl=process.env.REACT_APP_URL_API_VALIDATION+license.apiKey
        setQualityConfiguration(qualityConfig)
        setThError(data)

    }
    function setThWarningData(data){
        var qualityConfig = qualityConfiguration
        qualityConfig.thWarnAction=data.action
        qualityConfig.thWarnType=data.type
        qualityConfig.thWarnValue=data.value
        qualityConfig.apiKeyUrl=process.env.REACT_APP_URL_API_VALIDATION+license.apiKey
        setQualityConfiguration(qualityConfig)
        setThWarning(data)

    }

    function isEmpty  (obj){
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    function getThError(){
        if(!isEmpty(qualityConfiguration)){
            return {
                action: qualityConfiguration.thErrorAction,
                value: qualityConfiguration.thErrorValue,
                type: qualityConfiguration.thErrorType
            }
        }
        else{
            return undefined //nunca deberíamos entrar aquí
        }

    }

    function getThWarning(){
        if(!isEmpty(qualityConfiguration)){
            return {
                action: qualityConfiguration.thWarnAction,
                value: qualityConfiguration.thWarnValue,
                type: qualityConfiguration.thWarnType
            }
        }
        else{
            return undefined //nunca deberíamos entrar aquí
        }

    }

    function getControlObj(){
        if(!isEmpty(qualityConfiguration)){
            return {wrongRegisterAction: qualityConfiguration.wrongRegisterOutput, outputMode:qualityConfiguration.outputControl}
        }
        else{
            return undefined //nunca deberíamos entrar aquí
        }
        
        
    }
    

/*
    handleChange = name => event => {
        this.setState({ qualityConfiguration: { ...this.state.qualityConfiguration, [name]: event.target.checked } });
    };

    handleTextFieldChange = name => event => {
        this.setState({ qualityConfiguration: { ...this.state.qualityConfiguration, [name]: event.target.value } });
    };*/

    function onClickSave ()  {

        
        dispatch(actions.addConfiguration(qualityConfiguration,props.currentUser)).then(result=>
            {
                //notificationMessage(result,this.props.showMessage,'Configuration Updated','Error Updating Configuration')
            }
        )
    }

    function setBackProcessingConfig(data){
        var config = qualityConfiguration
        
        config.databricksInstance =data.databricksInstance
        config.databricksClusterId = data.databricksClusterId
        config.databricksSimulationNotebookPath = data.databricksSimulationNotebookPath
        config.databricksRuleRecommenderNotebookPath = data.databricksRuleRecommenderNotebookPath
        config.resourceGroupName = data.resourceGroupName
        config.regionName = data.regionName
        config.storageAccountName = data.storageAccountName
        config.containerName = data.containerName
        config.storageKey = data.storageKey
        config.sasToken = data.sasToken
        config.databricksToken=data.databricksToken
        config.databricksDeploymentPath = data.databricksDeploymentPath
        config.apiKeyUrl=process.env.REACT_APP_URL_API_VALIDATION+license.apiKey
        config.amazonConfiguration = data.amazonConfiguration
        config.preferedCloudProvider = data.preferedCloudProvider
        setQualityConfiguration(config)
    }

    function setAzureCredentialsConfig(data){
        var config = qualityConfiguration
        config.azureCredentials =data
        
        setQualityConfiguration(config)
    }

    function updateEventHubProps(data){
        var config = qualityConfiguration
        config.eventHubs=data
        config.apiKeyUrl=process.env.REACT_APP_URL_API_VALIDATION+license.apiKey
        setQualityConfiguration(config)
    }

    return (
        <div>
            {!qualityConfiguration &&
                <div className="ui segment" style={{ height: '100px' }}>
                        <div className="ui active inverted dimmer">
                            <div className="ui text loader">Loading</div>
                        </div>
                        <p></p>
                    </div>
            }
        
            {qualityConfiguration &&
            <MuiThemeProvider theme={QualityTheme}>
                <div className="dashboard ui segment" style={{ minHeight: '600px' }}>

                    <div style={{marginBottom:'10px'}}>
                    <AdvanceConfigurationQuality setThError = {setThErrorData} setThWarning = {setThWarningData} setControlObj={setControlObj}
                 thError={getThError()} 
                 thWarning = {getThWarning()} 
                 controlObj = {getControlObj()}/>  
                 </div>
                 <div style={{marginBottom:'10px'}}>
                    <BackProcessingConfiguration master ={true} configuration={qualityConfiguration} setConfig={setBackProcessingConfig}/>  
                </div>
                <div style={{marginBottom:'10px'}}>
                    <AzureBackendConfiguration configuration={qualityConfiguration} setConfig={setAzureCredentialsConfig}/>  
                </div>
               {(license.lambaActive===true || !license.lambdaActive) &&  <div style={{marginBottom:'10px'}}>
                    <StreamEventHubConfiguration configuration={qualityConfiguration} updateEventHubProps={updateEventHubProps}/>  
                </div>}
                <div style={{marginBottom:'10px'}}>        
                        <Button onClick={onClickSave} variant="contained" color="secondary" >
                            Save
          </Button>
                    </div>

                </div>
            </MuiThemeProvider>
        }
        </div>
    )
       


}