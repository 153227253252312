
import quality from './../apis/quality';
import {
    CREATE_DATAFORMAT, 
    FETCH_DATAFORMATS
    } from './types'
    import {axiosHeader,dispatchInfo, dispatchError} from '../components/common/axios/axiosHelper';


export const addDataFormat = (columns,name,userId) => async (dispatch,getState) => {
    var dataformat2Submit ={};
    dataformat2Submit.columns = columns
    dataformat2Submit.name=name
    dataformat2Submit.userId= userId;
   await quality.post('/dataformat/', dataformat2Submit,{
    headers: axiosHeader()
  }).then(function (response) {
        dispatch( {
            type : CREATE_DATAFORMAT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Dataformat ${name} created`)
        
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
      });
    
};

export const addDataFormatAndRegister = (columns,name,userId,dtoId,folderId) => async (dispatch,getState) => {
    var dataformat2Submit ={};
    dataformat2Submit.columns = columns
    dataformat2Submit.name=name
    dataformat2Submit.userId= userId;
   await quality.post(`/dataformat/register/dto/${dtoId}/folder/${folderId}`, dataformat2Submit,{
    headers: axiosHeader()
  }).then(function (response) {
        dispatch( {
            type : CREATE_DATAFORMAT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Dataformat ${name} created`)
        
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
      });
    
};

export const parseDataFormatBlob = (separator,blobFileName,userId, container) => async (dispatch,getState) => {

    let finalContainer = container ? container : "null"
   const response = await quality.post(`/dataformat/parse/azure/${separator}/${userId}/${finalContainer}`,blobFileName,{
    headers: axiosHeader()
  })
  return response
    
};

export const parseDataFormatS3 = (separator,blobFileName,userId, environment) => async (dispatch,getState) => {

   const response = await quality.post(`/dataformat/parse/aws/${separator}/${userId}/${environment}`,blobFileName,{
    headers: axiosHeader()
  })
  return response
    
};


export const fetchDataFormats = (user) =>{
    
    var id = user.id
    if(id){
        return async function(dispatch, getState){
            await quality.get(`/dataformats/${id}`,{
                headers: axiosHeader()
              }).then(function (response) {
                dispatch( {
                    type : FETCH_DATAFORMATS,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatchError(dispatch,error)
                
              });
            
        };
    }
    else{
        return async function(dispatch, getState){
        }
    }

    
};


export const deleteUnusedDataformats = (user) =>{
    return async function(dispatch, getState){
        const userName = user.id
       await quality.delete(`/dataformat/unused/${userName}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : FETCH_DATAFORMATS,
            payload: response.data
        });
        dispatchInfo(dispatch,`Deleted unused datasets`)
       })
       .catch(function (error) {
        dispatchError(dispatch,error)
         });
       
   };
}