
import quality from '../apis/quality';
import qualityLocalHost from './../apis/qualityLocalHost'
import {
  FETCH_PROJECTS,
  CREATE_PROJECT,
  FETCH_PROJECT,
  EDIT_PROJECT,
  DELETE_PROJECT,
} from './types'
import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';
import * as actions from './index'

export const fetchProjects = (userName) => {
  if (userName) {
    return async function (dispatch, getState) {

      await quality.get(`/projects/${userName}`, {
        headers: axiosHeader()
      }).then(function (response) {

        dispatch({
          type: FETCH_PROJECTS,
          payload: response.data
        });
      })
        .catch(function (error) {
          // dispatchError(dispatch,error)

        })


    };
  }

};


export const fetchProjectsAndReturn = (id)=> async dispatch => {
  if(!id){
    return []
  }
  var returnValue = []
  await quality.get(`/projects/${id}`,{
      headers: axiosHeader()
    }).then(function (response) {
      
      returnValue = response.data
  })
  .catch(function (error) {
      returnValue = []
    });
    return returnValue
}



export const createProjectShare = (formValues, user) => async (dispatch, getState) => {
  var returnValue = { result: 'undefined' }
  var userName = user.providerId
  formValues.owner = userName
  if (formValues.userName) {
    userName = formValues.userName

    quality.post('/project/', { ...formValues, userName }, {
      headers: axiosHeader()
    })
  }
  else {

    await quality.post('/project/', { ...formValues, userName }, {
      headers: axiosHeader()
    })
  }
  returnValue = { result: 'ok' }
  return returnValue;
};


export const copyProject = (projectId, projectName) => async dispatch => {
  var returnValue = { result: 'undefined' }
  await quality.post(`/project/copy/id/${projectId}/name/${projectName}`, null, {
    headers: axiosHeader()
  }).then(function (response) {

    dispatch({
      type: CREATE_PROJECT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Project copied with name ${projectName}`)


    returnValue = { result: 'ok' }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
};

export const shareProject = (projectId, projectName, userId) => async dispatch => {
  if (userId) {
    var returnValue = { result: 'undefined' }
    await quality.post(`/project/share/id/${projectId}/name/${projectName}/user/id/${userId}`, null, {
      headers: axiosHeader()
    }).then(function (response) {

      dispatchInfo(dispatch, `Project shared with name ${projectName}`)

      returnValue = { result: 'ok' }
    })
      .catch(function (error) {
        dispatchError(dispatch, error)

        returnValue = { result: 'ko', error: error }
      });
    return returnValue
  }

};

export const createProjectAndRegister = (formValues, dtoId, folderId) => async (dispatch, getState) => {
  let userName = undefined
  formValues.owner = userName
  var returnValue = { result: 'undefined' }
  if (formValues.userName) {
    userName = formValues.userName

    await quality.post(`/project/register/dto/${dtoId}/folder/${folderId}`, { ...formValues, userName }, {
      headers: axiosHeader()
    }).then(function (response) {
      dispatch({
        type: CREATE_PROJECT,
        payload: response.data
      });
      
      dispatchInfo(dispatch, `Project ${formValues.name} created`)


      returnValue = { result: 'ok' }
    })
      .catch(function (error) {
        dispatchError(dispatch, error)

        returnValue = { result: 'ko', error: error }
      });
  }
  else {

    await quality.post(`/project/register/dto/${dtoId}/folder/${folderId}`, { ...formValues, userName }, {
      headers: axiosHeader()
    }).then(function (response) {
      dispatch({
        type: CREATE_PROJECT,
        payload: response.data
      });
      dispatchInfo(dispatch, `Project ${formValues.name} created`)

      returnValue = { result: 'ok' }
    })
      .catch(function (error) {
        dispatchError(dispatch, error)

        returnValue = { result: 'ko', error: error }
      });
  }
  return returnValue
};


export const createProject = (formValues, userName) => async (dispatch, getState) => {
  formValues.owner = userName
  var returnValue = { result: 'undefined' }
  if (formValues.userName) {
    userName = formValues.userName

    await quality.post('/project/', { ...formValues, userName }, {
      headers: axiosHeader()
    }).then(function (response) {
      dispatch({
        type: CREATE_PROJECT,
        payload: response.data
      });
      dispatchInfo(dispatch, `Project ${formValues.name} created`)


      returnValue = { result: 'ok' }
    })
      .catch(function (error) {
        dispatchError(dispatch, error)

        returnValue = { result: 'ko', error: error }
      });
  }
  else {

    await quality.post('/project/', { ...formValues, userName }, {
      headers: axiosHeader()
    }).then(function (response) {
      dispatch({
        type: CREATE_PROJECT,
        payload: response.data
      });
      dispatchInfo(dispatch, `Project ${formValues.name} created`)

      returnValue = { result: 'ok' }
    })
      .catch(function (error) {
        dispatchError(dispatch, error)

        returnValue = { result: 'ko', error: error }
      });
  }
  return returnValue
};

export const fetchProject = _id => async dispatch => {
  if (_id) {
    await quality.get(`/project/${_id}`, {
      //headers: axiosHeader()
    }).then(function (response) {
      dispatch({
        type: FETCH_PROJECT,
        payload: response.data
      });
    })
      .catch(function (error) {
        dispatchError(dispatch, error)

      });
  }


};

export const editProject = (id, formValues) => async dispatch => {
  var returnValue = { result: 'undefined' }
  await quality.put(`/project/${id}`, formValues, {
    headers: axiosHeader()
  }).then(function (response) {

    dispatch({
      type: EDIT_PROJECT,
      payload: response.data
    });
    dispatchInfo(dispatch, `Project ${formValues.name} edited`)

    returnValue = { result: 'ok' }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
};

export const addAudit = (environment, projectId) => async dispatch => {
  var returnValue = { result: 'undefined' }
  await quality.put(`/project/audit/deployment/${environment}`, projectId, {
    headers: axiosHeader()
  }).then(function (response) {

    dispatch({
      type: EDIT_PROJECT,
      payload: response.data
    });

    returnValue = { result: 'ok' }
  })
    .catch(function (error) {
      dispatchError(dispatch, "Error adding audit info for deployment in environment " + environment)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue
};

export const deleteProject = (_id) => async dispatch => {
  var returnValue = { result: 'undefined' }
  await quality.delete(`/project/${_id}`, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: DELETE_PROJECT,
      payload: _id
    });
    dispatchInfo(dispatch, `Project deleted`)

    returnValue = { result: 'ok' }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue;

};

export const deleteProjectAndUnregister = (_id) => async dispatch => {
  var returnValue = { result: 'undefined' }
  await quality.delete(`/project/unregister/${_id}`, {
    headers: axiosHeader()
  }).then(function (response) {
    dispatch({
      type: DELETE_PROJECT,
      payload: _id
    });
    dispatchInfo(dispatch, `Project deleted`)

    returnValue = { result: 'ok' }
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });
  return returnValue;

};

export const getQualityConfigurationDeployment = (idProject, environment,simulationId) => async (dispatch, getState) => {

  var returnValue = {}
  await quality.get(`/project/string/datafile/${idProject}/${environment}/${simulationId === undefined ? null : simulationId}`, {
    headers: axiosHeader()
  }).then(function (response) {

    returnValue = response.data
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      returnValue = { result: 'ko', error: error }
    });


  return returnValue
}

export const createDatasourceRuleRecommender = (projectId, qualityPointId, dto) => async (dispatch, getState) => {


  await quality.post(`/project/${projectId}/qualitypoint/${qualityPointId}/rule/recommender/generate`,dto, {
    headers: axiosHeader()
  }).then(function (response) {

    dispatch({
      type: EDIT_PROJECT,
      payload: response.data
    });
    
    dispatchInfo(dispatch, `Datasource created based on rule recommender`)
  })
    .catch(function (error) {
      dispatchError(dispatch, error)

      
    });


}
