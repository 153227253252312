import React, {useRef} from 'react'
import S3 from 'react-aws-s3'
import {Button,LinearProgress, Typography} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import trackEvent from './../../../trackEvent'

function UploadS3(props){

    const theme = useTheme();

    var inputFileRef = React.useRef(null);

    const [uploading, setUploading] = React.useState(false)
    const [size, setSize] = React.useState(0)

    function uploadFiles (files, props) {
      
        files && files.length >0 && uploadFile(files[0],props);
    }
    

    const showFileDialog = () =>{
      inputFileRef.current && inputFileRef.current.click()

    }

    function uploadFile( fileInput, props) {
        let file = fileInput
        let newFileName = fileInput.name
        const config = {dirName:props.folder,
            bucketName: props.amazonConfiguration.bucketName,
            region: props.amazonConfiguration.awsServiceRegion,
            accessKeyId: props.amazonConfiguration.accessKey,
            secretAccessKey: props.amazonConfiguration.privateKey};

            setSize(file.size/1024/1024)
            setUploading(true)

        const ReactS3Client = new S3(config)
        ReactS3Client.uploadFile(file, newFileName).then(data=>{
            if(data.status=== 204){
                console.log('succeed')
                console.log(data)
                setUploading(false)
                props.onUpload(data.key)
            }
            else{
                console.log('fail')
            }
        }).catch(exception => console.log(exception))
    }

    
    return(
        <div>
      <input
        style={{ display: 'none' }}
        accept=".csv"
        ref={inputFileRef}
        type="file"
        multiple={false}
        onChange={e => uploadFiles(e.target.files,props)}
        onClick={(event)=> { 
          event.target.value = null
     }}
      />
      {uploading && 
      <div>
        <LinearProgress  style={{width:'65%'}}/>
        {size<20 && <Typography style={{width:'65%'}} variant={'subtitle2'}> {`Uploading File with size ${Math.round(size * 100) /100} MB`} </Typography>}
        {size>=20 && <Typography style={{width:'65%'}} variant={'subtitle2'}> {`Uploading File with size ${Math.round(size * 100) /100 } MB. It may take some time depending on your internet connection`} </Typography>}
      </div>
        }
      <Button variant='outlined' style={theme.buttons.buttonRegular} onClick={() => {trackEvent('InputFile','Upload File Button'); showFileDialog()}} >
        Upload File S3
      </Button>
    </div>
    )
}

export default UploadS3