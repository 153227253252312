import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import RecentQualityProjects from './RecentQualityProjects/RecentQualityProjects';
import QualityProjectsTable from './QualityProjectsTable/QualityProjectsTable';
import ProjectCreationDialog from './ProjectCreationDialog'
import * as projectHelper from './QualityProjectHelper'
import AppBarTemplate from './../../template/AppBarTemplate'
import Parallax from "./../../common/Parallax/Parallax.js";
import * as actions from './../../../actions'
import QualityMgtProjectTopBar from './QualityMgtProjectTopBar'
import ConfirmDialog from './../../common/ConfirmDialog'
import ProjectNewNameDialog from './copyAction/ProjectNewNameDialog';
import { useTheme } from '@material-ui/core/styles';
import { Paper, Tabs, Tab, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import trackEvent from './../../../trackEvent';

import { DataRepositoryTreeTable } from '../DataRepository/DataRepositoryTreeTrable/DataRepositoryTreeTable.js'
import * as convertDataHelper from "./../DataRepository/EntityDataRepository/Helpers/convertDataHelper";
import * as getterDTO from "./../DataRepository/EntityDataRepository/Helpers/getterDTOs";


import { AqtivaDialogDataRepository } from './../DataRepository/EntityDataRepository/AqtivaDialogDataRepository'
import { AqtivaDialogDataRepositoryRefactor } from './../DataRepositoryRefactor/EntityDataRepository/AqtivaDialogDataRepository'
import { TabsBar } from './../DataRepository/Tabs'
import { TabsBarRefactor } from './../DataRepositoryRefactor/Tabs'
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import {
  faList,
  faStream,
  faUserLock
} from "@fortawesome/free-solid-svg-icons";


export default function QualityManagementProjectsPage(props) {

  const theme = useTheme();
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = React.useState(0);

  const [openDialog, setDialogStatus] = React.useState(false)
  const [openDialogEdit, setDialogStatusEdit] = React.useState(false)
  const [openConfirmDialogDelete, setConfirmDialogDelete] = React.useState(false)
  const [openNewNameProjectDialog, setOpenNewNameProjectDialog] = React.useState(false)
  const [openDialogDataRepository, setOpenDialogDataRepository] = React.useState(false)

  const projects = useSelector(store => Object.values(store.projects))

  const user = useSelector(store => store.user)

  const [selectedProject, setSelectedProject] = React.useState()

  const [searchText, setSearchText] = React.useState()

  const [project2Delete, setProject2Delete] = React.useState(undefined)
  const [project2Copy, setProject2Copy] = React.useState(undefined)

  const [selectedItem, setSelectedItem] = React.useState({ itemId: "", type: "" }); // selectedItem del private
  const [selectedDtoId, setSelectedDtoId] = React.useState(undefined)
  const [expandedKeys, setExpandedKeys] = React.useState();



  const projectDataRepo = useSelector((store) =>
    Object.values(store.projectDataRepository)
  );


  React.useEffect(() => {
    if (user && user.id) {

      dispatch(
        actions.fetchProjectDataRepositoryPrivateWorkspace(
          user.id
        )
      );
    }
  }, [user]);

  React.useEffect(() => {
    if (user && user.id) {
      dispatch(actions.fetchProjects(user.id))
    }

  }, [user])


  React.useEffect(() => {
    dispatch(actions.fetchUser())
  }, [])


  function openDialogProjectCreation() {
    trackEvent('Project', 'Create New Project Button')
    setDialogStatus(true)
  }
  function openDialogDataRepositoryCallback() {
    trackEvent('Project', 'Import project')
    setOpenDialogDataRepository(true)
  }
  function openDialogProjectEdit(project) {
    trackEvent('Project', 'Edit Project Button')
    setSelectedProject(project)
    setDialogStatusEdit(true)
  }

  const handleCloseDialog = () => {
    setDialogStatus(false)
    setDialogStatusEdit(false)
  }



  const copyProject = (project) => {
    trackEvent('Project', 'Copy project')
    setProject2Copy(project)

    setOpenNewNameProjectDialog(true)
  }

  const shareProject = (project, userId2Share) => {
    trackEvent('Project', 'Share project')
    setProject2Copy(project)
    dispatch(actions.shareProject(project._id, project.name, userId2Share))

  }
  const confirmNewNameDialog = (newName) => {
    var project = Object.assign({}, project2Copy);
    project.name = newName
    dispatch(actions.copyProject(project._id, project.name))
    setOpenNewNameProjectDialog(false)
    setProject2Copy(undefined)

    /*
    var project = Object.assign({}, project2Copy);

    project.name = newName
    dispatch(actions.copyProject(project._id,newName))
    setOpenNewNameProjectDialog(false)
    setProject2Copy(undefined)
    */

  }
  const cancelNewNameDialog = (project) => {
    setOpenNewNameProjectDialog(false)
    setProject2Copy(undefined)
  }

  const deleteProjectAction = (project) => {
    trackEvent('Project', 'Delete project')
    setProject2Delete(project)
    setConfirmDialogDelete(true)
  }
  const confirmDialogDelete = () => {
    trackEvent('Project', 'Confirm Delete project')
    dispatch(actions.deleteProjectAndUnregister(project2Delete._id))
    setProject2Delete(undefined)
    setConfirmDialogDelete(false)

  }
  const cancelDialogDelete = () => {
    trackEvent('Project', 'Cancel Delete project')
    setConfirmDialogDelete(false)
  }
  const openDialogeditProject = (project) => {
    openDialogProjectEdit(project)
  }

  const updateSearchText = (searchText) => {
    setSearchText(searchText)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <div >
      <AppBarTemplate history={props.history} title='Projects' />




      <div style={{ 'padding': '2%' }}>
        <QualityMgtProjectTopBar
          style={theme.pageToolbar}
          openDialogCallback={openDialogProjectCreation}
          openDialogImportCallback={openDialogDataRepositoryCallback}
          updateText={updateSearchText}
        />
        <RecentQualityProjects
          style={theme.recentQualtiyProjects}
          id='recentQualityProjects'
          projects={projectHelper.filterProjectSearchText(projects, searchText)}
          history={props.history}

        />

        {openDialog && <ProjectCreationDialog
          id='projectCreationDialog'
          onClickSaveProject={handleCloseDialog}
          handleCloseCallback={handleCloseDialog}
        />}
        {openDialogEdit && <ProjectCreationDialog
          id='projectCreationDialog'
          onClickSaveProject={handleCloseDialog}
          handleCloseCallback={handleCloseDialog}
          project={selectedProject}
        />}
        {openConfirmDialogDelete && <ConfirmDialog
          id='confirmDialogDelete'
          confirmAction={confirmDialogDelete}
          cancelAction={cancelDialogDelete}
          title={`Confirm Delete Project ${project2Delete.name}`}
          open={openConfirmDialogDelete}
        />}
        {openNewNameProjectDialog && <ProjectNewNameDialog
          id='newNameProjectDialog'
          confirmAction={confirmNewNameDialog}
          cancelAction={cancelNewNameDialog}
          open={openNewNameProjectDialog}
        />}

        {openDialogDataRepository && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true' && <AqtivaDialogDataRepository
          dialog={openDialogDataRepository}
          setDialog={setOpenDialogDataRepository}
          title="Data Repository"
          showConfirmButton={false}

          titleIcon={faDatabase}
          confirmCallback={(event) => console.log("I am clicking here")}
        >
          <TabsBar user={user} />
        </AqtivaDialogDataRepository>}
        {openDialogDataRepository && process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true' && process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'false' && <AqtivaDialogDataRepositoryRefactor
          dialog={openDialogDataRepository}
          setDialog={setOpenDialogDataRepository}
          title="Data Repository"
          showConfirmButton={false}

          titleIcon={faDatabase}
          confirmCallback={(event) => console.log("I am clicking here")}
        >
          <TabsBarRefactor user={user} />
        </AqtivaDialogDataRepositoryRefactor>}






        <Tabs
          value={tabValue}
          /* style={{...theme.dataRepository.default, maxHeight:'0px', marginTop:'-10px', textAlign:'center', marginBottom:'20px'}} */
          style={{
            marginLeft: '10%', marginRight: '10%',
            border: 'none',
            color: '#696969',
            textTransform: 'capitalize',
            backgroundColor: 'white',
            maxHeight: '0px',
          }}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}

        >
          {(process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true' || process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true') &&
            <Tab
              style={{ ...theme.dataRepository.defaultNoMargin }}
              label={
                <div style={theme.dataRepository.elementAdjacent}>
                  <FontAwesomeIcon
                    icon={faList}
                    style={{
                      color: "#696969",
                      marginTop: "3px",
                      marginRight: "3px",
                    }}
                    fontSize="small"
                  />
                  <Typography variant="subtitle2" style={{ marginLeft: "2px" }}>
                    List View
                </Typography>
                </div>
              }
            />
          }
          {(process.env.REACT_APP_DATA_REPOSITORY_ALLOWED === 'true' || process.env.REACT_APP_DATA_REPOSITORY_DND_ALLOWED === 'true') &&
            <Tab
              style={theme.dataRepository.defaultNoMargin}
              label={
                <div style={theme.dataRepository.elementAdjacent}>
                  <FontAwesomeIcon
                    icon={faStream}
                    style={{
                      color: "#696969",
                      marginTop: "3px",
                      marginRight: "3px",
                    }}
                    fontSize="small"
                  />
                  <Typography variant="subtitle2" style={{ marginLeft: "2px" }}>
                    Tree View
                </Typography>
                </div>
              }
            />
          }
        </Tabs>
        {tabValue === 0 &&
          projects.length > 0 && <QualityProjectsTable
            id='qualityProjectsTable'
            rows={projectHelper.filterProjectSearchText(projects, searchText)}
            deleteProject={deleteProjectAction}
            editProject={openDialogeditProject}
            copyProject={copyProject}
            shareProject={shareProject}
          />
        }

        {tabValue === 1 &&
          projects.length > 0 &&
          <div style={{ marginLeft: '10%', marginRight: '10%',marginTop:'30px' }}>
            <DataRepositoryTreeTable
              enableContextMenu={false}
              type="private"
              showTitle={false}
              fullProjectView={true}
              dtoData={projectDataRepo}
              treeData={convertDataHelper.parseEntityDataRepositoryToTree(projectDataRepo)}
              iconTitle={faUserLock}
              title={`Select Folder in Private Workspace`}
              titleStyle={{ color: "#45b6fe", margin: 0 }}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setShowDialogs={{ export: undefined, new: undefined }}
              showDialogs={{ new: undefined }}
              expandedKeys={expandedKeys}
              setExpandedKeys={setExpandedKeys}
            />
          </div>
        }

      </div>
    </div>

  );

}
