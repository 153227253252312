import * as actions from "./../../actions";

export function markAsRead(notification, user, dispatch) {
    if (notification._id) dispatch(actions.markAsReadNotifications(notification._id, user.id));
}

export function clickOnNotification(notification, history, user, dispatch) {
  if (notification && notification.params && notification.params.simulationId) {
    if (notification.notificationType === "simulation") {
      history.push(`/${process.env.REACT_APP_PREFIX}/dashboard/${notification.params.simulationId}/simulation`);
    }
    markAsRead(notification, user, dispatch);
  }
  
  if (notification && notification.params && notification.params.rulesDTO && notification.params.rulesDTO.notebook_output && notification.params.rulesDTO.notebook_output.result) {
    if (notification.notificationType === "ruleRecommender") {
      history.push(
        {
          pathname: `/${process.env.REACT_APP_PREFIX}/project/${notification.params.projectId}/${notification.params.qpId}/true/${notification._id}`,
          state: { rulesDTONotification: notification.params.rulesDTO.notebook_output.result,
                  dataSet: notification.params.dataSet
           }
      }
      );
    }
  markAsRead(notification, user, dispatch);
  }
}
