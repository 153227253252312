import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import MaterialTable from "material-table";
import Icon from '@material-ui/core/Icon';
import { loadCSS } from 'fg-loadcss';
import * as actions from './../../../../actions'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { green, red } from '@material-ui/core/colors';
import DialogCreateMasterData from './DialogCreateMasterData'
import * as licenseHelper from './../../common/licenseHelper'
import Typography from '@material-ui/core/Typography'

export default function QualityMasterDataRepositoryTable(props) {
    const user = useSelector(store => store.user)
    const license = useSelector(store => store.license)
    const dispatch = useDispatch()
    const [showEditable, setShowEditable] = React.useState(false)
    const [showSearch, setShowSearch] = React.useState(false)
    const [selectedId, setSelectedId] = React.useState("")
    const [selectedRowData, setSelectedRowData] = React.useState()
    const [showDialogCreateMasterData, setShowDialogCreateMasterData] = React.useState(false)


    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
    }, []);

    function deleteDataRepository(_id) {
        dispatch(actions.deleteDataRepository(_id))
        props.setChangedRepo(true)
    }

    function editDataRepository(row) {
        setSelectedRowData(row)
        setSelectedId(row._id)
        setShowEditable(true)
    }
    function showDataRepository(row) {
        setSelectedRowData(row)
        setShowSearch(true)
    }

    const data = props.data
    if (!props.data) {
        return (
            <div></div>
        )
    }
    return (
        <div>

            <DialogCreateMasterData masterData={selectedRowData} editable={true} setChangedRepo={props.setRepositoriesChanged} open={showEditable} onClose={event => setShowEditable(false)}
                licenseId={license._id} userId={user.id} selected_id = {selectedId} setChangedRepo={props.setChangedRepo}/>
            <DialogCreateMasterData masterData={selectedRowData}  setChangedRepo={props.setChangedRepo} open={showSearch} onClose={event => setShowSearch(false)}
                licenseId={license._id} userId={user.id}  />
            <MaterialTable
                title="Positioning Actions Column Preview"
                data={data}
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Description', field: 'description' },
                    { title: 'File permission', field: 'filePermissionItem', render: rowData => (rowData.filePermissionItem == 'public_permission') ? <LockOpenIcon style={{ justifyContent: 'center', color: green[500] }} ></LockOpenIcon> : <LockIcon style={{ justifyContent: 'center', color: red[500] }}></LockIcon> },
                    { title: 'Path', field: 'blob' ,render: rowData =>  <Typography variant = 'caption'>{rowData.blobStorageDTO.blob_fileName} </Typography>  },
                    { title: 'User', field: 'userId', render: (rowData) => { return  licenseHelper.getUserNamefromId(license,rowData.userId)}  },
                    { title: 'Creation time', field: 'createTime' }
                ]}
                actions={[
                    {
                        icon: 'search',
                        tooltip: 'search User',
                        onClick: (event, rowData) => showDataRepository(rowData)
                    },
                    rowData =>({
                        icon: 'edit',
                        tooltip: 'edit User',
                        onClick: (event, rowData) => editDataRepository(rowData),
                        disabled: rowData.userId!==user.id
                    }),
                    rowData => ({
                        icon: 'delete',
                        tooltip: 'Delete User',
                        onClick: (event, rowData) => deleteDataRepository(rowData._id),
                        disabled: rowData.userId!==user.id
                    })
                ]}
               

                options={{

                    actionsColumnIndex: -1,
                    filtering: false,
                    search: false,
                    pageSize: 7,
                    maxBodyHeight: '90%',
                    minBodyHeight: 250
                }}
                components={{
                    Toolbar: props => (
                        <div>

                        </div>
                    ),
                }}
            />
        </div>

    )
}