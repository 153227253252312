
import React from 'react';
import {
    Grid, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, InputAdornment, Icon,
    ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import CustomInput from "../../../../../common/CustomInput/CustomInput.js";
import AqtivaDialog from "../../../../../common/AqtivaDialog";

import { useDispatch } from 'react-redux'
import * as actions from '../../../../../../actions'
import { useTheme } from '@material-ui/core/styles';
import trackEvent from './../../../../../../trackEvent'
import ExecutionConditionRule from './../../QualityDetailProject/Dialogs/DialogCreateRule/ExecutionConditionRule'



export default function DialogCreateFolder(props) {

    const theme = useTheme();
    const dispatch = useDispatch();
    const [editable, setEditable] = React.useState(props.folder ? true : false)
    const [name, setName] = React.useState(editable && props.folder ? props.folder.name : '')
    const [active, setActive] = React.useState()
    const datasourceId = (getDatasourceId(props.parent, props.parentType))
    const [conditions, setConditions] = React.useState([])

    function getDatasourceId(parent, parentType) {
        if (!parent) {
            return -1
        }
        if (parentType === 'Datasource') {
            return parent._id
        }
        else {
            return parent.datasourceId
        }
    }
    //set editable elements if qualityPoint is found in props
    React.useEffect(() => {

        setEditable(props.folder ? true : false)
        setName(props.folder ? props.folder.name : '')
        setConditions(props.folder ? props.folder.conditions : [])


    }, [props.folder]);
    //set editable elements if qualityPoint is found in props
    React.useEffect(() => {

        setEditable(props.qualityPoint ? true : false)
        setName(props.qualityPoint ? props.qualityPoint.name : undefined)
        setActive(props.qualityPoint ? props.qualityPoint.active : undefined)

    }, [props.qualityPoint]);

    function getParentName() {

        /*
        LOGICA
        1) si el objeto es editable, cogeremos el parent del objeto o "" si no tiene parent (no debería darse
        2) si no es editable
            2.1) si el parent es data source, parentName = ""
            2.2) si el parent es un folder:
                parentName = parent.name
                parentUri = parent.uri
        */
        if (editable && props.folder && props.folder.parent) {
            return props.folder.parentName
        }
        else if (editable && props.folder) {
            return ""
        }
        else if (!editable) {
            if (props.parentType === "Datasource") {
                return ""

            }
            else if (props.parentType === "Folder") {
                return props.parent.name
            }
        }
        else {
            return ""
        }
    }

    function getParentUri() {

        /*
        LOGICA
        1) si el objeto es editable, cogeremos el parent del objeto o "" si no tiene parent (no debería darse
        2) si no es editable
            2.1) si el parent es data source, parentName = ""
            2.2) si el parent es un folder:
                parentName = parent.name
                parentUri = parent.uri
        */
        if (editable && props.folder && props.folder.parent) {
            return props.folder.parentUri
        }
        else if (editable && props.folder) {
            return ""
        }
        else if (!editable) {
            if (props.parentType === "Datasource") {
                return ""

            }
            else if (props.parentType === "Folder") {
                return props.parent.url
            }
        }
        else {
            return ""
        }
    }







    const onChangeTextField = (event) => {

        setName(event.target.value)
    }


    const createFolder = () => {
        const fileItem = {
            url: `${getParentUri()}/${name}`,
            name: name,
            active: active,
            parentName: getParentName(),
            parentUri: getParentUri(),
            datasourceId: datasourceId,
            level: (props.parentType === 'Folder') ? props.parent.level + 1 : 1,
            conditions: conditions ? conditions : []


        }
        dispatch(actions.addFolder(props.project._id,
            (props.parentType === 'Folder') ? props.parent.datasourceId : props.parent._id,
            fileItem))
        props.setClose(false)

    }

    const editFolder = () => {
        var fileItem = Object.assign({}, props.folder);
        fileItem.name = name
        fileItem.conditions = conditions
        dispatch(actions.editFolder(props.project._id,
            (props.parentType === 'Folder') ? props.parent.datasourceId : props.parent._id,
            fileItem))
        props.setClose(false)

    }

    function setExecutionConditionsCallback(cond) {

        setConditions(cond)
    }



    return (
        <AqtivaDialog visible={props.open}
            maxWidth={'lg'}
            editable={editable}
            title={editable ? 'Edit Folder' : 'Create Folder'}
            titleIcon={faFolderOpen}
            confirmText={'Create'}
            cancelText={'Close'}
            editText={'Edit'}
            confirmCallback={createFolder}
            cancelCallback={event => props.setClose(false)}
            editCallback={editFolder}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <CustomInput
                        labelText="Folder Name"
                        id="folder_name_id"
                        formControlProps={{
                            fullWidth: false,
                            style: { width: '200%' }
                        }}

                        inputProps={{
                            type: "text",
                            onChange: onChangeTextField,
                            value: name,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                {undefined && <Grid item xs={12}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid item xs={1}>
                                <FontAwesomeIcon icon={faCog} style={{ color: '#76b3d6', fontSize: 20 }} />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant='subtitle2'>Execution Conditions</Typography>
                            </Grid>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <ExecutionConditionRule setConditions={setExecutionConditionsCallback}
                                dataset={props.datasource ? props.datasource.dataset : []} conditions={conditions} />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>}
            </Grid>



        </AqtivaDialog>
    )
}