export  function deleteProject(projectArray,project){
    return projectArray.filter((currentProject) => {return currentProject.id !== project.id})
}


const setNewId = (projects,currentProject) => {
    const ids = projects.map((currentProject) => {return currentProject.id})
    const sortedIds = ids.sort((a, b) => {return a - b})
    const lastId = sortedIds[sortedIds.length - 1] 
    return lastId + 1
}

export function copyProject(projectArray,project){
    const newProject = {
        id: setNewId(projectArray,project),
        name: project.name + "_copia",
        owner: project.owner,
        source: project.source,
        description: project.description,
        modificationDate: project.modificationDate,
    }
    let newProjectsArray = projectArray.map((currentProject) => {return currentProject})
    return newProjectsArray.push(newProject)
}

export function filterProjectSearchText(projects, searchText){
    
    if(searchText==="" || searchText===undefined){
        return projects
    }
   else{
        return projects.filter(project => (project.name.toLowerCase().includes(searchText.toLowerCase()) || 
        (project.description && project.description!==null && project.description.toLowerCase().includes(searchText.toLowerCase()) ) ))
   } 
}