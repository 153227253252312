
import React from 'react';
import { Grid,Dialog,DialogTitle,Typography,DialogContent,DialogActions,Button,InputAdornment,Icon} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import CustomInput from "../../../../../common/CustomInput/CustomInput.js";

import AdvanceConfigurationQuality from './../../../../../common/AdvanceConfigurationQuality'
import AqtivaDialog from './../../../../../common/AqtivaDialog'

import {useSelector,useDispatch} from 'react-redux';
import * as actions  from '../../../../../../actions';

import { useTheme } from '@material-ui/core/styles';
import trackEvent from './../../../../../../trackEvent';


export default function DialogCreateQualityPoint(props) {

    const theme = useTheme();

    const user = useSelector(store => store.user)
    const dispatch = useDispatch();

    const [editable,setEditable] = React.useState(props.qualityPoint ? true : false)
    const [ name, setName ] = React.useState(props.qualityPoint ? props.qualityPoint.name : '')
    //child components state
    const [thError, setThError] = React.useState()
    const [thWarning, setThWarning] = React.useState()
    const [wrongRegisterAction, setWrongRegisterAction] = React.useState()
    const [outputMode, setOutputMode] = React.useState()
    const [active, setActive] = React.useState()

    //error component state
    const [errorTypes, setErrorTypes] = React.useState();
    const [errorMsgs, setErrorMsgs] = React.useState();
    const [hasErrors,setHasErrors] = React.useState()

    function reset(){
        setName('')

    }

    //set editable elements if qualityPoint is found in props
    React.useEffect(() => {

        setEditable(props.qualityPoint ? true : false)
        setName(props.qualityPoint ? props.qualityPoint.name : '') 
        setActive(props.qualityPoint ? props.qualityPoint.active: true)
        setThError(props.qualityPoint ? props.qualityPoint.errorTh: {})
        setThWarning(props.qualityPoint ? props.qualityPoint.warningTh: {})
        if(props.qualityPoint){
            setControlObj(props.qualityPoint)
        }
        else{
            setWrongRegisterAction('')
            setOutputMode('') //nunca deberíamos entrar aquí
        }
       
        
    }, [props.qualityPoint]);

    React.useEffect(()=>{
        if(!editable){
           const thError = {
                type:props.configuration.thErrorType,
                value:props.configuration.thErrorValue,
                action: props.configuration.thErrorAction
            }
            const thWarning = {
                type:props.configuration.thWarnType,
                value:props.configuration.thWarnValue,
                action: props.configuration.thWarnAction
            }
            setThError(thError)
            setThWarning(thWarning)
            setWrongRegisterAction(props.configuration.wrongRegisterOutput)
            setOutputMode(props.configuration.outputControl)

        }
    },[props.configuration,editable])

    /*
    * CALLBACK SETTERS
    */
    function setControlObj(data){
        setWrongRegisterAction(data.wrongRegisterActionType)
        setOutputMode(data.controlModeType)
    }


    function getControlObj(){
        return {wrongRegisterAction: wrongRegisterAction, outputMode:outputMode}
   
    
}

    const onChangeTextField = (event) => {
        
        setName(event.target.value)
    }


    //**********/

    /*
    * ACTION CALLBACKS
    */

    //callback to save QualityPoint
    const createQualityPoint = () =>{
        const qualityPoint = {
            name: name, 
            active:true,
            userId: user.id,
            errorTh: thError,
            warningTh: thWarning,
            controlModeType: outputMode,
            wrongRegisterActionType:wrongRegisterAction
            
        }
        dispatch(actions.addQualityPipe(props.idProject, qualityPoint))

    }

    //callback to save QualityPoint
    const modifyQualityPoint = () =>{
        const qualityPoint = {
            _id:props.qualityPoint._id,
            name: name, 
            active: active,
            userId: user.id,
            errorTh: thError,
            warningTh: thWarning,
            controlModeType: outputMode,
            wrongRegisterActionType:wrongRegisterAction
            
        }
        dispatch(actions.editQualityPipe(props.idProject, qualityPoint))

    }

    //wrapper to save QualityPoint
    const saveQualityPoint = () =>{

        const {hasErrors} = validate() //{hasErrors, arrayErrorTypes, arrayErrorMsgs}
        if(!hasErrors){
            createQualityPoint()
            props.setClose(false)
            props.clearSelectedItem()
        }

       
    }

    //wrapper to edit QualityPoint
    const editQualityPoint = () =>{
        const {hasErrors} = validate() //{hasErrors, arrayErrorTypes, arrayErrorMsgs}
        if(!hasErrors){
            modifyQualityPoint()
            props.setClose(false)
            props.clearSelectedItem()
        }
    }
    

    /*
    * ERROR FORM HANDLER FUNCTIONS
    */

    /*
    * ERROR HANDLE FUNCTIONS
    */
   function isError(name){
    if(!errorTypes){
        return false
    }
    return errorTypes.includes(name)
}

function getErrorMessage(name){
    var idx = errorTypes.indexOf(name);
    if(idx===-1)
        {
            return ''
        } 
    else{
        return errorMsgs[idx]
    }   
}

 function validate () {
        var hasErrors = false
        const arrayErrorTypes = []
        const arrayErrorMsgs = []

    
        if(!name){
            hasErrors = true
            arrayErrorTypes.push('name')
            arrayErrorMsgs.push('A name must be provided')
        }
        

        setHasErrors(hasErrors)
        setErrorTypes(arrayErrorTypes)
        setErrorMsgs(arrayErrorMsgs)

        return {hasErrors, arrayErrorTypes, arrayErrorMsgs};

    }

 
    return(
        <AqtivaDialog visible={props.open}
            maxWidth = {'md'}
            editable={editable}
            title={editable ? 'Edit Quality Point' : 'Create Quality Point'}
            titleIcon={faProjectDiagram}
            confirmText={'Create'}
            cancelText={'Close'}
            editText={'Edit'}
            confirmCallback={event=>{saveQualityPoint(event);reset()}}
            cancelCallback={event => {
                        props.setClose(false)
                        props.clearSelectedItem()
                        reset()
            }}
            editCallback={event=>{editQualityPoint(event);reset()}}
        >
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>

                                <CustomInput
                                labelText="Quality Point Name"
                                id="ProjectName"
                                formControlProps={{
                                    fullWidth: false,
                                    style:{width:'60%'}
                                }}
                                
                                inputProps={{
                                    type: "text",
                                    onChange: onChangeTextField,
                                    value: name,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />
                                <div >
                                    {hasErrors && isError('name') &&  <Typography variant='caption' style={{color:'#ab2e27'}}> {getErrorMessage('name')}</Typography>}
                                </div>
                            </div>
                        </Grid>
                </Grid>
                 <AdvanceConfigurationQuality setThError = {setThError} setThWarning = {setThWarning} setControlObj={setControlObj}
                 thError={thError} 
                 thWarning = {thWarning} 
                 controlObj = {getControlObj()}/>      
                        
                            
                           

                        </AqtivaDialog>
    )
}