import React, { useEffect, useState } from 'react';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { Grid, Typography, InputAdornment, Icon, FormControlLabel, Switch, Select, MenuItem } from '@material-ui/core'
import AqtivaDialog from './../../../../common/AqtivaDialog'
import trackEvent from '../../../../../trackEvent';
import { useSelector, useDispatch } from 'react-redux';
import InputFile from './../../../..//common/azure-storage/components/InputFile'
import * as actions from '../../../../../actions'
import CustomInput from "../../../../common/CustomInput/CustomInput.js";
import TablePrevisualization from './../../../project/QualityDetailProjectPage/QualityDetailProject/Dialogs/DialogCreateDataFormat/TablePrevisualization'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DialogEditRecommendedRules from './../DialogEditRecommendedRules'
import RuleRecommenderAdvanceConfiguration from './RuleRecommenderAdvanceConfiguration'
import QualityItemSelector from './../../../../common/QualityItemSelector'
import * as configurationHelper from './../../../common/configurationHelper'
import { useTheme } from '@material-ui/core/styles';


export default function DialogRecommendedRules(props) {
    const dispatch = useDispatch();
    const theme = useTheme()

    const { configuration, open, setClose, userId, recommenderShowNotification } = props;
    const [uploadFileName, setUploadFileName] = useState('')
    const [error, setError] = useState({})
    const [separator, setSeparator] = React.useState('automatic')
    const [colFormats, setColFormats] = React.useState(props.datasetFromParams ? props.datasetFromParams.columns : [])
    const [checkAutomaticSeparator, setCheckAutomaticSeparator] = React.useState(true)
    const [dataset, setDataset] = React.useState(props.datasetFromParams ? props.datasetFromParams : undefined)
    const [parsingData, setParsingData] = React.useState(false)
    const [openEditRecommendedRulesDialog, setOpenEditRecommendedRulesDialog] = useState(false)

    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState()
    const [runId, setRunId] = useState()
    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    const [jsonConfig, setJsonConfig] = React.useState(undefined)

    const user = useSelector(store => store.user)
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))


    useEffect(()=>{

        if(qualityConfiguration && qualityConfiguration.environmentConfigurationList &&
            configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id).length>0){
                setSelectedEnvironment(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)[0].environmentName)
            }
            
           
    }, [qualityConfiguration])

    useEffect(() => {

        setColFormats(props.datasetFromParams ? props.datasetFromParams.columns : [])
        setDataset(props.datasetFromParams ? props.datasetFromParams : undefined)
    }, [uploadFileName]);


    useEffect(() => {
        validate()
    }, [uploadFileName]);

    useEffect(() => {
        if (recommenderShowNotification === true) {
            setOpenEditRecommendedRulesDialog(recommenderShowNotification)
        }
    }, []);

    useEffect(() => {
        if(props.datasetFromParams !== undefined){
            setColFormats(props.datasetFromParams ? props.datasetFromParams.columns : [])
            setDataset(props.datasetFromParams ? props.datasetFromParams : undefined)
            
        }
    }, [props.datasetFromParams ]);




    function validate() {
        var errors = {};
        if (!uploadFileName || uploadFileName === null) {
            errors.colFormats = 'You must upload a sample File';
        }

        if (props.qpId === undefined && (!selectedQualityPoint || selectedQualityPoint === null)) {
            errors.selectedQualityPoint = 'You must select a Quality Point';
        }

        if (!selectedEnvironment || selectedEnvironment === null) {
            errors.selectedEnvironment = 'You must selected an environment';
        }
        setError(errors)
        return errors;
    }


    async function onUpload(fileName) {
        setUploadFileName(fileName)
        trackEvent('Rule Recommender', 'Upload Data File')

        var sep = separator
        if (sep === ";") {
            sep = "semicolon"
        }
        else if (sep === "|") {
            sep = "pipe"
        }
        setParsingData(true)
        const response = await dispatch(actions.parseDataFormatBlob(sep, fileName, user.id, "rulerecommender"))
        setParsingData(false)
        setColFormats(response.data.columns)
        setDataset(response.data)
    }

    async function sendFile() {
        if (props.qpId !== undefined || selectedQualityPoint !== undefined) {
            let environment = qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0]
            let connStr = `DefaultEndpointsProtocol=https;AccountName=${environment ? environment.storageAccountName : 'sainputdemoquality'};AccountKey=${environment.storageKey};EndpointSuffix=core.windows.net`
            let ruleRecommenderPostDTO = {
                //connection??
                connectionString: connStr,
                containerName: "rulerecommender",
                blobPath: uploadFileName,
                separator: dataset ? dataset.separator : ",",
                jsonConfig: JSON.stringify(jsonConfig)
            }
            setOpenEditRecommendedRulesDialog(true)
            let recommJobDTO = await dispatch(actions.postDataForRecommendation(selectedEnvironment, userId, ruleRecommenderPostDTO, props.projectId, props.qpId))
            if(recommJobDTO!==undefined && recommJobDTO.data!==undefined){
                const run_id = recommJobDTO.data.run_id
            setRunId(run_id)
        }
            }
            


    }

    function setColFormatsCallback(newFormat) {
        setColFormats(newFormat)
    }
    function onChangeSeparator(event) {
        setSeparator(event.target.value)
    }

    function setCloseAll() {
        setOpenEditRecommendedRulesDialog(false)
        props.setClose(false)
    }

    function isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }


    return (
        <div>
            <DialogEditRecommendedRules open={openEditRecommendedRulesDialog} setClose={setCloseAll}
                configuration={configuration}
                runId={runId}
                dataset={dataset}
                setDataset={setDataset}
                projectId={props.projectId}
                qpId={props.qpId !== undefined ? props.qpId : selectedQualityPoint !== undefined ? selectedQualityPoint._id : undefined}
                recommenderShowNotification={recommenderShowNotification}
                //ruleDTOId = {ruleDTOId}
                runId={runId}
                userId={user.id}
                history={props.history}
            />

            <AqtivaDialog visible={open}
                maxWidth={'md'}
                editable={false}
                title={'Rule recommender'}
                titleIcon={faProjectDiagram}
                confirmText={'Send'}
                cancelText={'Close'}
                editText={'Edit'}
                confirmCallback={event => {
                    const results = validate()
                    if (isEmpty(results)) {
                        sendFile()
                    }

                }}
                cancelCallback={event => {
                    setClose(false)
                }}
                editCallback={event => { return event; }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            {props.qpId === undefined && props.project !== undefined &&
                                <Grid item xs={8}>
                                    <QualityItemSelector
                                        title={'Select a Quality Point'} type='QualityPoint'
                                        value={selectedQualityPoint}
                                        onChange={event => { setSelectedQualityPoint(event.target.value); validate() }}
                                        itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints.filter(x => x.active === true) : [])}
                                        itemId={'_id'} itemValue={'name'}
                                    />
                                    {error && error.selectedQualityPoint &&
                                        <div className="ui error message" style={theme.errors.errorText}>
                                            <div className="header" >{error.selectedQualityPoint}</div>
                                        </div>}
                                </Grid>
                            }
                            <Grid item xs={12} style={{marginTop:'-10px'}}>
                                <Typography variant='subtitle2'>Select an environment</Typography>
                                <Select
                                    value={selectedEnvironment}
                                    onChange={event => { setSelectedEnvironment(event.target.value); validate() }}
                                    inputProps={{
                                        name: 'Environment',
                                        id: 'Environment',
                                    }}
                                    style={{ width: '55%', marginRight: '20px' }}
                                >
                                    {qualityConfiguration && qualityConfiguration.environmentConfigurationList &&
                                        configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id).map(element => {
                                            return <MenuItem key={element.environmentName} value={element.environmentName}>{element.environmentName}</MenuItem>
                                        })}

                                </Select>
                                {error && error.selectedEnvironment &&
                                    <div className="ui error message" style={theme.errors.errorText}>
                                        <div className="header" >{error.selectedEnvironment}</div>
                                    </div>}
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checkAutomaticSeparator}
                                            onChange={event => {
                                                setCheckAutomaticSeparator(event.target.checked);
                                                setSeparator('automatic')
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant='body2'> Automatic Separator Estimation</Typography>}
                                />
                            </Grid>

                            {!checkAutomaticSeparator &&
                                <Grid item xs={12}>
                                    <CustomInput
                                        labelText="Separator"
                                        id="separator_id"
                                        formControlProps={{
                                            fullWidth: false,
                                            style: { width: '30%' }
                                        }}

                                        inputProps={{
                                            type: "text",
                                            onChange: onChangeSeparator,
                                            value: separator,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FontAwesomeIcon icon={faCode} style={{ fontSize: 20 }} />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <div >
                                    <Typography variant='subtitle2'>Select a File to use as sample to receive recommended rules</Typography>
                                    <div style={{ marginTop: '5%' }}>
                                        <InputFile onUpload={onUpload} folder={`${userId}/stagging`}
                                            storageAccountName={selectedEnvironment ? qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].storageAccountName : 'sainputdemoquality'}
                                            containerName={'rulerecommender'}
                                            sasToken={selectedEnvironment ? qualityConfiguration.environmentConfigurationList.filter(x => x.environmentName === selectedEnvironment)[0].sasToken : '?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-09-11T14:57:13Z&st=2020-06-22T06:57:13Z&spr=https&sig=f18IZ5vTdx%2FkwLGFjAEhpRZ%2FBZkS%2B3d5Zrv5c1xZZfc%3D'}
                                            buttonText={'Upload File'} />
                                    </div>

                                </div>
                            </Grid>



                            
                        </Grid>
                    </Grid>
                        <Grid item xs={6}>
                            <div style={{marginTop:'10px'}}>

                            
                            <TablePrevisualization
                                columnFormats={colFormats}
                                setColumnFormats={setColFormatsCallback}
                                parsingData={parsingData}
                            />
                            </div>

                        </Grid>
                        <Grid item xs={12}>
                                <RuleRecommenderAdvanceConfiguration
                                    setJsonConfig={setJsonConfig}
                                />
                            </Grid>


                    </Grid>
                
            </AqtivaDialog>
        </div>
    )
}



