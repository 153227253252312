import React from "react";

import File from "./File/index";
import Folder from "./Folder/index";

//Este componente es una especie de wrapper que sirve para englobar las zonas de drag and drop.
//Cuando queramos aplicarlo lo haremos en DataRepositoryTreeTable y le pasaremos por {component} la zona que nos interesa.
export function DragDropTemplate(
  node,
  fullProjectView,
  props,
  component,
) {

  if (fullProjectView === true && node.data.type !== "Folder") {
    return (
      <File itemId={node.key} repositoryType={props.type}>
        { component }
      </File>
    );
  } else {
    return (
      <Folder propsParent={props} itemId={node.key} repositoryType={props.type}>
       { component }
      </Folder>
    );
  }
}