import React from 'react';
import MaterialTable from 'material-table'
import * as actions  from './../../../actions'
import {useSelector,useDispatch} from 'react-redux'

import {
    makeStyles, Grid, Dialog, DialogTitle, Typography, DialogContent,Select,Input,MenuItem,Chip,
    DialogActions, Button, InputAdornment, LinearProgress, FormControlLabel, Switch,Icon,InputLabel
} from '@material-ui/core'

import CustomInput from "./../../common/CustomInput/CustomInput.js";


import { useTheme } from '@material-ui/core/styles';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons'


export default function DialogCreateLicense(props) {

  
    const theme = useTheme();
    const dispatch = useDispatch()

    function generateApiKey(){
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
    function getOneYearAheadDate(){
        const d = new Date
        var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var c = new Date(year + 1, month, day);
    return c
    }

    const [licenseName,setLicenseName] = React.useState('')
    const [apiKey, setApiKey] = React.useState(generateApiKey())
    const [numMaxUsers, setNumMaxUsers] = React.useState(5)
    const [initDate, setInitDate] = React.useState(new Date().toISOString().split('T')[0])
    const [endDate, setEndDate] = React.useState(getOneYearAheadDate().toISOString().split('T')[0])
    const [email, setEmail] = React.useState('')
    const [userProvider,setUserProvider] = React.useState(undefined)
    const [lambdaActivated, setLambdaActivated] = React.useState(false)



    function updateLicenseName(event){
        setLicenseName(event.target.value)
    }
    function updateNumMaxUsers(event){
        setNumMaxUsers(event.target.value)
    }
    function updateInitDate(event){
        setInitDate(event.target.value)
    }
    function updateEndDate(event){
        setEndDate(event.target.value)
    }
    function updateUserEmail(event){
        setEmail(event.target.value)
    }
    function onHandleProviderChange(event){
        setUserProvider(event.target.value)
    }

    async function create(){
        var user ={
            name:'',
            email: email.toLowerCase(),
            provider: userProvider,
            role:"ADMIN"

        }
        const userCreated = await dispatch(actions.createUser(user))
        if(userCreated && userCreated!=null){
            //user created correctly
            const license={
                userId: userCreated.id,
                licenseName: licenseName,
                apiKey: apiKey,
                isUserLicense: false,
                users:[
                    userCreated
                ],
                initDate: initDate,
                endDate: endDate,
                numMaxUsers: numMaxUsers,
            }
            var licenseCreated = await dispatch(actions.createLicense(license))
            if(licenseCreated && licenseCreated!=null){
                userCreated.licenseId = licenseCreated._id
                licenseCreated.users = [userCreated]
                const userUpdated = await dispatch(actions.updateUser(userCreated))
                const licenseUpdated = await dispatch(actions.updateLicense(licenseCreated))
                
            }
        }
        props.onClose()
    }


    return (
        
        <Dialog
                aria-labelledby="create-license-dialog"
                open={props.visible}
                fullWidth={true}
                maxWidth={'sm'}

            >
                <DialogTitle style={theme.dialogs.dialogTitle}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item>
                            <FontAwesomeIcon icon={faIdBadge} style={theme.dialogs.dialogTitleAwesomeIcon} />

                        </Grid>
                        <Grid item >
                            <Typography  style={theme.dialogs.dialogTitleText}>
                                {`Create License`}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>

                <Grid container spacing={2}>
                <Grid item xs={12}> 
                            <CustomInput
                                labelText="License Name"
                                id="licenseName"
                                formControlProps={{
                                    fullWidth: true,
                                    
                                }}
                                
                                inputProps={{
                                    type: "text",
                                    value: licenseName,
                                    onChange: updateLicenseName,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />
                        </Grid>
                        <Grid item xs={8}> 
                            <CustomInput
                                labelText="API Key"
                                id="apiKey"
                                formControlProps={{
                                    fullWidth: true,
                                    
                                }}
                                
                                inputProps={{
                                    type: "text",
                                    value: apiKey,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />
                        </Grid>

                        <Grid item xs={4}> 
                            <CustomInput
                                labelText="Max Number of Users"
                                id="numMaxUsers"
                                formControlProps={{
                                    fullWidth: true,
                                    
                                }}
                                
                                inputProps={{
                                    type: "number",
                                    onChange: updateNumMaxUsers,
                                    value: numMaxUsers,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />
                        </Grid>
                        
                        <Grid item xs={6}> 
                            <CustomInput
                                labelText="Init Date"
                                id="initDate"
                                formControlProps={{
                                    fullWidth: true,
                                    
                                }}
                                
                                inputProps={{
                                    type: "date",
                                    onChange: updateInitDate,
                                    value: initDate,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />
                        </Grid>
                        <Grid item xs={6}> 
                        <CustomInput
                                labelText="End Date"
                                id="endDate"
                                formControlProps={{
                                    fullWidth: true,
                                    
                                }}
                                
                                inputProps={{
                                    type: "date",
                                    onChange: updateEndDate,
                                    value: endDate,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}> 
                                <Typography variant='body2'>License Owner User</Typography>
                        </Grid>

                        <Grid item xs={6}> 
                        <CustomInput
                                labelText="User Email"
                                id="userEmail"
                                formControlProps={{
                                    fullWidth: true,
                                    
                                }}
                                
                                inputProps={{
                                    type: "text",
                                    onChange: updateUserEmail,
                                    value: email,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />
                        </Grid>
                        <Grid item xs={6}>
                        <InputLabel htmlFor="age-auto-width" style={{marginTop:'5%'}}>Provider</InputLabel>
                            <Select
                            
                            value={userProvider}
                            onChange={onHandleProviderChange}
                            input={<Input name="age" id="age-auto-width" />}
                            fullWidth
                            >
                           
                           
                            <MenuItem value={'google'}>google</MenuItem>
                            <MenuItem value={'azure'}>azure</MenuItem>
                            </Select>
                            
                            
                        </Grid>

                        <Grid item xs={12}>
                        <InputLabel htmlFor="age-auto-width" style={{marginTop:'5%'}}>Execution Environment Activation</InputLabel>
                                <FormControlLabel style={theme.config.formControlLong}
                                control={
                                    <Switch
                                        checked={lambdaActivated}
                                        onChange={event => setLambdaActivated(event.target.checked)}
                                        value={lambdaActivated}
                                        color="primary"
                                    />
                                }
                                label="Lambda Execution Capability"
                            />
                            
                            
                        </Grid>

                      
                </Grid>

                </DialogContent>
                <DialogActions>
                <div>
                    <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm}
                    onClick = {event=>create()} >
                        Save
                    </Button>
                   
                    
                    <Button id='CancelButton' variant='outlined'  style={theme.buttons.buttonCancel} 
                    onClick={event=>props.onClose()}>
                        Cancel
                    </Button>
                </div>   
                </DialogActions>
            </Dialog>


    );
}