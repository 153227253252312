
import React from 'react'
import {Button, Grid,Popper, ButtonGroup, Grow,Paper,ClickAwayListener,MenuItem, MenuList} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useSelector,useDispatch} from 'react-redux'
import * as actions  from '../../../../../actions'
import {FETCH_INFO} from '../../../../../actions/types'

import DialogDeployExternal from './../QualityDetailProject/Dialogs/DialogDeployExternal'
import trackEvent from './../../../../../trackEvent'
import * as configurationHelper from './../../../common/configurationHelper'
import { useTheme } from '@material-ui/core/styles';


export default function DeployExternalButton(props) {

      const dispatch = useDispatch();
      const theme = useTheme();

      const user = useSelector(store => store.user)

      const [options, setOptions] = React.useState((props.qualityConfiguration  && configurationHelper.filterAllowedEnvironments(
        props.qualityConfiguration.environmentConfigurationList,user.id) ) 
      ? configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList,user.id).map(x=>x.environmentName) : ['No Environment']);
      const [open, setOpen] = React.useState(false);
      const anchorRef = React.useRef(null);
      const [selectedIndex, setSelectedIndex] = React.useState(0);

      const [showDeployExternalDialog, setShowDeployExternalDialog] = React.useState(false)
      const [selectedEnvironment, setSelectedEnvironment] = React.useState('')

      React.useEffect(() => {
        setOptions((props.qualityConfiguration  && configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList,user.id) ) 
        ? configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList,user.id).map(x=>x.environmentName) : ['No Environment'])
    }, [props.qualityConfiguration]);

     
    
      const handleToggleButton=(event)=> {
        setOpen((prevOpen) => !prevOpen);

      }

      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
      };

      const handleMenuItemClickButtonExport=(event, index)=> {
          setSelectedIndex(index)
          setOpen(false)
     
    }

    async function asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }

    const asyncDeployForEach = async (data,environment,containerClient, projectId) => {
      await asyncForEach(data, async (x) => {
        var filename = "deploy/"+environment +'/'+projectId+'/'+JSON.parse(x).quality_point.quality_point_name+".json"
            filename =filename.split(' ').join('_')
            
          
            const blockBlobClient = containerClient.getBlockBlobClient(filename)
            blockBlobClient.uploadBrowserData(x).then(
              response=>{
                dispatch( {
                  type : FETCH_INFO,
                  payload: `QualityPoint ${JSON.parse(x).quality_point.quality_point_name} updated to BlobStorage`
              });
              }
            )

            await dispatch(actions.deployNotebook2DatabricksAzure(environment,user.id,projectId,JSON.parse(x).quality_point.quality_point_name))

      });
      
    }

     function onClickDeploy(e,environment){
       setSelectedEnvironment(environment)
        setShowDeployExternalDialog(true)      
      
      }
      function onCloseDeploy(){
        setShowDeployExternalDialog(false)      
      
      }
      
    
    return( 
        <div style={{ width:'100%'}}>
          <DialogDeployExternal visible={showDeployExternalDialog}
          onClose = {onCloseDeploy}
          environment = {selectedEnvironment}
          project = {props.project}
          />
           <ButtonGroup ref={anchorRef}    style={{ width:'100%'}}
                     aria-label="split button">
                         <Button style={props.visible===true ? theme.buttons.buttonGroupRegular : theme.buttons.buttonGroupRegularDisabled}
                         onClick={e=>{
                           if(props.visible === true){
                            trackEvent('DeployExternalButton','Deploy Button');
                            onClickDeploy(e,options[selectedIndex])
                           }
                          }}
                         startIcon={<FontAwesomeIcon icon={faAngleDoubleDown} 
                         style={props.visible===true ? {fontSize:14,marginBottom:'5%'}: {fontSize:14,marginBottom:'5%',color:"#959595"}} />}
                       >
                         <div style={props.visible===true  ? theme.common.text.buttonText : theme.common.text.buttonTextDisabled}>

                             {`External Tool, Env: ${options[selectedIndex]}`}
                          </div>
                        </Button>
                        <Button style={props.visible===true ? {borderWidth: "1px",} : {borderWidth: "1px",color:"#959595"}}
                            size="small"
                            aria-haspopup="true"
                            onClick={event=>{
                              if(props.visible===true){
                              trackEvent('DeployExternalButton','ArrowDropDown Button');
                              handleToggleButton(event)}}
                            }
                            
                        >
                        <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <div style={{ zIndex:24 }}>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper style={{zIndex: "10000",}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" style={{zIndex: "10000",}}>
                  {options.map((option, index) => (
                            <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={event => handleMenuItemClickButtonExport(event, index)}
                            >
                                <div style={{fontSize: '0.7vw', color: '#76b3d6', textTransform: 'none', fontWeight: 'bold'}}>{` ${option}`}</div>
                                
                            </MenuItem>
                            ))}
                  
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </div>
       </div>
    )

}