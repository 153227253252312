import React from 'react';
import AppBarTemplate from './../../template/AppBarTemplate'
import Parallax from "./../../common/Parallax/Parallax.js";
import QualityRepository from './../QualityRepository'
import ReactGA from 'react-ga';


ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//ReactGA.pageview('quality/repository'); 

export default function QualityRepositoryPage(props) {

    return (
        <div >
            <AppBarTemplate history={props.history} title='Quality Repository'/>
           
            <div style={{'padding':'2%'}}>
                <QualityRepository>
                    
                </QualityRepository>
            
            </div>
        </div>

    );
}