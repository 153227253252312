import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  faShareSquare,
  faUserLock,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { DataRepositoryTreeTable } from "../../../DataRepositoryTreeTrable/DataRepositoryTreeTable.js";
import { Grid, Typography } from "@material-ui/core";
import * as convertDataHelper from "../../Helpers/convertDataHelper";
import { AqtivaDialogDataRepository } from "../../AqtivaDialogDataRepository";
import { getEntityIdFromFileInRepository } from "../../Helpers/getterDTOs";
//import {AlertDialog} from "../AlertDialog" este dialog no es necesario en este caso, pero podría ser una solución a las excepciones
import { useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import * as dtoHelper from "./../../Helpers/getterDTOs";

export function OptionExport({
  showDialog,
  setShowDialog,
  expandedKeys,
  setExpandedKeys,
  expandedKeysShared,
  setExpandedKeysShared,
  entityName,
  ...props
}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const user = useSelector((store) => store.user);

  //const [dialogAlert, setDialogAlert] = useState(false)
  const [error, setError] = useState("");

  const entityDataRepo = useSelector((store) =>
    Object.values(store[entityName])
  );
  const entityDataRepoShared = useSelector((store) =>
    Object.values(store[entityName + "Shared"])
  );

  //Anterior a refactor
  /*   const projectDataRepo = useSelector((store) =>
  Object.values(store.projectDataRepository)
);
const projectDataRepoShared = useSelector((store) =>
  Object.values(store.projectDataRepositoryShared)
);
 */

  useEffect(() => {
    //console.log(showDialog);
    if (
      showDialog &&
      props.selectedItem &&
      props.selectedItemShared &&
      showDialog === "rightClick"
    ) {
      exportFile(props.selectedItem, props.selectedItemShared);
    }
  }, [showDialog]);

  useEffect(() => {
    validate(props.selectedItem, props.selectedItemShared);
  }, [props.selectedItem, props.selectedItemShared]);

  function validate(selectedItem, selectedItemShared) {
    if (!selectedItem || !selectedItem.itemId) {
      setError(
        "A file needs to be selected in Private Workspace to Import. No element selected."
      );
      return false;
    }
    if (selectedItem.type !== "Files") {
      setError(
        "A file needs to be selected in Private Workspace to Export. You have selected a folder."
      );
      return false;
    }
    if (!selectedItemShared || !selectedItemShared.itemId) {
      setError(
        "A folder needs to be selected in Shared Workspace to Import. No element selected."
      );
      return false;
    }
    if (selectedItemShared.type !== "Folder") {
      setError(
        "A folder needs to be selected in Shared Workspace to Export. You have selected a file."
      );
      return false;
    }
    setError("");
    return true;
  }

  function exportFile(selectedItem, selectedItemShared) {
    const validated = validate(selectedItem, selectedItemShared);
    if (validated) {
      //console.log("export");
      //console.log(selectedItem);
      dispatch(
        props.actions.exportFileFromRepository(
          user.id,
          dtoHelper.getDTOIdFromFolder(
            entityDataRepoShared,
            selectedItemShared.itemId
          ), //dto destino
          selectedItemShared.itemId, //item destino
          dtoHelper.getDTOIdFromFilePrivate(
            entityDataRepo,
            selectedItem.itemId
          ), //dto origen
          selectedItem.itemId, //item origen
          getEntityIdFromFileInRepository(entityDataRepo, selectedItem.itemId)
        )
      );
      setShowDialog(false);
    }
    /* if (selectedItem.type === "Files" && selectedItemShared.type === "Folder") {
      dispatch(
        actions.exportFileFromRepository(
          user.id, 
          projectDataRepoShared[0]._id, //dto id
          selectedItemShared.itemId,
          getProjectIdFromFileInRepository(projectDataRepo, selectedItem.itemId)
        )
      )
      setShowDialog(false)
    } else {
      setDialogAlert(true)
      
    } */ //Gestión de alertas que tenía previamente utilizando los estados (siempre salía el diálogo de alerta)
  }

  return (
    <>
      {/*      <AlertDialog
        showDialog={dialogAlert}
        setDialog={setDialogAlert}
        title={"Important"}
        titleIcon={faExclamationTriangle}
        children={<p>To <b>Export</b> from your Private Workspace, select a file from your Private Workspace and a destination folder from Shared Workspace</p>}
      /> */}
      <AqtivaDialogDataRepository
        dialog={!!showDialog}
        setDialog={setShowDialog}
        title={"Export from Private Workspace"}
        titleIcon={faUpload}
        confirmText={"Export"}
        confirmCallback={() =>
          exportFile(props.selectedItem, props.selectedItemShared)
        }
      >
        <Grid container spacing={1}>
          {error !== "" && (
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                style={{
                  ...theme.dataRepository.errorMessage,
                  marginLeft: "15px",
                }}
              >
                {error}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <div style={{ margin: "5px" }}>
              <DataRepositoryTreeTable
                type="private"
                dtoData={entityDataRepo}
                treeData={convertDataHelper.parseEntityDataRepositoryToTree(
                  entityDataRepo
                )}
                iconTitle={faUserLock}
                title={`Private Workspace User ${user.name}`}
                titleStyle={{ color: "#45b6fe", margin: 0 }}
                selectedItem={props.selectedItem}
                setSelectedItem={props.setSelectedItem}
                expandedKeys={expandedKeys}
                setExpandedKeys={setExpandedKeys}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ margin: "5px" }}>
              <DataRepositoryTreeTable
                type="shared"
                dtoData={entityDataRepoShared}
                /*                treeData={convertDataHelper.parseEntityDataRepositoryToTree(
                  entityDataRepoShared
                )} */
                //Hemos añadido filterSharedRepository para que no aparezcan las carpetas privadas del user
                treeData={convertDataHelper.filterSharedRepository(
                  convertDataHelper.parseEntityDataRepositoryToTree(
                    entityDataRepoShared
                  )
                )}
                iconTitle={faShareSquare}
                title={"Shared projects area"}
                titleStyle={{ color: "#45b6fe", margin: 0 }}
                selectedItem={props.selectedItemShared}
                setSelectedItem={props.setSelectedItemShared}
                expandedKeys={expandedKeysShared}
                setExpandedKeys={setExpandedKeysShared}
              />
            </div>
          </Grid>
        </Grid>
      </AqtivaDialogDataRepository>
    </>
  );
}
