import React from "react";
import { loadCSS } from "fg-loadcss";
import {Button} from "@material-ui/core";
import { AqtivaDialogDataRepository } from './EntityDataRepository/AqtivaDialogDataRepository'
import { TabsBar } from './Tabs'
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import AppBarTemplate from "../../template/AppBarTemplate";
import * as pathHelper from "./../../template/PathHelper";

import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../actions";

export default function DataRepository (props) {
  const [dialog, setDialog] = React.useState(true);
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);

  React.useEffect(() => {
    dispatch(actions.fetchUser());
  }, []);

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);

  return (
    <div>
      <AppBarTemplate
        history={props.history}
        title={"Data Repository"}
        arrayPath={pathHelper.getPathArray("Dashboard")}
      />
      
      <Button onClick={() => setDialog(true)}>CLICK TO OPEN DIALOG</Button>
      <AqtivaDialogDataRepository 
        dialog={dialog} 
        setDialog={setDialog} 
        title="Data Repository"
        titleIcon={faDatabase}
        confirmCallback={(event) => console.log("I am clicking here")}
      >
        <TabsBar user={user}/>
      </AqtivaDialogDataRepository>
    </div>
  );
}
