import React from 'react';

import {ExpansionPanel,ExpansionPanelSummary,ExpansionPanelDetails, Grid, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import EHubsTable from './EHubsTable'


export default function StreamEventHubConfiguration(props) {

    return(
        <ExpansionPanel
        style={{width:'100%'}}>
                        <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                                 <Grid item xs={1}>
                                <FontAwesomeIcon icon={faCog} style={{color:'#76b3d6', fontSize:20}} />
                                </Grid>
                                <Grid item xs={11}>
                                     <Typography variant = 'subtitle2'>Streaming Data Configuration</Typography>
                                </Grid>
                        
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                        
                                <div style={{width:'10%', minWidth:'800px'}}>
                                <Typography variant="subtitle2"> EventHub Configuration</Typography>
                                <EHubsTable updateEventHubProps = {props.updateEventHubProps}
                                configuration ={props.configuration}/>
                                </div>
                        

                        </ExpansionPanelDetails>
                </ExpansionPanel>
)
    }