import _ from 'lodash';
import {FETCH_RULE_RECOMMENDER
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_RULE_RECOMMENDER:
            return action.payload;
        default:
            return state;
        
    }
}