import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import Link from "@material-ui/core/Link";
import { Typography, Switch,Tooltip } from '@material-ui/core';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import {faFileAlt} from '@fortawesome/free-solid-svg-icons'
import { faHubspot } from '@fortawesome/free-brands-svg-icons'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { useDrag } from "react-dnd";
import * as helper from './../QualityProjectTableHelper'
 


export function NameCellTemplate(node, project, setSelectedItem, restartRule) {
  
    if(node.data.type === 'dvd'){
    }
    return (
        <div style={{ display: 'inline-flex', width:'70%'}}>
            {(node.data.type === 'Quality Point') && <FontAwesomeIcon icon={faProjectDiagram} style={{ color: '#76b3d6', fontSize: 'calc(9px + 0.3vw)',  marginRight: '10%' }} />}
            {(node.data.type === 'Datasource' && node.data.datasourceType!=='REAL_TIME_LAMBDA') && <FontAwesomeIcon icon={faDatabase} style={{ color: '#76b3d6', fontSize: 'calc(9px + 0.3vw)',  marginRight: '10%' }} />}
            {(node.data.type === 'Datasource' && node.data.datasourceType==='REAL_TIME_LAMBDA') && <FontAwesomeIcon icon={faHubspot} style={{ color: '#76b3d6', fontSize: 'calc(9px + 0.3vw)',  marginRight: '10%' }} />}
            
            {(node.data.nodeType=== 'Rule') && <FontAwesomeIcon  icon={faFileAlt} style={{ color: '#03ad39', fontSize: 'calc(9px + 0.3vw)', marginRight: '10%' }} />}
            {(node.data.type === 'Folder') && <FontAwesomeIcon icon={faFolderOpen} style={{ color: '#cc9c33', fontSize: 'calc(9px + 0.3vw)', marginRight: '10%' }} />}

            {(node.data.type === 'Quality Point') &&
                <Typography style={{ cursor: 'pointer', width:'100%', color: '#76b3d6',fontSize: 'calc(8px + 0.3vw)' }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project)
                        setSelectedItem(item, itemType)
                    }}
                >
                    {node.data.name} </Typography>
            }
            {(node.data.type === 'Datasource') &&
                

                <div>
                    <Typography style={{ cursor: 'pointer', width:'100%', color: '#404040',fontSize: 'calc(8px + 0.3vw)' }} variant='subtitle2'
                        onDoubleClick={e => {
                            const { item, itemType } = helper.obtainEditableItem(node.key, project)
                            setSelectedItem(item, itemType)
                        }}
                    >
                        {node.data.dataset ? `${node.data.name} (${node.data.dataset.name})` : node.data.name}  </Typography>
                </div>
            }

            {(node.data.type === 'Folder') &&
                <Typography style={{ cursor: 'pointer',width:'100%', color: '#404040',fontSize: 'calc(7px + 0.3vw)'  }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project)
                        setSelectedItem(item, itemType)
                    }}
                >
                    {node.data.name} </Typography>
            }
            
            {(node.data.nodeType === 'Rule') && (node.data.type === 'dvd') &&
                 <div style={{ display: 'inline-flex'}}/*  ref={dragRef} */>
                     
                    <Typography  style={{ cursor: 'pointer', color: '#404040', width:'100%',fontSize: 'calc(7px + 0.3vw)' }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project)
                        setSelectedItem(item, itemType)
                    }}
                    >
                        {node.data.name} 
                    </Typography>
                    <Tooltip title={'Reset KPIs to restart rule'} >
                        <div>
                            <FontAwesomeIcon icon={faSyncAlt} style={{ marginLeft:'10px',color: '#4697c4', fontSize: 16, }}
                            onClick={event=>restartRule(node.data._id)} />
                        </div>
                        
                    </Tooltip>
                </div>
                
            }

            {(node.data.nodeType === 'Rule') && (node.data.type !== 'dvd') &&
                <Typography style={{ cursor: 'pointer', color: '#404040', width:'100%',fontSize: 'calc(7px + 0.3vw)' }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project)
                        setSelectedItem(item, itemType)
                    }}
                >
                    {node.data.name} </Typography>
            }
            
        </div>
    );
}