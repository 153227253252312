import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ChartLineBiaxialTotalEventRules from "../ChartBiaxialTotalEventRules";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  elementAdjacent: {
    display: "flex",
    justifyContent: "left",
  },
}));

//Pasamos por props los datos al componente "hijo": ChartLineBiaxialTotalEventRules
export default function ChartTotalEventRules(props) {
  const classes = useStyles();

  const [chartData, setChartData] = React.useState([])

  return (
    <div>
      <Paper className={classes.paper} style={{ minHeight: "320px" }}>
         <Typography>Event rules by time</Typography>
        <ChartLineBiaxialTotalEventRules chartData={props.dataTotalRecords.graphData} />
      </Paper>
    </div>
  )

}