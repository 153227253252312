export function createRuleForBackend(common, specific,id,conditions){

    var rule = {
        name: common.common.name,
        warningTh : common.common.thWarning,
        errorTh: common.common.thError,
        controlModeType: common.common.output_control,
        wrongRegisterActionType: common.common.exclude_wrong_regs_next_rule,
        dimension: common.common.dimension,
        columns: common.common.selected_columns ? common.common.selected_columns : [],
        folder: common.common.folder!=={} ? common.common.folder : undefined,
        _id : id,
        classType: getRuleClassType(common.common.type),
        active: true,
        conditions: conditions ? conditions : []

    }
    if(rule.folder && rule.folder.name === 'No Folder'){
        rule.folder = undefined
    }
    
    Object.assign(rule, specific);
    return rule
}

export function getRuleClassType(baseType){

    if(baseType==='dup'){
        return 'duplicated'
    }
    else if(baseType ==='cell_range'){
        return 'ranges'
    }
    else if(baseType==='cell_null'){
        return 'null'
    }
    else if(baseType==='cell_based'){
        return 'ranges'
    }

    return baseType
}

export function createCommonRule(name_,priority_,type_,subtype_,output_control_,exclude_wrong_regs_next_rule_,
    dimension_,thError_, thWarning_, selectedColumns_, selectedQualityPoint,selectedDatasource,selectedFolder){
        const name = name_ ? name_ : undefined
        const priority = priority_ ? priority_ : -1
        const type = type_ ? type_ : undefined
        const subtype = subtype_ ? subtype_ : undefined
        const dimension = dimension_ ? dimension_ :undefined
        const output_control = output_control_ ? output_control_ : undefined
        const exclude_wrong_regs_next_rule = exclude_wrong_regs_next_rule_ ? exclude_wrong_regs_next_rule_ : undefined
        const selectedColumns =  selectedColumns_? selectedColumns_ : undefined
        const qualityPoint= selectedQualityPoint
        const datasource = selectedDatasource

    const commonRule =  {
        common:
        {
            name: name,
            priority: priority,
            type: type,
            subtype: subtype,
            dimension: dimension,
            output_control: output_control,
            exclude_wrong_regs_next_rule: exclude_wrong_regs_next_rule,
            selected_columns : selectedColumns,
            qualityPoint: qualityPoint,
            datasource: datasource,
            folder: selectedFolder,
            thWarning : thWarning_,
            thError : thError_
        }
    }
    return commonRule
}

export function getDimensionFromRuleType(ruleType){
    switch(ruleType){
        case 'statistics':
           return 'format'
        case 'time':
            return 'timeliness'
        case 'duplicated':
            return 'unicity'
        default:
            return 'integrity'
      
    }
}

export function validateCommonRule (data) {
    var hasErrors = false
    const arrayErrorTypes = []
    const arrayErrorMsgs = []

    const commonRule = data.common

    if(!commonRule.name){
        hasErrors = true
        arrayErrorTypes.push('name')
        arrayErrorMsgs.push('The name must be provided')
    }

    if(!commonRule.type){
       hasErrors = true
       arrayErrorTypes.push('type')
       arrayErrorMsgs.push('A rule type must be selected')
   }
   if(!commonRule.subtype){
    hasErrors = true
    arrayErrorTypes.push('subtype')
    arrayErrorMsgs.push('A rule subtype must be selected')
}
   if(!commonRule.output_control){
       hasErrors = true
       arrayErrorTypes.push('output_control')
       arrayErrorMsgs.push('A Output Control mode must be selected')
   }
   if(!commonRule.exclude_wrong_regs_next_rule){
    hasErrors = true
    arrayErrorTypes.push('exclude_wrong_regs_next_rule')
    arrayErrorMsgs.push('A Wrong Register Control mode must be selected')

    
}

if(!commonRule.dimension){
    hasErrors = true
    arrayErrorTypes.push('dimension')
    arrayErrorMsgs.push('A Dimension must be selected')
}
if(!commonRule.qualityPoint){
    hasErrors = true
    arrayErrorTypes.push('selectedQualityPoint')
    arrayErrorMsgs.push('A Quality Point must be selected')
}
if(!commonRule.datasource){
    hasErrors = true
    arrayErrorTypes.push('selectedDatasource')
    arrayErrorMsgs.push('A Datasource must be selected')
}

if(commonRule.type!=='format' &&  commonRule.type !=='adHoc' && commonRule.type!=='statistics' &&  commonRule.type!=='checkBetweenLoads'){
    if(!commonRule.selected_columns || isEmpty(commonRule.selected_columns)){
        hasErrors = true
        arrayErrorTypes.push('selectedColumns')
        arrayErrorMsgs.push('At least one column must be selected')
    }
}

if((commonRule.type==='dup'|| commonRule.type==='duplicated') && commonRule.selected_columns && commonRule.selected_columns.length>1){
    
        hasErrors = true
        arrayErrorTypes.push('selectedColumns')
        arrayErrorMsgs.push('Duplicated check only accepts one column (simple or combined)')
    
}



   return {hasErrors, arrayErrorTypes, arrayErrorMsgs};


}

export function isEmpty (obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function getSourceForRule(ruleType,source){
        
    if(source && ruleType==='time'){
        return source.filter(x=>x.type==='Date')
    }

    return source
  }

  export function isSelectedColumnDisabledMessage(ruleType,source){
    if(ruleType==='format' || ruleType==='statistics'|| ruleType ==='adHoc')
    {
        return 'This rule does not accept column selection. It is applied to the whole dataset'
    }
    else if(ruleType ==='checkBetweenLoads'){
        return 'This rule does not accept column selection as usual. It is selected in the specific rule definition'
    }
    else if (ruleType==='time'){

        if(getSourceForRule(ruleType,source) && getSourceForRule(ruleType,source).length===0){
            return 'This rule needs at least one Date column. No Date columns found in dataset'
        }
    }

    else{
        return '';
    }
  }

   /*
      Function in charge of availability of column selection based on rule selection
      */
 export function isSelectedColumnDisabled(ruleType,selectedColumns){
        if(ruleType==='format' || ruleType==='statistics' || ruleType ==='checkBetweenLoads' || ruleType ==='adHoc'){
            return true
        } 
        else if(ruleType==='dup'|| ruleType==='duplicated'){
            if(selectedColumns && selectedColumns.length>0){
                return true
            }
        } 
            return false;
      }

export function generateFolderArray(folderArray){
        var newFolderArray = folderArray.slice()
        newFolderArray.push({itemID:'/',name:'No Folder'})
        return newFolderArray
    }

export function compareColumns( a, b ) {
        if ( a.position < b.position ){
          return -1;
        }
        if ( a.position > b.position ){
          return 1;
        }
        return 0;
      }
