import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import * as actions from "../../../../actions";
import moment from "moment";

import {Drawer,FormControl,InputLabel,Select,Divider,Typography,Grid,Paper,Button,MenuItem,Checkbox,FormControlLabel} from "@material-ui/core";
import { DateTimePicker } from "react-rainbow-components";
import FilterListIcon from "@material-ui/icons/FilterList";
import * as configurationHelper from "../../common/configurationHelper";

const withFilterVW = 16;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  BackdropProps: {
    background: "transparent",
  },
  drawerPaper: {
    height: "auto",
    margin: "20px",
    marginTop: "100px",
    borderRadius: "10px",
    border: "0px",
    top: "auto",
    backgroundColor: "transparent",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    overflow: "hidden",
  },
  content: {
    width: "100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default function FilterMenuTotalEventRules(props) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const classes = useStyles();

  let qualityConfiguration = useSelector((store) => store.qualityConfiguration);
  const user = useSelector((store) => store.user);
  const license = useSelector((store) => store.license);
  const projects = useSelector((store) => Object.values(store.projects));

  //Estados para gestionar la fecha y hora

  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "months").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [checkedCurrentMonth, setCheckedCurrentMonth] = React.useState(false);

  //Estados para gestionar otros datos

  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedProject, setselectedProject] = React.useState(null);

  const [environment, setEnvironment] = React.useState("");
  const [selectedEnvironment, setSelectedEnvironment] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (user && user.id) {
      dispatch(actions.fetchConfigurationByUserId(user.id));
    }
  }, [user]);

  React.useEffect(() => {
    if (
      qualityConfiguration &&
      configurationHelper.filterAllowedEnvironments(
        qualityConfiguration.environmentConfigurationList,
        user.id
      )
    ) {
      setEnvironment(
        configurationHelper.filterAllowedEnvironments(
          qualityConfiguration.environmentConfigurationList,
          user.id
        )
      );
    }
  }, [qualityConfiguration]);

  //Fetch gráfica
  React.useEffect(() => {
    if(license._id) {
      let personalId = user.id
      if(user && user.role){
        if (user.role === "SUPER_ADMIN" || user.role === "ADMIN"){
          personalId = null
        }
      }
      dispatch(
        actions.fetchTotalRecords(
          license._id,
          selectedProject,
          selectedUser,
          selectedEnvironment,
          startDate,
          endDate,
          personalId
        )
      );
    }
  }, [
    startDate,
    endDate,
    selectedUser,
    selectedProject,
    license._id,
    selectedEnvironment,
  ]);

  //Fetch tabla
  React.useEffect(() => {
    if (license._id){
      let personalId = user.id
      if(user && user.role){
        if (user.role === "SUPER_ADMIN" || user.role === "ADMIN"){
          personalId = null
        }
      }
      dispatch(actions.fetchTotalRecordsTableData(  
        license._id,
        startDate,
        endDate,
        personalId));
      } 
    }, 
    [license._id,startDate, endDate]); 


  //Current month
  React.useEffect(() => {
    if (checkedCurrentMonth) {
      const endDate_ = new Date()
      setStartDate(moment(endDate_).startOf('month').toDate());
      setEndDate(endDate_)


    }
  }, [checkedCurrentMonth]);

  const handleChangeCurrentMonth = (ev) => {
    setCheckedCurrentMonth(ev);
  };

  const getMenuItemsUser = (itemsArray) => {
    let itemsToShow = [];
    itemsArray.map((m) =>
      itemsToShow.push(
        <MenuItem key={m.name} value={m.id}>
          <Typography variant="caption" style={{ fontWeight: "bold" }}>
            {`(own) ${m.name}`}
          </Typography>
        </MenuItem>
      )
    );
    return itemsToShow;
  };
  const getMenuItems = (itemsArray) => {
    let itemsToShow = [];
    itemsArray.map((m) =>
      itemsToShow.push(
        <MenuItem key={m._id} value={m._id}>
          <Typography variant="caption" style={{ fontWeight: "bold" }}>
            {`(own) ${m.name}`}
          </Typography>
        </MenuItem>
      )
    );
    return itemsToShow;
  };

  const getMenuItemsLicense = (itemsArray) => {
    let itemsToShow = [];
    if (itemsArray && itemsArray.length > 0) {
      itemsArray
        .filter((m) => !m.userPermission || m.userPermission.type === "Public")
        .map((m) =>
          itemsToShow.push(
            <MenuItem key={m._id} value={m._id}>
              <Typography variant="caption">
                {`(${findUserNameFromIdInLicense(license, m.userId)}) ${m.name
                  }`}
              </Typography>
            </MenuItem>
          )
        );
    }

    return itemsToShow;
  };

  function findUserNameFromIdInLicense(license, userId) {
    if (license && license._id && license.users && license.users.length > 0) {
      for (var i = 0; i < license.users.length; i++) {
        if (license.users[i].id === userId) {
          return license.users[i].name;
        }
      }
    }
    return userId;
  }

  return (
    <>
      <div style={{ textAlign: "end" }}>
        <Button
          aria-controls="simple-menu"
          startIcon={<FilterListIcon />}
          aria-haspopup="true"
          onClick={() => (open ? setOpen(false) : setOpen(true))}
        >
          Filter
        </Button>
      </div>
      <div className={classes.root}>
        <main
          className={classes.content}
          style={
            open
              ? { marginRight: `${withFilterVW + 2}vw` }
              : { marginRight: "0" }
          }
        >
          {props.children}
        </main>
        <Drawer
          ModalProps={{
            BackdropProps: {
              classes: {
                root: classes.BackdropProps,
              },
            },
          }}
          variant="temporary"
          disableScrollLock={true}
          onEscapeKeyDown={() => setOpen(false)}
          onBackdropClick={() => setOpen(false)}
          className={classes.drawer}
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Divider />
          <Paper style={{ width: `${withFilterVW}vw`, height: `100%` }}>
            <div style={theme.dashboard.filter.mainTitleDiv}>
              <Typography variant="subtitle2" style={{ color: "#FFFFFF" }}>
                Query selection
              </Typography>
            </div>
            <FormControl style={theme.dashboard.filter.form}>
              <InputLabel htmlFor="age-native-simple">
                <Typography variant="caption"> Environment </Typography>
              </InputLabel>
              <Select
                onChange={(event) => setSelectedEnvironment(event.target.value)}
              >
                <MenuItem key="all environments" value={null}>
                  <Typography variant="caption">
                    all environments
                  </Typography>
                </MenuItem>
                {environment &&
                  environment.map((e) => (
                    <MenuItem key={e.environmentName} value={e.environmentName}>
                      <Typography variant="caption">
                        {e.environmentName}
                      </Typography>
                    </MenuItem>
                  ))}
                {!environment && (
                  <MenuItem key={environment._id} value={environment._id}>
                    <Typography variant="caption">
                      {selectedEnvironment}
                    </Typography>
                    {selectedEnvironment}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl style={theme.dashboard.filter.form}>
              <InputLabel htmlFor="age-native-simple">
                <Typography variant="caption"> Project </Typography>
              </InputLabel>
              <Select
                value={selectedProject}
                onChange={(event) => setselectedProject(event.target.value)}
              >
                <MenuItem key={"all projects"} value={null}>
                  <Typography variant="caption">
                    all projects
                      </Typography>
                </MenuItem>

                {getMenuItems(projects)}
                {getMenuItemsLicense(props.projectsLicense)}
              </Select>
            </FormControl>
            <FormControl style={theme.dashboard.filter.form}>
              <InputLabel htmlFor="age-native-simple">
                <Typography variant="caption"> User </Typography>
              </InputLabel>
              <Select
                value={selectedUser}
                onChange={(event) => setSelectedUser(event.target.value)}
              >
                <MenuItem key={"all users"} value={null}>
                  <Typography variant="caption">
                    all users
                  </Typography>
                </MenuItem>
                {license.users && getMenuItemsUser(license.users)}
              </Select>
            </FormControl>
            <div style={{ marginTop: "6%" }}>
              <Divider light />
            </div>
            <div style={theme.dashboard.filter.mainTitleDiv}>
              <Typography variant="subtitle2" style={{ color: "#FFFFFF" }}>
                Time period selection
              </Typography>
            </div>

            {
              <div style={{ marginLeft: "20px" }}>
                <Grid container>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedCurrentMonth}
                          onChange={(event) =>
                            handleChangeCurrentMonth(!checkedCurrentMonth)
                          }
                          name="checked"
                          color="primary"
                        />
                      }
                      style={theme.notifications.showUnreadCheck}
                      label="Current month"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                      <DateTimePicker
                        disabled={checkedCurrentMonth}
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        formatStyle="small"
                        style={{
                          maxWidth: "85%",
                          margin: "3%",
                        }}
                        label={
                          <Typography variant="caption"> From </Typography>
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                      <DateTimePicker
                        disabled={checkedCurrentMonth}
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        formatStyle="small"
                        style={{
                          maxWidth: "85%",
                          margin: "3%",
                          marginBottom: "5%",
                        }}
                        label={<Typography variant="caption"> To </Typography>}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
          </Paper>
        </Drawer>
      </div>
    </>
  );
}
