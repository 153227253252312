import React from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { AppBar, Toolbar, ClickAwayListener, Badge } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import AccountCircle from "@material-ui/icons/AccountCircle";

import Sidebar from "./Sidebar";
import ProfileMenu from "./ProfileMenu";
import NotificationsMenu from "./NotificationsMenu";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./../../actions";
import { USER } from "./../../Constants";
import PathNavigation from "./PathNavigation";

import SentryGenerateAlert from "./SentryGenerateAlert";

import { useTheme } from "@material-ui/core/styles";
import { useInterval } from "./../common/useInterval";


const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "#212121 !important",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function AppBarTemplate(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  

  const unreadNotifications = useSelector((store) =>
    Object.values(store.notifications)
  );

  const [open, setOpen] = React.useState(false);

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);

  const openProfile = Boolean(anchorElProfile);
  const openNotifications = Boolean(anchorElNotification);

  var userStr = localStorage.getItem(USER);
  var user = {};
  try {
    user = JSON.parse(userStr);
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.error(e);
    } else {
      console.error(e);
    }
  }




  useInterval(() => {
    if(user && process.env.REACT_APP_NOTIFICATIONS_ALLOWED ==='true'){
    dispatch(actions.fetchUnreadNotifications(user.id))
    dispatch(actions.fetchNotifications(user.id))
    }
    if(user){
      dispatch(actions.fetchLicense(user.id));
    }
}
,10000)



  const handleClickAway = () => {
    setOpen(false);
  };

  //eventSource.onerror = (event: any) => console.log('error', event);

  React.useEffect(() => {
    if (user !== null && user.id) {
      dispatch(actions.fetchLicense(user.id));
    }
    if(user && process.env.REACT_APP_NOTIFICATIONS_ALLOWED ==='true'){
      dispatch(actions.fetchUnreadNotifications(user.id))
      dispatch(actions.fetchNotifications(user.id))
      }
      if(user){
        dispatch(actions.fetchLicense(user.id));
      }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const handleMenuNotifications = (event) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseNotifications = () => {
    setAnchorElNotification(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {undefined && <SentryGenerateAlert />}
        <AppBar position="static" className={classes.appbar}>
          <Toolbar style={{ marginBottom: "-5px", marginTop: "-5px" }}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <img
              src="/aqtiva_logo_white.png"
              alt="logo"
              style={{ width: "35px", marginLeft: "10px", marginRight: "10px" }}
            />

            <PathNavigation
              title={props.title}
              history={props.history}
              arrayPath={props.arrayPath}
              user={user}
            />
            {process.env.REACT_APP_NOTIFICATIONS_ALLOWED==='true' && 
             
            <div>
              <IconButton
                aria-label="notifications"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuNotifications}
                style={{color: (unreadNotifications && unreadNotifications.length > 0 ? "inherit" : "#6B6B6B") }}
              >
                <Badge
                  badgeContent={
                    unreadNotifications ? unreadNotifications.length : 0
                  }
                  color="primary"
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    style={theme.notifications.badge}
                    fontSize="small"
                  />
                </Badge>
              </IconButton>
              <NotificationsMenu
                id="menu-appbar"
                openNotifications={openNotifications}
                handleCloseNotifications={handleCloseNotifications}
                history={props.history}
                anchorElNotification={anchorElNotification}
              />
            </div> }
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuProfile}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <ProfileMenu
                id="menu-appbar"
                openProfile={openProfile}
                handleCloseProfile={handleCloseProfile}
                anchorElProfile={anchorElProfile}
                history={props.history}
              />
            </div>
          </Toolbar>
        </AppBar>

        <Sidebar
          open={open}
          handleDrawerClose={handleDrawerClose}
          className={classes.appbar}
          localPath={props.history.location.pathname}
        />
      </div>
    </ClickAwayListener>
  );
}

export default AppBarTemplate;
