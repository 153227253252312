
import React from 'react'
import {Button, Grid,Popper, ButtonGroup, Grow,Paper,ClickAwayListener,MenuItem, MenuList} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { faHubspot } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {API_MS_URL} from '../../../../../Constants'
import {axiosHeader} from '../../../../common/axios/axiosApiHelper'
import { BlobServiceClient } from '@azure/storage-blob';
import {useSelector,useDispatch} from 'react-redux'
import * as actions  from '../../../../../actions'
import {FETCH_INFO} from '../../../../../actions/types'
import trackEvent from '../../../../../trackEvent'
import * as configurationHelper from '../../../common/configurationHelper'
import { useTheme } from '@material-ui/core/styles';
import DeployDialogStreamConfirmation from './DeployDialogStreamConfirmation'
import DeployDialogStreamDeploymentType from './DeployDialogStreamDeploymentType'

export default function DeployButtonLambda(props) {

      const dispatch = useDispatch();
      const theme = useTheme();
      const user = useSelector(store => store.user)
      const license = useSelector(store =>store.license)
      const [showDialogDeployType, setShowDialogDeployType] = React.useState(false)
      const [showDialogDeployInfo, setShowDialogDeployInfo] = React.useState(false)

      const [options, setOptions] = React.useState((props.qualityConfiguration  && configurationHelper.filterAllowedEnvironments(
        props.qualityConfiguration.environmentConfigurationList,user.id) ) 
      ? configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList,user.id).map(x=>x.environmentName)
       : ['No Environment Found']);
      const [open, setOpen] = React.useState(false);
      const anchorRef = React.useRef(null);
      const [selectedIndex, setSelectedIndex] = React.useState(0);

      const[selectedEnvironment, setSelectedEnvironment] = React.useState(undefined)
      const [selectedDeploymentType, setSelectedDeploymentType] = React.useState(0)

      React.useEffect(() => {
        setOptions((props.qualityConfiguration  && configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList,user.id) ) 
        ? configurationHelper.filterAllowedEnvironments(props.qualityConfiguration.environmentConfigurationList,user.id).map(x=>x.environmentName) : ['No Environment'])
    }, [props.qualityConfiguration]);

 
    React.useEffect(()=>{
      if(props.setSelectedEnvironment){
        props.setSelectedEnvironment(options[selectedIndex])
      }
      
    }, [selectedIndex, options])
    
      const handleToggleButton=(event)=> {
        setOpen((prevOpen) => !prevOpen);

      }

      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
      };

      const handleMenuItemClickButtonExport=(event, index)=> {
          setSelectedIndex(index)
          setOpen(false)
     
    }

    async function asyncForEach(array, callback) {
      if(array){
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
      }
    } 

    function getDeploymentDTO(filename, datasource, configuration, userId, environment){
      let environmentConfiguration = configuration.environmentConfigurationList.filter(x=>x.environmentName === environment)[0]
      return{
          credentialDTO: configuration.azureCredentials,
          eventHubConnection:datasource.inputConnector.conn_str,
          resourceGroupName:environmentConfiguration.resourceGroupName,
          regionName:environmentConfiguration.regionName,
          blobConnectionString:`DefaultEndpointsProtocol=https;AccountName=${environmentConfiguration.storageAccountName};AccountKey=${environmentConfiguration.storageKey};EndpointSuffix=core.windows.net`,
          containerName: environmentConfiguration.containerName,
          blobName:filename,
          simulation:false,
          userId:userId,
          simulationEventHub:{
              connectionString: datasource.inputConnector.conn_str,
              resourceGroup: datasource.inputConnector.resourcegroup,
              namespace: datasource.inputConnector.namespace,
              name: datasource.inputConnector.name,
  
          }
      }
  }

    const asyncDeployForEach = async (data,environment,containerClient, projectId, type) => {
      await asyncForEach(data, async (x) => {


        const qualitypointName = JSON.parse(x).quality_point.name ? JSON.parse(x).quality_point.name : JSON.parse(x).quality_point.quality_point_name
        var filename = "deploy/stream/"+environment+"/"+user.id+"/"+JSON.parse(x).quality_point.id+"/"+JSON.parse(x).quality_point.data_sources[0].name+".json"
        //var filename = "deploy/"+environment +'/'+projectId+'/'+qualitypointName+".json"
        filename =filename.split(' ').join('_')
          
        const blockBlobClient = containerClient.getBlockBlobClient(filename)
            blockBlobClient.uploadBrowserData(x).then(
              response=>{
                dispatch( {
                  type : FETCH_INFO,
                  payload: `QualityPoint ${qualitypointName} updated to BlobStorage`
              });
              }
            )
            if(type===1){
              let datasource = props.project.qualityPoints.filter(data=>data._id === JSON.parse(x).quality_point.id)[0].datasources[0]
              let dto = getDeploymentDTO(filename, datasource, props.qualityConfiguration, user.id, environment)
           
              dispatch(actions.deployStreamQualityPoint(dto, user.id+"afdeploy"+datasource._id,license.apiKey,props.project._id,
              JSON.parse(x).quality_point.id, datasource._id,environment)).then(response=>{
                
  
              })
            }
            

      }).then(response=>{
        //setNotificationVisible(false)
      });
      
    }

    async function onClickDeploy(e,environment, type){
      if(environment){

     
      if(props.project.environment === 'On-premise'){
        //if(!props.project.qualityPoints[0].datasources[0].type){
          fetch(API_MS_URL+`/project/datafile/${props.project._id}/${environment}`,{
            headers: axiosHeader()
          })
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = props.project._id+"_"+ new Date().getTime()+".zip";
              a.click();
               });
          });
       /* }
        else{
          fetch(API_MS_URL+`/version/2/project/datafile/${props.project._id}/${environment}`,{
            headers: axiosHeader()
          })
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = props.project._id+"_"+ new Date().getTime()+".zip";
              a.click();
               });
          });
        }*/
       
      }
      else{
        if(type===1){
          setShowDialogDeployInfo(true)
        }
        let storageAccountName=props.qualityConfiguration ? props.qualityConfiguration.storageAccountName: 'sainputdemoquality'
        let containerName = props.qualityConfiguration ? props.qualityConfiguration.containerName: 'unit-test'
        let sasToken = props.qualityConfiguration  ? props.qualityConfiguration.sasToken: 
        '?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-09-11T14:57:13Z&st=2020-06-22T06:57:13Z&spr=https&sig=f18IZ5vTdx%2FkwLGFjAEhpRZ%2FBZkS%2B3d5Zrv5c1xZZfc%3D'
        
        const response = await dispatch(actions.getQualityConfigurationDeployment(props.project._id,environment))                     
        
          const STORAGE_ACCOUNT_NAME = storageAccountName
          const CONTAINER_NAME = containerName
          // for browser, SAS_TOKEN is get from API?
          const SAS_TOKEN = sasToken
          const sasURL = `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${SAS_TOKEN}`
        
          const blobServiceClient = new BlobServiceClient(sasURL)
          const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME)

          const configs = response.configuration
          await asyncDeployForEach(configs,environment,containerClient, props.project._id, type)
          dispatch(actions.addAudit(environment,props.project._id))
        
        
      }
    }
      
  }

  function calbackClickDeployButton(e){
    dispatch(actions.checkIfDeployedForProjectId(license.apiKey, props.project._id)).then(response=>
      {
      if(response === false){
        onClickDeploy(e,options[selectedIndex], 1)
      }
      else{
        setSelectedEnvironment(options[selectedIndex])
        setShowDialogDeployType(true)
      }
    })
    
  }
    return(
        <div style={{width:'100%'}}  >
          <DeployDialogStreamDeploymentType visible={showDialogDeployType} setVisible={setShowDialogDeployType}
          simpleDeployment = {event=>onClickDeploy(undefined,selectedEnvironment,0)}
          fullDeployment = {event=>onClickDeploy(undefined,selectedEnvironment,1)}/>
           <DeployDialogStreamConfirmation visible={showDialogDeployInfo} setVisible={setShowDialogDeployInfo}
         />
           <ButtonGroup ref={anchorRef}  style={{width:'100%'}}   
                     aria-label="split button">
                     
                        <Button style={props.visible===true ? theme.buttons.buttonGroupRegular : theme.buttons.buttonGroupRegularDisabled}
                         onClick={e=>{
                           if(props.visible===true){
                            trackEvent('DeployButton','Deploy Button');
                            calbackClickDeployButton(e)
                           }
                          
                          }}
                         startIcon={<FontAwesomeIcon icon={faHubspot} style={props.visible===true  ?{fontSize:14,marginBottom:'5%'} : {fontSize:14,marginBottom:'5%',color:"#959595"} } />}
                       >
                         <div style={props.visible===true  ? theme.common.text.buttonText : theme.common.text.buttonTextDisabled}>
                         {`Deploy Lambda to Env: ${options[selectedIndex]}`}
                        </div>
                        

                        </Button>
                        <Button style={props.visible===true ? {borderWidth: "1px",} : {borderWidth: "1px",color:"#959595"}}
                            size="small"
                            aria-haspopup="true"
                            onClick={event=>{
                              if(props.visible===true){
                              trackEvent('DeployButton','Arroy Dropdown Button');
                              handleToggleButton(event)
                              }
                            }}
                            
                        >
                        <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <div style={{ zIndex:24 }}>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper style={{zIndex: "10000",}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" style={{zIndex: "10000",}}>
                  {options.map((option, index) => (
                            <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={event => handleMenuItemClickButtonExport(event, index)}
                            >
                                <div style={{fontSize: '0.7vw', color: '#76b3d6', textTransform: 'none', fontWeight: 'bold'}}>{` ${option}`}</div>
                                
                            </MenuItem>
                            ))}
                  
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </div>
       </div>
    )

}