import React from "react";
import { useDrag } from "react-dnd";
 

//Limitamos los archivos para que puedan moverse con dnd aplicando useDrag (al contrario que las carpetas)
export function File(
  props
) {
  const [{ isDragging }, dragRef] = useDrag({
    item: {
      type: "card",
      itemId: props.itemId,
      repositoryType: props.repositoryType,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  
    return (
      <div style={{backgroundColor: isDragging ? "#fbb" : "transparent", marginLeft:"20px", width:"100%", display:"inline", paddingTop:"1px"}} ref={dragRef} >
      <span style={{display:"inline-block", width:"60%", height:"100%"}}>
        { props.children }
      </span>
      </div>
    );
}
export default File