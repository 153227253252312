import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'
import { BrowserRouter } from 'react-router-dom';
import reducers from './reducers';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';

import { createStore, applyMiddleware } from 'redux';

import './index.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';


Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DNS });


const store = createStore(reducers, (applyMiddleware(thunk)))

const callback = list => {
  list.getEntries().forEach(entry => {
    ReactGA.timing({
      category: "Load Performace",
      variable: 'Server Latency',
      label: entry.name,
      value: entry.responseStart - entry.requestStart
    })
    ReactGA.timing({
      category: "Render Performace",
      variable: entry.name,
      value: entry.duration
    })
  })
}

var observer = new PerformanceObserver(callback);
observer.observe({ entryTypes: ['navigation'] })

const callbackPaint = list => {
  list.getEntries().forEach(entry => {
    ReactGA.timing({
      category: "Paint",
      variable: entry.name,
      value: entry.startTime
    })
  })
}

var observerPaint = new PerformanceObserver(callbackPaint);
observerPaint.observe({ entryTypes: ['paint'] })

ReactDOM.render(
  <Provider store={store}>

    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

