import React from 'react';
import TextField from '@material-ui/core/TextField';

import {makeStyles,Button,Typography,Select,Input,Chip,Avatar,Grid,
    MenuItem,Switch,FormControlLabel,FormControl} from  '@material-ui/core';
import  AddIcon from '@material-ui/icons/Add';
import  CloseIcon from '@material-ui/icons/Close';

import { styles, QualityTheme } from './../../../../../../../../style/CustomTheme'

import {getDataFromProperties} from './SpecificRuleHelper'
import trackEvent from './../../../../../../../../trackEvent'


export default function FormRuleValueRange (props) {

    const classes = makeStyles(styles(QualityTheme));
    const [rule_max_range,setRule_max_range] = React.useState(getDataFromProperties(props.editableRule, props.specific,'rule_max_range',undefined));
    const [rule_min_range, setRule_min_range] = React.useState(getDataFromProperties(props.editableRule, props.specific,'rule_min_range',undefined));
    const [textValueRange, setTextValueRange] = React.useState('');
    const [rule_range_array,setRule_range_array] = React.useState(getDataFromProperties(props.editableRule, props.specific,'rule_range_list',[]).length>0 ?
    getDataFromProperties(props.editableRule, props.specific,'rule_range_list',[]).split(',') : []);
    const [value_range_check,setValue_range_check] = React.useState(props.specific &&(props.specific.rule_max_range || props.specific.rule_min_range )? true: false);
    const [type_range,setType_range] = React.useState('between');
    const [commonSelectedColumn, setCommonSelectedColumn] = React.useState((props.commonRule && props.commonRule.common) ? props.commonRule.common.selected_columns: undefined)
    const [columnType ,setColumnType]= React.useState(getColumType())



    
    React.useEffect(() => {
        setValue_range_check(props.specific ? ( props.specific.value_range_check ? value_range_check : (props.specific.rule_max_range || props.specific.rule_min_range )? true: false) : true)
    }, [props.specific]);

    function getColumType () {
        if(commonSelectedColumn.length>1 || commonSelectedColumn[0].cols.length > 1){
            return 'string'
        }
        var  stringValue = commonSelectedColumn[0].type
        if(stringValue){
            if(stringValue && (stringValue.toLowerCase() === 'integer' || stringValue.toLowerCase()==='float')){
                return 'number'
            }
            return stringValue.toLowerCase()
        }
        else{
            const val2Compare = commonSelectedColumn[0].cols[0]
            var stringValue = undefined
            //vamos a buscarlo en las columnas del dataset
            if(props.datasetColumns){
                for (var i = 0; i < props.datasetColumns.length; i++) {
                    if(val2Compare === props.datasetColumns[i].name){
                        stringValue = props.datasetColumns[i].type
                        break
                    }
        
                }
                if(stringValue){
                    if(stringValue && (stringValue.toLowerCase() === 'integer' || stringValue.toLowerCase()==='float')){
                        return 'number'
                    }
                    return stringValue.toLowerCase()
                }

            }
            return 'string'
        }
        

       
    }
    function createSpecific(){
        //creamos el objeto de regla especifica


        const specific = {
            rule_range_type: columnType,
            rule_range_list: rule_range_array.join(','),
            rule_max_range: rule_max_range,
            rule_min_range: rule_min_range,
            value_range_check:value_range_check
        }
        props.setSpecific(specific)
       
    }

    React.useEffect(() => {
        getColumType()
    }, [commonSelectedColumn]);


    React.useEffect(() => {
        createSpecific() 
    }, [rule_range_array,rule_max_range,rule_min_range,value_range_check]);


   
    
    function onClickAddString2List (e) {
        var array = rule_range_array.slice()
        array.push(textValueRange)
        setRule_range_array(array)
        setTextValueRange('')
        
         
    }
    function onChangeTextValueRange (e) {
        setTextValueRange(e.target.value)
    }
    function onChangeMinRange  (e) {
        setRule_min_range(e.target.value)
    }
    function onChangeMaxRange (e) {
        setRule_max_range(e.target.value)
    }
    
    function onChangeRangeType (event) 
    {
        setType_range(event.target.value)
    }

    function handleChangeValueRangeCheck(event){
        setValue_range_check(event.target.checked)
    }

  
    function onDeleteSelectedItem (value) {
        
        var selectedColTemp = rule_range_array.slice()
        for( var i = 0; i < rule_range_array.length; i++){ 
            if ( value === rule_range_array[i]) {
                selectedColTemp.splice(i, 1); 
              break
            }
        
         }
        setRule_range_array(selectedColTemp)
       
    }
    
    
   
        return (
            <div>
                
            <div>
            {columnType ==='string' && 
            <div>
                <Typography variant='button'> Value List</Typography>
                 <Grid container className={classes.gridForm} spacing={0} >
                
                 <Grid key={'1'} item style={{ width: '210px' }}>
                    <TextField  
                        value={textValueRange} 
                        autoComplete='off'
                        onChange={onChangeTextValueRange}
                        label={'Add Value to List'}
                        style={{marginRight:'5px',width:'200px'}}
                        
                        margin="dense"
                        />
                    </Grid>
                    <Grid key={'2'} item style={{ width: '80px' }}>
                    <Button variant="outlined" color="primary" size="small" aria-label="add"
                     style={{marginTop:'20px',marginBottom:'20px',padding:'5px',
                     marginRight:'5px'}}
                    onClick = {event=>{
                        trackEvent('CreateRuleSpecific','ValueRange Null Rule Add Custom Value  Button')
                        onClickAddString2List(event)}}>
                        <AddIcon />
                    </Button>
                    </Grid>
                    <Grid key={'3'} item style={{ width: '300px', marginTop:'18px' }}>
                    <Select
                            
                            style={{width:'300px',height:'35px'}}
                            value={rule_range_array}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => {
                                return <div className={classes.chips}>
                                  {selected && selected.map(value => (
                                    <Chip 
                                    avatar={<Avatar>
                                        <CloseIcon style={{fontSize:'8px',cursor:'pointer'}} 
                                    onClick={(e)=>{onDeleteSelectedItem(value);
                                        trackEvent('CreateRuleSpecific','ValueRange Delete Selected Item')
                                    }} /></Avatar>}
                                    key={value} 
                                    label={value} className={classes.chip} />
                                  ))}
                                </div>
                                }}
                             >

                            { rule_range_array && 
                             rule_range_array.map(e => (
                            <MenuItem key={e} value={e} >
                            <Chip 
                                    avatar={<Avatar>
                                        <CloseIcon style={{fontSize:'8px',cursor:'pointer'}} 
                                    onClick={(event)=>{
                                        onDeleteSelectedItem(e);
                                        trackEvent('CreateRuleSpecific','ValueRange Delete Selected Item');
                                    }} /></Avatar>}
                                    key={e} 
                                    label={e} className={classes.chip} />
                            </MenuItem>
                            ))}

                             </Select>
                       </Grid>
                       </Grid> 
                       </div>
                }

{(columnType ==='number' ) && 
                <div>   
                <FormControlLabel
                control={
                <Switch
                    checked={ value_range_check}
                    onChange={ handleChangeValueRangeCheck}
                    value={ value_range_check}
                    color="primary"
                                                />
                }
                label="Value Range Selection"
                />
                 
                 {! value_range_check && 
                 <div>
                <Typography variant='button'> Value List</Typography>
                 <Grid container className={classes.gridForm} spacing={0} >

                 <Grid key={'1'} item style={{ width: '210px' }}>
                    <TextField  type='number'
                        value={ textValueRange} 
                        autoComplete='off'
                        onChange={ onChangeTextValueRange}
                        label={'Add Value to List'}
                        style={{marginRight:'5px',width:'200px'}}
                        
                        margin="dense"
                        />
                    </Grid>
                    <Grid key={'2'} item style={{ width: '80px' }}>
                    <Button variant="outlined" color="primary" size="small" aria-label="add"
                     style={{marginTop:'20px',marginBottom:'20px',padding:'5px',
                     marginRight:'5px'}}
                    onClick = { event=>{
                        trackEvent('CreateRuleSpecific','ValueRange Add Strings 2 List')
                        onClickAddString2List(event)}}>
                        <AddIcon />
                    </Button>
                    </Grid>
                    <Grid key={'3'} item style={{ width: '300px', marginTop:'18px' }}>
                    <Select
                            
                            style={{width:'300px',height:'35px'}}
                            value={ rule_range_array}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => {
                                return <div className={classes.chips}>
                                  {selected && selected.map(value => (
                                    <Chip 
                                    avatar={<Avatar>
                                        <CloseIcon style={{fontSize:'8px',cursor:'pointer'}} 
                                    onClick={(e)=> {onDeleteSelectedItem(value);
                                        trackEvent('CreateRuleSpecific','ValueRange Delete Selected Item');
                                    }} /></Avatar>}
                                    key={value} 
                                    label={value} className={classes.chip} />
                                  ))}
                                </div>
                                }}
                             >

                            { rule_range_array && 
                             rule_range_array.map(e => (
                            <MenuItem key={e} value={e} >
                            <Chip 
                                    avatar={<Avatar>
                                        <CloseIcon style={{fontSize:'8px',cursor:'pointer'}} 
                                    onClick={(event)=> {
                                        onDeleteSelectedItem(e);
                                        trackEvent('CreateRuleSpecific','ValueRange Delete Selected Item');
                                    }} /></Avatar>}
                                    key={e} 
                                    label={e} className={classes.chip} />
                            </MenuItem>
                            ))}

                             </Select>
                       </Grid>
                       </Grid> 
                    </div>
                 }
                   { value_range_check &&
                   <div>
                        <Typography variant='button'> Value Range </Typography>
                       <Grid container className={classes.gridForm} spacing={0} >
                       <Grid key={'0'} item style={{width:'160px'}}>
                       <FormControl className={classes.formControl}>
                            <Typography variant='subtitle2'>Range Type</Typography>
                            <Select 
                            inputProps={{
                                name: 'range type',
                                id: 'range type'

                            }}
                            style={{width:'150px'}}
                            value={ type_range}
                            onChange = { onChangeRangeType}
                           
                             >
                            <MenuItem key={'max'}   value={'max'}>
                                <Typography>max value</Typography>
                            </MenuItem>
                            <MenuItem key={'min'}  value={'min'}>
                                <Typography>min value</Typography>
                            </MenuItem>
                            <MenuItem key={'between'} value={'between'}>
                                <Typography>between</Typography>
                            </MenuItem>
                  </Select>
                  </FormControl>          
                 </Grid>
                 { type_range !=='max' && <Grid key={'1'} item style={{ width: '210px' }}>
                    <TextField  type='number'
                        value={ rule_min_range} 
                        autoComplete='off'
                        onChange={ onChangeMinRange}
                        label={'Min. Value Range'}
                        style={{marginRight:'5px',width:'200px'}}
                        
                        margin="dense"
                        />
                    </Grid>
                 }
                 { type_range !=='min' && 
                    <Grid key={'2'} item style={{ width: '210px' }}>
                    <TextField  type='number'
                        value={ rule_max_range} 
                        autoComplete='off'
                        onChange={ onChangeMaxRange}
                        label={'Max. Value Range'}
                        style={{marginRight:'5px',width:'200px'}}
                        margin="dense"
                        />
                    </Grid>
                 }
                    </Grid>
                    </div>
                   }
                       </div>
                }
                </div>

               
            </div>
        );
    
}

