import React from "react";
import { useSelector, useDispatch } from "react-redux";
import AppBarTemplate from "../../template/AppBarTemplate";
import * as pathHelper from "./../../template/PathHelper";
import FilterMenuKPIS from "./FilterMenuKPIS";
import KPICard from "./KPICard";
import * as actions from "./../../../actions";

import * as userHelper from './../../quality/common/userHelper'

export default function QualityKpis(props) {
  const dispatch = useDispatch();


  const user = useSelector((store) => store.user);
  const license = useSelector((store) => store.license);
  const projects = useSelector((store) => Object.values(store.projects));
  const dataKPI = useSelector((store) => store.KPIS);
  const [projectsLicense, setProjectsLicense] = React.useState([]);
  const [showEmpty, setShowEmpty] = React.useState((userHelper.getUserPreferenceProperty(user,"governanceShowNoDataProjects")==undefined ||
  userHelper.getUserPreferenceProperty(user,"governanceShowNoDataProjects")===false)? false: true)
  const [showOwnProjects, setShowOwnProjects] = React.useState((userHelper.getUserPreferenceProperty(user,"governanceShowOnlyOwnProjects")==undefined ||
  userHelper.getUserPreferenceProperty(user,"governanceShowOnlyOwnProjects")===false)? false: true)
  const [showSelectedProjects, setShowSelectedProjects] = React.useState((userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects")==undefined ||
  userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects").length ===0 )? false: true)

  const [selectedProjects, setSelectedProjects] = React.useState((userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects")==undefined ||
  userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects").length ===0 )? []: userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects"));



  const toggleShowEmpty = () => setShowEmpty(showEmpty => !showEmpty);
  const toggleShowOwnProjects = () => setShowOwnProjects(showOwnProjects => !showOwnProjects);

  function setSelectedProjectsCallback(value){
    userHelper.updateUserPreference(dispatch,user,"governanceKPISelectedProjects", value)
    setSelectedProjects(value)
  }

  

  function toggleShowEmptyCallback(){
    userHelper.updateUserPreference(dispatch,user,"governanceShowNoDataProjects", !showEmpty)
  }

  function toggleShowOwnProjectsCallback(){
    userHelper.updateUserPreference(dispatch,user,"governanceShowOnlyOwnProjects", !showOwnProjects)
  }
  
  
  

  //console.log("Estos son los proyectos que me traigo del store", projects);
  //console.log("Esta es la data ", dataKPI);

  React.useEffect(() => {
    console.log('asdfgafds',userHelper.getUserPreferenceProperty(user,"governanceShowNoDataProjects"))
    setSelectedProjects((userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects")==undefined ||
    userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects").length ===0 )? []: userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects"))

    setShowSelectedProjects((userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects")==undefined ||
    userHelper.getUserPreferenceProperty(user,"governanceKPISelectedProjects").length ===0 )? false: true)

    setShowEmpty((userHelper.getUserPreferenceProperty(user,"governanceShowNoDataProjects")==undefined ||
    userHelper.getUserPreferenceProperty(user,"governanceShowNoDataProjects")===false)? false: true)

    setShowOwnProjects((userHelper.getUserPreferenceProperty(user,"governanceShowOnlyOwnProjects")==undefined ||
    userHelper.getUserPreferenceProperty(user,"governanceShowOnlyOwnProjects")===false)? false: true)
  }, [user]);

  React.useEffect(() => {
    if (user && user.id) {
      dispatch(actions.fetchProjects(user.id));
      if (license && license._id) {
        getLicenseProjects().then((response) => setProjectsLicense(response));
      } else {
        dispatch(actions.fetchLicense(user.id));
      }
    }
  }, [user, license._id]);

  React.useEffect(() => {
    dispatch(actions.fetchUser());
  }, []);

  async function getLicenseProjects() {
    var projects = [];
    if (license && license._id && license.users && license.users.length > 0) {
      for (var i = 0; i < license.users.length; i++) {
        let licenseUser = license.users[i];
        if (user.id !== licenseUser.id) {
          var userProjects = await dispatch(
            actions.fetchProjectsAndReturn(licenseUser.id)
          );
          projects = projects.concat(userProjects);
        }
      }
    }
    return projects;
  }


  
  return (
    <>
      <AppBarTemplate
        history={props.history}
        title={"KPI Governance"}
        arrayPath={pathHelper.getPathArray("Governance")}
      />
      <FilterMenuKPIS projects={projects} projectsLicense={projectsLicense} 
      toggleShowEmpty ={toggleShowEmptyCallback}  showEmpty = {showEmpty}
      toggleShowOwnProjects ={toggleShowOwnProjectsCallback} 
      showOwnProjects = {showOwnProjects}
      setSelectedProjects = {setSelectedProjectsCallback} selectedProjects = {selectedProjects}
      setShowSelectedProjects = {setShowSelectedProjects} showSelectedProjects = {showSelectedProjects}
      urlParams = {props.match} history={props.history}>
        <KPICard dataKPI={dataKPI} showEmpty = {showEmpty} showOwnProjects = {showOwnProjects}  history={props.history} showSelectedProjects = {showSelectedProjects}
        selectedProjects = {selectedProjects}/>
      </FilterMenuKPIS >
    </>
  );
}
