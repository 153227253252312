export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const CREATE_DATAFORMAT = 'CREATE_DATAFORMAT';
export const FETCH_DATAFORMATS = 'FETCH_DATAFORMATS';
export const FETCH_CONFIGURATION = 'FETCH_CONFIGURATION';
export const UPDATE_CONFIGURATION = 'FETCH_CONFIGURATION';
export const UPDATE_SIMULATION = 'EDIT_SIMULATION';
export const FETCH_RECORDS  = 'FETCH_RECORDS';
export const FETCH_RECORDS_INTERVAL  = 'FETCH_RECORDS_INTERVAL';
export const FETCH_SIMULATIONS  = 'FETCH_SIMULATIONS';
export const DELETE_SIMULATIONS  = 'DELETE_SIMULATIONS';
export const AUTHORIZED='AUTHORIZED';
export const UNAUTHORIZED='UNAUTHORIZED';
export const FETCH_LICENSE='FETCH_LICENSE';
export const FETCH_USER='FETCH_USER';
export const UPDATE_LICENSE='UPDATE_LICENSE';

export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_INFO = 'FETCH_INFO';

export const UPDATE_MASTER_DATA='UPDATE_MASTER_DATA';
export const FETCH_MASTER_DATA='FETCH_MASTER_DATA';
export const DELETE_MASTER_DATA='DELETE_MASTER_DATA';

export const FETCH_RULE_RECOMMENDER='FETCH_RULE_RECOMMENDER';
export const FETCH_JOB_OUTPUT='FETCH_JOB_OUTPUT';
export const FETCH_STREAM_EVENTHUB_CONFIG = 'FETCH_STREAM_EVENTHUB_CONFIG';

//ProjectDataRepository

export const FETCH_PROJECTS_DATA_REPOSITORY = 'FETCH_PROJECTS_DATA_REPOSITORY';
export const FETCH_PROJECT_DATA_REPOSITORY = 'FETCH_PROJECT_DATA_REPOSITORY';
export const CREATE_PROJECT_DATA_REPOSITORY = 'CREATE_PROJECT_DATA_REPOSITORY';
export const DELETE_PROJECT_DATA_REPOSITORY= 'DELETE_PROJECT_DATA_REPOSITORY';
export const EDIT_PROJECT_DATA_REPOSITORY = 'EDIT_PROJECT_DATA_REPOSITORY';

export const FETCH_PROJECTS_DATA_REPOSITORY_SHARED = 'FETCH_PROJECTS_DATA_REPOSITORY_SHARED';
export const FETCH_PROJECT_DATA_REPOSITORY_SHARED = 'FETCH_PROJECT_DATA_REPOSITORY_SHARED';
export const CREATE_PROJECT_DATA_REPOSITORY_SHARED = 'CREATE_PROJECT_DATA_REPOSITORY_SHARED';
export const DELETE_PROJECT_DATA_REPOSITORY_SHARED= 'DELETE_PROJECT_DATA_REPOSITORY_SHARED';
export const EDIT_PROJECT_DATA_REPOSITORY_SHARED = 'EDIT_PROJECT_DATA_REPOSITORY_SHARED';

//Notifications menu

export const FETCH_UNREAD_NOTIFICATIONS = 'FETCH_UNREAD_NOTIFICATIONS';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'

//Datasets batch

export const FETCH_DATASETS_BATCH_DATA_REPOSITORY = 'FETCH_DATASETS_BATCH_DATA_REPOSITORY';
export const FETCH_DATASET_BATCH_DATA_REPOSITORY = 'FETCH_DATASET_BATCH_DATA_REPOSITORY';
export const CREATE_DATASETS_BATCH_DATA_REPOSITORY = 'CREATE_DATASETS_BATCH_DATA_REPOSITORY';
export const DELETE_DATASETS_BATCH_DATA_REPOSITORY = 'DELETE_DATASETS_BATCH_DATA_REPOSITORY';
export const EDIT_DATASETS_BATCH_DATA_REPOSITORY = 'EDIT_DATASETS_BATCH_DATA_REPOSITORY';

export const FETCH_DATASETS_BATCH_DATA_REPOSITORY_SHARED = 'FETCH_DATASETS_BATCH_DATA_REPOSITORY_SHARED';
export const FETCH_DATASET_BATCH_DATA_REPOSITORY_SHARED = 'FETCH_DATASET_BATCH_DATA_REPOSITORY_SHARED';
export const CREATE_DATASETS_BATCH_DATA_REPOSITORY_SHARED = 'CREATE_DATASETS_BATCH_DATA_REPOSITORY_SHARED';
export const DELETE_DATASETS_BATCH_DATA_REPOSITORY_SHARED = 'DELETE_DATASETS_BATCH_DATA_REPOSITORY_SHARED'
export const EDIT_DATASETS_BATCH_DATA_REPOSITORY_SHARED = 'EDIT_DATASETS_BATCH_DATA_REPOSITORY_SHARED';


//Rules

export const FETCH_RULES_DATA_REPOSITORY = 'FETCH_RULES_DATA_REPOSITORY';
export const FETCH_RULE_DATA_REPOSITORY = 'FETCH_RULE_DATA_REPOSITORY';
export const CREATE_RULES_DATA_REPOSITORY = 'CREATE_RULES_DATA_REPOSITORY';
export const DELETE_RULES_DATA_REPOSITORY = 'DELETE_RULES_DATA_REPOSITORY';
export const EDIT_RULES_DATA_REPOSITORY = 'EDIT_RULES_DATA_REPOSITORY';

export const FETCH_RULES_DATA_REPOSITORY_SHARED = 'FETCH_RULES_DATA_REPOSITORY_SHARED';
export const FETCH_RULE_DATA_REPOSITORY_SHARED = 'FETCH_RULE_DATA_REPOSITORY_SHARED';
export const CREATE_RULES_DATA_REPOSITORY_SHARED = 'CREATE_RULES_DATA_REPOSITORY_SHARED';
export const DELETE_RULES_DATA_REPOSITORY_SHARED = 'DELETE_RULES_DATA_REPOSITORY_SHARED';
export const EDIT_RULES_DATA_REPOSITORY_SHARED = 'EDIT_RULES_DATA_REPOSITORY_SHARED'

//KPIS
export const FETCH_KPIS_GLOBAL = 'FETCH_KPIS_GLOBAL'

//TOTAL RECORDS
export const FETCH_TOTAL_RECORDS = 'FETCH_TOTAL_RECORDS'
export const FETCH_TOTAL_RECORDS_TABLE= 'FETCH_TOTAL_RECORDS_TABLE'

//DASHBOARD DATES
export const SET_DASHBOARD_DATES = 'SET_DASHBOARD_DATES'