import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChartKPIDimension from '../commons/ChartKPIDimension';
import * as mockData from '../commons/mockData'
import CHartBarsRules from '../commons/ChartBarsRules';
import CHartBarsRules2 from '../commons/ChartBarsRules2';

import PieChartRules from '../commons/PieChartRules';
import PaddedPieScoreFull from '../commons/PaddedPieScoreFull';
import ListRules from '../commons/ListRules';
import DimensionKPI from '../commons/DimensionKPI';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        cursor: 'pointer',
        color: theme.palette.text.secondary,
    },
    selectedPaper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        borderColor: '#76b3d6',
        borderWidth: '10px',
    },
    elementAdjacent: {
        display: 'flex',
        justifyContent: 'left'
    },
}));

export default function QualityDashboardSimulation(props) {

    const classes = useStyles();

    const [tiny, setTiny] = React.useState(props.innerWidth < 1368 ? true : false)
    const [veryTiny, setVeryTiny] = React.useState(props.innerWidth < 1068 ? true : false)
    const [smallWindowFlag, setSmallWindowFlag] = React.useState(getSmallWindow(props.innerWidth < 1368 ? true : false))

    const percentPaper = '5%'

    var percentValueIntegrity = '-1'
    var percentValueValidity = '-1'
    var percentValueUnicity = '-1'
    var percentValueVeracity = '-1'
    var percentValueFormat = '-1'


    React.useEffect(() => {
        setTiny(props.innerWidth < 1368 ? true : false)
        setVeryTiny(props.innerWidth < 1068 ? true : false)
        setSmallWindowFlag(getSmallWindow(props.innerWidth < 1368 ? true : false))
    }, [props.innerWidth, props.openFilter]);

    function getSmallWindow(tiny) {

        if ((tiny === true && props.openFilter === true) || veryTiny === true) {
            return true
        }
        return false
    }


    function toggleDimValidity() {
        if (props.selectedDimValidity === true && percentValueValidity !== '-1') {
            props.setSelectedDimValidity(false)
        }
        else {
            if (percentValueValidity !== '-1') {
                props.setSelectedDimValidity(true)
            }

        }
    }

    function toggleDimVeracity() {

        if (props.selectedDimVeracity === true && (percentValueVeracity !== '-1' || percentValueFormat !== '-1')) {
            props.setSelectedDimVeracity(false)
        }
        else {
            if (percentValueVeracity !== '-1' || percentValueFormat !== '-1')
                props.setSelectedDimVeracity(true)
        }
    }

    function toggleDimUnicity() {
        if (props.selectedDimUnicity === true && percentValueUnicity !== '-1') {
            props.setSelectedDimUnicity(false)
        }
        else {
            if (percentValueUnicity !== '-1')
                props.setSelectedDimUnicity(true)
        }
    }

    function toggleDimIntegrity() {
        if (props.selectedDimIntegrity === true && percentValueIntegrity !== '-1') {
            props.setSelectedDimIntegrity(false)
        }
        else {
            if (percentValueIntegrity !== '-1')
                props.setSelectedDimIntegrity(true)
        }
    }



    function createDimensionArray() {
        var arrayDimension = []
        if (props.selectedDimIntegrity === true) {
            arrayDimension.push('integrity')
        }
        if (props.selectedDimUnicity === true) {
            arrayDimension.push('unicity')
        }
        if (props.selectedDimValidity === true) {
            arrayDimension.push('validity')
        }
        if (props.selectedDimVeracity === true) {
            arrayDimension.push('veracity')
            arrayDimension.push('format')
        }
        return arrayDimension
    }

    function filterRuleByDimension(obj, element, dName) {
        if (obj === undefined) {
            return undefined
        }
        const clone = JSON.parse(JSON.stringify(obj));
        let selectedDimensions = createDimensionArray()
        var newValue = clone[element]

        if (selectedDimensions.length > 0 && newValue && newValue.length > 0) {
            clone[element] = newValue.filter(x => selectedDimensions.includes(x[dName]))
        }
        return clone
    }

    function filterRawData(obj) {
        if (obj === undefined) {
            return undefined
        }
        const clone = JSON.parse(JSON.stringify(obj));
        let selectedDimensions = createDimensionArray()
        var newValue = clone
        if (selectedDimensions.length > 0 && newValue && newValue.length > 0) {
            return newValue.filter(x => selectedDimensions.includes(x.dimension))
        }
        return newValue
    }





    if (props.simulationData && props.simulationData.dimensionKPIChartDAOList) {
        if (props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "integrity")) {
            percentValueIntegrity = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "integrity").percentOKDimension
        }
        if (props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "validity")) {
            percentValueValidity = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "validity").percentOKDimension

        }
        if (props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "unicity")) {
            percentValueUnicity = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "unicity").percentOKDimension

        }
        if (props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "veracity")) {
            percentValueVeracity = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "veracity").percentOKDimension

        }
        if (props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "format")) {
            percentValueFormat = props.simulationData.dimensionKPIChartDAOList.dimensionKPI.find(element => element.dimensionName === "format").percentOKDimension

        }

    }


    return (
        <div style={{ width: props.openFilter === false ? '100%' : '80%' }}>

            <Grid container spacing={3} justify='space-evenly'>
                {props.simulationData.stillRunning && props.simulationData.stillRunning === true &&

                    <Grid item xs={12} >
                        <Paper elevation={3} style={{ padding: '5px' }}>
                            <Typography variant='caption' style={{ color: '#4697c4' }}> Simulation still running, showing non final consolidated results</Typography>
                        </Paper>

                    </Grid>
                }
                <Grid item xs={4} >
                    <Paper className={classes.paper} style={{ padding: '0px', width: '100%', minHeight: '285px' }}>
                        <div style={{ marginLeft: percentPaper, marginRight: percentPaper }}>
                            <Typography variant='subtitle2'>Total Score</Typography>
                            <PaddedPieScoreFull
                                height='250px'
                                smallWindowFlag={smallWindowFlag}
                                data={props.simulationData} />
                        </div>

                    </Paper>
                </Grid>
                <Grid item xs={8} >
                    <Paper className={classes.paper} style={{ padding: '0px', width: '100%', minHeight: '280px', paddingBottom: '20px' }}>
                        <Typography variant='subtitle2' style={{ marginBottom: '20px' }}>Dimension Scores</Typography>
                        <Grid container spacing={3} justify='space-evenly'>

                            <Grid item xs={5} >
                                <Paper
                                    style={{ padding: '0px', width: '90%', cursor: percentValueValidity !== '-1' ? 'pointer' : 'default' }}
                                    className={props.selectedDimValidity === true ? classes.selectedPaper : classes.paper}
                                    elevation={props.selectedDimValidity === true ? 12 : 1}
                                    onClick={toggleDimValidity}>
                                    <DimensionKPI percentValue={percentValueValidity}
                                        smallWindowFlag={smallWindowFlag} dimension={'Validity'} height={'100px'} />
                                </Paper>
                            </Grid>
                            <Grid item xs={5} >
                                <Paper style={{ padding: '0px', width: '90%', cursor: percentValueUnicity !== '-1' ? 'pointer' : 'default' }}
                                    className={props.selectedDimUnicity === true ? classes.selectedPaper : classes.paper}
                                    elevation={props.selectedDimUnicity === true ? 12 : 1}
                                    onClick={toggleDimUnicity}>
                                    <DimensionKPI percentValue={percentValueUnicity}
                                        smallWindowFlag={smallWindowFlag} dimension={'Unicity'} height={'100px'} />
                                </Paper>
                            </Grid>
                            <Grid item xs={5} >
                                <Paper style={{ padding: '0px', width: '90%', cursor: percentValueIntegrity !== '-1' ? 'pointer' : 'default' }}
                                    className={props.selectedDimIntegrity === true ? classes.selectedPaper : classes.paper}
                                    elevation={props.selectedDimIntegrity === true ? 12 : 1}
                                    onClick={toggleDimIntegrity}>
                                    <DimensionKPI percentValue={percentValueIntegrity}
                                        smallWindowFlag={smallWindowFlag} dimension={'Integrity'} height={'100px'} />
                                </Paper>
                            </Grid>
                            <Grid item xs={5} >
                                <Paper c style={{ padding: '0px', width: '90%', cursor: (percentValueFormat !== '-1') ? 'pointer' : 'default' }}
                                    className={props.selectedDimVeracity === true ? classes.selectedPaper : classes.paper}
                                    elevation={props.selectedDimVeracity === true ? 12 : 1}
                                    onClick={toggleDimVeracity}>
                                    <DimensionKPI percentValue={percentValueFormat}
                                        smallWindowFlag={smallWindowFlag} dimension={'Veracity'} height={'100px'} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>

                </Grid>







                <Grid item xs={4}>
                    <Paper className={classes.paper} style={{ height: '340px' }}>
                        <div style={{ marginLeft: percentPaper, marginRight: percentPaper }}>
                            <Typography variant='subtitle2'>Rules by dimension</Typography>
                            <PieChartRules piechartrules={'PieRules'}
                                smallWindowFlag={smallWindowFlag}
                                height='300px'
                                data={props.simulationData && filterRuleByDimension(props.simulationData.rulesByDimensionChartDAO, 'rulesByDimensionDAO', 'dimensionName')}

                            />
                        </div></Paper>
                </Grid>

                <Grid item xs={4}>
                    <Paper className={classes.paper} style={{ height: '340px' }}>
                        <div style={{ marginLeft: percentPaper, marginRight: percentPaper }}>
                            <Typography variant='subtitle2'>Rules Execution Flow</Typography>
                            <CHartBarsRules2 barRules={'Rules'}
                                data={filterRawData(props.rawData)
                                }
                                height='300px'
                            />
                        </div></Paper>
                </Grid>

                <Grid item xs={4}>
                    <Paper className={classes.paper} style={{ height: '340px' }}>
                        <div style={{ marginLeft: percentPaper, marginRight: percentPaper }}>
                            <Typography variant='subtitle2'>Rules OK/KO</Typography>
                            <CHartBarsRules barRules={'Rules'}
                                data={props.simulationData && filterRuleByDimension(props.simulationData.ruleNamePercentsChartDAO, 'ruleNamePercentsDAO', 'dimension')}
                                height='300px'
                            />
                        </div></Paper>
                </Grid>






            </Grid>


        </div>

    );
}