
import React from 'react';
import { Grid,Dialog,DialogTitle,Typography,DialogContent,DialogActions,Button,Stepper,Step,StepLabel} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-regular-svg-icons'

import {useSelector,useDispatch} from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { styles, QualityTheme } from './../../../../../../../style/CustomTheme'
import BasicRuleDataForm from './BasicRuleDataForm'
import * as selectionHelper from './SpecificRuleDataForm/RuleSelectionSpecificFormHelper'
import * as ruleHelper from './CreateRuleHelper'
import * as actions from './../../../../../../../actions'
import { useTheme } from '@material-ui/core/styles';
import trackEvent from './../../../../../../../trackEvent'
import ExecutionConditionRule from './ExecutionConditionRule';




export default function DialogCreateRule(props) {
    const theme = useTheme();
    const dispatch = useDispatch();

    //variables de estado
    const [editable,setEditable] = React.useState(props.rule ? true : false)
    const [activeStep, setActiveStep] = React.useState(0);
    const [ruleType,setRuleType] = React.useState(props.rule ? ruleHelper.getRuleClassType(props.rule.type) : '')
    const steps = getSteps();
    const [commonRule, setCommonRule] = React.useState()
    const [specificRule,setSpecificRule] = React.useState()
    const [executionConditions, setExecutionConditions] = React.useState([])

    React.useEffect(() => {
     setRuleType(props.rule ? ruleHelper.getRuleClassType(props.rule.type) : '')
     setEditable(props.rule ? true : false)
     setExecutionConditions(props.rule ? props.rule.conditions: [])
     
  }, [props.rule]);

  function clearRule(){
    setRuleType('')
    setCommonRule({})
    setSpecificRule({})
    setActiveStep(0)
  }

    function setRuleTypeCallback(type){
      
      setRuleType(ruleHelper.getRuleClassType(type))
    }

    //wrapper to save rule
    const saveItem = () =>{
        handleFinish()
        props.setClose(false)
    }

    //wrapper to edit rule
    const editItem = () =>{
      handleFinish()
        props.setClose(false)
    }

    function setSpecificRuleCallback(value){
      setSpecificRule(value)
    }

    function setCommonRuleCallback(rule){
      setCommonRule(rule)
    }

    function setExecutionConditionsCallback(conditions){
      setExecutionConditions(conditions)
    }


    /*
    **** FUNCIONES RELACIONADAS CON STEPPER
    */
    function getSteps() {
        return ['Basic Rule Data', 'Specific Rule Data', 'Execution Conditions'];
      }

    function getStepContent(step) {
        switch (step) {
          case 0:
            return <BasicRuleDataForm project = {props.project} setRuleType={setRuleTypeCallback} setCommonRule ={setCommonRuleCallback} commonRule={commonRule} rule={props.rule}
            datasource ={props.datasource} qualityPoint = {props.qualityPoint} folder ={props.folder}/>
          case 1:
            return selectionHelper.selectSpecificForm(ruleType,commonRule, commonRule.common.datasource, setSpecificRuleCallback, props.rule, specificRule)
          case 2:
            
            return <ExecutionConditionRule project = {props.project} setConditions = {setExecutionConditionsCallback} conditions = {executionConditions}
            dataset ={commonRule.common.datasource.dataset ? commonRule.common.datasource.dataset: []} />
            case 3:
            
              return '';
          default:
            return 'Unknown step';
        }
      }

     function handleFinish(){
       const ruleId= props.rule ? props.rule._id : undefined
       const rule = ruleHelper.createRuleForBackend(commonRule,specificRule,ruleId, executionConditions)

       if(ruleId){
        dispatch(actions.editRule(props.project._id,commonRule.common.qualityPoint._id,commonRule.common.datasource._id,rule))
       
       }
       else{
         
          dispatch(actions.addRule(props.project._id,commonRule.common.qualityPoint._id,commonRule.common.datasource._id,rule))
         
        
       
       }
       setActiveStep(0)
       clearRule()
       
     }
      

      const handleNext = () => {
        if(activeStep === steps.length - 1 ){
          handleFinish()
        }
        if(activeStep === 0)
        {
          const {hasErrors, arrayErrorTypes, arrayErrorMsgs} = ruleHelper.validateCommonRule(commonRule)
          if(hasErrors){
            return
          }
        }
    
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      };
    
      const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
      };
    
     
    
      const handleReset = () => {
        setActiveStep(0);
      };

      /*
      */
    
    return(
        <Dialog open={props.open} 
            fullWidth={true}
            maxWidth = {'lg'}>
                     <DialogTitle style={theme.dialogs.dialogTitle}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                    <FontAwesomeIcon icon={faFileAlt} style={theme.dialogs.dialogTitleAwesomeIcon}/>
                                
                            </Grid>
                            <Grid item >
                                <Typography style={theme.dialogs.dialogTitleText}>
                                    {editable ? 'Edit Rule' : 'Create Rule'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>

                    <Stepper activeStep={activeStep}
                    >
                            {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                           
                            
                            return (
                                <Step key={label} {...stepProps} style={{color:'#FFFFFF'}}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                            })}
                        </Stepper>
                        <div>
        {activeStep === steps.length ? (
          <div>
            <Typography>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={event=>{
              trackEvent('CreateRule','Reset Button')
              handleReset(event)}} style={theme.buttons.buttonConfirm}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
          </div>
        )}
      </div>

                    </DialogContent>

                    <DialogActions>

                    <div>
            {activeStep!==0 &&
              <Button disabled={activeStep === 0} onClick={event=>{
                trackEvent('CreateRule','Back Button')
                handleBack(event)
                }} variant='outlined'  style={theme.buttons.buttonConfirm}>
                Back
              </Button>}
              

              {activeStep !== (steps.length-1) && <Button
                variant='outlined' 
                onClick={event=>{
                  trackEvent('CreateRule','Next Button')
                  handleNext(event)}}
                  style={theme.buttons.buttonConfirm}
              >
                 Next
              </Button>}
            </div>
          
                      
                {!editable && activeStep === (steps.length-1) && <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm}  
                onClick={event=>{
                  trackEvent('CreateRule','Save Button')
                  saveItem(event)
                  }}>Save</Button>}
                    {editable && activeStep === (steps.length-1) && <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm}  
                    onClick={event=>{
                      trackEvent('CreateRule','Edit Button')
                      editItem(event)}}>Edit</Button>}
                    <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonCancel} onClick={event=>{
                        trackEvent('CreateRule','Cancel Button')
                        clearRule()
                        props.setClose(false)
                        }}>Cancel
                    </Button>
                </DialogActions>

        </Dialog>
    )
}