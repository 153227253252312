
import quality from '../apis/quality';
import {
    EDIT_PROJECT,
    } from './types'
    import {axiosHeader,dispatchInfo, dispatchError} from '../components/common/axios/axiosHelper';





export const addFolder = (idProject,datasourceId, folder)=> async dispatch => {
    var returnValue = {result:'undefined'}    
    await quality.post(`/datasource/folder/${idProject}/${datasourceId}`, folder,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Folder ${folder.name} created`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}

      });
      return returnValue
}

export const editFolder = (idProject,datasourceId, folder)=> async dispatch => {
    var returnValue = {result:'undefined'}    
    await quality.post(`/datasource/folder/edit/${idProject}/${datasourceId}`, folder,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Folder ${folder.name} created`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}

      });
      return returnValue
}


export const deleteFolder = (idProject,datasourceId, folderLevel,folderName,parentName)=> async dispatch => {

    var returnValue = {result:'undefined'}    
    await quality.delete(`/datasource/folder/${idProject}/${datasourceId}/${folderLevel}/${folderName}/${parentName}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Folder ${folderName} deleted`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        returnValue = {result:'ko',error:error}

      });
      return returnValue
}

