import React from 'react';
import {makeStyles,TextField,Chip} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import {getDataFromProperties} from './SpecificRuleHelper'



const useStyles = makeStyles((theme) => ({
    
      chipContainerRule: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(0.1),
        },
      },
  }));

export default function FormRuleNull(props){

    
    const classes = useStyles();


    const [nullValueCheck, setNullValueCheck] = React.useState((true))
    const [emptyValueCheck, setEmptyValueCheck] = React.useState(getDataFromProperties(props.editableRule, props.specific,'checkEmpty',false))
    const [specificNullValues, setSpecificNullValues] = React.useState(getDataFromProperties(props.editableRule, props.specific,'nullValues',undefined) === undefined ? [] :
    getDataFromProperties(props.editableRule, props.specific,'nullValues',[]).split(","))
    const [tmpNullValue, setTmpNullValue] =React.useState()

    React.useEffect(() => {
        createSpecific()
    }, [nullValueCheck,emptyValueCheck,specificNullValues]);


    function createSpecific(){
        //creamos el objeto de regla especifica
        const specific = {
            nullValues:   specificNullValues.join(","),
            checkEmpty:  emptyValueCheck
        }
        props.setSpecific(specific)
       
    }

    function handleChangeEmptyValue (event) {
        setEmptyValueCheck(event.target.checked)
        createSpecific()
      };
      function handleChangeNullValue (event) {
        setNullValueCheck(event.target.checked)
        createSpecific()
      };

      function onChangeCustomNullValue(event){
        setTmpNullValue(event.target.value)
        
      }

      function onKeyDownCustomNullValue(e){
        if(e.keyCode === 13 && tmpNullValue!==''){
            
            var tmpSpecificNullValues = specificNullValues.slice()
            tmpSpecificNullValues.push(tmpNullValue)
            setSpecificNullValues(tmpSpecificNullValues)
            setTmpNullValue('')
         }
      }

      function handleCloseChipCallback(value){
        var selectedColTemp = specificNullValues.slice()
        for( var i = 0; i <specificNullValues.length; i++){ 
            if ( value.position === specificNullValues[i].position) {
                selectedColTemp.splice(i, 1); 
              break
            }
        
         }

         setSpecificNullValues(selectedColTemp)

    }

    return (
        <div>
           
            <Typography variant='caption'> Parameters for Null Detection Rule</Typography>
         
            <div>
                <FormControlLabel
                 control={
                    <Checkbox
                            checked={nullValueCheck}
                            onChange={handleChangeNullValue}
                            value="nullValueCheck"
                            color="primary"
                    />
                }
                label="Detect Null Values"
                />
            </div>
            <div>
                <FormControlLabel
                    control={
                    <Checkbox
                            checked={emptyValueCheck}
                            onChange={handleChangeEmptyValue}
                            value="emptyValueCheck"
                            color="primary"
                            
                    />
                    }
                label="Detect EmptyValues"
                />
                
            </div>

            <div style={{width:'30%', marginBottom:'1%'}}>
                                            <TextField 
                                                fullWidth={true}
                                                id='custom_null_value_id' 
                                                label='Add Custom Null Values'
                                                inputProps={{
                                                    style: {fontSize: 10,} 
                                                }}
                                                value = {tmpNullValue}
                                                onChange={onChangeCustomNullValue}
                                                onKeyDown={onKeyDownCustomNullValue}
                                            />
                                            </div>
                                            <div>
                                                    {specificNullValues && specificNullValues.length >0 &&
                                                    <Typography variant='body2' style={{marginBottom:'1%'}}> Custom Null Values defined</Typography>}
                                            </div>
                                            <div className={classes.chipContainerRule}>

                                                
                                                {specificNullValues && specificNullValues.map(e=>{
                                                    return(
                                                        <div>
                                                            <Chip
                                                    label={e}
                                                    key={e}
                                                    onDelete={event =>handleCloseChipCallback(e)}
                                                    
                                                  />
                                                        </div>
                                                    )
                                                    
                                                }

                                                )}
                                            </div>

           
        </div>
    );

   
}