import React from "react";
import { useDispatch } from "react-redux";
import { Tabs, Tab, Typography, Paper } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faFilter, faDatabase} from "@fortawesome/free-solid-svg-icons";
import { ProjectDataRepository } from "../ProjectDataRepository";
import { DatasetDataRepository } from "../DatasetDataRepository";
import { RuleDataRepository } from "../RuleDataRepository";
import * as actions from "../../../../actions";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-regular-svg-icons";

const actionsByType = {
  projectDataRepository: {
    fetchProjectDataRepositorySharedWorkspace:
      actions.fetchProjectDataRepositorySharedWorkspace,
    fetchProjectDataRepositoryPrivateWorkspace:
      actions.fetchProjectDataRepositoryPrivateWorkspace,
    deleteParentFolderFromRepository: actions.deleteParentFolderFromRepository,
    deleteFolderFromRepositoryAndSave: actions.deleteFolderFromRepositoryAndSave,
    deleteFileFromRepository: actions.deleteFileFromRepository,
    addFolderToRepository: actions.addFolderToRepository,
    addRootFolderToRepository: actions.addRootFolderToRepository,
    exportFileFromRepository: actions.exportFileFromRepository,
    importFileToRepository: actions.importFileToRepository,
  },
  datasets: {
    fetchProjectDataRepositorySharedWorkspace:
      actions.fetchDataSetBatchSharedWorkspace,
    fetchProjectDataRepositoryPrivateWorkspace:
      actions.fetchDataSetBatchPrivateWorkspace,
    deleteParentFolderFromRepository:
      actions.deleteParentFolderFromRepositoryDataSetBatch,
    deleteFolderFromRepositoryAndSave:actions.deleteParentFolderFromRepositoryAndSaveyDataSetBatch,
      deleteFileFromRepository: actions.deleteFileFromRepositoryDataSetBatch,
    addFolderToRepository: actions.addFolderToRepositoryDataSetBatch,
    addRootFolderToRepository: actions.addRootFolderToRepositoryDataSetBatch,
    exportFileFromRepository: actions.exportFileFromRepositoryDataSetBatch,
    importFileToRepository: actions.importFileToRepositoryDataSetBatch,
  },
  rules: {
    fetchProjectDataRepositorySharedWorkspace:
      actions.fetchRulesDataRepositorySharedWorkspace,
    fetchProjectDataRepositoryPrivateWorkspace:
      actions.fetchRulestDataRepositoryPrivateWorkspace,
    deleteParentFolderFromRepository:
      actions.deleteParentFolderFromRepositoryRules,
    deleteFileFromRepository: actions.deleteFileFromRepositoryRules,

    addFolderToRepository: actions.addFolderToRepositoryRules,
    deleteFolderFromRepositoryAndSave:actions.deleteFolderFromRepositoryAndSaveRules,
    addRootFolderToRepository: actions.addRootFolderToRepositoryRules,
    exportFileFromRepository: actions.exportFileFromRepositoryRules,
    importFileToRepository: actions.importFileToRepositoryRules,
  },
};

export function TabsBar({ user, selectedProjectId, defaultValue }) {
  const [value, setValue] = React.useState(defaultValue ? defaultValue : 0);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          style={{
            marginBottom: "10px",
            border: "none",
            color: "#696969",
            textTransform: "capitalize",
            backgroundColor: "white",
            maxHeight: "0px",
            marginTop: "-10px",
            textAlign: "center",
            marginLeft: "0px",
          }}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          centered
        >
          <Tab
            style={{ ...theme.dataRepository.default }}
            label={
              <div style={theme.dataRepository.elementAdjacent}>
                <FontAwesomeIcon
                  icon={faArchive}
                  style={{
                    color: "#696969",
                    marginTop: "3px",
                    marginRight: "3px",
                  }}
                  fontSize="small"
                />
                <Typography variant="subtitle2" style={{ marginLeft: "2px" }}>
                  Projects
                </Typography>
              </div>
            }
          />

          <Tab
            style={theme.dataRepository.default}
            label={
              <div style={theme.dataRepository.elementAdjacent}>
                <FontAwesomeIcon
                  icon={faDatabase}
                  style={{
                    color: "#696969",
                    marginTop: "3px",
                    marginRight: "3px",
                  }}
                  fontSize="small"
                />
                <Typography variant="subtitle2" style={{ marginLeft: "2px" }}>
                  Datasets
                </Typography>
              </div>
            }
          />
          <Tab
            style={theme.dataRepository.default}
            label={
              <div style={theme.dataRepository.elementAdjacent}>
                <FontAwesomeIcon
                  icon={faFilter}
                  style={{
                    color: "#696969",
                    marginTop: "3px",
                    marginRight: "3px",
                  }}
                  fontSize="small"
                />
                <Typography variant="subtitle2" style={{ marginLeft: "2px" }}>
                  Rules
                </Typography>
              </div>
            }
          />
        </Tabs>
        {value === 0 && (
          <ProjectDataRepository
            value={value}
            user={user}
            actions={actionsByType.projectDataRepository}
            iconType1={faFolder} //Pasamos por props los iconos de CellTemplate
            iconType2={faProjectDiagram}
            entityName="projectDataRepository"
            selectedProjectId = {selectedProjectId}
          />
        )}

        {value === 1 && (
          <DatasetDataRepository
            value={value}
            user={user}
            actions={actionsByType.datasets}
            entityName="datasetBatchDataRepository"
          /> //Pasamos por props los iconos de CellTemplate pero dentro de DataSetDataRepository, que es una "copia" de ProjectDataRepository
        )}

        {value === 2 && (
          <RuleDataRepository
            value={value}
            user={user}
            actions={actionsByType.rules}
            entityName="rulesDataRepository"
          />
        )}
      </Paper>
    </>
  );
}
