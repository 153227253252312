import React from 'react';

import {
  PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer
} from 'recharts';


export default function PieChartRules(props) {

  const COLORS = [ '#5CCFD2', '#5CB5D2', '#5CA0D2', '#5C6CD2', '#3B49A5','#242D68'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <div style={{height:props.height, position: 'relative'}}>


      {props.data && props.data.rulesByDimensionDAO &&  
        <ResponsiveContainer width='100%' >
          <PieChart  width={50} height={50}>
            
            <Pie

              data={props.data.rulesByDimensionDAO}
              
              innerRadius={props.smallWindowFlag === true ? 25 : 40}
              outerRadius={props.smallWindowFlag===true ? 80 : 100}
              fill="#8884d8"
              paddingAngle={5}
              labelLine={true}
              label={renderCustomizedLabel}
              cx="50%" cy="50%"
              fill="#8884d8"
              dataKey="percentRulesDimension"
              label nameKey="dimensionName"
              
            >
              
              {
                props.data.rulesByDimensionDAO.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
              }
            </Pie>
            <Legend layout="vetical" verticalAlign="middle" align="right" />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>}



    </div>
  )
}
