import React from 'react';

export default function FormRuleFormat(props){

    function createSpecific(ruleColumnNames){
        //creamos el objeto de regla especifica
        const specific = {
            columnNames: ruleColumnNames.join(','),
            columnNumber: ruleColumnNames.length
        }
        props.setSpecific(specific)
       
    }

    React.useEffect(() => {
       
        createSpecific(props.columns.map(e=>e.name))
    }, [ props.columns]);

    return(
        <div></div>
    )
}

