
import quality from '../apis/quality';
import { FETCH_RULE_RECOMMENDER, FETCH_JOB_OUTPUT } from './types'
//import {axiosHeader} from './../util/APIUtils'
import { axiosHeader, dispatchInfo, dispatchError } from '../components/common/axios/axiosHelper';
import qualityLocalHost from '../apis/qualityLocalHost';



export const fetchRuleRecommender = () => async (dispatch, getState) => {

   await quality.get(`/databricks/rule/recommender`, {
      headers: axiosHeader()
   }).then(function (response) {

      dispatch({
         type: FETCH_RULE_RECOMMENDER,
         payload: response.data
      });

   })
      .catch(function (error) {
         dispatchError(dispatch, error)
      });
}

export const postDataForRecommendation = (environmentName, userId, ruleRecommenderPostDTO, projectId, qpId) => async (dispatch, getState) => {
   var returnValue = {}
   await quality.post(`/databricks/rule/recommender/${environmentName}/${userId}/project/${projectId}/qp/${qpId}`,ruleRecommenderPostDTO, {
      headers: axiosHeader()
   }).then(function (response) {

      returnValue = response
      dispatchInfo(dispatch, `File path sent to Databricks`)

   })
      .catch(function (error) {
         dispatchError(dispatch, error)
      });
   return returnValue
}

export const getJobRunOutput = (run_id, instance, token) => async (dispatch, getState) => {
   var returnValue = {}
   await quality.get(`/databricks/job/run/ouput/get/${run_id}/${instance}/${token}`, {
      headers: axiosHeader()
   }).then(function (response) {
      returnValue = response
      dispatch({
         type: FETCH_JOB_OUTPUT,
         payload: response.data
      });
   })
      .catch(function (error) {
         dispatchError(dispatch, error)
      });
   return returnValue
}


export const setRuleRecommenderNotificable = (projectId, qualityPointId, jobParams) => async (dispatch,getState) => {
   var returnValue = {result:'undefined'}
   
  await quality.post(`/databricks/rule/recommender/update/notificable/${projectId}/${qualityPointId}`,jobParams,{
   headers: axiosHeader()
 }).then(function (response) {
       
       returnValue = response.data
       
   })
   .catch(function (error) {
       
       returnValue = {result:'ko',error:error}
     });
     return returnValue
};


// TO DO check path, needed params and possibly reducer
export const fetchRulesFromNotification = (ruleDTOId) => async (dispatch, getState) => {
   var returnValue = {}
   await quality.get(`/databricks/rule/ruleDTOId/${ruleDTOId}`, {
      headers: axiosHeader()
   }).then(function (response) {
      returnValue = response
      dispatch({
         type: FETCH_JOB_OUTPUT,
         payload: response.data
      });
   })
      .catch(function (error) {
         dispatchError(dispatch, error)
      });
   return returnValue
}