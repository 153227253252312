import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import * as actions from "../../../../actions";
import moment from 'moment'
import * as configurationHelper from "../../common/configurationHelper";
import {Drawer, FormControl, FormControlLabel, Checkbox, InputLabel, Select, Divider, Typography, Grid, Paper, Button, MenuItem,Input} from "@material-ui/core";
import { DateTimePicker } from "react-rainbow-components";
import FilterListIcon from "@material-ui/icons/FilterList";

const withFilterVW = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: 'center',
  },
  BackdropProps: {
    background: "transparent",

  },
  drawerPaper: {
    height: "auto",
    margin: "20px",
    marginTop: "100px",
    borderRadius: "10px",
    border: "0px",
    top: "auto",
    backgroundColor: "transparent",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    overflow: "hidden"
  },
  content: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default function FilterMenuKPIS(props) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const classes = useStyles();

  let qualityConfiguration = useSelector((store) => store.qualityConfiguration);
  const user = useSelector((store) => store.user);
  const projects = useSelector((store) => Object.values(store.projects));
  
  const license = useSelector((store) => store.license);
  const [projectsLicense, setProjectsLicense] = React.useState([])

  const dashboardDates = useSelector((store) => store.dashboardDates);

/*   console.log('dashboardDates', dashboardDates) */
  const [startDate, setStartDate] = useState(dashboardDates && dashboardDates.length>0 ? dashboardDates.startDate : moment(new Date()).subtract(6, 'months').toDate());
  const [endDate, setEndDate] = useState(dashboardDates && dashboardDates.length>0 ? dashboardDates.endDate : moment(new Date()).subtract(6, 'months').add(6, 'months').toDate());
  const [open, setOpen] = React.useState(false);

  const [selectedQualityProject, setselectedProject] = React.useState();
  const [environment, setEnvironment] = React.useState("");
  const [selectedEnvironment, setSelectedEnvironment] = React.useState(null);
  const [selectedAgregation, setSelectedAgregation] = React.useState("project");
  const [entityIds, setEntityIds] = React.useState([]);
  const [path, setPath] = React.useState(false);




  const handleChange = (event) => {
    props.setSelectedProjects(event.target.value)
    if(event.target.value && event.target.value.length>0){
      props.setShowSelectedProjects(true)
    }
    else{
      props.setShowSelectedProjects(false)
    }
  };



  function getProjects(){
    dispatch(actions.fetchProjects(user.id))
}

async function getLicenseProjects(){
  var projects = []
  if(license && license._id && license.users && license.users.length>0){
      for(var i=0;i<license.users.length;i++){
          let licenseUser = license.users[i]
          if(user.id!==licenseUser.id){ //only other users in license
              var userProjects = await dispatch(actions.fetchProjectsAndReturn(licenseUser.id))
              projects=projects.concat(userProjects)
          }
      }
  }
  return projects
}

  React.useEffect(() => {
    if(user && user.id){
        getProjects()
        if(license && license._id){
            getLicenseProjects().then(response=>
                setProjectsLicense(response)
                )
            
        }
        else{
            dispatch(actions.fetchLicense((user).id))
        }
        
    }
   
},[user, license._id])

  React.useEffect(() => {
    if(startDate & endDate){
        let value ={
            startDate: startDate,
            endDate: endDate
          }
      dispatch( {
        type : 'SET_DASHBOARD_DATES',
        payload: value
        
    });
    }
    
  }, [startDate,endDate]);




  React.useEffect(() => {
    if (
      qualityConfiguration &&
      configurationHelper.filterAllowedEnvironments(
        qualityConfiguration.environmentConfigurationList,
        user.id
      )
    ) {
      setEnvironment(
        configurationHelper.filterAllowedEnvironments(
          qualityConfiguration.environmentConfigurationList,
          user.id
        )
      );
    }
  }, [qualityConfiguration]);




  React.useEffect(() => {
    //console.log('use Effect filter menu kpi', startDate, endDate, license._id, selectedEnvironment, selectedAgregation, props.urlParams)
    if (props.urlParams && props.urlParams.params && props.urlParams.params.aggregationLevel && props.urlParams.params.entityIds) {
      //set selectedAgregation to aggregationLevel
      const aggLevel = props.urlParams.params.aggregationLevel
       setSelectedAgregation(aggLevel)
      //split entityIds by separator
      const entityIds = props.urlParams.params.entityIds.split("-")
      //call fetch to the correct aggregationLevel
      if (aggLevel == "project") {
        dispatch(
          actions.fetchKPISProjects(license._id, selectedEnvironment, startDate, endDate,  entityIds )
        )
      }
      else if (aggLevel == "datasource") {
        dispatch(
          actions.fetchKPISDataSource(license._id, selectedEnvironment, startDate, endDate, entityIds)
        )
      }
      else if (aggLevel == "qualityPoint") {
        dispatch(
          actions.fetchKPISQualityPoint(license._id, selectedEnvironment, startDate, endDate,entityIds)
        )
      }
    } 
    else { 
      if (license && license._id) {
        //const entityIds = null
        if (selectedAgregation == "project") {
          dispatch(
            actions.fetchKPISProjects(license._id, selectedEnvironment, startDate, endDate,  entityIds)
          );
        }
        else if (selectedAgregation == "datasource") {
          dispatch(
            actions.fetchKPISDataSource(license._id, selectedEnvironment, startDate, endDate, entityIds )
          )
        }
        else if (selectedAgregation == "qualityPoint") {
          dispatch(
            actions.fetchKPISQualityPoint(license._id, selectedEnvironment, startDate, endDate, entityIds )
          )
        }
      }
    }


  }, [startDate, endDate, license._id, selectedEnvironment, selectedAgregation, props.urlParams]);

  React.useEffect(() => {
    updateDates()
  }, [startDate,endDate]);


  function updateDates(startDate,endDate){
    dispatch( {
        type : 'SET_DASHBOARD_DATES',
        payload: 
        {
          startDate: startDate,
          endDate: endDate
        }
    })}

    const getMenuItems = (itemsArray) => {
      let itemsToShow = []
      itemsArray.map(m =>
          itemsToShow.push(<MenuItem key={m._id} value={m._id}>
              <Typography variant = 'caption' style={{fontWeight:'bold'}}>
                  {`(own) ${m.name}`}</Typography></MenuItem>)
      )
      return itemsToShow
  }

  const getMenuItemsLicense = (itemsArray) => {
      let itemsToShow = []
      if(itemsArray && itemsArray.length>0){
          itemsArray.filter(m=>(!m.userPermission || m.userPermission.type==='Public')).map(m =>
              itemsToShow.push(<MenuItem key={m._id} value={m._id}>
                  <Typography variant = 'caption'>
                  {`(${findUserNameFromIdInLicense(license,m.userId)}) ${m.name}`}
                  </Typography></MenuItem>)
          )
      }
      
      return itemsToShow
  }

  function findUserNameFromIdInLicense(license,userId){
     
    if(license && license._id && license.users && license.users.length>0){
        for(var i=0;i<license.users.length;i++){
            if(license.users[i].id===userId){
                return license.users[i].name
            }
        }
    }
    return userId
}

function findProjectNameById(projects, licenseProjects, id){
  let projectsFilter = projects.filter(x=>x._id===id)
  let projectsLicenseFilter = licenseProjects.filter(x=>x._id===id)
  if(projectsFilter!==undefined && projectsFilter.length >0){
    return projectsFilter[0].name
  }
  if(projectsLicenseFilter!==undefined && projectsLicenseFilter.length >0){
    return projectsLicenseFilter[0].name
  }
  return 'not found'
}

  return (
    <>
      <div style={{ textAlign: "end" }}>
        <Button
          aria-controls="simple-menu"
          style={{marginTop: props.up && props.up===true ? '-60px':'0px'}}
          startIcon={<FilterListIcon />}
          aria-haspopup="true"
          onClick={() => (open ? setOpen(false) : setOpen(true))}
        >
          Filter
        </Button>
      </div>
      <div className={classes.root}>
        <main
          className={classes.content}
          style={open? { marginRight: `${withFilterVW + 2}px`} : { marginRight: "0" }}
        >
          {props.children}
        </main>
        <Drawer
          ModalProps={{
            BackdropProps: {
              classes: {
                root: classes.BackdropProps,
              },
            },
          }}
          variant="temporary"
          disableScrollLock={true}
          onEscapeKeyDown={() => setOpen(false)}
          onBackdropClick={() => setOpen(false)}
          className={classes.drawer}
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Divider />
          <Paper style={{ width: `${withFilterVW}px`, height: `100%` }}>
            <div style={{ ...theme.dashboard.filter.mainTitleDiv, textAlign: "center" }}>
              <Typography variant="subtitle2" style={{ color: "#FFFFFF" }}>
                Query selection
              </Typography>
            </div>
            

            <FormControl style={theme.dashboard.filter.form}>
              <InputLabel htmlFor="age-native-simple">
                <Typography variant="caption"> Environment </Typography>
              </InputLabel>
              <Select
                onChange={(event) => {

                  setSelectedEnvironment(event.target.value)
                  props.history.push(`/${process.env.REACT_APP_PREFIX}/governance`)
                }
                }
              >
                <MenuItem key={"all environments"} value={null}>
                  <Typography variant="caption">
                    all environments
                      </Typography>
                </MenuItem>
                {environment &&
                  environment.map((e) => (
                    <MenuItem key={e.environmentName} value={e.environmentName}>
                      <Typography variant="caption">
                        {e.environmentName}
                      </Typography>
                    </MenuItem>
                  ))}
                {!environment && (
                  <MenuItem key={environment._id} value={environment._id}>
                    <Typography variant="caption">
                      {selectedEnvironment}
                    </Typography>
                    {selectedEnvironment}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl style={theme.dashboard.filter.form}>
              <InputLabel htmlFor="age-native-simple">
                <Typography variant="caption"> Aggregation level </Typography>
              </InputLabel>
              <Select
                onChange={(event) => {
                  setSelectedAgregation(event.target.value)
                  props.history.push(`/${process.env.REACT_APP_PREFIX}/governance`)
                }}
              >
                <MenuItem key="project" value="project">
                  <Typography variant="caption"> Projects </Typography>
                </MenuItem>
                <MenuItem key="qualityPoint" value="qualityPoint">
                  <Typography variant="caption"> Quality points </Typography>
                </MenuItem>
                <MenuItem key="dataset" value="datasource">
                  <Typography variant="caption"> Datasets </Typography>
                </MenuItem>
              </Select>
            </FormControl>
            <div style={{ marginLeft: "20px" }}>
              <Grid container>
                <Grid item xs={12} >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.showEmpty}
                        defaultChecked={props.showEmpty}
                        onChange={(event) =>
                          props.toggleShowEmpty()
                        }
                        name="checked"
                        color="primary"
                      />
                    }
                    style={theme.notifications.showUnreadCheck}
                    label={<Typography variant="caption">
                      Show No Data Items
                    </Typography>}
                  />
                </Grid>

                <Grid item xs={12} >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.showOwnProjects}
                        defaultChecked={props.showOwnProjects}
                        onChange={(event) =>
                          props.toggleShowOwnProjects()
                        }
                        name="checked"
                        color="primary"
                      />
                    }
                    style={theme.notifications.showUnreadCheck}
                    label={<Typography variant="caption">
                      Only own proyects
                    </Typography>}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: "6%" }}>
              <Divider light />
            </div>

            <FormControl style={theme.dashboard.filter.form}>

            <InputLabel htmlFor="age-native-simple">
                <Typography variant='caption' > Select Quality Projects to visualize</Typography>
            </InputLabel>
            <Select
                multiple
                value={props.selectedProjects}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => <Typography variant='caption'>{(selected.map(x=>findProjectNameById(projects,projectsLicense,x))).join(', ')}</Typography>}
            >
                {getMenuItems(projects)}
                {getMenuItemsLicense(projectsLicense)}
            </Select>
            </FormControl>    

             <div style={{ marginTop: "6%" }}>
              <Divider light />
            </div>        

            <div style={theme.dashboard.filter.mainTitleDiv}>
              <Typography variant="subtitle2" style={{ color: "#FFFFFF" }}>
                Time period selection
              </Typography>
            </div>

            {
              <div style={{ marginLeft: "20px" }}>

                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                      <DateTimePicker
                        //disabled={}
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        formatStyle="small"
                        style={{maxWidth: "85%"}}
                        label={<Typography variant="caption"> From </Typography>}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                      <DateTimePicker
                        //disabled={}
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        formatStyle="small"
                        style={{maxWidth: "85%",marginBottom: "5%"}}
                        label={<Typography variant="caption"> To </Typography>}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
          </Paper>
        </Drawer>
      </div>
    </>
  );
}
