import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import { DataRepositoryHeaderButtons } from "../DataRepositoryHeaderButtons";
import { useSelector, useDispatch } from "react-redux";
//import * as actions from "../../../../actions"; ya no hace falta pasarlas porque ahora vienen por props
import { OptionExport } from "./Options/OptionExport";
import { OptionImport } from "./Options/OptionImport";
import { OptionNew } from "./Options/OptionNew";
import { OptionGraph } from "./Options/OptionGraph";
import { DataRepositoryTreeTable } from "../DataRepositoryTreeTrable/DataRepositoryTreeTable.js";
import { faShareSquare, faUserLock } from "@fortawesome/free-solid-svg-icons";
import * as convertDataHelper from "./Helpers/convertDataHelper";

export function EntityDataRepository(props) {
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState({ itemId: "", type: "" }); // selectedItem del private
  const [selectedItemShared, setSelectedItemShared] = useState({
    itemId: "",
    type: "",
  });

  const [expandedKeys, setExpandedKeys] = useState();
  const [expandedKeysShared, setExpandedKeysShared] = useState();

  const [showImportDialog, setShowImportDialog] = React.useState(false);
  const [showExportDialog, setShowExportDialog] = React.useState(false);
  const [showNewSharedDialog, setShowNewSharedDialog] = React.useState(false);
  const [showNewDialog, setShowNewDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showDeleteSharedDialog, setShowDeleteSharedDialog] = React.useState(false);
  const [showNewDialogParent, setShowNewDialogParent] = React.useState(false);
  const [showGraphDialog, setShowGraphDialog] = React.useState(false);
  const [defaultFolderId, setDefaultFolderId] = React.useState(" ");

  const entityDataRepo = useSelector((store) =>
    Object.values(store[props.entityName])
  );
  const entityDataRepoShared = useSelector((store) =>
    Object.values(store[props.entityName + "Shared"])
  );


  function findEntityInDTOs(selectedIds, dtos){
     for (var i = 0; i < dtos.length; i++) {
      if(dtos[i].qualityEntityRepoVirtualDTOS && dtos[i].qualityEntityRepoVirtualDTOS.length>0 ){
        
        for (var j = 0; j < dtos[i].qualityEntityRepoVirtualDTOS.length; j++) {
            if(dtos[i].qualityEntityRepoVirtualDTOS[j].entityId === selectedIds){
              setSelectedItem({ itemId: dtos[i].qualityEntityRepoVirtualDTOS[j]._id, type: "Files"})
              break;
            }
        }
      }
    }
  }
  //if a default selected item needs to be shown
  React.useEffect(()=>{

    if(props.selectedPrivateEntityId!==undefined && entityDataRepo!==undefined && entityDataRepo.length>0 && selectedItem.itemId ===""){
      findEntityInDTOs(props.selectedPrivateEntityId,entityDataRepo)
    }

  },[props.selectedPrivateEntityId,entityDataRepo])

  React.useEffect(()=>{
    let defaultFolderIdNumber = entityDataRepo[0] && entityDataRepo[0]._id
    setDefaultFolderId(defaultFolderIdNumber)
  },[entityDataRepo])

  
  React.useEffect(() => {
    if (props.user && props.user.id) {
      dispatch(
        props.actions.fetchProjectDataRepositorySharedWorkspace(
          /* "5d829501ee51b877f859a29c" */ props.user.id
        )
      );
      dispatch(
        props.actions.fetchProjectDataRepositoryPrivateWorkspace(
          /* "5d829501ee51b877f859a29c" */ props.user.id
        )
      );
    }
  }, [props.user]);

  return (
    <div>
      <DataRepositoryHeaderButtons
        setShowImportDialog={setShowImportDialog}
        setShowExportDialog={setShowExportDialog}
        setShowNewDialogParent={setShowNewDialogParent}
      />
      <Divider />
      <DataRepositoryTreeTable
        actions={props.actions}
        enableContextMenu={true}
        type="shared"
        dtoData={entityDataRepoShared}
        defaultFolderId={defaultFolderId}
        treeData={convertDataHelper.filterSharedRepository(
          convertDataHelper.parseEntityDataRepositoryToTree(
            entityDataRepoShared
          ),
          props.user.id
        )} //Hemos añadido filterSharedRepository para que no aparezcan las carpetas privadas del user
        iconTitle={faShareSquare}
        title={"Shared projects area"}
        titleStyle={{ color: "#45b6fe", margin: 0 }}
        selectedItem={selectedItemShared}
        setSelectedItem={setSelectedItemShared}
        setShowDialogs={{
          import: setShowImportDialog,
          export: setShowExportDialog,
          new: setShowNewSharedDialog,
          graph: setShowGraphDialog,
          delete: setShowDeleteSharedDialog,
        }}
        showDialogs={{ new: showNewSharedDialog, delete: showDeleteSharedDialog}}
        expandedKeys={expandedKeysShared}
        setExpandedKeys={setExpandedKeysShared}
        iconType1={props.iconType1}
        iconType2={props.iconType2}
        user={props.user}
      />
      <DataRepositoryTreeTable
        actions={props.actions}
        enableContextMenu={true}
        type="private"
        dtoData={entityDataRepo}
        defaultFolderId={defaultFolderId}
        treeData={convertDataHelper.parseEntityDataRepositoryToTree(
          entityDataRepo
        )}
        iconTitle={faUserLock}
        title={`Private Workspace User ${props.user.name}`}
        titleStyle={{ color: "#45b6fe", margin: 0 }}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setShowDialogs={{
          import: setShowImportDialog,
          export: setShowExportDialog,
          new: setShowNewDialog,
          delete: setShowDeleteDialog,
        }}
        showDialogs={{ new: showNewDialog, delete: showDeleteDialog }}
        expandedKeys={expandedKeys}
        setExpandedKeys={setExpandedKeys}
        iconType1={props.iconType1}
        iconType2={props.iconType2}
        user={props.user}
      />
      {/* Import y export están aquí juntas porque dependen de los estados de ambas treetables, mientras que addfolder y delete están en otras partes del componente de las que dependen directamente */}
      <OptionExport
        actions={props.actions}
        showDialog={showExportDialog}
        setShowDialog={setShowExportDialog}
        selectedItemShared={selectedItemShared} //folderid
        setSelectedItemShared={setSelectedItemShared}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        entityId={entityDataRepo}
        expandedKeys={expandedKeys}
        setExpandedKeys={setExpandedKeys}
        expandedKeysShared={expandedKeysShared}
        setExpandedKeysShared={setExpandedKeysShared}
        entityName={props.entityName}
      />
      <OptionImport
        actions={props.actions}
        showDialog={showImportDialog}
        setShowDialog={setShowImportDialog}
        selectedItemShared={selectedItemShared} //folderid
        setSelectedItemShared={setSelectedItemShared}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        entityId={entityDataRepo}
        expandedKeys={expandedKeys}
        setExpandedKeys={setExpandedKeys}
        expandedKeysShared={expandedKeysShared}
        setExpandedKeysShared={setExpandedKeysShared}
        entityName={props.entityName}
      />
      <OptionGraph
        showDialog={showGraphDialog}
        setShowDialog={setShowGraphDialog}
        selectedItemShared={selectedItemShared}
        entityName={props.entityName}
      />
      {/*  Add new root folder */}
      <OptionNew
        actions={props.actions}
        showDialog={showNewDialogParent}
        setShowDialog={setShowNewDialogParent}
        //type="private"
        parent={true}
      />
    </div>
  );
}
