
import React from 'react';

import { Grid,Typography} from '@material-ui/core'

import ThresholdCard from './ThresholdCard'
import OutputControlCard from './OutputControlCard'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core/styles';

import { faCog } from '@fortawesome/free-solid-svg-icons'


export default function AdvanceConfigurationQuality(props) {

    
    const theme = useTheme()

    function isValid(){
        if(props.thWarning && props.thError && props.controlObj && props.controlObj.wrongRegisterAction && props.controlObj.outputMode){
            return true
        }
        return false
    }
   
    return(
                <ExpansionPanel
                style={{width:'100%'}}>
                                <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                         <Grid item xs={1}>
                                        <FontAwesomeIcon icon={faCog} style={{color:'#76b3d6', fontSize:20}} />
                                        </Grid>
                                        <Grid item xs={11}>
                                             <Typography variant = 'subtitle2'>Advance Configuration</Typography>
                                             {!isValid() && 
                                           
                                                <div className="ui error message" style={theme.errors.errorText}>
                                                    <div className="header" >Error in Advance Configuration</div>
                                                </div>
                                            
                                                    }
                          
                                        </Grid>
                                        
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                        <ThresholdCard title='Warning Threshold' setTh={props.setThWarning} obj={props.thWarning}/>
                                        </Grid>
                                    <Grid item xs={4}>
                                    <ThresholdCard title='Error Threshold' setTh={props.setThError} obj={props.thError}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <OutputControlCard title='Execution Parameters' setObj={props.setControlObj} obj = {props.controlObj}/>
                                    </Grid>
                            </Grid>

                                </ExpansionPanelDetails>
                        </ExpansionPanel>
    )
}