import React from 'react';

import {makeStyles,TextField, FormControlLabel,ExpansionPanel,ExpansionPanelSummary,ExpansionPanelDetails, Grid, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { styles, QualityTheme } from '../../../../style/CustomTheme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';



export default function AzureBackendConfiguration(props) {
        

        const classes = makeStyles(styles(QualityTheme));

        const [azureCredentials, setAzureCredentials] = React.useState(props.configuration && props.configuration.azureCredentials ? 
                props.configuration.azureCredentials : {})

        
        function setAzureCredentialsValue(param, value){
                var oldAzureCredentials = Object.assign({}, azureCredentials);
                oldAzureCredentials[param]=value
                setAzureCredentials(oldAzureCredentials)
        }

        React.useEffect(() => {


                setAzureCredentials(props.configuration && props.configuration.azureCredentials && props.configuration.azureCredentials!==null ?
                        props.configuration.azureCredentials : (props.parentConfiguration && props.parentConfiguration.azureCredentials ?
                                props.parentConfiguration.azureCredentials : {}))
                
                 
                }, [props.configuration, props.parentConfiguration]);

        React.useEffect(() => {
                props.setConfig(azureCredentials)              
        }, [azureCredentials]);

        

    return(
        <MuiThemeProvider theme={QualityTheme}>
        <ExpansionPanel
        style={{width:'100%'}}>
                        <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                                 <Grid item xs={1}>
                                <FontAwesomeIcon icon={faCog} style={{color:'#76b3d6', fontSize:20}} />
                                </Grid>
                                <Grid item xs={11}>
                                     <Typography variant = 'subtitle2'>Azure Main Configuration</Typography>
                                </Grid>
                        
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                                <div>
                                <div style={{width:'100%', minWidth:'800px'}}>
                                <FormControlLabel style={{marginLeft:'5%',width:'50%'}}
                                        control={
                                            <TextField style={{width:'100%'}}
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="databricksInstance"
                                                label="Azure Client Id"
                                                className={classNames(classes.textField, classes.dense)}
                                                margin="dense"
                                                value={(azureCredentials && azureCredentials.clientId) ? azureCredentials.clientId : ''}
                                                onChange={(event) => {setAzureCredentialsValue('clientId',event.target.value)} }
                                            />} />
                                <FormControlLabel style={{marginLeft:'5%',width:'50%'}}
                                        control={
                                            <TextField style={{width:'100%'}}
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="databricksClusterId"
                                                label="Azure Domain"
                                                className={classNames(classes.textField, classes.dense)}
                                                margin="dense"
                                                value={(azureCredentials && azureCredentials.domain) ? azureCredentials.domain : ''}
                                                onChange={(event) => {setAzureCredentialsValue('domain',event.target.value)} }
                                            />} />
                                <FormControlLabel style={{marginLeft:'5%',width:'50%'}}
                                        control={
                                            <TextField style={{width:'100%'}}
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="databricksToken"
                                                type="password"
                                                label="Azure Client Secret"
                                                className={classNames(classes.textField, classes.dense)}
                                                margin="dense"
                                                value={(azureCredentials && azureCredentials.secret) ? azureCredentials.secret : ''}
                                                onChange={(event) => {setAzureCredentialsValue('secret',event.target.value)} }
                                            />} />
                        
                                 <FormControlLabel style={{marginLeft:'5%',width:'50%'}}
                                        control={
                                            <TextField style={{width:'100%'}}
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="databricksSimulationNotebookPath"
                                                label="Azure subscription"
                                                className={classNames(classes.textField, classes.dense)}
                                                margin="dense"
                                                value={(azureCredentials && azureCredentials.subscription) ? azureCredentials.subscription : ''}
                                                onChange={(event) => {setAzureCredentialsValue('subscription',event.target.value)} }
                                            />} />

                                
                                </div>
                                </div>
                                
                        

                        </ExpansionPanelDetails>
                </ExpansionPanel>
                </MuiThemeProvider>
)
    }