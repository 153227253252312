import _ from 'lodash';
import {FETCH_STREAM_EVENTHUB_CONFIG
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_STREAM_EVENTHUB_CONFIG:
            return action.payload
        default:
            return state;
        
    }
}