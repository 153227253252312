import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import {
    Grid, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, InputAdornment, Icon, LinearProgress,
    FormControlLabel, Switch, TextField, InputLabel, Select, Input
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import { useDispatch, useSelector } from 'react-redux'

import CustomInput from "../../../../common/CustomInput/CustomInput.js";
import * as actions from '../../../../../actions'
import InputFile from '../../../../common/azure-storage/components/InputFile'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import {dispatchInfo} from '../../../../../components/common/axios/axiosHelper';





export default function DialogCreateMasterData(props) {

    const theme = useTheme();

    const user = useSelector(store => store.user)
    const configuration = useSelector(store => (store.qualityConfiguration))

    React.useEffect(() => {
        if(user && user.id){
            dispatch(actions.fetchConfigurationByUserId(user.id))
            
        }
       
      }, [user]);

    const dispatch = useDispatch()


    const [name, setName] = React.useState(props.masterData ? props.masterData.name :'')
    const [description, setDescription] = React.useState(props.masterData ? props.masterData.description:'')
    const [privacity, setPrivacity] = React.useState(props.masterData ? props.masterData.filePermissionItem.split("_")[0]: 'public')

    const [tags, setTags] = React.useState([])
    const [tagName, setTagName] = React.useState('')
    const [tagValue, setTagValue] = React.useState('')

    const [fileUploadName, setFileUploadName] = React.useState('')

    const [checkAutomaticSeparator, setCheckAutomaticSeparator] = React.useState(true)
    const [separator, setSeparator] = React.useState(props.masterData ? props.masterData.separator: 'automatic')
    const [separatorClean, setSeparatorClean] = React.useState(props.masterData ? props.masterData.separator: 'automatic')


    const [isUploadingData, setIsUploadingData] = React.useState(false)
    const [isParsing, setIsParsing] = React.useState(false)

    const [colFormats, setColFormats] = React.useState(props.masterData ? props.masterData.columns: [])

    //error component state
    const [errorTypes, setErrorTypes] = React.useState();
    const [errorMsgs, setErrorMsgs] = React.useState();
    const [hasErrors, setHasErrors] = React.useState()
    const [error, setError] = React.useState({})


    //callbacks
    const onChangeName = (event) => { setName(event.target.value) }
    const onChangeDescription = (event) => { setDescription(event.target.value) }


    React.useEffect(() => {
        if(props.masterData){
            setName(props.masterData.name)
            setDescription(props.masterData.description)
            setPrivacity(props.masterData.filePermissionItem.split("_")[0])
            setSeparator(props.masterData.separator)
            setSeparatorClean(props.masterData.separator)
            setCheckAutomaticSeparator(false)
            setColFormats(props.masterData.columns)
        }
    }, [props.masterData]);

    async function onUpload(fileName) {


        //si está en automático el separador es automatic y volvemos a sobreescribir

        var separator2Api = separatorClean
        if (checkAutomaticSeparator) {
            separator2Api = 'automatic'
            setSeparator('automatic')
            setSeparatorClean('automatic')
        }

        setIsUploadingData(false)
        setColFormats([])
        setIsParsing(true)

        var format = {
            name: name,
            fileUploadName: fileName
        }

        //Obtenemos la simulacion del campo de respuesta

        setFileUploadName(fileName)
        setError(validate(format))

        const response = await dispatch(actions.parseDataFormatBlob(separator2Api, fileName, user.id))

        setIsParsing(false)
        setColFormats(response.data.columns)
        setSeparator(response.data.separator)
        setSeparatorClean(response.data.separator)

    }

    function handleSeparatorChange(event) {

        var format = {
            name: name,
            fileUploadName: fileUploadName
        }
        setSeparator(event.target.value)
        setError(validate(format))


    };

    /*
    * ERROR HANDLE FUNCTIONS
    */
    function isError(name) {
        if (!errorTypes) {
            return false
        }
        return errorTypes.includes(name)
    }

    function getErrorMessage(name) {
        var idx = errorTypes.indexOf(name);
        if (idx === -1) {
            return ''
        }
        else {
            return errorMsgs ? errorMsgs[idx] : ''
        }
    }

    function validate() {
        var hasErrors = false
        const arrayErrorTypes = []
        const arrayErrorMsgs = []


        if (!name) {
            hasErrors = true
            arrayErrorTypes.push('name')
            arrayErrorMsgs.push('A name must be provided')
        }


        setHasErrors(hasErrors)
        setErrorTypes(arrayErrorTypes)
        setErrorMsgs(arrayErrorMsgs)

        return { hasErrors, arrayErrorTypes, arrayErrorMsgs };

    }


    function onSubmit(e) {

        const masterDataDTO = {
            licenseId: props.licenseId,
            userId: props.userId,
            blobStorageDTO:{
                account_name: configuration ? configuration.storageAccountName : 'sainputdemoquality',
                container_name: configuration ? configuration.containerName : 'unit-test',
                blob_path:`${props.userId}/master_data`,
                userId:props.userId,
                blob_fileName:fileUploadName
            },
            filePermissionItem: privacity+"_permission",
            name: name,
            description: description,
            columns: colFormats,
            separator: separatorClean,
            fileType: 'csv',
            tags: tags
        }

        dispatch(actions.addDataRepository(masterDataDTO)).then(response=>{
            dispatchInfo(dispatch,`Master Data ${name} created`)
            onClose()
        })
        if(props.setChangedRepo){
            props.setChangedRepo(true)
        }
        
    }

    function onUpdate(e) {

        const masterDataDTO = {
            licenseId: props.licenseId,
            userId: props.userId,
            blobStorageDTO:{
                account_name: configuration ? configuration.storageAccountName : 'sainputdemoquality',
                container_name: configuration ? configuration.containerName : 'unit-test',
                blob_path:`${props.userId}/master_data`,
                userId:props.userId,
                blob_fileName:fileUploadName
            },
            _id : props.selected_id,
            filePermissionItem: privacity+"_permission",
            name: name,
            description: description,
            columns: colFormats,
            separator: separatorClean,
            fileType: 'csv',
            tags: tags
        }

        dispatch(actions.editDataRepository(masterDataDTO)).then(response=>{
            dispatchInfo(dispatch,`Master Data ${name} updated`)
            onClose()
        })
        props.setChangedRepo(true)
    }
    function onClose() {
        setName(null)
        setFileUploadName('')
        setSeparator(',')

        setError({})

        setIsUploadingData(false)
        props.onClose();
    }

    function addTag(){
        const tempTag ={
            name: tagName,
            value:tagValue
        }
        setTags(tags => [...tags, tempTag]);
    }



    return (
        <Dialog open={props.open}
            fullWidth={true}
            maxWidth={'md'}>

            <DialogTitle style={theme.dialogs.dialogTitle}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <FontAwesomeIcon icon={faTable} style={theme.dialogs.dialogTitleAwesomeIcon} />

                    </Grid>
                    <Grid item >
                        <Typography style={theme.dialogs.dialogTitleText}>
                            Add Master Data
                            </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>

                <Grid container spacing={3} >
                    <Grid item xs={6}>
                        <Grid container spacing={3} >
                            <Grid item xs={12} style={{ marginBottom: '-5%' }}>

                                <div>

                                    <CustomInput
                                        labelText="Master Data Name"
                                        id="MasterDataName"
                                        formControlProps={{
                                            fullWidth: false,
                                            style: { width: '80%' }
                                        }}


                                        inputProps={{
                                            type: "text",
                                            onChange: onChangeName,
                                            value: name,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Icon className="fas fa-signature" />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <div >
                                        {hasErrors && isError('name') && <Typography variant='caption' style={{ color: '#ab2e27' }}> {getErrorMessage('name')}</Typography>}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} >
                                <div>
                                    <CustomInput
                                        labelText="Description"
                                        id="Description"
                                        formControlProps={{
                                            fullWidth: true,
                                            style: { width: '80%' }
                                        }}

                                        inputProps={{
                                            defaultValue: description,
                                            type: "text",
                                            onChange: onChangeDescription,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Icon className="fas fa-align-left" />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                           {undefined &&  <Grid item xs={12}>
                                <Typography variant='subtitle2'>
                                    Tags
                        </Typography>
                                <div style={theme.elementAdjacent}>
                                    <TextField
                                        fullWidth='true'
                                        style={{ width: '29%', marginRight: '2%' }}
                                        id='Tag'
                                        label='Tag Name'
                                        inputProps={{
                                            style: { fontSize: 10 }

                                        }}
                                        onChange={event => setTagName(event.target.value)}
                                    />
                                    <TextField
                                        fullWidth='true'
                                        style={{ width: '29%', marginRight: '2%' }}
                                        id='Tag'
                                        label='Tag Value'
                                        inputProps={{
                                            style: { fontSize: 10 }
                                        }}
                                        onChange={event => setTagValue(event.target.value)}
                                    />
                                    <Button style={theme.buttons.buttonConfirm} variant='outlined' onClick = {event => addTag()}>
                                        Add Tag
                        </Button>
                                </div>
                            </Grid>}

                            <Grid item xs={12}>

                                <InputLabel htmlFor="typeWarnTh-selection-helper" style={theme.inputLabel}>Master Data Privacity</InputLabel>
                                <Select
                                    native
                                    value={privacity}
                                    style={{
                                        width: '80%',
                                        fontSize: 14,

                                    }}
                                    onChange={event => { setPrivacity((event.target.value)) }}
                                    input={<Input id="select-multiple-checkbox" />}
                                >
                                    <option value="public">Public</option>
                                    <option value="private">Private</option>

                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <InputFile onUpload={onUpload} folder={`${props.userId}/masterData`}
                                    storageAccountName={configuration ? configuration.storageAccountName : 'sainputdemoquality'}
                                    containerName={configuration ? configuration.containerName : 'unit-test'}
                                    sasToken={configuration ? configuration.sasToken : '?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-09-11T14:57:13Z&st=2020-06-22T06:57:13Z&spr=https&sig=f18IZ5vTdx%2FkwLGFjAEhpRZ%2FBZkS%2B3d5Zrv5c1xZZfc%3D'}
                                    buttonText={'Upload File'} />
                            </Grid>

                            <Grid item xs={12} >

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checkAutomaticSeparator}
                                            onChange={event => {
                                                setCheckAutomaticSeparator(event.target.checked);
                                                setSeparator('automatic')
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant='body2'> Automatic Separator Estimation</Typography>}

                                />
                                </Grid>
                                <Grid item xs={12} >
                                {!checkAutomaticSeparator &&
                                    <TextField type="text" value={separator} autoComplete='off'
                                        id="separator"
                                        label='Separator'
                                        style={{ marginTop: '5px' }}
                                        onChange={handleSeparatorChange}
                                    />
                                }
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <div style={{ marginTop: '10px' }}>
                            <label style={{ fontWeight: 'bold' }}> Columns Obtained from Sample Data </label>
                            <DataTable value={colFormats ? colFormats : []}
                                editable={true}
                                style={{ algin: 'center', width: '100%' }}
                                scrollable={true} scrollHeight="200px">
                                <Column field="position" header="Position"
                                    style={{
                                        background: 'transparent',
                                        borderColor: 'transparent',
                                        fontSize: '12px',
                                        textAlign: 'right',
                                        width: '20%'
                                    }} />
                                <Column field="name" header="Name"
                                    style={{
                                        background: 'transparent',
                                        borderColor: 'transparent',
                                        fontSize: '12px',
                                        textAlign: 'right',
                                        width: '40%'
                                    }} />
                                <Column field="type" header="Format"
                                    style={{
                                        background: 'transparent',
                                        borderColor: 'transparent',
                                        fontSize: '12px',
                                        textAlign: 'right',
                                        width: '40%'
                                    }} />

                            </DataTable>
                            {
                                isParsing &&
                                <div>
                                    <LinearProgress />
                                    <Typography variant={'subtitle2'}> Parsing File. Depending on file's size it may take some time </Typography>
                                </div>
                            }
                        </div>
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                {!props.masterData &&
                <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm} onClick={event => {
                    onSubmit(event);
                }}>Create</Button>
                }
                {props.masterData && props.editable &&
                <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm} onClick={event => {
                    onUpdate(event);
                }}>Edit</Button>
                }
                <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonCancel} onClick={event => {
                    onClose(event)
                }}>
                    Cancel
                            </Button>
            </DialogActions>
        </Dialog>
    )

}
