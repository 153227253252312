import React from 'react';

import {
    AreaChart,  Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer
} from 'recharts';
import Typography from '@material-ui/core/Typography'; 


const getLabelTooltip= (data) =>{
    if(data === 'percentOKRuleNameIntegrity'){
        return 'OK Integrity data percent'
    }
    if(data === 'percentKORuleNameIntegrity'){
        return 'KO Integrity data percent'
    }

    if(data === 'percentOKRuleNameUnicity'){
        return 'OK Unicity data percent'
    }
    if(data === 'percentKORuleNameUnicity'){
        return 'KO Unicity data percent'
    }

    if(data === 'percentOKRuleNameVeracity'){
        return 'OK Veracity data percent'
    }
    if(data === 'percentKORuleNameVeracity'){
        return 'KO Veracity data percent'
    }

    if(data === 'percentOKRuleNameValidity'){
        return 'OK Validity data percent'
    }
    if(data === 'percentKORuleNameValidity'){
        return 'KO Validity data percent'
    }

    if(data === 'percentOKRuleName'){
        return 'OK data percent'
    }
    if(data === 'percentKORuleName'){
        return 'KO data percent'
    }
    return data
}

const CustomTooltip = ({ active, payload, label }) => {

    if (active) {
      return (
        <div className="custom-tooltip" style={{backgroundColor:'#FFFFFFDD'}}>
          <Typography variant ='subtitle2'>
              {`Date ${label}`}
          </Typography>
          {payload && payload.length>0 && payload.map(data =>
            <div>
                <Typography variant ='caption' style={{color:data.color}}>
              {`${getLabelTooltip(data.name)} : ${data.value}%`}
          </Typography>
            </div>
            )}
        </div>
      );
    }
  
    return null;
  };

export default function ChartLineBiaxial(props) {

    function sumTimeSeries(){
        if(props.data && props.data.percentsTimeSeriesChartDAO){
            
        }
    }

    if(!props.dimensionKPI){
        return (
            <ResponsiveContainer width="100%" height={200} >
                <AreaChart
                        width={1150}
                        height={200}
                    data={props.data && props.data.percentsTimeSeriesChartDAO && props.data.percentsTimeSeriesChartDAO.percentesTimeSeriasDAO ?
                        props.data.percentsTimeSeriesChartDAO.percentesTimeSeriasDAO : []
                    }
                >
                    <XAxis dataKey="date"  tick={{fontSize: 12}}/>
                    <YAxis  tick={{fontSize: 12}}/>
                    
                    <Tooltip content={<CustomTooltip />} />
        
                    <Area  type="monotone" dataKey="percentOKRuleName" stroke="#40cf42" fill="#40cf42AA" />
                    <Area  type="monotone" dataKey="percentKORuleName" stroke="#e32727" fill="#e32727AA" />
                </AreaChart>
            </ResponsiveContainer>
        )
    }
    else{
        
        return(
            <ResponsiveContainer width="100%" height={200} >
            <AreaChart
                    width={1150}
                    height={250}
                    data={props.data && props.data.percentsTimeSeriesDimensionDAO  ?
                        props.data.percentsTimeSeriesDimensionDAO : []
                   }
            >
                <XAxis dataKey="date"tick={{fontSize: 12}} />
                <YAxis  tick={{fontSize: 12}}/>
                
                <Tooltip content={<CustomTooltip />} />
                {props.selectedDimIntegrity===true &&<Area  type="monotone" dataKey="percentOKRuleNameIntegrity" stroke="#40cf42" fill="#40cf42AA" />}
                {props.selectedDimIntegrity===true && <Area  type="monotone" dataKey="percentKORuleNameIntegrity" stroke="#e32727" fill="#e32727AA" />}
                  
                {props.selectedDimVeracity===true &&<Area  type="monotone" dataKey="percentOKRuleNameVeracity" stroke="#40cf42" fill="#40cf42AA" />}
                {props.selectedDimVeracity===true && <Area  type="monotone" dataKey="percentKORuleNameVeracity" stroke="#e32727" fill="#e32727AA" />}
                

                {props.selectedDimValidity===true &&<Area  type="monotone" dataKey="percentOKRuleNameValidity" stroke="#40cf42" fill="#40cf42AA" />}
                {props.selectedDimValidity===true && <Area  type="monotone" dataKey="percentKORuleNameValidity" stroke="#e32727" fill="#e32727AA" />}
                
                {props.selectedDimUnicity===true &&<Area  type="monotone" dataKey="percentOKRuleNameUnicity" stroke="#40cf42" fill="#40cf42AA" />}
                {props.selectedDimUnicity===true && <Area  type="monotone" dataKey="percentKORuleNameUnicity" stroke="#e32727" fill="#e32727AA" />}
                

            </AreaChart>
        </ResponsiveContainer>
        )
    }
    
}