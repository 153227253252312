import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core/styles';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'

import { Typography } from '@material-ui/core'
import moment from 'moment'


export default function DeployAuditComponent(props) {

    const theme = useTheme()
    const [, updateState] = React.useState();

    React.useEffect(()=>{
        updateState({})
        
      }, [props.environment])


    function noApplyDeploy(project) {
       
        //only if it has one qualitypoint, datasource and one rule
        if (project.qualityPoints && project.qualityPoints.length > 0) {
            for (var i = 0; i < project.qualityPoints.length; i++) {
                if (project.qualityPoints[i].datasources && project.qualityPoints[i].datasources.length > 0) {
                    for (var j = 0; j < project.qualityPoints[i].datasources.length; j++) {
                        if (project.qualityPoints[i].datasources[j].rules && project.qualityPoints[i].datasources[j].rules.length > 0) {
                            return false
                        }
                    }
                }
            }
        }
        return true


    }



    function noDeploy(project) {
        if (props.deploymentAudit===true){
            return false
        }
        if (!project.deploymentAudit || project.deploymentAudit === null) {
            return true
        }
        if(project.deploymentAudit.lastEnvironmentDeployments[props.environment] && project.deploymentAudit.lastEnvironmentDeployments[props.environment].deploymentDate){
            return false;
        }
        else{
            return true
        }
    }

    function oldDeployment(project, environment) {
        if (props.deploymentAudit===true){
            return false
        }
        if (!project.deploymentAudit || project.deploymentAudit === null) {
            return true
        }
        if (project.deploymentAudit && environment) {
            if (project.deploymentAudit.lastEnvironmentDeployments[environment]) {
                let deployDate = (Date.parse(project.deploymentAudit.lastEnvironmentDeployments[environment].deploymentDate))
                let projectDate = (Date.parse(project.updateDate))
                if (deployDate < projectDate) {
                    return true
                }
            }
        }
        return false
    }

    if (noApplyDeploy(props.project)) {
        return (
            <div>

            </div>
        )
    }
    else {

        if (noDeploy(props.project, props.environment)) {
            return (
                <div style={{ marginLeft: '10%' }}>
                    <FontAwesomeIcon icon={faCloudDownloadAlt} style={theme.icons.iconErrorBig} />
                    <Typography variant='caption' style={theme.errors.errorText}> This project has not been deployed </Typography>
                </div>
            )
        }
        else {
            if (oldDeployment(props.project, props.environment)) {
                return (
                    <div style={{ marginLeft: '10%' }}>
                        <FontAwesomeIcon icon={faCloudDownloadAlt} style={theme.icons.iconErrorBig} />
                        <Typography variant='caption' style={theme.errors.errorText}>   Deployed version is older than current project version </Typography>
                    </div>
                )
            }
            else {
                return (
                    <div style={{ marginLeft: '10%' }}>
                        <FontAwesomeIcon icon={faCloudUploadAlt} style={theme.icons.iconInfoBig} />
                        <Typography variant='caption' style={theme.info.infoText}>   Project deployed and updated </Typography>
                    </div>
                )
            }
        }
    }
}
