import React from 'react';
import {makeStyles,Typography,TextField} from '@material-ui/core';
import { styles, QualityTheme } from './../../../../../../../../style/CustomTheme'
import {getDataFromProperties} from './SpecificRuleHelper'



export default function FormRuleDuplicated(props){

    const classes = makeStyles(styles(QualityTheme));
    const [maxDuplicatedCount, setMaxDuplicatedCount] = React.useState(parseInt(getDataFromProperties(props.editableRule, props.specific,'maxDuplicatedCount',1))+1 )

    React.useEffect(() => {
        createSpecific()
    }, [maxDuplicatedCount]);

    function createSpecific(){
        //creamos el objeto de regla especifica
        const specific = {
            maxDuplicatedCount: maxDuplicatedCount-1
        }
        props.setSpecific(specific)
       
    }




    function handleTextFieldChange (event) {  
        setMaxDuplicatedCount(event.target.value)
        createSpecific()
    };
   
    
    return(

            <div className={classes.actionsContainer}>
                <Typography variant='caption'> Num of Rows to consider a duplicated</Typography>
                <div>
                    <TextField type="number" value={maxDuplicatedCount} autoComplete='off'
                        style={{ width: '15%' }}
                        margin="dense"
                        InputProps={{ inputProps: { min: 2, max: 100 } }}
                       id='timelinessValue' 
                        onChange={handleTextFieldChange} />
                    
                    </div>
            
            </div>          

    )
}
