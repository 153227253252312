
import React from 'react';
import { Grid, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, InputAdornment, Icon } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import QualityItemSelector from './../../../../../../common/QualityItemSelector'


import { useSelector, useDispatch } from 'react-redux'
import { BlobServiceClient } from '@azure/storage-blob';
import * as blobHelper  from './../../../../../../../components/common/azure-storage/services/BlobStorageService'
import {axiosHeader} from './../../../../../../common/axios/axiosApiHelper'

import { useTheme } from '@material-ui/core/styles';
import trackEvent from './../../../../../../../trackEvent'



export default function DialogDeployExternal(props) {
    const theme = useTheme();

    var license = useSelector(store => store.license)

    const user = useSelector(store => store.user)
    const dispatch = useDispatch();

    const [apiKey, setApiKey] = React.useState('""')
    const [selectedQualityPoint, setSelectedQualityPoint] = React.useState('')
    const [selectedDataSource, setSelectedDataSource] = React.useState('')
    const [selectedQualityPointId, setSelectedQualityPointId] = React.useState('"')
    const [selectedDataSourceName, setSelectedDatasourceName] = React.useState('""')

    //metodos cuando se selecciona un qualitypoint
    React.useEffect(() => {
        if (selectedQualityPoint) {
            setSelectedQualityPointId(selectedQualityPoint._id)
        }
        if (selectedQualityPoint && selectedQualityPoint.datasources && selectedQualityPoint.datasources.length == 1) {
            setSelectedDataSource(selectedQualityPoint.datasources[0])
            setSelectedDatasourceName(selectedQualityPoint.datasources[0].name)
        }
    }, [selectedQualityPoint]);

    //metodos cuando se selecciona un datasource
    React.useEffect(() => {
        if (selectedDataSource) {
            setSelectedDatasourceName(selectedDataSource.name)
        }

    }, [selectedDataSource]);

    //metodo a ejecutar al inicio
    React.useEffect(() => {
        if (props.project && props.project.qualityPoints && props.project.qualityPoints.length > 0) {
            setSelectedQualityPoint(props.project.qualityPoints[0])
            setSelectedQualityPointId(props.project.qualityPoints[0]._id)
        }
    }, [props.project]);

    

   function download() {
        // fake server request, getting the file url as response
        setTimeout(() => {
          const response = {
            file: process.env.REACT_APP_URL_PROXY_JAR,
          };
          // server sent the url to the file!
          // now, let's download:
          window.open(response.file);
          // you could also do:
          // window.location.href = response.file;
        }, 100);
      }


    return (
        <div>
            <Dialog open={props.visible}
                fullWidth={true}
                maxWidth={'md'}>

                <DialogTitle style={theme.dialogs.dialogTitle}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <FontAwesomeIcon icon={faAngleDoubleDown} style={theme.dialogs.dialogTitleAwesomeIcon}/>

                        </Grid>
                        <Grid item style={{ marginTop: '1%' }}>
                            <Typography style={theme.dialogs.dialogTitleText}>
                                {`Deploy to External Tools, Environment: ${props.environment}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2} style={{marginTop:'2%'}}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2'>
                                Execution Instruction
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='caption'>
                                {`java -jar ProxyActiva-1.0.1.jar ${license ? license.apiKey : ""} "%LOCAL_DATA_PATH%" ${props.project._id} ${selectedQualityPointId} ${selectedDataSourceName} "%DATA_SEPARATOR%" ${props.environment}`}
                            </Typography>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='caption'>
                                %LOCAL_DATA_PATH% must be overwritten with the local path of the file to process
                             </Typography>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='caption'>
                                %DATA_SEPARATOR% must be set to the local file separator
                             </Typography>

                        </Grid>
                        <Grid item xs={6}>
                            <QualityItemSelector
                                title={'Select a Quality Point'} type='QualityPoint'
                                value={selectedQualityPoint}
                                onChange={event => setSelectedQualityPoint(event.target.value)}
                                itemsArray={((props.project && props.project.qualityPoints) ? props.project.qualityPoints : [])}
                                itemId={'_id'} itemValue={'name'}
                            />


                        </Grid>
                        {selectedQualityPoint && selectedQualityPoint.datasources &&
                            selectedQualityPoint.datasources.length > 1 &&
                            <Grid item xs={6}>


                                <QualityItemSelector
                                    title={'Select a Data Source'} type='Datasource'
                                    value={selectedDataSource}
                                    onChange={event => setSelectedDataSource(event.target.value)}
                                    itemsArray={selectedQualityPoint.datasources}
                                    itemId={'_id'} itemValue={'name'}
                                />

                            </Grid>}
                    </Grid>




                </DialogContent>

                <DialogActions>
                <Button id='CancelButton' variant='outlined' color='primary' style={{ fontWeight: 'bold' }} onClick={event => {
                        trackEvent('Deploy External','Download Jar button')
                        download()
                    }}>Download Jar</Button>
                    <Button id='CancelButton' variant='outlined' style={theme.buttons.buttonConfirm}  onClick={event => {
                        trackEvent('Deploy External','Cancel Button')
                        props.onClose(false)
                    }}>Cancel</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}