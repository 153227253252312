import React from 'react';
import {makeStyles,TextField,Grid ,Typography} from '@material-ui/core';
import classNames from 'classnames';
import {Button,Checkbox,FormControlLabel} from  '@material-ui/core';

import { styles, QualityTheme } from './../../../../../../../../style/CustomTheme'
import {getDataFromProperties} from './SpecificRuleHelper'
import trackEvent from './../../../../../../../../trackEvent'


export default function FormRegularExp(props){

    const classes = makeStyles(styles(QualityTheme));

    const [expTextField,setExpTextField] = React.useState(getDataFromProperties(props.editableRule, props.specific,'expRule',''))
    const [testRegExp,setTestRegExp] = React.useState('')
    const [ resultRegExpTest, setResultRegExpTest] = React.useState('')
    const [noMatching, setNoMatching] = React.useState(false)
    const [negateExpression, setNegateExpression] = React.useState(getDataFromProperties(props.editableRule, props.specific,'negateExpression',false))

    function createSpecific(){
        //creamos el objeto de regla especifica
        const specific = {
            expRule: expTextField,
            negateExpression: negateExpression
        }
        props.setSpecific(specific)
       
    }
    
    React.useEffect(() => {
        createSpecific() 
    }, [expTextField]);

    

    function testRegularExpressionCallback(){
        try{
            const res = testRegExp.match(new RegExp(expTextField))
        if(res === null){
            setNoMatching(true)
        }
        else{
            setResultRegExpTest(res)
            setNoMatching(false)
        }
        }
        catch (error) {
            setNoMatching(true)
          }
        
       
    }

    return(
        <div style={{marginTop:'5px'}}>
            <div style={{width:'50%'}}>
            <FormControlLabel
                 control={
                    <Checkbox
                            checked={negateExpression}
                            onChange={event=>setNegateExpression(event.target.checked)}
                            value="nullValueCheck"
                            color="primary"
                    />
                }
                label="Detect data not matching regex"
                />
            </div>
            <div style={{width:'50%'}}>
                <TextField
                    fullWidth={true}
                    id="expTextField"
                    label="Regex Definition"
                    InputLabelProps={{ shrink: true, style:{ fontSize:'12px'} }}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    value={expTextField} 
                    onChange={event =>{setExpTextField(event.target.value); setResultRegExpTest(''); createSpecific()}}
                />
            </div>
            
            <div style={{width:'100%'}}>
                <Grid container spacing={1}>
                        <Grid item xs={5}>
                        <TextField
                            fullWidth={true}
                            id="testRegExpId"
                            label="Test String to Test Reg. Expression"
                            InputLabelProps={{ shrink: true, style:{ fontSize:'12px'} }}
                            className={classNames(classes.textField, classes.dense)}
                            margin="dense"
                            value={testRegExp} 
                            onChange={event =>{setTestRegExp(event.target.value); setResultRegExpTest('')}}
                        />

                        </Grid>
                        <Grid item xs={2} style={{marginTop:'2%'}}>
                            <Button variant='outlined' style={{color: '#76b3d6', fontWeight: 'bold', marginBottom: '5%'}} 
                                onClick={event=>{
                                    trackEvent('CreateRuleSpecific','Regular Exp Text Button')
                                    testRegularExpressionCallback(event)
                                    }}>
                                    <i className="icon plus"></i>
                                        Test Reg. Exp
                            </Button>
                        </Grid>
                        {resultRegExpTest && resultRegExpTest!=='' &&
                            <Grid item xs={5} style={{marginTop:'3%'}}>
                                <Typography variant='body1'>
                                {resultRegExpTest}
                                </Typography>
                            </Grid>
                        }
                        { noMatching===true &&
                            <Grid item xs={5} style={{marginTop:'3%'}}>
                                <Typography variant='body1'>
                                No matching found
                                </Typography>
                            </Grid>
                        }

                </Grid>
        </div>
    </div>
    )
}
