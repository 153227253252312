
import React from 'react'
import {TextField,Grid} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

export default function SearchButton(props) {

    const [ searchText, setText] = React.useState()

    const onChange = (event) => {
        setText(event.target.value)
        props.updateText(event.target.value)
    }

    
    return(
        <div style={{display: 'flex',
        justifyContent: 'center'}}>
                 <SearchIcon style={{color:'#474b51',marginTop:'25px'}} />              
                    <TextField  id="search_button_id" label="Search..." 
                        onChange={onChange}
                        value={searchText}
                    />
               
           
       </div>
    )

}