import React from 'react'

import {Dialog,DialogTitle,DialogActions} from '@material-ui/core'
import {Grid,Typography,IconButton,Button} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import trackEvent from './../../trackEvent'

export default function ConfirmDialog(props) {
    const theme = useTheme();




    return (
        <div>
            <Dialog open={props.open}>
                <DialogTitle style={theme.dialogs.dialogTitle}>
                <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <FontAwesomeIcon icon={faCheckCircle} style={theme.dialogs.dialogTitleAwesomeIcon}/>
                        </Grid>
                        <Grid >
                            <Typography 
                            style={theme.dialogs.dialogTitleText}>
                                {props.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogActions>
                <Grid container spacing={1} style={{marginLeft:'10%'}}>
                <Grid item xs={6}>
                    <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm} 
                    onClick={event =>{trackEvent('Confirm','Confirm Button');props.confirmAction(event)}}>Confirm</Button>
                    </Grid>
                    <Grid item xs={6}>
                    <Button id='CancelButton' variant='outlined'  style={theme.buttons.buttonCancel} 
                    onClick={event =>{trackEvent('Confirm','Cancel Button');props.cancelAction(event)}}>Cancel</Button>
                    </Grid>
                </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}