import React from 'react';
import {useSelector,useDispatch} from 'react-redux'

import {
    FormControl, Grid, Dialog, DialogTitle, Typography, DialogContent,Select,Input,MenuItem,Chip,
    DialogActions, Button, InputAdornment, LinearProgress, FormControlLabel, Switch,Icon,InputLabel
} from '@material-ui/core'

import CustomInput from "./../../../common/CustomInput/CustomInput.js";


import { useTheme } from '@material-ui/core/styles';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function DialogCreateGroup(props) {


  
    const theme = useTheme();
    const dispatch = useDispatch()

    const [editable, setEditable] = React.useState(props.group ? true : false)
    const [selectedUsers,setSelectedUsers] = React.useState([])
    const [groupName, setGroupName] = React.useState('')
    

    function updateGroupName(event){
        setGroupName(event.target.value)
    }
    
    function create(){
        const userGroup = {
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            licenseId: props.licenseId,
            role:'USER',
            groupName:groupName,
            users: selectedUsers
        }
        props.onAddGroup(userGroup)

        setGroupName('')
        setSelectedUsers([])

        props.onClose(false)
    }

    const handleChange = (event) => {
        setSelectedUsers(event.target.value);

      };


    return (
        
        <Dialog
                aria-labelledby="create-license-dialog"
                open={props.visible}
                fullWidth={true}
                maxWidth={'sm'}

            >
                <DialogTitle style={theme.dialogs.dialogTitle}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item>
                            <FontAwesomeIcon icon={faUsers} style={theme.dialogs.dialogTitleAwesomeIcon} />

                        </Grid>
                        <Grid item >
                            <Typography  style={theme.dialogs.dialogTitleText}>
                                {`Create User Group`}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>

                <Grid container spacing={2}>
                <Grid item xs={12}> 
                            <CustomInput
                                labelText="Group Name"
                                id="groupName"
                                formControlProps={{
                                    fullWidth: true,
                                    
                                }}
                                
                                inputProps={{
                                    type: "text",
                                    value: groupName,
                                    onChange: updateGroupName,
                                    endAdornment: (
                                    <InputAdornment position="end">
                                    <Icon className="fas fa-signature" />
                                    </InputAdornment>
                                    )
                                }}
                                />
                        </Grid>
                        <Grid item xs={12}> 
        <InputLabel id="demo-mutiple-chip-label">Users</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          style={{ width: '100%' }}
          value={selectedUsers}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div >
              {selected.map((value) => (
                <Chip key={value.id} label={value.name} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {props.users.map((value) => (
            <MenuItem key={value.id} value={value} >
              {value.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
                        

                </Grid>

                </DialogContent>
                <DialogActions>
                <div>
                    <Button id='SaveButton' variant='outlined' style={theme.buttons.buttonConfirm}
                    onClick = {event=>create()} >
                        Save
                    </Button>
                   
                    
                    <Button id='CancelButton' variant='outlined'  style={theme.buttons.buttonCancel} 
                    onClick={event=>props.onClose()}>
                        Cancel
                    </Button>
                </div>   
                </DialogActions>
            </Dialog>


    );
}