import React from 'react';

import './QualityProjectTreeTable.css'
import { TreeTable } from 'primereact/treetable';
import { Column } from "primereact/column";

import * as helper from './QualityProjectTableHelper'
import * as renderHelper from './QualityProjectTableRenderHelper'
import * as projectHelper from './../QualityProjectHelper'
import {
    Typography, Select, MenuItem
} from '@material-ui/core'

import {StyledMenu, StyledMenuItem} from './../../../../../common/CustomizedMenu'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faRecordVinyl } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt,faFolderOpen } from '@fortawesome/free-regular-svg-icons'
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import trackEvent from './../../../../../../trackEvent'
import { NameCellTemplate } from './NameCellTemplate'
import { DragDropTemplate } from './DragDropTemplate'

import AqtivaDialog from '../../../../../common/AqtivaDialog/index.js';
import * as configurationHelper from '../../../../common/configurationHelper'

import { useDrag } from "react-dnd";

import * as actions from '../../../../../../actions';


const initialState = {
    mouseX: null,
    mouseY: null,
  };

  

export default function QualityDetailProjectTable(props) {

    const theme = useTheme();
    const dispatch = useDispatch();

    const user = useSelector((store) => store.user);

    var project = (props.project)
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))


    const[selectedItem, setSelectedItem] = React.useState()
    const [itemType, setItemType] = React.useState()

    const [state, setState] = React.useState(initialState);

    //state for restar rule dialog
    const [restartRuleDialogVisible, setRestartRuleDialogVisible] = React.useState(false)
    const [ruleId, setRuleId] = React.useState('')


    const [environments,setEnvironments] = React.useState([])
    const [selectedEnvironment, setSelectedEnvironment] = React.useState('')
    
    const [modifiedProject, setModifiedProject] = React.useState([])

    React.useEffect(() => {
        if(qualityConfiguration && 
            qualityConfiguration.environmentConfigurationList){
                setEnvironments(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList,user.id).map(x=>x.environmentName))
                const environmentPreferedSim = configurationHelper.filterAllowedEnvironments(
                    qualityConfiguration.environmentConfigurationList,user.id).filter(x=>x.prefered4Simulation === true)
                if(environmentPreferedSim && environmentPreferedSim.length>0){
                    setSelectedEnvironment(environmentPreferedSim[0].environmentName)
                }
                else{
                    setSelectedEnvironment(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList,user.id)[0] ? 
                    configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList,user.id)[0].environmentName: undefined)
                }
        }
        
        
    }, [qualityConfiguration]);

    React.useEffect(() => {
        setModifiedProject(helper.convertProjectToNodes(filterProject(project)))
        
    }, [project]);





    function restartRule(ruleId_){
        setRuleId(ruleId_)
        setRestartRuleDialogVisible(true)
    }
    
    

    function filterProject(project){

        if(props.searchText && props.searchText!==""){
            return projectHelper.searchProject(props.searchText,project)
        }
        else{
            return project
        }
    }

    const handleClick = (event) => {
        const {item, itemType,qualitypointId,datasourceId}= helper.obtainEditableItem(event.value, filterProject(props.project))
        setItemType(itemType)
        setSelectedItem(item)
        event.originalEvent.preventDefault();
        setState({
        mouseX: event.originalEvent.clientX - 2,
        mouseY: event.originalEvent.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
      };

    const handleEditAction = () =>{
        
        trackEvent('DetailProjectTable context menu','Edit');
        props.setSelectedItem(selectedItem,itemType)
        
        setState(initialState);
        

    }

    const handleDeleteAction = () =>{

        trackEvent('DetailProjectTable context menu','Delete');
        props.deleteItem(selectedItem,itemType)
       
        setState(initialState);

    }

    const handleRuleRecommender = () =>{

        trackEvent('DetailProjectTable context menu','Rule Recommender');
        props.handleRuleRecommender(selectedItem,itemType)
       
        setState(initialState);

    }

    const handleAddDatasourceAction = () =>{
        trackEvent('DetailProjectTable context menu','Add Datasource');
        if(itemType==="Qualitypoint"){
            props.addDatasource(selectedItem,itemType)
        }
        
        setState(initialState);

    }


    const handleAddFolderAction = () =>{
        trackEvent('DetailProjectTable context menu','Add Folder');
        props.addFolder(selectedItem,itemType)
        setState(initialState);

    }
    const handleAddRuleAction = () =>{
        trackEvent('DetailProjectTable context menu','Add Rule');
        props.addRule(selectedItem,itemType)
        setState(initialState);

}
    

    function getContextMenuQualityPoint(){
        return(
            <div>
                <StyledMenu
                        keepMounted
                        open={state.mouseY !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                        }
                    >
                         <StyledMenuItem onClick={handleAddDatasourceAction}>
                            <ListItemIcon>
                                 <FontAwesomeIcon icon={faDatabase} style={{fontSize:14,marginLeft:'15%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Add Datasource" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={handleEditAction}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" style={{marginLeft:'10%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Edit QualityPoint" />
                        </StyledMenuItem>
                       
                        <StyledMenuItem onClick={handleDeleteAction}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" style={{marginLeft:'10%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Delete QualityPoint" />
                        </StyledMenuItem>

                        {process.env.REACT_APP_RULE_RECOMMENDER_ALLOWED === 'true' && <StyledMenuItem onClick={handleRuleRecommender}>
                            <ListItemIcon>
                                 <FontAwesomeIcon icon={faRecordVinyl} style={{fontSize:14,marginLeft:'15%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Rule Recommender" />
                        </StyledMenuItem>}
                        
                    </StyledMenu>
            </div>
        )
    }

    function getContextMenuDatasource(){
        return(
            <div>
                <StyledMenu
                        keepMounted
                        open={state.mouseY !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                        }
                    >
                         <StyledMenuItem onClick={handleAddRuleAction}>
                            <ListItemIcon>
                                 <FontAwesomeIcon icon={faFileAlt} style={{fontSize:14,marginLeft:'15%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Add Rule" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={handleAddFolderAction}>
                            <ListItemIcon>
                                 <FontAwesomeIcon icon={faFolderOpen} style={{fontSize:14,marginLeft:'15%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Add Folder" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={handleEditAction}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" style={{marginLeft:'10%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Edit Datasource" />
                        </StyledMenuItem>
                       
                        <StyledMenuItem onClick={handleDeleteAction}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" style={{marginLeft:'10%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Delete Datasource" />
                        </StyledMenuItem>
                        
                    </StyledMenu>
            </div>
        )
    }

    function getContextMenuFolder(){
        return(
            <div>
                <StyledMenu
                        keepMounted
                        open={state.mouseY !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                        }
                    >
                         <StyledMenuItem onClick={handleAddRuleAction}>
                            <ListItemIcon>
                                 <FontAwesomeIcon icon={faFileAlt} style={{fontSize:14,marginLeft:'15%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Add Rule" />
                        </StyledMenuItem>

                        <StyledMenuItem onClick={handleAddFolderAction}>
                            <ListItemIcon>
                                 <FontAwesomeIcon icon={faFolderOpen} style={{fontSize:14,marginLeft:'15%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Add Folder" />
                        </StyledMenuItem>
                        
                        <StyledMenuItem onClick={handleEditAction}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" style={{marginLeft:'10%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Edit Folder" />
                        </StyledMenuItem>
                       
                        <StyledMenuItem onClick={handleDeleteAction}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" style={{marginLeft:'10%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Delete Folder" />
                        </StyledMenuItem>
                        
                    </StyledMenu>
            </div>
        )
    }


    function getContextMenuRule(){
        return(
            <div>
                <StyledMenu
                        keepMounted
                        open={state.mouseY !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                        }
                    >
                         <StyledMenuItem onClick={handleEditAction}>
                            <ListItemIcon>
                            <EditIcon fontSize="small" style={{marginLeft:'10%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Edit Rule" />
                        </StyledMenuItem>
                       
                        <StyledMenuItem onClick={handleDeleteAction}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" style={{marginLeft:'10%'}} />
                            </ListItemIcon>
                            <ListItemText primary="Delete Rule" />
                        </StyledMenuItem>
                        
                    </StyledMenu>
            </div>
        )
    }

    return (
        <div style={{width:'100%', zIndex:3}}>
            {(itemType==="Qualitypoint") && getContextMenuQualityPoint()}
            {(itemType==="Datasource") && getContextMenuDatasource()}
            {(itemType==="Folder") && getContextMenuFolder()}
            {(itemType==="Rule") && getContextMenuRule()}

            <AqtivaDialog visible={restartRuleDialogVisible}
            fullWidth={true}
            maxWidth={'sm'}
            title={'Clean rule KPIs'}
            editable={false}
            titleIcon={faDatabase}
            confirmText={'Clean'}
            cancelText={'Cancel'}
            confirmCallback={event=>{dispatch(actions.resetDvdKpiRule(project._id, ruleId, selectedEnvironment));
                                        setRestartRuleDialogVisible(false)}}
            cancelCallback={event => {
                setRestartRuleDialogVisible(false)
            }}
        >
            <div>
            <Typography variant='subtitle2'>Select an environment</Typography>
                                    <Select
                                                value={selectedEnvironment}
                                                onChange={event => setSelectedEnvironment(event.target.value)}
                                                inputProps={{
                                                    name: 'Environment',
                                                    id: 'Environment',
                                                }}
                                                style={{width:'55%', marginRight:'20px'}}
                                            >
                                            {qualityConfiguration && qualityConfiguration.environmentConfigurationList &&
                                            configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList,user.id).map(element=>{
                                                return <MenuItem key = {element.environmentName} value={element.environmentName}>{element.environmentName}</MenuItem> 
                                            })}

                                    </Select>
             </div>

            </AqtivaDialog>
           <TreeTable
                    value={modifiedProject}
                    
                    //contextMenuSelectionKey={this.state.selectedNodeKey}
                    onContextMenuSelectionChange={event => {
                        handleClick(event)}
                    }
                    >
                        {process.env.REACT_APP_LINKS_TO_DEPLOY_ALLOWED==='true'}
                        <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: '12px',
                        textAlign: 'right',
                        width: '2%',
                    }}
                        field="none" header=" "  body={data=>renderHelper.go2DeployTemplate(data,props.qualityConfiguration,user.id,project, props.selectedEnvironment)}></Column>
                    
                    <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'right',
                        width: '2%',
                    }}
                        field="more" header=" "  body={data=>renderHelper.simulateCellTemplate(data,props.onClickOpenDialogSimulation)}></Column>
                    <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'left',
                        width: '5%',

                    }}
                        field="type" header="Type" body={data => 
                        <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.typeTemplate(data)}
                        </div>
                        }>
                    
                    </Column>
                    <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'left',
                        width:'10%'
                    }}
                        field="enabled" header="Enabled" body={data => renderHelper.enableTemplate(data,filterProject(project), props.editQualityPipe, props.editDatasource, props.editRule)}></Column>
                    <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'left',
                        width: '25%'
                    }}
                        field="name" header="Name" expander body={data=>DragDropTemplate(NameCellTemplate(data,filterProject(project),props.setSelectedItem,restartRule), data, modifiedProject, project)}></Column>
                    <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'center',
                        width:'8%'
                    }}
                        field="conditional" header="Conditional" body={data => renderHelper.conditionalTemplate(data,theme)}></Column>

                     <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'left',
                        width:'15%'
                    }}
                        field="thError" header="Error Th." body={data => 
                            <div style={theme.common.text.tableProjectCellText}>
                                { renderHelper.thErrorTemplate(data)}
                            </div>
                       }></Column>
                     <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'left',
                        width:'10%'
                    }}
                        field="thWarning" header="Warning Th." body={data => 
                            <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.thWarningTemplate(data)}
                        </div>
                        }></Column>
                    <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'center',
                        width:'10%'
                    }}
                        field="controlModeType" header="Output Control" 
                        body={data => 
                            <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.outputControllCellTemplate(data)}
                        </div>
                        }
                        ></Column>
                    <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'center',
                        width:'10%'
                        
                    }}
                        field="wrongRegisterActionType" header="Bad Regs Action"
                        body={data => 
                            <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.wrongActionCellTemplate(data)}
                        </div>
                        }
                        >
                        </Column>
                    <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'left',
                        width:'10%'
                        
                    }}
                        field="dimension" header="Dimension"
                        body={data => 
                            <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.dimensionCellTemplate(data)}
                        </div>
                        }
                        >
                        </Column>

                    <Column style={{
                        background: 'transparent',
                        borderColor: 'transparent',
                        fontSize: theme.common.text.tableProjectText.fontSize,
                        textAlign: 'left',
                        width:'10%'
                    }}
                        field="ruleSpecificType" header="Rule Type" 
                        body={data => 
                            <div style={theme.common.text.tableProjectCellText}>
                            {renderHelper.ruleTypeTemplate(data)}
                        </div>
                        }
                        >
                        </Column>
                       
                </TreeTable>
        </div>

    );
}