
export const ruleTypesBatch = [
    { label: 'Schema Validation', value: 'statistics' },
    { label: 'Null Detection', value: 'null' },
    { label: 'Regular Expression', value: 'exp' },
    { label: 'Value Ranges', value: 'ranges' },
    { label: 'Timeliness', value: 'time' },
    { label: 'Duplicated Check', value: 'duplicated' },
    { label: 'Check Data between loads', value: 'checkBetweenLoads' },
    { label: 'Single Source of Truth', value: 'masterData' },
    { label: 'Ad-Hoc Expression', value: 'adHoc' }
 

];

export const ruleTypesLambda = [
    { label: 'Schema Validation', value: 'statistics' },
    { label: 'Null Detection', value: 'null' },
    { label: 'Regular Expression', value: 'exp' },
    { label: 'Value Ranges', value: 'ranges' },
    { label: 'Timeliness', value: 'time' },
    { label: 'Ad-Hoc Expression', value: 'adHoc' }

];