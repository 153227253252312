import React from "react";
import AqtivaDialog from "../../../../common/AqtivaDialog";

export function AqtivaDialogDataRepository({
  dialog,
  setDialog,
  title,
  titleIcon,
  confirmText,
  children,
  confirmCallback,
  showConfirmButton,
}) {
  return (
    <AqtivaDialog
      visible={dialog}
      maxWidth={"lg"}
      title={title}
      titleIcon={titleIcon}
      showConfirmButton={showConfirmButton}
      confirmText={confirmText}
      cancelText={"Close"}
      confirmCallback={() => {
        confirmCallback();
      }}
      cancelCallback={(event) => setDialog(false)}
    >
      {children}
    </AqtivaDialog>
  );
}
