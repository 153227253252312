import React from "react";
import {
  Grid,
} from "@material-ui/core";
import AppBarTemplate from "../../template/AppBarTemplate";
import * as pathHelper from "../../template/PathHelper";
import * as actions from "../../../actions";
import FilterMenuTotalEventRules from "./FilterMenuTotalEventRules";
import ChartTotalEventRules from "./ChartTotalEventRules";
import CardTotalEventRules from "./CardTotalEventRules";
import TableTotalEventRules from "./TableTotalEventRules"
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";

export default function QualityConsumption(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector((store) => store.user);
  const license = useSelector((store) => store.license);
  const projects = useSelector((store) => Object.values(store.projects));
  const dataTotalRecords = useSelector((store) =>store.totalRecords);
  const tableTotalEventRulesData = useSelector((store) =>store.totalRecordsTable);

  const [projectsLicense, setProjectsLicense] = React.useState([]);

  //Pasamos por props la info de dataTotalRecords a los componentes "hijos": el filter, la card y el chart


  React.useEffect(() => {
    if (user && user.id) {
      dispatch(actions.fetchProjects(user.id));
      if (license && license._id) {
        getLicenseProjects().then((response) => setProjectsLicense(response));
      } else {
        dispatch(actions.fetchLicense(user.id));
      }
    }
  }, [user, license._id]);

  React.useEffect(() => {
    dispatch(actions.fetchUser());
  }, []);

  async function getLicenseProjects() {
    var projects = [];
    if (license && license._id && license.users && license.users.length > 0) {
      for (var i = 0; i < license.users.length; i++) {
        let licenseUser = license.users[i];
        if (user.id !== licenseUser.id) {
          var userProjects = await dispatch(
            actions.fetchProjectsAndReturn(licenseUser.id)
          );
          projects = projects.concat(userProjects);
        }
      }
    }
    return projects;
  }

  return (
    <>
      <AppBarTemplate
        history={props.history}
        title={"Consumption"}
        arrayPath={pathHelper.getPathArray("Consumption")}
      />

      <FilterMenuTotalEventRules
        projects={projects}
        projectsLicense={projectsLicense}
      >
        <div style={theme.QualityKPIS.container}>
          <Grid container justify="center" spacing={1}>
            <Grid item xs={2}>
              <CardTotalEventRules dataTotalRecords={dataTotalRecords}/>
            </Grid>
            <Grid item xs={9}>
              <ChartTotalEventRules dataTotalRecords={dataTotalRecords} />
            </Grid>
          </Grid>
        </div>
        <TableTotalEventRules tableTotalEventRulesData={tableTotalEventRulesData}/>
      </FilterMenuTotalEventRules>
    </>
  );
}
