import React, { useState } from "react";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { AqtivaDialogDataRepository } from "../../AqtivaDialogDataRepository";
import { useTheme } from "@material-ui/core/styles";

import {
  TextField,
  NativeSelect,
  FormControl,
  InputLabel,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";

export function OptionNew({
  showDialog,
  setShowDialog,
  selectedItem,
  type,
  parent,
  dtoId,
  actions,
}) {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const theme = useTheme();

  const [folderName, setFolderName] = useState("");
  const [visibility, setVisibility] = useState("Public");
  /* const [chosenType, setChosenType] = useState("Private");
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
  }); */
  const [typeValue, setTypeValue] = React.useState("private");
  const handleChangeType = (event) => {
    setTypeValue(event.target.value);
  };

  return (
    <>
      <AqtivaDialogDataRepository
        dialog={showDialog}
        setDialog={setShowDialog}
        title={
          parent === true
            ? "Create new shared parent folder"
            : "Create new folder"
        }
        titleIcon={faFolderPlus}
        confirmCallback={() => {
          setShowDialog(false);
          //console.log("confirmCallback", typeValue, folderName, dtoId, selectedItem);
          if (parent === true) {
            dispatch(
              actions.addRootFolderToRepository(
                typeValue,
                folderName,
                user.id,
                visibility
              )
            ).then((response) => {
              setFolderName("");
              setVisibility("Public");
            });
          } else {
            dispatch(
              actions.addFolderToRepository(
                type,
                folderName,
                dtoId(),
                selectedItem.itemId,
                visibility
              )
            ).then((response) => {
              setFolderName("");
              setVisibility("Public");
            });
          }
        }}
      >
        <div>
          <TextField
            stlye={{ width: "200px" }}
            id="standard-basic"
            label="Folder Name"
            value={folderName}
            onChange={(event) => setFolderName(event.target.value)}
          />
          <FormControl style={{ width: "100%", marginTop: "10px" }}>
            <InputLabel htmlFor="visibility-selection-helper">
              Visibility Control
            </InputLabel>
            <NativeSelect
              style={{
                width: "180px",
                fontSize: 14,
              }}
              value={visibility}
              onChange={(event) => setVisibility(event.target.value)}
            >
              <option key={"0"} value={"Public"}>
                Public
              </option>
              <option key={"1"} value={"Private"}>
                Private
              </option>
            </NativeSelect>
          </FormControl>

          {parent && (
            <div>
              {/*  <Typography style={{marginTop:"20px"}}>Choose a workspace to create your root folder</Typography>
              <Button
                variant="contained"
                color="primary"
                style={{
                  ...theme.common.text.buttonTextSmall, marginRight:"50px", marginTop:"10px"
                }}
                onChange={(event) => setChosenType(event.target.value)}
              >
                Private workspace
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  ...theme.common.text.buttonTextSmall, marginTop:"10px"
                }}
                onChange={(event) => setChosenType(event.target.value)}
              >
                Shared workspace
              </Button> */}

              <Typography style={{ marginTop: "20px" }}>
                Choose a workspace to create your root folder
              </Typography>
              <RadioGroup
                aria-label="type"
                name="type1"
                value={typeValue}
                onChange={handleChangeType}
                //onChange={(event) => setChosenType(event.target.value)}
                style={{
                  ...theme.common.text.regularText,
                }}
              >
                <FormControlLabel
                  value="shared"
                  control={
                    <Radio
                      color="primary"
                      style={{
                        ...theme.common.text.regularText,
                      }}
                    />
                  }
                  label="Shared Workspace"
                  style={{
                    ...theme.common.text.regularText,
                  }}
                />
                <FormControlLabel
                  value="private"
                  control={
                    <Radio
                      color="primary"
                      style={{
                        ...theme.common.text.regularText,
                      }}
                    />
                  }
                  label="Private Workspace"
                  style={{
                    ...theme.common.text.regularText,
                  }}
                />
              </RadioGroup>
            </div>
          )}
        </div>
      </AqtivaDialogDataRepository>
    </>
  );
}
