import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { Grid, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel,InputLabel,Input,Chip } from '@material-ui/core'
import Button from '@material-ui/core/Button';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'


import FormControl from '@material-ui/core/FormControl';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import DialogCreateNewEnvironment from './DialogCreateNewEnvironment'
import BackProcessingConfiguration from './BackProcessingConfiguration'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import { useTheme } from '@material-ui/core/styles';

import ConfirmDialog from './../../../common/ConfirmDialog'
import Fab from '@material-ui/core/Fab';
import DialogInfoEnvironmentPreparation from './DialogInfoEnvironmentPreparation'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ConnectorSettings(props) {

    const [selectedEnvironment, setSelectedEnvironment] = useState('')
    const [environmentConfiguration, setEnvironmentConfiguration] = useState({})
    const [showNewEnvironmentDialogFlag, setShoNewEnvironmentDialogFlag] = useState(false)

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)
    const [visibleInfoEnvironment, setVisibleInfoEnvironment]= useState(false)
    var license = useSelector(store => store.license)

    const [selectedUsers, setSelectedUsers] = React.useState([])
    const [selectedGroups, setSelectedGroups] = React.useState([])



    const dispatch = useDispatch()
    const theme = useTheme();
    

    const qualityConfiguration = useSelector(store => store.qualityConfiguration)


    React.useEffect(() => {
        //set selectedUsers and groups based on their id

        if(environmentConfiguration && license){
            const allowedUsers = environmentConfiguration.allowedUsers
            if(allowedUsers && allowedUsers.length >0){
            const filterUsers = license.users.filter(x=> allowedUsers.map(x=>x.id).includes(x.id))
            if(filterUsers && filterUsers.length>0){
                setSelectedUsers(filterUsers)
            }
        }
           
           
            
        }


    }, [environmentConfiguration, license]);


    React.useEffect(() => {
        if (props.currentUser && props.currentUser.id) {
            dispatch(actions.fetchConfigurationByUserId(props.currentUser.id))
        }
    }, [props.currentUser]);

    React.useEffect(() => {
        if (qualityConfiguration && qualityConfiguration.environmentConfigurationList && !selectedEnvironment) {
            setSelectedEnvironment(qualityConfiguration.environmentConfigurationList[0].environmentName)
            setEnvironmentConfiguration(qualityConfiguration.environmentConfigurationList[0])
        }
    }, [qualityConfiguration]);




    function addEnvironment2Configuration(environmentName) {

        var config = qualityConfiguration
        if (!config.environmentConfigurationList) {
            config.environmentConfigurationList = []
        }
        const newEnvironment = {
            environmentName: environmentName,
            mongoDBCheck: true,
            mongoDBendPoint: process.env.REACT_APP_CONNECTOR_BASE_MONGO_ENDPOINT,
            mongoDBdatabase: process.env.REACT_APP_CONNECTOR_BASE_MONGO_DATABASE,
            mongoDBcollection: process.env.REACT_APP_CONNECTOR_BASE_MONGO_COLLECTION_LOG,
            mongoDBCheckKPI: true,
            mongoDBendPointKPI: process.env.REACT_APP_CONNECTOR_BASE_MONGO_ENDPOINT,
            mongoDBdatabaseKPI: process.env.REACT_APP_CONNECTOR_BASE_MONGO_DATABASE,
            mongoDBcollectionKPI: process.env.REACT_APP_CONNECTOR_BASE_MONGO_COLLECTION_KPI,
            accessType: "public_access",
            resourceGroupName : environmentConfiguration.resourceGroupName,
            regionName: environmentConfiguration.regionName,
            storageAccountName: environmentConfiguration.storageAccountName,
            storageKey: environmentConfiguration.storageKey,
            containerName: environmentConfiguration.containerName,
            databricksInstance : environmentConfiguration.databricksInstance,
            databricksClusterId: environmentConfiguration.databricksClusterId,
            databricksSimulationNotebookPath: environmentConfiguration.databricksSimulationNotebookPath,
            databricksRuleRecommenderNotebookPath: environmentConfiguration.databricksRuleRecommenderNotebookPath,
            databricksToken : environmentConfiguration.databricksToken,
            databricksDeploymentPath: environmentConfiguration.databricksDeploymentPath
        }
        config.environmentConfigurationList.push(newEnvironment)
        setShoNewEnvironmentDialogFlag(false)

        onClickSave(config);
    }
    function onClickDeleteEnvironment(environmentName) {


        var config = qualityConfiguration
        if (!config.environmentConfigurationList) {
            return
        }
        var newEnvironments = config.environmentConfigurationList.filter(x => x.environmentName !== environmentName)

        config.environmentConfigurationList = newEnvironments
        setShoNewEnvironmentDialogFlag(false)
        onClickSave(config);

    }





    /*Handlers new Environment*/

    function onClickNewEnvironmentDialogButton() {
        setShoNewEnvironmentDialogFlag(true)
    }

    function onAddNewEnvironment(environmentName) {
        addEnvironment2Configuration(environmentName)
    }
    function onClickCloseNewEnvironmentDialog() {
        setShoNewEnvironmentDialogFlag(false)
    }

    /*****/

    function handleChange(name, event) {
        var envConfig = Object.assign({}, environmentConfiguration);
        envConfig[name] = event.target.checked
        setEnvironmentConfiguration(envConfig)

    };

    function handleTextFieldChange(name, event) {
        var envConfig = Object.assign({}, environmentConfiguration);
        envConfig[name] = event.target.value
        setEnvironmentConfiguration(envConfig)
    };

    function handleMultipleSelect(name, event) {
        var values =event.target.value
        if(values && values.length>0){
            values = values.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
        }


        var envConfig = Object.assign({}, environmentConfiguration);
        envConfig[name] = values
        setEnvironmentConfiguration(envConfig)
    };

    function handleMultipleSelectUsers(event) {
        var values =event.target.value
        setSelectedUsers(values)
        handleMultipleSelect('allowedUsers',event)
    };

    function onClickSave(config_) {

        var config = config_
        if (!config) {
            config = Object.assign({}, qualityConfiguration)
        }

        /* Añado el environment creado a la configuracion*/
        const envIndex = config.environmentConfigurationList.findIndex(e => e.environmentName === selectedEnvironment)
        var configuration = qualityConfiguration
        configuration.environmentConfigurationList[envIndex] = environmentConfiguration

        dispatch(actions.addConfiguration(configuration, props.currentUser))


    }

    function getEnvironmentConfigurationFromName(name) {
        const env = qualityConfiguration.environmentConfigurationList.find(val => val.environmentName === name)
        return env;
    }

    function setBackProcessingConfig(data) {
        var config = environmentConfiguration
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
               config[key] = data[key] 
            }
        }
        
       
        setEnvironmentConfiguration(config)

    }

    function deleteEnvironment() {

        onClickDeleteEnvironment(selectedEnvironment)
        setShowConfirmDeleteDialog(false)
    }

    function cancelConfirmDelete() {
        setShowConfirmDeleteDialog(false)
    }





    return (
        <div style="dashboard ui segment" style={{ minHeight: '600px' }}>

            <DialogInfoEnvironmentPreparation
            visible={visibleInfoEnvironment}
            onClose={event => setVisibleInfoEnvironment(false)} />
            <ConfirmDialog
                open={showConfirmDeleteDialog}
                confirmAction={deleteEnvironment}
                cancelAction={cancelConfirmDelete}
                onHide={cancelConfirmDelete}
                title={`Confirm Delete Environment ${selectedEnvironment}`} />

            <DialogCreateNewEnvironment open={showNewEnvironmentDialogFlag}
                onSubmit={onAddNewEnvironment}
                onHide={onClickCloseNewEnvironmentDialog} />
            <div>
                < Grid container spacing={1} style={{ marginBottom: '1%' }} alignItems="flex-end">
                    <Grid item xs={2}>
                        <Button onClick={onClickNewEnvironmentDialogButton} variant="outlined" color="primary">
                            Create New Environment
                                </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={event => setShowConfirmDeleteDialog(true)} variant="outlined" color="secondary">
                            Delete Environment
                                </Button>
                    </Grid>
                    <Grid item xs={2}>
                    <Fab size="small" color="primary" aria-label="Close"
                    onClick={event=>setVisibleInfoEnvironment(true)}>
                        <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 30 }} />
                    </Fab>
                    <Typography variant='caption' style={{marginLeft:'10px'}}>Environment preparation info</Typography>
                    </Grid>
                </Grid>

                <Typography variant='subtitle2'>Select an environment</Typography>
                <Select
                    value={selectedEnvironment}
                    onChange={(e) => {
                        setSelectedEnvironment(e.target.value);
                        setEnvironmentConfiguration(getEnvironmentConfigurationFromName(e.target.value))
                    }}
                    inputProps={{
                        name: 'Environment',
                        id: 'Environment',
                    }}
                    style={{ width: '200px', marginRight: '20px' }}
                >
                    {qualityConfiguration && qualityConfiguration.environmentConfigurationList
                        && qualityConfiguration.environmentConfigurationList.map(element => {
                            return <MenuItem value={element.environmentName}>{element.environmentName}</MenuItem>
                        })}

                </Select>

            </div>
            <div style={{ marginTop: '20px' }}>
                <Typography variant="subtitle2">{`Environment ${environmentConfiguration.environmentName} Configuration`}</Typography>

                <ExpansionPanel
                    style={{ width: '100%' }}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid item xs={1}>
                            <FontAwesomeIcon icon={faCog} style={{ color: '#76b3d6', fontSize: 20 }} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2'>Prefered Configuration</Typography>
                        </Grid>

                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>



                        < Grid container spacing={1} >
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={environmentConfiguration.prefered4Simulation ? environmentConfiguration.prefered4Simulation : false}
                                            onChange={event => handleChange('prefered4Simulation', event)}
                                            value={environmentConfiguration.prefered4Simulation}
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant="caption">Prefered Simulation Environment</Typography>}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={environmentConfiguration.prefered4Running ? environmentConfiguration.prefered4Running : false}
                                            onChange={event => handleChange('prefered4Running', event)}
                                            value={environmentConfiguration.prefered4Running}
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant="caption">Prefered Running Environment</Typography>}
                                />
                            </Grid>

                        </ Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

            </div>

            <div>
                <ExpansionPanel
                    style={{ width: '100%' }}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid item xs={1}>
                            <FontAwesomeIcon icon={faCog} style={{ color: '#76b3d6', fontSize: 20 }} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2'>Access Policy Configuration</Typography>
                        </Grid>

                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                    < Grid container spacing={1} >
                            <Grid item xs={2}>
                            <InputLabel id="demo-mutiple-chip-label">Select Access Policy</InputLabel>
                            <Select
                                native
                                value={environmentConfiguration.accessType}
                                onChange={event =>handleTextFieldChange('accessType', event)}
                                inputProps={{
                                    name: 'age',
                                    id: 'age-native-simple',
                                }}
                                >
                                <option value={'public_access'}>Public</option>
                                <option value={'protected_access'}>Protected</option>
                                </Select>
                            </Grid>
                            {environmentConfiguration.accessType==='protected_access' &&
                            <Grid item xs={4}> 
                            <InputLabel id="demo-mutiple-chip-label">Allowed Users</InputLabel>
                            <Select
                              labelId="demo-mutiple-chip-label"
                              id="demo-mutiple-chip"
                              multiple
                              style={{ width: '100%' }}
                              value={selectedUsers}
                              onChange={event =>handleMultipleSelectUsers(event)}
                              input={<Input id="select-multiple-chip" />}
                              renderValue={(selected) => (
                                <div >
                                  {selected.map((value) => (
                                    <Chip key={value.id} label={value.name} />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              {license.users.map((value) => (
                                <MenuItem key={value.id} value={value} >
                                  {value.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                            }
                            
                    </ Grid>
                    

                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>

            <div>
                <ExpansionPanel
                    style={{ width: '100%' }}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid item xs={1}>
                            <FontAwesomeIcon icon={faCog} style={{ color: '#76b3d6', fontSize: 20 }} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2'>Log Configuration</Typography>
                        </Grid>

                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <div >
                                <FormControlLabel style={theme.config.formControl}
                                    control={
                                        <Switch
                                            checked={environmentConfiguration.kafkaCheck ? environmentConfiguration.kafkaCheck : false}
                                            onChange={event => handleChange('kafkaCheck', event)}
                                            value={environmentConfiguration.kafkaCheck}
                                            color="primary"
                                        />
                                    }
                                    label="Kafka"
                                />


                                <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.kafkaCheck}
                                    control={
                                        <TextField
                                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                            id="kafkaBrokers"
                                            label="Brokers"
                                            style={theme.config.textField}
                                            margin="dense"
                                            value={environmentConfiguration.kafkaBrokers}
                                            onChange={(event) => {
                                                handleTextFieldChange('kafkaBrokers', event)
                                            }}
                                        />} />
                                <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.kafkaCheck}
                                    control={
                                        <TextField
                                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                            id="kafkaTopic"
                                            label="Quality Topic"
                                            style={theme.config.textField}
                                            margin="dense"
                                            value={environmentConfiguration.kafkaTopic}
                                            onChange={(event) => handleTextFieldChange('kafkaBrokers', event)}
                                        />} />
                                <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.kafkaCheck}
                                    control={
                                        <TextField
                                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                            id="kafkaSchemaRegistry"
                                            label="Schema Registry URL"
                                            style={theme.config.textField}
                                            margin="dense"
                                            value={environmentConfiguration.kafkaSchemaRegistry}
                                            onChange={(event) => handleTextFieldChange('kafkaSchemaRegistry', event)}
                                        />} />
                                <FormControl style={theme.config.formControl} disabled={!environmentConfiguration.kafkaCheck}
                                >
                                    <Typography variant='caption' htmlFor="kafkaFormat">Format</Typography>
                                    <Select
                                        style={theme.config.formControlSelect}
                                        value={environmentConfiguration.kafkaFormat}
                                        onChange={(event) => handleTextFieldChange('kafkaFormat', event)}
                                        inputProps={{
                                            name: 'Message Format',
                                            id: 'kafka_message_format',
                                        }}
                                    >
                                        <MenuItem value={'json'}>json</MenuItem>
                                        <MenuItem value={'avro'}>avro</MenuItem>

                                    </Select>
                                </FormControl>
                                <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.kafkaCheck}
                                    control={
                                        <TextField
                                            InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                            id="authSaslJaasConfig"
                                            label="jaas File"
                                            style={theme.config.textField}
                                            margin="dense"
                                            value={environmentConfiguration.authSaslJaasConfig}
                                            onChange={(event) => handleTextFieldChange('authSaslJaasConfig', event)}
                                        />} />
                            </div>
                            <div>
                                <div >
                                    <FormControlLabel style={theme.config.formControl}
                                        control={
                                            <Switch
                                                checked={environmentConfiguration.eventHubCheck ? environmentConfiguration.eventHubCheck : false}
                                                onChange={event => handleChange('eventHubCheck', event)}
                                                value={environmentConfiguration.eventHubCheck}
                                                color="primary"
                                            />
                                        }
                                        label="Event Hub"
                                    />
                                    <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.eventHubCheck}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="eventHubBrokers"
                                                label="Brokers"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.eventHubBrokers}
                                                onChange={(event) => handleTextFieldChange('eventHubBrokers', event)}
                                            />} />
                                    <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.eventHubCheck}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="eventHubTopic"
                                                label="Quality Topic"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.eventHubTopic}
                                                onChange={(event) => handleTextFieldChange('eventHubTopic', event)}
                                            />} />
                                    <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.eventHubCheck}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="eventHubAuthSaslMechanism"
                                                label="SASL Mechanism"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.eventHubAuthSaslMechanism}
                                                onChange={(event) => handleTextFieldChange('eventHubAuthSaslMechanism', event)}
                                            />} />
                                    <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.eventHubCheck}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="eventHubAuthSecurityProtocol"
                                                label="Security Protocol"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.eventHubAuthSecurityProtocol}
                                                onChange={(event) => handleTextFieldChange('eventHubAuthSecurityProtocol', event)}
                                            />} />
                                    <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.eventHubCheck}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="eventHubSaslJaasConfig"
                                                label="jaas File"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.eventHubSaslJaasConfig}
                                                onChange={(event) => handleTextFieldChange('eventHubSaslJaasConfig', event)}
                                            />} />
                                </div>
                            </div>
                            <div>
                                <div >
                                    <FormControlLabel style={theme.config.formControl}
                                        control={
                                            <Switch
                                                checked={environmentConfiguration.cosmoDBCheck ? environmentConfiguration.cosmoDBCheck : false}
                                                onChange={event => handleChange('cosmoDBCheck', event)}
                                                value={environmentConfiguration.cosmoDBCheck}
                                                color="primary"
                                            />
                                        }
                                        label="Cosmo DB"
                                    />
                                    <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.cosmoDBCheck}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="cosmoDBendPoint"
                                                label="EndPoint"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.cosmoDBendPoint}
                                                onChange={(event) => handleTextFieldChange('cosmoDBendPoint', event)}
                                            />} />
                                    <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.cosmoDBCheck}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="cosmoDBdatabase"
                                                label="Database"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.cosmoDBdatabase}
                                                onChange={(event) => handleTextFieldChange('cosmoDBdatabase', event)}
                                            />} />
                                    <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.cosmoDBCheck}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="cosmoDBcollection"
                                                label="Collection"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.cosmoDBcollection}
                                                onChange={(event) => handleTextFieldChange('cosmoDBcollection', event)}
                                            />} />

                                    <FormControlLabel style={theme.config.formControl} disabled={!environmentConfiguration.cosmoDBCheck}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="cosmoDBmasterKey"
                                                label="Master Key"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.cosmoDBmasterKey}
                                                onChange={(event) => handleTextFieldChange('cosmoDBmasterKey', event)}
                                            />} />
                                </div>
                            </div>
                            <div>
                                <div >
                                    <FormControlLabel style={theme.config.formControl}
                                        control={
                                            <Switch
                                                checked={environmentConfiguration.mongoDBCheck ? environmentConfiguration.mongoDBCheck : false}
                                                value={environmentConfiguration.mongoDBCheck}
                                                color="primary"
                                            />
                                        }
                                        label="Mongo DB"
                                    />
                                    <FormControlLabel style={theme.config.formControl} disabled={true}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="mongoDBendPoint"
                                                label="EndPoint"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.mongoDBendPoint}
                                                onChange={(event) => handleTextFieldChange('mongoDBendPoint', event)}
                                            />} />
                                    <FormControlLabel style={theme.config.formControl} disabled={true}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="mongoDBdatabase"
                                                label="Database"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.mongoDBdatabase}
                                                onChange={(event) => handleTextFieldChange('mongoDBdatabase', event)}
                                            />} />
                                    <FormControlLabel style={theme.config.formControl} disabled={true}
                                        control={
                                            <TextField
                                                InputLabelProps={{ shrink: true, style: { fontSize: '12px' } }}
                                                id="mongoDBcollection"
                                                label="Collection"
                                                style={theme.config.textField}
                                                margin="dense"
                                                value={environmentConfiguration.mongoDBcollection}
                                                onChange={(event) => handleTextFieldChange('mongoDBcollection', event)}
                                            />} />
                                </div>
                            </div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <div style={{ marginBottom: '10px' }}>
                    <BackProcessingConfiguration master ={false} configuration={environmentConfiguration}
                        parentConfiguration={qualityConfiguration} setConfig={setBackProcessingConfig} />
                </div>
            </div>


            <Button onClick={event => onClickSave(undefined)} variant="outlined" style={theme.buttons.buttonConfirm}>
                Save
          </Button>

        </div>

    );




}
