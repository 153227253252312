
import React from 'react'
import {TextField,Grid} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useTheme } from '@material-ui/core/styles';

export default function SearchButton(props) {
    const theme = useTheme()

    const [ searchText, setText] = React.useState()

    const onChange = (event) => {
        setText(event.target.value)
        props.updateText(event.target.value)
    }

    
    return(
           <div style={{display: 'flex',
           justifyContent: 'space-between', marginTop:'-10px'}}>
                    <SearchIcon style={{color:'#474b51',marginTop:'25px'}} />
                
                    <TextField  id="search_button_id" label="Search..." 
                        onChange={onChange}
                        value={searchText}
                    />
               </div>
           
    )

}