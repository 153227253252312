import React from "react";
import { useSelector/* , useDispatch */ } from "react-redux";
import AppBarTemplate from "../../template/AppBarTemplate";
import * as pathHelper from "../../template/PathHelper";
import FilterMenuKPIS from "./FilterMenuKPIS";
import KPICard from "./KPICard";
/* import * as actions from "../../../actions"; */
import {Button} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import trackEvent from './../../../trackEvent'
import {faUndo} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function QualityKpisDetail(props) {
/*   const dispatch = useDispatch(); */
  const theme = useTheme()

/*   const user = useSelector((store) => store.user);
  const license = useSelector((store) => store.license);  */
  const projects = useSelector((store) => Object.values(store.projects));
  const dataKPI = useSelector((store) => store.KPIS);
  const [projectsLicense, setProjectsLicense] = React.useState([]);
  const [showEmpty, setShowEmpty] = React.useState(props.match && props.match.params && props.match.params.aggregationLevel && props.match.params.entityIds ? true : false)
  const toggleShowEmpty = () => setShowEmpty(showEmpty => !showEmpty);

  return (
    <>
      <AppBarTemplate
        history={props.history}
        title={props.match.params.aggregationLevel}
        arrayPath={pathHelper.getPathArray("GovernanceDetail")}
      />
      <Button  onClick = {event => { props.history.goBack(); trackEvent('DetailProject', 'New Item Button')}}
                 style={theme.buttons.buttonRegular}
                startIcon={<FontAwesomeIcon icon={faUndo} style={{color:'#76b3d6', fontSize:14}} />} 
                 >
                   <div style={theme.common.text.buttonText}>
                   Back
                   </div>
                   
                        
                </Button>
      <FilterMenuKPIS projects={projects} projectsLicense={projectsLicense} toggleShowEmpty ={toggleShowEmpty} setShowEmpty = {setShowEmpty}
      urlParams = {props.match} history={props.history} up={true}>
        
        <KPICard dataKPI={dataKPI} showEmpty = {showEmpty} history={props.history}/>
      </FilterMenuKPIS >
    </>
  );
}
