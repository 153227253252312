export function convertProjectToNodes(project) {
    
    if (project === undefined || project.qualityPoints === undefined || project.qualityPoints === null) {
        return []
    }
    var i;
    var nodesTemp = [];
    for (i = 0; i < project.qualityPoints.length; i++) {
        nodesTemp[i] = extractQualityPoint(i, project.qualityPoints[i]);
    }
    return (nodesTemp);
}

export function getThFromObject(obj) {
    return (obj !== null && obj !== undefined) ? obj.action.substring(0, 8) + ' ' + obj.value + (obj.type === 'number' ? '' : '%') : ''
}

function extractRule(i,j,k,rule){
    var value = {
        key: i.toString() + '-' + j.toString()+ '-' + k.toString(),
        data: rule,
        index: k
    }
    value.data.nodeType='Rule'


    return value;
}

function extractRule4Folder(parentKey,k,rule){
    var value = {
        key: parentKey+ '-' + k.toString(),
        data: rule,
        
    }
    value.data.nodeType='Rule'

    return value;
}

function extractDatasource(i, j, datasource) {

    var rulesTemp = [];
    var folders = [];
    const folderLevel = 1
    if (datasource.rules === null || datasource.rules.length === 0) {
        rulesTemp = [];
    }
    const nFolders = datasource.folders ? datasource.folders.length : 0
    var rulesLevel1 = datasource.rules ? datasource.rules.filter(x=>(x.folder===null || x.folder ===undefined)) : []
    if (rulesLevel1) {
        var k =0;
        
        for (k = 0; k < rulesLevel1.length; k++) {
        rulesTemp[k] = extractRule(i,j,k+nFolders,rulesLevel1[k])
        }

    }
    if (datasource.folders) {

        var k = 0;
        const baseFolders = datasource.folders.filter(x=>x.level === folderLevel)
        for (k = 0; k < baseFolders.length; k++) {
                folders[k] = extractFolder(k, baseFolders[k], folderLevel, datasource.folders, i.toString() + '-' + j.toString(),datasource.rules)

        }

    }

    var value = {
        key: i.toString() + '-' + j.toString(),
        data: datasource,     
    }
    
    value.data.subtype= value.data.type
    value.data.type= "Datasource"
    if (datasource.folders && !(datasource.folders.length === 0)) {
        value.children = folders

    }
    if (datasource.rules && !(datasource.rules.length === 0)) {
        value.children=value.children ? value.children.concat(rulesTemp) : rulesTemp

    }
    return (value);
}
function extractFolder( folderIndex, folder, folderLevel, foldersBase, parentKey, rules) {
   
    var rules4Folder= []
    if(rules){
        rules4Folder = rules.filter(x=> ((x.folder !==null) && (x.folder !== undefined) && (x.folder.url === folder.url)))
    }
     
    var value = {
        key: parentKey + '-' + folderIndex,
        data: folder,
    }
    value.data.type='Folder'
    value.data.level = folderLevel
    //miramos iterativamente si hay mas folders
    var folders = [];
    var k = 0;
    const foldersLevel = foldersBase.filter(data => (data.level === (folderLevel + 1)) && data.parentName===folder.name)
    if (foldersLevel.length > 0) {
        for (k = 0; k < foldersLevel.length; k++) {
            if (foldersLevel[k].level === (folderLevel + 1)) {
                folders[k] = extractFolder(k, foldersLevel[k], folderLevel + 1,
                    foldersBase.filter(f => (f.parentName === folder.name) || (f.level > folderLevel + 1))
                    , parentKey + '-' + folderIndex,rules)
            }
        }
        value.children = folders

    }
    var rulesTemp = [];
    if (rules4Folder) {
        var k =0;
        
        for (k = 0; k < rules4Folder.length; k++) {
        rulesTemp[k] = extractRule4Folder(parentKey + '-' + folderIndex,k+foldersLevel.length,rules4Folder[k])
        }

    }
    
    if(value.children){
        value.children=value.children.concat(rulesTemp)
    }
    else{
        value.children =rulesTemp
    }
    

    return value

}

function extractQualityPoint(i, qualityPoint) {
    var datasourcesTemp = [];
    if (qualityPoint.datasources !== null) {
        var j;
        for (j = 0; j < qualityPoint.datasources.length; j++) {
            datasourcesTemp[j] = extractDatasource(i, j, qualityPoint.datasources[j])
        }
    }
    var value = {
        key: i.toString(),
        data: qualityPoint,
        
         
    }
    value.data.type= "Quality Point"
    if (!(qualityPoint.datasources === null) && !(qualityPoint.datasources === undefined) && !(qualityPoint.datasources.length === 0)) {
        value.children = datasourcesTemp
    }

    return value
}

export function obtainEditableItem(selectedNodeKey, project) {
    //Vamos a detecter por el numero de guiones el nivel del nodo seleccionado
    const levelNode = (selectedNodeKey.split("-").length);
    var itemType = '';
    var qualitypointId = '';
    var datasourceId = '';
    var item = null;
    if (levelNode === 1) { //QualityPoint
        itemType = 'Qualitypoint'
        const nodes = convertProjectToNodes(project)
        const node = nodes.find(e => e.key === selectedNodeKey.split("-")[0]);
        qualitypointId = node.data._id;
        item = findQualityPoint(project, qualitypointId);
    }
    if (levelNode === 2) {
        itemType = 'Datasource' //Datasource
        const nodes = convertProjectToNodes(project)
        const nodeParent = nodes.find(e => e.key === selectedNodeKey.split("-")[0]);
        const node = nodeParent.children.find(e => e.key === selectedNodeKey);
        qualitypointId = nodeParent.data._id;
        datasourceId = node.data._id;
        item = findDatasource(project, qualitypointId, datasourceId);

    }
    if (levelNode === 3) {

        const nodes = convertProjectToNodes(project)
        const nodeQualityPoint = nodes.find(e => e.key === selectedNodeKey.split("-")[0]);
        const nodeDatasource = nodeQualityPoint.children.find(e => e.key === (selectedNodeKey.split("-")[0] + "-" + selectedNodeKey.split("-")[1]));
        const nodeData = nodeDatasource.children.find(e => e.key === selectedNodeKey);
        itemType = nodeData.data.nodeType ? nodeData.data.nodeType : nodeData.data.type
        if (itemType === "Rule") {
            qualitypointId = nodeQualityPoint.data._id;
            datasourceId = nodeDatasource.data._id;
            const rule = nodeData.data
            item = findRule(project, qualitypointId, datasourceId, rule.name);
        }
        if (itemType === "Folder") {
            qualitypointId = nodeQualityPoint.data._id;
            datasourceId = nodeDatasource.data._id;
            const folder = nodeData.data
            item = findFolder(project, qualitypointId, datasourceId, folder.name);
        }

    }

    if (levelNode > 3) {

        const nodes = convertProjectToNodes(project)
        const nodeQualityPoint = nodes.find(e => e.key === selectedNodeKey.split("-")[0]);
        const nodeDatasource = nodeQualityPoint.children.find(e => e.key === (selectedNodeKey.split("-")[0] + "-" + selectedNodeKey.split("-")[1]));
        //const nodeData = nodeDatasource.children.find(e => e.key === selectedNodeKey);

        const levelFolder = selectedNodeKey.split("-").length - 2
            var k = 0
            var nodeDataBase = nodeDatasource.children[selectedNodeKey.split("-")[2]]
            for (k = 0; k < levelFolder - 1; k++) {
                nodeDataBase = nodeDataBase.children[selectedNodeKey.split("-")[3 + k]]
            }
            const nodeData = nodeDataBase

            itemType = nodeData.data.nodeType ? nodeData.data.nodeType : nodeData.data.type

        if (itemType === "Rule") {
            qualitypointId = nodeQualityPoint.data._id;
            datasourceId = nodeDatasource.data._id;
            const rule = nodeData.data
            item = findRule(project, qualitypointId, datasourceId, rule.name);
        }
        if (itemType === "Folder") {
            qualitypointId = nodeQualityPoint.data._id;
            datasourceId = nodeDatasource.data._id;
            const folder = nodeData.data
            item = findFolder(project, qualitypointId, datasourceId, folder.name);
        }

    }
    return { item, itemType, qualitypointId, datasourceId }


}

function findQualityPoint(project, id) {
    return project.qualityPoints.find(e => e._id === id);

}
function findDatasource(project, qualityPointId, datasourceId) {
    return project.qualityPoints.find(e => e._id === qualityPointId).datasources.find(e => e._id === datasourceId);

}
function findRule(project, qualityPointId, datasourceId, ruleName) {
    return project.qualityPoints.find(e => e._id === qualityPointId).datasources.find(e => e._id === datasourceId).rules.find(e => e.name === ruleName);

}
function findFolder(project, qualityPointId, datasourceId, folderName) {
    return project.qualityPoints.find(e => e._id === qualityPointId).datasources.find(e => e._id === datasourceId).folders.find(e => e.name === folderName);

}


export function handleChangeActive(event, node, project, editQualityPipe, editDatasource, editRule) {
    var checked = event.target.checked
    //var nodeTemp = node
    const selectedNodeKey = node.key

    var { item, itemType, qualitypointId, datasourceId } = obtainEditableItem(selectedNodeKey, project)

    item.active = checked;
    if (itemType === "Qualitypoint") {
        editQualityPipe(project._id, item)
    }
    else if (itemType === "Datasource") {
        editDatasource(project._id, qualitypointId, item, item._id)
    }
    else if (itemType === "Rule") {
        editRule(project._id,qualitypointId, datasourceId,item)
    }
}


