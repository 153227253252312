import React from 'react'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as EventHubIcon } from './../../common/EventHubTextIcon/azure-event-hub.svg'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add'
import {Button} from '@material-ui/core'

import DialogCreateEventHub from './DialogCreateEventHub'


const useStyles = makeStyles((theme) => ({
    elementAdjacent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '80%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14
    }
}));

export default function SelectHierarchyEhubs(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [streams, setStreams] = React.useState(props.streams ? props.streams : {})
    const [flatStream, setFlatStream] = React.useState(props.streams ? getElementList(props.streams) : [])
    const [selectedValue, setSelectedValue] = React.useState(props.value ? props.value : {})
    const [enableCreation, setEnableCreation] = React.useState(props.enableCreation ? props.enableCreation : false)

    const [showDialogCreateEventHub, setShowDialogCreateEventHub] = React.useState(false)


    React.useEffect(() => {
        setFlatStream(getElementList(streams))
       
    }, [streams]);

    React.useEffect(() => {
        if(selectedValue && !isEmpty(selectedValue) ){
           let index =  flatStream.findIndex(x=>x.name === selectedValue.name && x.namespace === selectedValue.namespace && x.resourcegroup === selectedValue.resourcegroup)
            if (index>=0){
                setSelectedValue(flatStream[index])
              
            }
        }
    }, [flatStream]);

    function isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }


    React.useEffect(() => {
        setStreams(props.streams)
    }, [props.streams]);

    function onCloseDialogCreateEventHub(){
        setShowDialogCreateEventHub(false)
        setSelectedValue({})
    }


    function getElementList(stream) {
        var arrayFlat = []
        for (var i = 0; i < stream.length; i++) {
            var rg = stream[i]
            for (var j = 0; j < rg.namespaces.length; j++) {
                var namespace = rg.namespaces[j]
                var elementNS = {
                    type: 'namespace',
                    name: namespace.name
                }
                arrayFlat.push(elementNS)
                for (var k = 0; k < namespace.eventHubs.length; k++) {
                    var eventhub = namespace.eventHubs[k]
                    var elementEH = {
                        type: 'eventhub',
                        name: eventhub.name,
                        namespace: namespace.name,
                        resourcegroup : rg.name
                    }
                    arrayFlat.push(elementEH)
                }
            }
        }
        
        return arrayFlat
    }

    return (
        <div>
        <DialogCreateEventHub onClose={onCloseDialogCreateEventHub} visible={showDialogCreateEventHub}/>

        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="typeWarnTh-selection-helper">{props.title}</InputLabel>
            <Select
                labelId="select-license-type"
                id="select-license-type"
                style={{ width: '100%' }}
                value={selectedValue}
                onChange={event=>{props.onChange(event); setSelectedValue(event.target.value)}}
            >
                {enableCreation &&
                <MenuItem key={'create eventhub button'} value={'hola'}>
                    <div style={{width:'100%', display: 'flex',
        justifyContent: 'left' }} onClick={event=>setShowDialogCreateEventHub(true)}>
                    <AddIcon style={{fontSize:'15', color:'#4697c4'}}/>
                    <Typography variant='caption' style={{ marginLeft: '10px', color:'#4697c4' }}>Create EventHub</Typography>
                    </div>
                
            </MenuItem>}
                {flatStream.length < 1 &&
                    <MenuItem value={{}}>

                        <CircularProgress style={{ width: '15px', height: '15px' }} />
                        <Typography variant='caption' style={{ marginLeft: '10px' }}>Loading Data</Typography>
                    </MenuItem>
                }
                
                
                {flatStream.map(data => data.type === 'namespace' ?
                    <ListSubheader key={data.name}>
                        <Typography variant='subtitle2'>{data.name}</Typography>
                    </ListSubheader> :
                    <MenuItem key={data.namespace+'_'+data.name} value={data}>
                        <EventHubIcon style={{ width: '15px', height: '15px', marginTop: '3px', marginRight: '3px' }} />
                        <Typography variant='caption' style={{ marginLeft: '10px' }}>{data.name}</Typography>
                    </MenuItem>)}

            </Select>
        </FormControl>
        </div>
    )

}