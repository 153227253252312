import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { MuiThemeProvider  } from '@material-ui/core/styles';
import {QualityTheme} from './../../../style/CustomTheme'
import DialogContent from '@material-ui/core/DialogContent';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Grid,Typography} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'


class DatasetViewDialog extends Component{

   

    render(){
        return(
            <MuiThemeProvider theme={QualityTheme}>
            <Dialog
                aria-labelledby="simulate-managesimulation-title"
                open={this.props.show}
                fullWidth={true}
                maxWidth = {'md'}
            >
                 <DialogTitle style={{backgroundColor: '#474b51'}}>
                    <Grid container spacing={4}>
                        <Grid item>
                                <FontAwesomeIcon icon={faDatabase} style={{color:'#D3D0D3', fontSize:30}} />
                            
                        </Grid>
                        <Grid item style={{marginTop: '1%'}}>
                            <Typography style={{'display': 'flex', 'justifyContent': 'flex-start', marginBottom:'1%', color: '#D3D0D3'}}>
                                Dataset Detail
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent id="mamangesimulation-filters" >

           <div style={{marginTop:'10px'}}>
           <label style={{fontWeight:'bold'}}> Dataset </label>
           <DataTable value={this.props.dataset ? this.props.dataset.columns : []}
                editable={true}
                style={{algin:'center',width:'100%'}}
                scrollable={true} scrollHeight="200px">
               <Column field="position" header="Position"
               style={{
                   background:'transparent',
                   borderColor: 'transparent', 
                   fontSize:'12px',
                   textAlign: 'right',
                   width:'20%'
                   }}  />
               <Column field="name" header="Name"
               style={{
                   background:'transparent',
                   borderColor: 'transparent', 
                   fontSize:'12px',
                   textAlign: 'right',
                   width:'40%'
                   }}  />
               <Column field="type" header="Format"
               editor={this.formatEditor}
               style={{
                   background:'transparent',
                   borderColor: 'transparent', 
                   fontSize:'12px',
                   textAlign: 'right',
                   width:'40%'
                   }}  />
               
           </DataTable>
           </div>
           </DialogContent>
           <DialogActions>
                        <Button id='CancelButton' variant='outlined' style={{color: 'red', fontWeight: 'bold'}}  onClick={this.props.onClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
       
        )
    }
}

export default DatasetViewDialog;