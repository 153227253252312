import React from "react";
import { useSelector } from "react-redux";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-regular-svg-icons";

import { EntityDataRepository } from "../EntityDataRepository";

export function DatasetDataRepository(props) {
  const user = useSelector((store) => store.user);
  //Pasamos por props los iconos de CellTemplate
  //console.log("props datasetData",props)
  return (
    <>
      <EntityDataRepository
        user={user}
        iconType1={faFolder}
        iconType2={faDatabase}
        actions={props.actions}
        entityName={props.entityName}
      />
    </>
  );
}
