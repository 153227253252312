
import quality from '../apis/quality';
import {
    EDIT_PROJECT
    } from './types'
    import {axiosHeader,dispatchInfo, dispatchError} from '../components/common/axios/axiosHelper';



export const deleteDatasource = (idProject,pipeId,datasourceId)=> async dispatch => {
    var returnValue = {result:'undefined'}
    await quality.delete(`/datasource/${idProject}/${pipeId}/${datasourceId}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Datasource with id ${datasourceId} deleted`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}
      });
      return returnValue
}


export const addDatasourceV1 = (idProject,qualityPointId,datasource)=> async dispatch => {
    var returnValue = {result:'undefined'}    
    await quality.post(`/datasource/${idProject}/${qualityPointId}`, datasource,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Datasource ${datasource.name} created`)
        
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}

      });
      return returnValue
}

export const addDatasourceV2 = (idProject,qualityPointId,datasource)=> async dispatch => {
    var returnValue = {result:'undefined'}    
    await quality.post(`/version/2/datasource/${idProject}/${qualityPointId}`, datasource,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Datasource ${datasource.name} created`)
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}

      });
      return returnValue
}


export const editDatasource = (idProject,qualityPointId,ds)=> async dispatch => {
    var returnValue = {result:'undefined'}
    var datasource = ds;
   await quality.put(`/datasource/${idProject}/${qualityPointId}`, datasource,{
    headers: axiosHeader()
  }).then(function (response) {
        dispatch( {
            type : EDIT_PROJECT,
            payload: response.data
        });
        dispatchInfo(dispatch,`Datasource ${datasource.name} modified`)
        returnValue = {result:'ok'}
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        returnValue = {result:'ko',error:error}
      });
      return returnValue
}