import axios from 'axios';
import {API_MS_URL,ACCESS_TOKEN} from './../../../Constants'

import {
    FETCH_INFO,
    FETCH_ERROR
   } from './../../../actions/types'

export default axios.create(
    {
        baseURL: API_MS_URL
    }
);

export const axiosHeader =() =>{
    const headersA = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Cache-Control': 'no-cache'
       
    }
    
    return headersA
}

export const axiosHeaderToken =(accessToken) =>{
    const headersA = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (accessToken),
        
       
    }
    
    return headersA
}



export function dispatchInfo(dispatch, msg, detail){
    var m = {detail: detail,msg: msg, id:Math.floor(Math.random() * 100)}
    
    if(detail===undefined){
     m = {msg: msg, id:Math.floor(Math.random() * 100)}   
    }
    dispatch( {
        type : FETCH_INFO,
        payload: m
    });
   
}

export function dispatchError(dispatch, error){

    

    var m = {}
    
    if(error.response && error.response.data && error.response.data.error && error.response.data.error.message){
        if(error.response.data.error.errors && error.response.data.error.errors.length>0){
            let domain = error.response.data.error.errors[0].domain
            let message = `Error in ${domain} element: ${error.response.data.error.message}`
            m = {msg: message,detail: domain, id:Math.floor(Math.random() * 100)}   
            dispatch( {
                type : FETCH_ERROR,
                payload: m
            });
        }
        else{
            m = {msg: error.response.data.error.message, id:Math.floor(Math.random() * 100)}   
            dispatch( {
                type : FETCH_ERROR,
                payload: m
            });
        }
        
    }

    else if(error.response && error.response.data && error.response.data.error && error.response.data.message){
        m = {msg: error.response.data.message, detail: error.response.data.error,  id:Math.floor(Math.random() * 100)}   
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }

    else if(error.response && error.response.data.error){
        m = {msg: error.response.data.error, id:Math.floor(Math.random() * 100)}   
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }

    else if(error.response && error.response.data && error.response.data.message){
        m = {msg: error.response.data.message, id:Math.floor(Math.random() * 100)}
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }

    else if(error && error.message && error.stack){
        m = {msg: error.message, detail:error.stack, id:Math.floor(Math.random() * 100)}   
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }

    else if(error){
        m = {msg: error, id:Math.floor(Math.random() * 100)}   
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }
    else{
        m = {msg: 'Unexpected error', id:Math.floor(Math.random() * 100)}   
        dispatch( {
            type : FETCH_ERROR,
            payload: m
        });
    }
}

