import _ from 'lodash';
import {FETCH_DATAFORMATS,
        CREATE_DATAFORMAT
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_DATAFORMATS:
                return {..._.mapKeys(action.payload,'_id')}
           
        
        case CREATE_DATAFORMAT:
            return {...state, [action.payload._id]: action.payload};
       
        default:
            return state;
        
    }
}