
import React from 'react'
import {Button,Grid} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useTheme } from '@material-ui/core/styles';

export default function AddProjectButton(props) {

    const theme = useTheme();

    return(
       <div>
           <Button  onClick = {props.onClick} style={theme.buttons.buttonGlow}
           startIcon={<AddIcon />} >
               <div style={theme.common.text.buttonText}>
                    New Project
               </div>
              
                   
                </Button>
       </div>
    )

}