import React from 'react'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import StreamDataRepository from './StreamDataRepository'
import FileDataRepository from './FileDataRepository'

import { makeStyles, withStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(3),
    },
    demo2: {
      backgroundColor: '#FFFFFF',
    },
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  

  
export default function DataRepository(props) {
    const classes = useStyles();
    const [valueDataRepo, setValueDataRepo] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValueDataRepo(newValue);
    };

    const StyledTabs = withStyles({
        indicator: {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          '& > div': {
            maxWidth: 80,
            width: '100%',
            backgroundColor: '#635ee7',
          },
        },
      })((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
      

    const StyledTab = withStyles((theme) => ({
        root: {
          textTransform: 'none',
          color: '#000',
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: theme.typography.pxToRem(15),
          
          marginRight: theme.spacing(1),
          '&:focus': {
            opacity: 1,
          },
        },
      }))((props) => <Tab disableRipple {...props} />);

    return(
        <div className={classes.demo2}>
            <StyledTabs value={valueDataRepo} onChange={handleChange} aria-label="styled tabs example">
                <StyledTab label="File Data" />
                <StyledTab label="Stream Data" />
            </StyledTabs>
            <TabPanel value={valueDataRepo} index={0}>
                <FileDataRepository/>
            </TabPanel>
            <TabPanel value={valueDataRepo} index={1}>
                <StreamDataRepository/>
            </TabPanel>
      </div>
    )
}