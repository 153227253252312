import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "./../../components/common/Header/Header.js";
import Footer from "./../../components/common/Footer/Footer.js";
import GridContainer from "./../../components/common/Grid/GridContainer.js";
import GridItem from "./../../components/common/Grid/GridItem.js";
import Button from "./../../components/common/CustomButtons/Button.js";
import Card from "./../../components/common/Card/Card.js";
import CardBody from "./../../components/common/Card/CardBody.js";
import CardHeader from "./../../components/common/Card/CardHeader.js";
import CardFooter from "./../../components/common/Card/CardFooter.js";
import CustomInput from "./../../components/common/CustomInput/CustomInput.js";

import styles from "./../../assets/jss/material-kit-react/views/loginPage.js";

import image from "./../../assets/img/bg7.jpg";

import {GOOGLE_AUTH_URL,FACEBOOK_AUTH_URL,ACCESS_TOKEN} from './../../Constants'


import {useDispatch} from 'react-redux'
import * as actions  from './../../actions'
import Typography from "@material-ui/core/Typography";

import trackEvent from './../../trackEvent';
import { dispatchError } from "../common/axios/axiosHelper.js";




const useStyles = makeStyles(styles);

function routeChange(props) {
  let path = `/license`;
  props.history.push(path);
}



export default function LoginPage(props) {
  //ReactGA.pageview(window.location.pathname + window.location.search);
  
  console.log(props)
  const dispatch = useDispatch();
  var token = localStorage.getItem(ACCESS_TOKEN);

  const [errorMessage, setErrorMessage] = React.useState(undefined)

  React.useEffect(() => {
    if(props && props && props.location && props.location.state && props.location.state.error!==undefined ){
    }
    else{
      if(token){
        dispatch(actions.fetchUser())
      }
    }
    
  }, []);

  React.useEffect(() => {
    console.log('pues entro')
    if(props && props && props.location && props.location.state && props.location.state.error!==undefined ){
      console.log('entro aquiiiii')
      if(props.location.state.error===""){
        dispatchError(dispatch,"User not found")
      }
      else{
        dispatchError(dispatch, props.location.state.error)
      }
    }
  }, []);


  

  

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
 
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Aqtiva"
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
       
        
        <div className={classes.container}>
          <GridContainer justify="center" style={{marginTop:'0%'}}>
              <GridItem xs={12} sm={12} md={12}>
              <img 
              src={`${process.env.PUBLIC_URL}/aqtiva_logo_text.png`} 
              style={{width:'30%',marginLeft:'35%',marginBottom:'5%'}}
              />
              </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                  
                      <img className="img-fluid" 
                      src={`${process.env.PUBLIC_URL}/aqtiva_logo_white.png`} 
                      style={{width:'15%', height:'15%', marginBottom:'-5%'}}
                      />
                    <h4>Login</h4>
                    
                    
                    <div className={classes.socialLine}>
                      
                      <Button
                        justIcon
                        target="_self"
                        href={FACEBOOK_AUTH_URL}
                        color="transparent"
                        onClick={ event=> trackEvent('Login','Login Azure')}
                       
                      >
                        <i className={"fab fa-microsoft"} />
                      </Button>
                      <Button
                        justIcon
                        href={GOOGLE_AUTH_URL}
                        target="_blank"
                        color="grey"
                        onClick={e => {e.preventDefault(); trackEvent('Login','Login AWS')}}
                      >
                        <i className={"fab fa-amazon"} />
                      </Button>
                      <Button
                        justIcon
                        href={GOOGLE_AUTH_URL}
                        target="_blank"
                        color="transparent"
                        onClick={ event=> trackEvent('Login','Login Azure')}
                        
                      >
                        <i className={"fab fa-google-plus-g"} />
                      </Button>
                    </div>
                  </CardHeader>
                   
                   {undefined &&
                  <CardBody>
                    <CustomInput
                      labelText="First Name..."
                      id="first"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                  </CardBody>
                  }
                  <CardFooter className={classes.cardFooter}>
                    <div>
                    <Typography variant = 'subtitle' style={{margin:'5px'}}>
                     Login with
                   </Typography>
                   <Link href={FACEBOOK_AUTH_URL} >
                    Azure
                  </Link>
                  <Typography variant = 'subtitle' style={{margin:'5px'}}>
                     or
                   </Typography>
                  <Link href={GOOGLE_AUTH_URL} >
                    Google
                  </Link>
                    </div>
                   
                  </CardFooter>
                  <div style={{marginBottom:'15px'}}>
                  <Typography variant = 'caption' style={{marginLeft:'35%'}}>
                    Powered by
                    </Typography>
                    {process.env.REACT_APP_CLOUD_PROVIDER ==='azure' &&
                      <img alt="" style={{ height: '30px', width: '30px', marginBottom: '-10px', marginLeft:'5px' }}
                      src={require('./../../resources/icons/AzureIcon.svg')} />
                    }

                    {process.env.REACT_APP_CLOUD_PROVIDER ==='aws' &&
                      <img alt="" style={{ height: '30px', width: '30px', marginBottom: '-10px', marginLeft:'5px' }}
                      src={require('./../../resources/icons/AmazonIcon.svg')} />
                    }
                  
                  </div>
                </form>
               
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}

