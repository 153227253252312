import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { Button, MenuItem, Typography, Divider, Popover, Paper, ListItemText } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles, Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

//import DatePicker from "react-datepicker";
import { DateTimePicker } from 'react-rainbow-components';
import * as actions from '../../../../actions'

import * as configurationHelper from './../../common/configurationHelper'
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment'


 

export default function  FilterMenu(props) {

    const theme = useTheme()
    const dispatch = useDispatch();
    const user = useSelector(store => store.user)
    var qualityConfiguration = useSelector(store => (store.qualityConfiguration))
    const dashboardDates = useSelector((store) => store.dashboardDates);


    const [environment, setEnvironment] = React.useState("")
    const [selectedEnvironment, setSelectedEnvironment] = React.useState("")
    const [projects, setProjects] = React.useState(props.projects)
    const [selectedQualityProject, setselectedQualityProject] = React.useState()
    const [qualityPoints, setQualityPoints] = React.useState([])
    const [selectedQualityPointsId, setSelectedQualityPointsId] = React.useState([])
    const [datasources, setDatasources] = React.useState([])
    const [selectedDatasourcesId, setSelectedDatasourcesId] = React.useState([])
    const [checkedAcc, setcheckedAcc] = React.useState(true)
    const [simulations, setSimulations] = React.useState([])
    const [filteredSimulations, setFilteredSimulations] = React.useState([])
    const [selectedSimulationId, setSelectedSimulationId] = React.useState()
    const [checkedMin, setCheckedMin] = React.useState(false)
    const [checkedHour, setCheckedHour] = React.useState(false)
    const [checkedDay, setCheckedDay] = React.useState(false)
    const [startDate, setStartDate] = useState(dashboardDates && dashboardDates.length>0 ? dashboardDates.startDate : moment(new Date()).subtract(6, 'months').toDate());
    const [endDate, setEndDate] = useState(dashboardDates && dashboardDates.length>0 ? dashboardDates.endDate : new Date());
    const [checkedLastExecution, setCheckedLastExecution] = React.useState(false)
    const [lastNexecutions, setLastNexecutions] = React.useState(10)
    const [anchorEl, setAnchorEl] = React.useState(null);


    const passDataToParent = (data) => { props.passChildData(data) }

    const functionHandlerRaw = (data) => { props.passRawChildData(data) }




    React.useEffect(() => { getInitData() }, [props.initialIdSimulation]); 

    React.useEffect(() => {

        if (checkedMin) {
            var startDate_ = new Date()
            var endDate_ = new Date()
            startDate_.setMinutes(startDate_.getMinutes() - 1)
            setStartDate(startDate_);
            setEndDate(endDate_)
        }
        else if (!checkedDay && !checkedHour && !checkedMin) {
            var last2MonthDate = new Date()
            last2MonthDate.setMonth(last2MonthDate.getMonth() - 2)
            setStartDate(last2MonthDate);
            setEndDate(new Date());

        }

    }, [checkedMin]);

    React.useEffect(() => {

        if (checkedHour) {
            var startDate_ = new Date()
            var endDate_ = new Date()
            startDate_.setHours(startDate_.getHours() - 1)
            setStartDate(startDate_);
            setEndDate(endDate_)
        }
        else if (!checkedDay && !checkedHour && !checkedMin) {
            var last2MonthDate = new Date()
            last2MonthDate.setMonth(last2MonthDate.getMonth() - 2)
            setStartDate(last2MonthDate);
            setEndDate(new Date());
        }

    }, [checkedHour]);

    React.useEffect(() => {

        if (checkedDay) {
            var startDate_ = new Date()
            var endDate_ = new Date()
            startDate_.setHours(startDate_.getHours() - 72)
            setStartDate(startDate_);
            setEndDate(endDate_)
        }
        else if (!checkedDay && !checkedHour && !checkedMin) {
            var last2MonthDate = new Date()
            last2MonthDate.setMonth(last2MonthDate.getMonth() - 2)
            setStartDate(last2MonthDate);
            setEndDate(new Date());
        }

    }, [checkedDay]);

    React.useEffect(() => {
        if (checkedLastExecution && simulations) {
            //first we sort by date
            simulations.sort(function (a, b) {
                var dateA = new Date(a.creationDate), dateB = new Date(b.creationDate);
                return dateA - dateB;
            })

            //once sorted by date, we will take N last simulations
            // setFilteredSimulations(last(sorted_simulations, lastNexecutions))
        }
        else if (!checkedLastExecution && simulations) {
            // setFilteredSimulations((simulations.filter(x => selectedQualityPointsId.includes(x.idQualitypoint))))
        }
    }, [lastNexecutions, checkedLastExecution]);

    React.useEffect(() => {

        if (user && user.id) {

            dispatch(actions.fetchConfigurationByUserId(user.id))
        }

    }, [user]);




    React.useEffect(() => {
        if (qualityConfiguration && configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id)) {
            setEnvironment(configurationHelper.filterAllowedEnvironments(qualityConfiguration.environmentConfigurationList, user.id))
        }

    }, [qualityConfiguration]);

    React.useEffect(() => {
        if (props.projects) {
            setProjects(props.projects)
        }
        var newDate = new Date(startDate.toString())
        newDate.setMonth(newDate.getMonth() - 2)
        setStartDate(newDate)

    }, [props.projects]);


    React.useEffect(() => {
        if (props.projects && selectedQualityProject) {
            let qps = props.projects.find(x => x._id === selectedQualityProject).qualityPoints
            setQualityPoints(qps)
            if (qps && qps.length > 0) {
                setSelectedQualityPointsId([qps[0]._id])
            }

            var new_env = props.projects.find(x => x._id === selectedQualityProject).environment
            setSelectedEnvironment(new_env)

            //solo hacerlo en la simulación
            dispatch(actions.getSimulationFromProjectId(selectedQualityProject)).then(response => {
                if (response.data) {
                    const simulations = response.data.filter(x => x.state === 'Succeed' || x.state === 'Finished').sort((a, b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
                    setSimulations(simulations)
                    if(simulations.length>0){
                        setSelectedSimulationId(simulations[0]._id)
                    }
                    
                }
                else {
                    setSimulations(response.data)
                }

            })
        }
    }, [selectedQualityProject]);

    React.useEffect(() => {
        if (selectedSimulationId) {
            dispatch(actions.getSimulationDAOForSimulationId(selectedSimulationId)).then(response => {
                //enviar a index (parent component) los datos de la response
                passDataToParent(response.data)


            })
            dispatch(actions.getRecordsForSimulation(selectedSimulationId)).then(response => {
                //enviar a index (parent component) los datos de la response)
                functionHandlerRaw(response.data)


            })
            let date = new Date()

            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let hour = date.getHours()
            let min = date.getMinutes()
            let secs = date.getSeconds()
            let projectName = props.projects.find(x=>x._id===selectedQualityProject) ? 
            props.projects.find(x=>x._id===selectedQualityProject).name : undefined
            if(projectName!==undefined){
                props.setPdfUrl(`/dashboard/simulation/pdf/${selectedSimulationId}/${projectName.replace(/\s+/g,"_")}`)
                props.setPdfName(projectName.replace(/\s+/g,"_")+"_simulation_"+selectedSimulationId+"_report_"+year+"-"+month+"-"+day+"-"+hour+"-"+min+"-"+secs+".pdf")
       
            }
                 }

    }, [selectedSimulationId]);




    React.useEffect(() => {
        if (qualityPoints && selectedQualityPointsId) {

            setDatasources(compare(qualityPoints, selectedQualityPointsId))

            if (simulations) {
                setFilteredSimulations(simulations.filter(item => selectedQualityPointsId.includes(item.idQualitypoint)).sort((a, b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0)))
                //setFilteredSimulations(simulations)
            }
        }

    }, [selectedQualityPointsId, simulations]);

    function getInitData() {

        if (props.initialIdSimulation) {
            dispatch(actions.getSimulationDAOForSimulationId(props.initialIdSimulation)).then(response => {
                //enviar a index (parent component) los datos de la response)
                passDataToParent(response.data)
            })
            dispatch(actions.getRecordsForSimulation(props.initialIdSimulation)).then(response => {
                //enviar a index (parent component) los datos de la response)
                functionHandlerRaw(response.data)
            })
        }
        let date = new Date()

            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let hour = date.getHours()
            let min = date.getMinutes()
            let secs = date.getSeconds()
            let projectName = props.projects.find(x=>x._id===selectedQualityProject) ? 
            props.projects.find(x=>x._id===selectedQualityProject).name : undefined
            if(projectName!==undefined){
                props.setPdfUrl(`/dashboard/simulation/pdf/${selectedSimulationId}/${projectName.replace(/\s+/g,"_")}`)
                props.setPdfName(projectName.replace(/\s+/g,"_")+"_simulation_"+selectedSimulationId+"_report_"+year+"-"+month+"-"+day+"-"+hour+"-"+min+"-"+secs+".pdf")

            }
                
    }

    
    function compare(arr1, arr2) {

        const objMap = [];
        if (arr1 !== undefined) {
            if (arr1 && arr2) {
                arr1.forEach((e1) => arr2.forEach((e2) => {
                    if (e1._id === e2) {
                        if (e1.datasources) {
                            e1.datasources.forEach((ds) => { objMap.push(ds) })
                        }

                    }
                }
                ));
                return objMap;
            }
            else {
                return objMap;
            }
        }
        else {
            return objMap;
        }

    }




    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        props.setOpenFilter(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        props.setOpenFilter(false)
    };

    const getMenuItems = (itemsArray) => {
        let itemsToShow = []
        itemsArray.map(m =>
            itemsToShow.push(<MenuItem key={m._id} value={m._id}>
                <Typography variant='caption' > {m.name}</Typography>
            </MenuItem>)
        )
        return itemsToShow
    }

    var handleChangeMin = (ev) => {
        setCheckedDay(false)
        setCheckedHour(false)
        setCheckedMin(ev)
    };
    var handleChangeHour = (ev) => {
        setCheckedDay(false)
        setCheckedHour(ev)
        setCheckedMin(false)
    };
    var handleChangeDay = (ev) => {
        setCheckedDay(ev)
        setCheckedHour(false)
        setCheckedMin(false)
    };

    //takes the last N elements of an array
    var first = function first(array, n) {
        if (array == null) return void 0;

        return array.slice(0, n);
    };


    var filterByDateRange = function filterByDateRange(array, startDate_, endDate_) {
        return array.filter(a => {
            var date = new Date(a.creationDate);
            return (date >= startDate_ && date <= endDate_);
        })
    };



    function setQualityProjectCallback(event) {
        let selectedProject = event.target.value
        setselectedQualityProject(selectedProject)


    }

    return (
        <div >

            <Button aria-controls="simple-menu" startIcon={<FilterListIcon />} aria-haspopup="true" onClick={handleClick}>
                Filter
            </Button>

            <Popover
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ marginTop:'30px', height:'100%'}}
            >
                <Paper style={{ width: "21vw", height: "100%" }}  >
                    <div  >
                        <div style={theme.dashboard.filter.mainTitleDiv}>
                            <Typography variant='subtitle2' style={{ color: '#FFFFFF' }} > Source Selection</Typography>
                        </div>




                        <FormControl style={theme.dashboard.filter.form}>

                            <InputLabel htmlFor="age-native-simple">
                                <Typography variant='caption' > Quality Project</Typography>
                            </InputLabel>
                            <Select
                                onChange={setQualityProjectCallback}
                            >
                                {getMenuItems(projects)}
                            </Select>
                        </FormControl>

                        <FormControl style={theme.dashboard.filter.form}>

                            <InputLabel htmlFor="age-native-simple">
                                <Typography variant='caption' > Quality Point</Typography>
                            </InputLabel>
                            <Select
                                multiple
                                renderValue={(selected) => (' ')}
                                value={Array.from(selectedQualityPointsId)}
                                onChange={event => setSelectedQualityPointsId(event.target.value)}
                            >
                                {qualityPoints && qualityPoints.map(m =>
                                    <MenuItem key={m._id} value={m._id}>
                                        <Checkbox checked={Array.from(selectedQualityPointsId).indexOf(m._id) > -1} />
                                        <Typography variant='caption' > {m.name}</Typography>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>


                        <FormControl style={theme.dashboard.filter.form} >

                            <InputLabel htmlFor="age-native-simple">
                                <Typography variant='caption' > DataSource</Typography>
                            </InputLabel>
                            <Select
                                multiple
                                value={selectedDatasourcesId}
                                renderValue={(selected) => (' ')}
                                onChange={event => setSelectedDatasourcesId(event.target.value)}
                            >
                                {datasources && datasources.map(m =>
                                    <MenuItem key={m._id} value={m._id}>
                                        <Checkbox checked={selectedDatasourcesId.indexOf(m._id) > -1} />
                                        <Typography variant='caption' > {m.name}</Typography>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>



                        {props.viewtype === "simulation" && <div style={{ minWidth: '100%' }}>
                            <FormControl style={theme.dashboard.filter.form} >

                                <InputLabel htmlFor="age-native-simple">
                                    <Typography variant='caption' > Simulation</Typography>
                                </InputLabel>
                                <Select
                                    value={selectedSimulationId}
                                    onChange={event => setSelectedSimulationId(event.target.value)}
                                >
                                    {filteredSimulations && checkedLastExecution && filterByDateRange(first(filteredSimulations.filter(function (el) {
                                        return el.state == "Running" || el.state >= "Succeed" || el.state >= "Finished";
                                    }), lastNexecutions), startDate, endDate).map(m =>
                                        <MenuItem key={m._id} value={m._id}>
                                            <Typography variant='caption' > {m.name}</Typography>
                                        </MenuItem>
                                    )}
                                    {filteredSimulations && !checkedLastExecution && filterByDateRange(filteredSimulations.filter(function (el) {
                                        return el.state == "Running" || el.state >= "Succeed" || el.state >= "Finished";
                                    }), startDate, endDate).map(m =>
                                        <MenuItem key={m._id} value={m._id}>
                                            <Typography variant='caption' > {m.name}</Typography>
                                        </MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                        </div>}
                        <div style={{ marginTop: '6%' }}>
                            <Divider light />
                        </div>
                        <div style={theme.dashboard.filter.mainTitleDiv}>
                            <Typography variant='subtitle2' style={{ color: '#FFFFFF' }} > Environment Selection</Typography>
                        </div>

                        {undefined && <FormControl style={theme.dashboard.filter.form}  >
                            <InputLabel size={"medium"} htmlFor="Environment-form">
                                <Typography variant='caption' > Environment</Typography>

                            </InputLabel>
                            <Select
                                onChange={event => setSelectedEnvironment(event.target.value)}
                            >
                                {environment && environment.map(e =>
                                    <MenuItem key={e.environmentName} value={e.environmentName} >
                                        <Typography variant='caption' >  {e.environmentName}</Typography>
                                    </MenuItem>)}
                                {!environment &&
                                    <MenuItem key={environment._id} value={environment._id} >
                                        <Typography variant='caption' >  {selectedEnvironment}</Typography>
                                        {selectedEnvironment}</MenuItem>}

                            </Select>
                        </FormControl>}



                        {undefined && <div >
                            <div style={{ marginTop: '6%' }}>
                                <Divider light />
                            </div>
                            <Grid container >
                                <Grid item xs={6} >
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedAcc} onChange={event => setcheckedAcc(!checkedAcc)} name="checkedA" />}
                                        label="Accumulative"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={!checkedAcc} defaultChecked={false} onChange={event => setcheckedAcc(!checkedAcc)} name="checkedA" />}
                                        label="Split Panel"
                                    />
                                </Grid>
                            </Grid>
                            <Divider light />
                        </div>}
                        <div style={{ marginTop: '6%' }}>
                            <Divider light />
                        </div>

                        <div style={theme.dashboard.filter.mainTitleDiv}>
                            <Typography variant='subtitle2' style={{ color: '#FFFFFF' }} > Time Period Selection</Typography>
                        </div>
                        <div >
                            <div style={{ marginTop: '2%' }}>
                            </div>
                            <Grid container >

                                <Grid item xs={4} >
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedMin} onChange={event => handleChangeMin(!checkedMin)} name="checkedA" />}
                                        label={<Typography variant='caption' > Last min</Typography>}
                                        labelPlacement="top"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedHour} defaultChecked={false} onChange={event => handleChangeHour(!checkedHour)} name="checkedA" />}
                                        label={<Typography variant='caption' > Last hour</Typography>}
                                        labelPlacement="top"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedDay} defaultChecked={false} onChange={event => handleChangeDay(!checkedDay)} name="checkedA" />}
                                        label={<Typography variant='caption' > Last day</Typography>}
                                        labelPlacement="top"
                                    />
                                </Grid>

                                {
                                    <div style={{ marginLeft:'20px' }}>
                                    < Grid container >

                                        <Grid item xs={6}>

                                            <DateTimePicker
                                                disabled={checkedDay || checkedHour || checkedMin}
                                                value={startDate}
                                                onChange={date => setStartDate(date)}
                                                formatStyle="small"
                                                style={{
                                                    maxWidth: "85%",
                                                    marginRight: "3%"
                                                }}
                                                label={<Typography variant='caption' > From </Typography>}

                                            />

                                        </Grid>

                                        <Grid item xs={6}>
                                            <DateTimePicker
                                                disabled={checkedDay || checkedHour || checkedMin}

                                                value={endDate}
                                                onChange={date => setEndDate(date)}
                                                formatStyle="small"
                                                style={{
                                                    
                                                    maxWidth: "85%",
                                                    marginRight: "5%"
                                                }}
                                                label={<Typography variant='caption' > To </Typography>}
                                            />

                                        </Grid>

                                    </Grid>
                                    </div>}

                            </Grid>
                            <div style={{ marginTop: '6%' }}>
                            <Divider light />
                        </div>
                        </div>
                        <div style={{ marginLeft:'20px' }}>
                        <Grid container>
                            <Grid item xs={6}>
                            {undefined &&    <FormControlLabel
                                    control={<Checkbox checked={checkedLastExecution} defaultChecked={false} onChange={event => setCheckedLastExecution(!checkedLastExecution)} name="checkedLastM" />}
                                    label={<Typography variant='caption' > Last Executions </Typography>}
                                />}

                            </Grid>
                            {checkedLastExecution &&
                                <Grid item xs={6}>
                                  {undefined &&  <FormControl size={"medium"}  >

                                        <InputLabel htmlFor="age-native-simple">
                                        <Typography variant='caption' > Last Executions </Typography>
                                        </InputLabel>
                                        <Select
                                            value={lastNexecutions}
                                            onChange={event => setLastNexecutions(event.target.value)}
                                        >
                                            <MenuItem key={1} value={1}>1</MenuItem>
                                            <MenuItem key={2} value={2}>2</MenuItem>
                                            <MenuItem key={3} value={3}>3</MenuItem>
                                            <MenuItem key={4} value={4}>4</MenuItem>
                                            <MenuItem key={5} value={5}>5</MenuItem>
                                            <MenuItem key={6} value={6}>6</MenuItem>
                                            <MenuItem key={7} value={7}>7</MenuItem>
                                            <MenuItem key={8} value={8}>8</MenuItem>
                                            <MenuItem key={9} value={9}>9</MenuItem>
                                            <MenuItem key={10} value={10}>10</MenuItem>
                                        </Select>
                                    </FormControl>}
                                </Grid>
                            }
                        </Grid>
                        </div>
                    </div>
                </Paper>
            </Popover>
        </div >
    );
}