
import quality from '../apis/quality';

//import {axiosHeader} from './../util/APIUtils'
import {axiosHeader,dispatchInfo, dispatchError} from '../components/common/axios/axiosHelper';



export const deployNotebook2DatabricksAzure = (environment,userId,projectName,qpName) => async (dispatch,getState) => {
    
    var returnValue = {}    
   await quality.post(`/databricks/version/1/publish/notebook/version/1/azure/${environment}/${userId}/${qpName}/${projectName}`,null,{
       headers: axiosHeader()
     }).then(function (response) {
           
           returnValue = response.data
           dispatchInfo(dispatch,`Quality Point ${qpName} deployed to Databricks`)
           
       })
       .catch(function (error) {
        dispatchError(dispatch,error)
           
           returnValue = {result:'ko',error:error}
         });
   
   
  return returnValue
       }