
import quality from '../apis/quality';
import {FETCH_ERROR
   } from './types'
   import {axiosHeader,dispatchInfo, dispatchError} from '../components/common/axios/axiosHelper';
   import qualityLocalHost from '../apis/qualityLocalHost';




export const getRecordsForSimulation = (idSimulation) => async (dispatch,getState) => {
    
    if(idSimulation)
    {
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/records/simulation/id/${idSimulation}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
        }
        return {result:'ok', data:[]}
};

export const getSimulationDAOForSimulationId = (simulationId) => async (dispatch,getState) => {

    if(simulationId)
    {
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/dashboard/simulation/${simulationId}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
            returnValue = {result:'ko',error:error}
          });
    
    
   return returnValue
        }
        return {result:'ok', data:[]}
};


export const getDashboardGlobalMock = () => async (dispatch,getState) => {
    
  var returnValue = {result:'undefined'}
       
  await quality.get(`/dashboard/global`,{
      headers: axiosHeader()
    }).then(function (response) {
          
          returnValue = {result:'ok', data:response.data}
      })
      .catch(function (error) {
          dispatchError(dispatch,error)
          
          returnValue = {result:'ko',error:error}
        });
  
  
 return returnValue
      
     
};
