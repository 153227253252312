import React from "react";
import { useDrop } from "react-dnd";
import * as dtoHelper from "../../../EntityDataRepository/Helpers/getterDTOs";
import { useSelector, useDispatch } from "react-redux";

//Limitamos las carpetas para que sean receptoras en vez de elementos a mover usando useDrop.
//También incluimos aquí la lógica de import y export, ya que es aquí donde se ejecutan las acciones al hacer drop.

export function Folder(props) {
  const dispatch = useDispatch();

  //Lógica de import y export

  const entityDataRepo = useSelector((store) =>
      Object.values(store[props.propsParent.entityName])
  );
  const entityDataRepoShared = useSelector((store) =>
      Object.values(store[props.propsParent.entityName + "Shared"])
  );
  
  function importFile(selectedItem, selectedItemShared) {
    dispatch(
      props.propsParent.actions.importFileToRepository(
          props.propsParent.user.id,
            dtoHelper.getDTOIdFromFileShared(
                entityDataRepoShared,
                selectedItemShared.itemId
            ), //dto origen
            dtoHelper.getDTOIdFromFolder(entityDataRepo, selectedItem.itemId), //dto destino
            selectedItemShared.itemId, //item origen
            selectedItem.itemId, //item destino
            dtoHelper.getEntityIdFromFileInRepositoryShared(
                entityDataRepoShared,
                selectedItemShared
            )
        )
    );
  }

  function exportFile(selectedItem, selectedItemShared) {
    dispatch(
      props.propsParent.actions.exportFileFromRepository(
        props.propsParent.user.id,
            dtoHelper.getDTOIdFromFolder(
               entityDataRepoShared,
                selectedItemShared.itemId
            ), //dto destino
            selectedItemShared.itemId, //item destino
            dtoHelper.getDTOIdFromFilePrivate(
                entityDataRepo,
                selectedItem.itemId
            ), //dto origen
            selectedItem.itemId, //item origen
            dtoHelper.getEntityIdFromFileInRepository(entityDataRepo, selectedItem.itemId)
        )
    );
}
//

//Incluimos en el apartado de drop las acciones que queremos que ejecute cuando este se realice
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: "card",
    drop: (item, monitor) => {      
      if(item.itemId && item.repositoryType === "shared" && props.repositoryType === "private") {
        importFile({itemId: props.itemId}, {itemId: item.itemId})
      } 
      
      if(item.itemId && item.repositoryType === "private" && props.repositoryType === "shared") {
        exportFile({itemId: item.itemId}, {itemId: props.itemId})
      }

    },
    canDrop: () => true,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
  });

    return (
     /*  <div }> */
      <div ref={dropRef} style={{backgroundColor: isOver ? "lightblue" : "transparent", width:"100%", display:"inline"}}>
        <span style={{display:"inline-block", width:"60%", height:"100%"}}>
        { props.children }
        </span>
      </div>
      /* </div> */
    );
}
export default Folder