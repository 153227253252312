import React from 'react';

import {
    Grid, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, InputAdornment, Icon,
    Select, MenuItem, TextField, FormControl, InputLabel, LinearProgress
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'

import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../actions';
import { axiosHeader, dispatchInfo, dispatchError } from './../../../../components/common/axios/axiosHelper';
import AqtivaDialog from './../../../../components/common/AqtivaDialog'



export default function DialogCreateEventHub(props) {

    const theme = useTheme();
    const dispatch = useDispatch();

    const streams = useSelector(store => store.streamConfiguration)
    const qualityConfigurationSelector = useSelector(store => (store.qualityConfiguration))

    const [resourceGroups, setResourceGroups] = React.useState(getResourceGroupsFromStreams(streams))
    const [namespaces, setNamespaces] = React.useState(getNamespacesFromStreams(streams))
    const [eventHubs, setEventHubs] = React.useState(getEventHubsFromStreams(streams))

    const [selectedResourceGroup, setSelectedResourceGroup] = React.useState({})
    const [selectedNamespace, setSelectedNamespace] = React.useState({})
    const [selectedEventHub, setSelectedEventHub] = React.useState('')

    const [creatingEventHub, setCreatingEventHub] = React.useState(false)

    const [creationError, setCreationError] = React.useState(undefined)


    React.useEffect(() => {
        setResourceGroups(getResourceGroupsFromStreams(streams))
        setNamespaces(getNamespacesFromStreams(streams))
        setEventHubs(getEventHubsFromStreams(streams))
    }, [streams]);

    function getResourceGroupsFromStreams(streams) {
        if (isEmpty(streams)) {
            return []
        }
        return streams.map(data => data.name)
    }

    function getNamespacesFromStreams(streams) {
        if (isEmpty(streams)) {
            return []
        }
        return streams.flatMap(data => data.namespaces).map(data => data.name)
    }
    function getEventHubsFromStreams(streams) {
        if (isEmpty(streams)) {
            return []
        }

        return streams.flatMap(data => data.namespaces).flatMap(data => data.eventHubs).map(data => data.name)
    }


    function isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    function createEventHubCallback(event) {
        //call function to create EventHub
        createEventHub()
    }

    function closeCallback() {
        reset()
        props.onClose()

    }

    function reset() {
        setSelectedEventHub({})
        setSelectedNamespace({})
        setSelectedEventHub('')
    }

    function createEventHub() {
        
        if (eventHubs.includes(selectedEventHub)) {
            setCreationError(`There is already a eventHub with the name ${selectedEventHub}`)
        }
        else {
            setCreatingEventHub(true)

            const dto = {
                region: 'westeurope',
                resourceGroupName: selectedResourceGroup,
                resourceType: 'namespaces',
                providerNamespace: 'Microsoft.EventHub',
                eventHubName: selectedEventHub,
                eventHubNamespaceName: selectedNamespace
            }

            const azureCredentials = qualityConfigurationSelector.azureCredentials
            setCreationError(undefined)
            dispatch(actions.createEventHub(azureCredentials.clientId, azureCredentials.domain,
                azureCredentials.secret, azureCredentials.subscription,
                dto)).then(response => {
                    setCreatingEventHub(false)
                    dispatch(actions.fetchEventHubTree(azureCredentials.clientId, azureCredentials.domain, azureCredentials.secret, azureCredentials.subscription))
                    dispatchInfo('EventHub Created')
                }
                ).catch(function (error) {
                    //setCreationError(`Azure Error creating EventHub`)
                    //dispatchError(dispatch, error)

                })
        }



    }


    return (
            <AqtivaDialog visible={props.visible}
            maxWidth = {'lg'}
            title={'Create EventHub'}
            titleIcon={faDatabase}
            confirmText={'Create'}
            cancelText={'Close'}
            confirmCallback={createEventHubCallback}
            cancelCallback={closeCallback}
            >
            
                    <Grid container spacing={1} alignItems="center" style={{ width: '100%' }}>
                        {creationError && <Grid item xs={12}>
                            <TextField
                                error
                                style={{ width: '100%', color: 'red' }}
                                id="outlined-error"
                                label="Error"
                                value={creationError}
                                variant="outlined"
                            />

                        </Grid>}
                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel >ResourceGroup</InputLabel>
                                <Select fullwidth style={{ width: '100%' }}
                                    labelId="select-license-type"
                                    id="select-license-type"
                                    value={selectedResourceGroup}
                                    onChange={e => setSelectedResourceGroup(e.target.value)}

                                >
                                    {resourceGroups.map(data =>
                                        <MenuItem value={data}>{data}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel >Namespace</InputLabel>
                                <Select fullwidth style={{ width: '100%' }}
                                    labelId="select-license-type"
                                    id="select-license-type"
                                    value={selectedNamespace}
                                    onChange={e => setSelectedNamespace(e.target.value)}

                                >
                                    {namespaces.map(data =>
                                        <MenuItem value={data}>{data}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{ fontSize: '2px', width: '100%' }}
                                id="eventhubname"
                                type="text"
                                label="EventHub Name"
                                value={selectedEventHub}
                                onChange={e => setSelectedEventHub(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />


                        </Grid>
                        {creatingEventHub &&
                            <Grid item xs={12}>
                                <LinearProgress />
                                <Typography variant='caption'>Creating EventHub... it may take some time</Typography>
                            </Grid>
                        }
                    </Grid>


                
            </AqtivaDialog>
    )

}