import React from 'react'
import CustomTabs from './../../../common/CustomTabs/CustomTabs'

import { loadCSS } from 'fg-loadcss';



import Face from "@material-ui/icons/Face";
import SyncAlt from "@material-ui/icons/SyncAlt";
import List from "@material-ui/icons/List";
import AccountSettings from './AccountSettings';
import ConnectorSettings from './ConnectorSettings';
import QualityDefaults from './QualityDefaults';





export default function QualityConfiguration(props) {

 
    const tabSelected = 0

    React.useEffect(() => {
        loadCSS(
          'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
          document.querySelector('#font-awesome-css'),
        );
      }, []);


    return(
        <div>
            <CustomTabs
                headerColor="primary"
                value={tabSelected}
                tabs={[
                    {
                      tabName: "Account Settings",
                      tabIcon: Face,
                      tabContent: (
                        <AccountSettings currentUser={props.currentUser}/>
                      )
                    },
                    {
                      tabName: "Environment Settings",
                      tabIcon: SyncAlt,
                      tabContent: (
                       <ConnectorSettings currentUser={props.currentUser}/>
                      )
                    },
                    {
                      tabName: "Aqtiva Common Settings",
                      tabIcon: List,
                      tabContent: (
                        <QualityDefaults currentUser={props.currentUser}/>
                      )
                    }
                ]}/>
                
                
        </div>
    )
    
}