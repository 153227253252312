import { FETCH_KPIS_GLOBAL, FETCH_TOTAL_RECORDS, FETCH_TOTAL_RECORDS_TABLE } from "./types";
import {
  axiosHeader,
  dispatchError,
  dispatchInfo,
} from "../components/common/axios/axiosHelper";
import quality from '../apis/quality';
import qualityLocalHost from "../apis/qualityLocalHost";

export const fetchTotalRecords = (licenseId, projectId, userId, environment, dateInitial, dateFinal, personalId) => async (dispatch, getState) => {
  var returnValue = { result: "undefined" };
  //personalId = null
  await quality
    .get(`/dashboard/total/events/rules/role/${personalId}/licenseId/${licenseId}/projectId/${projectId}/userId/${userId}/environment/${environment}/dateInitial/${dateInitial}/dateFinal/${dateFinal}`, {
      headers: axiosHeader(),
    })
    .then(function (response) {
      returnValue = { result: "ok", data: response.data };
      dispatch({
        type: FETCH_TOTAL_RECORDS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatchError(dispatch, error);
    });
  return returnValue;
};

export const fetchTotalRecordsTableData = (licenseId, dateInitial, dateFinal, personalId) => async (dispatch, getState) => {
  var returnValue = { result: "undefined" };
  //personalId = null
  await quality
    .get(`/dashboard/table/events/rules/role/${personalId}/licenseId/${licenseId}/dateInitial/${dateInitial}/dateFinal/${dateFinal}`, {
      headers: axiosHeader(),
    })
    .then(function (response) {
      returnValue = { result: "ok", data: response.data };
      dispatch({
        type: FETCH_TOTAL_RECORDS_TABLE,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatchError(dispatch, error);
    });
  return returnValue;
};

export const fetchKPISProjects = (licenseId,environment,dateInitial,dateFinal) => async (dispatch, getState) => {

  var returnValue = { result: "undefined" };

  quality
    .get(`/dashboard/kpi/governances/by/project/licenseId/${licenseId}/environment/${environment}/dateInitial/${dateInitial}/dateFinal/${dateFinal}`, {
      headers: axiosHeader(),
    })
    .then(function (response) {
      returnValue = { result: "ok", data: response.data };
      dispatch({
        type: FETCH_KPIS_GLOBAL,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatchError(dispatch, error);
    });
  return returnValue;
};

export const fetchKPISDataSource = (licenseId,environment,dateInitial,dateFinal,datasrcIds) => async (dispatch, getState) => {
  var returnValue = { result: "undefined" };
  quality
    .get(`/dashboard/kpi/governances/by/datasource/licenseId/${licenseId}/environment/${environment}/dateInitial/${dateInitial}/dateFinal/${dateFinal}`, {
      headers: axiosHeader(),
    })
    .then(function (response) {
      var rawResponse = response.data;
      if (datasrcIds && datasrcIds.length > 0) {
        // filtras la respuesta por los qpIds (que el entityId esté contenido en qpIds)
        const newKPIs = rawResponse.globalDashboardDAOs.filter(card => datasrcIds.includes(card.entityId))
        rawResponse.globalDashboardDAOs = newKPIs
      }
      returnValue = { result: "ok", data: rawResponse };
      dispatch({
        type: FETCH_KPIS_GLOBAL,
        payload: rawResponse,
      });
    })
    .catch(function (error) {
      dispatchError(dispatch, error);
    });
  return returnValue;
};

export const fetchKPISQualityPoint = (licenseId,environment,dateInitial,dateFinal,qpIds) => async (dispatch, getState) => {
  var returnValue = { result: "undefined" };

  quality
    .get(
      `/dashboard/kpi/governances/by/qualityPoints/${qpIds}/licenseId/${licenseId}/environment/${environment}/dateInitial/${dateInitial}/dateFinal/${dateFinal}`,
      {
        headers: axiosHeader(),
      }
    )
    .then(function (response) {
      var rawResponse = response.data;
      if (qpIds && qpIds.length > 0) {
        // filtras la respuesta por los qpIds (que el entityId esté contenido en qpIds)
        const newKPIs = rawResponse.globalDashboardDAOs.filter(card => qpIds.includes(card.entityId))
        rawResponse.globalDashboardDAOs = newKPIs
      }
      returnValue = { result: "ok", data: rawResponse };
      dispatch({
        type: FETCH_KPIS_GLOBAL,
        payload: rawResponse,
      });
    })
    .catch(function (error) {
      dispatchError(dispatch, error);
    });
  return returnValue;
};
