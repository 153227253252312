
//const baseUrl = 'http://62-210-202-96.rev.poneytelecom.eu'
export const baseUrlLogin = process.env.REACT_APP_LOGIN_HOST
export const baseUrlFront= process.env.REACT_APP_FRONT_HOST
export const API_MS_URL = process.env.REACT_APP_BACK_HOST

export const ACCESS_TOKEN = 'current_token';
export const AUTHENTICATION = 'authentication';
export const USER='current_user'


export const OAUTH2_REDIRECT_URI = baseUrlFront+'/oauth2/redirect'

export const GOOGLE_AUTH_URL = baseUrlLogin + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = baseUrlLogin + '/oauth2/authorize/azure?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = baseUrlLogin + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;

