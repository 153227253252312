import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import LoadingData from './LoadingData'
import  * as actions from './../../../../../../../actions'
import { useSelector, useDispatch } from 'react-redux'
import { faFilter} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function flattenObj(obj, parent, res = {},count){
    var countNew = count ? count+1 : 1
    if(countNew>2){
        return res
    }
    for(let key in obj){
        let propName = parent ? parent + '.' + key : key;
        if(typeof obj[key] == 'object'){
            flattenObj(obj[key], propName, res);
        } else {
            res[propName] = obj[key];
        }
    }
    return res;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  card: {
    width:'100%',
    margin:'15px',
   
  },
}));

export default function TopicTable(props) {
    const dispatch = useDispatch();

    const classes = useStyles(); 
    const [topicData,setTopicData] = useState([props.topicData]);
    const [dataRemote, setData] = useState([])
    const [columns, setColumns] = useState(undefined)
    const [results, setResults] = useState(undefined)
    const [loadingData, setLoadingData] = React.useState(false)
    
    const [state, setState] = React.useState({
        flatten: true,
        maxNum:10
      }); 
      
      const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
      };
      const handleChangeTextField = name => event => {
        setState({ ...state, [name]: event.target.value });
      };
    const getKeyValueFromJSON = (json) => {
        var keyValues = []
        // Read key
    for (var key in json) {
        let value = json[key]
        keyValues.push({key: key, value:value})
        
    }

    return keyValues
    }
    const fetchData =   () => {
         let n_messages =state.maxNum
          setLoadingData(true)
          dispatch(actions.getEHubsData(props.endPoint, props.entityPath,props.sakName,props.sak, n_messages))
          .then(resultsTmp=>{
            setResults(resultsTmp)
           
           const data =resultsTmp && resultsTmp.length>0 && resultsTmp.map(e=>(JSON.parse(e))).map(e=>(state.flatten ? flattenObj(e): e))
           if(data && data.length>0){
              setColumns(Array.from(Object.keys(data[0]).map(e=>({title:e,field:e}))))
            
             setData(data)
             setLoadingData(false)
            }
          })
           
           
            

      }
   
      useEffect( () => {
        fetchData()
      }, [state.maxNum]);

      useEffect( () => {
        if(results){
            const data = results.data.map(e=>JSON.parse(e)).map(e=>({ key:e.key,value:JSON.parse(e.value)})).
        map(e=>(state.flatten ? flattenObj(e): ({key:JSON.stringify(e.key),value:JSON.stringify(e.value)})))
        if(data && data.length>0){
        setData(data)
        setColumns(Array.from(Object.keys(data[0]).map(e=>({title:e,field:e,}))))
        }
        }
      }, [state.flatten]);

    useEffect( () => {
        
        fetchData();
      }, []);

      return(
        <div className={classes.card}>
          

         {loadingData &&  <LoadingData/>}   
        <MaterialTable 
          title="Data"
          columns={columns}
          data={dataRemote}
          icons={{ Filter: () =>           <FontAwesomeIcon icon={faFilter}
          style={{fontSize:'calc(6px + 0.2vw)'}}></FontAwesomeIcon>
        }} 
          options={{
            grouping: true,
            filtering: true,
            headerStyle: {
                fontSize:'calc(8px + 0.2vw)',
              backgroundColor: '#4697c4',
              color: '#FFF'
            },
            cellStyle:{
                fontSize:'calc(6px + 0.2vw)',
            },
            filterCellStyle:{
                fontSize:'calc(6px + 0.2vw)',
            },
            
          }}
          components={{
            Toolbar: props => (
              <div>
                  
                <div style={{padding: '0px 10px'}}>
                <TextField
                    id="standard-number"
                    label="Max number of Messages"
                    value={state.maxNum}
                    onChange={handleChangeTextField('maxNum')}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    margin="normal"
                />
                
              </div>
              </div>
            ),
          }}
          
         >

         </MaterialTable>
       
        
        </div>
      );
}
