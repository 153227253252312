import React from 'react'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Grid from '@material-ui/core/Grid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons'

export const getProjectIcon = (source) => {
        if(source === 'AWS'){
            return(
                <FontAwesomeIcon icon={faCloud} style={{height:'1.5vw', width:'1.5vw', color: '#76b3d6'}}  />
                
            )
        }
        else if(source === 'Azure'){
            return(
                <FontAwesomeIcon icon={faCloud} style={{height:'1.5vw', width:'1.5vw', color: '#76b3d6'}}  />
            )
        }
        else{
            return(
                <StarBorderIcon 
                    style={{height:'1.5vw', width:'1.5vw', color: '#76b3d6'}}
                />
            )
        }
}


export const createProjectCard = (project, cardId, history, theme) => {
        let projectIcon = getProjectIcon(project.environment)
        return(
            <Grid item id={cardId} key={cardId}>
                <Card style={{width: '11vw', height: '100px', marginRight:'2.5vw'}}
                onClick = {event => history.push(`/${process.env.REACT_APP_PREFIX}/project/${project._id}`)}>
                    <Button style={{width: '100%', height: '100%'}}>
                        <Grid container>
                            <Grid item xs={12}>
                                {projectIcon}
                            </Grid>
                            <Grid item xs={12}>
                            <div style={theme.common.text.boldRegularText}>
                            {project.name}
                             </div>
                                
                            </Grid>
                        </Grid>
                    </Button>
                </Card>
            </Grid>
        );
    }