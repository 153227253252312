import React from 'react'
import { FormControl,Select,MenuItem,Typography,InputLabel} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faProjectDiagram, faDatabase,faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import {ReactComponent as EventHubIcon} from  './../../common/EventHubTextIcon/azure-event-hub.svg'


const useStyles = makeStyles((theme) => ({
    elementAdjacent: {
        display: 'flex',
        justifyContent: 'space-between' 
      },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width:'80%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    resize:{
        fontSize:14
      }
  }));

export default function QualityItemSelector  (props) {


    
    const classes = useStyles();

    return(
                                    <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="typeWarnTh-selection-helper">{props.title}</InputLabel>
                                                <Select 
                                                    style={{ width: '100%' }}
                                                    value={props.value}
                                                    onChange={props.onChange}
                                                    inputProps={{
                                                        name: props.title,
                                                        id: props.title+'-selection',

                                                    }}
                                                >
                                                    {props.itemsArray.map((value) =>
                                                        <MenuItem key={value[props.itemId]} value={value}><div className={classes.elementAdjacent}>
                                                            {(props.type === 'StreamDataformat') && <EventHubIcon style={{width:'15px', height:'15px', marginTop:'3px', marginRight:'3px'}} />}
                                                            {(props.type === 'QualityPoint') && <FontAwesomeIcon icon={faProjectDiagram} style={{color:'#76b3d6', fontSize:18}} />}
                                                            {(props.type === 'Datasource') && <FontAwesomeIcon icon={faDatabase} style={{color:'#76b3d6', fontSize:18}} />}
                                                            {(props.type === 'Folder') && <FontAwesomeIcon icon={faFolderOpen} style={{color:'#76b3d6', fontSize:18}} />}
                                                             <Typography variant = 'subtitle2'>{value[props.itemValue]}</Typography></div>
                                                        </MenuItem>
                                                    )}

                                                </Select> 
                                                
                                                </FormControl>
    )
}