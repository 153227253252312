import React from 'react';
import { Typography, Switch,Tooltip } from '@material-ui/core';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import DeleteIcon from '@material-ui/icons/Delete';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import AllOutIcon from '@material-ui/icons/AllOut';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faStopCircle } from '@fortawesome/free-solid-svg-icons'

import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import {faFileAlt} from '@fortawesome/free-solid-svg-icons'
import {faPlayCircle} from '@fortawesome/free-regular-svg-icons'
import {faTable} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFilter} from '@fortawesome/free-solid-svg-icons'
import {faDownload} from '@fortawesome/free-solid-svg-icons'

import { faHubspot } from '@fortawesome/free-brands-svg-icons'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'






import {Button} from 'primereact/button';

import * as helper from './QualityProjectTableHelper'
import * as configurationHelper from './../../../../common/configurationHelper'

import trackEvent from './../../../../../../trackEvent'

export function simulateCellTemplate(node,onClickOpenDialogSimulation) {
    if (node.data.datasources && node.data.datasources.length>0 && node.data.type === 'Quality Point' && node.data.active) {   
        return (
            <div>
                {(node.data.type === 'Quality Point') && <FontAwesomeIcon icon={faPlayCircle} style={{  fontSize: 18, marginTop: '15%', marginRight: '10%' }} 
                
                onClick={event => {onClickOpenDialogSimulation(node.data,event);
                    trackEvent('DetailProjectTable','Simulate');}}/>}
            
            </div>
        );
    }

    return (<div></div>)
}

export function enableTemplate(node, project, editQualityPipeCallback,editDatasourceCallback,editRuleCallback) {
    if(node.data.type==='Folder'){
            return(
                <div></div>
            )
    }
    return (
        
        <Switch checked={node.data.active} value="Enabled" color='primary' stlye={{ heigth: '2px' }}
            onClick={e => {
                trackEvent('DetailProjectTable','Enable-Disable');
                 helper.handleChangeActive(e, node, project, editQualityPipeCallback, editDatasourceCallback,editRuleCallback); }} />

    );

}


export function go2DeployTemplate(node,configuration, userId, project, environment) {

    //create route

    let configs=configurationHelper.filterAllowedEnvironments(
        configuration.environmentConfigurationList,userId)
    let config = configs ? configs.filter(x=>x.environmentName===environment) : {}
  
    var route =''
    if(config && config.length >0){
        route = "https://"+config[0].databricksInstance+"#workspace"+config[0].databricksDeploymentPath+"/"+project._id+"/"+node.data.name+".scala"
    }


    if (node.data.type === 'Quality Point' && node.data.active && project.deploymentAudit && project.deploymentAudit!=null) {   
        return (
            <Tooltip title={'Go to Deployed Notebook'} >
            <a target="_blank" href={route}>
            
                {(node.data.type === 'Quality Point') && <FontAwesomeIcon icon={faDownload} style={{  fontSize: 18, marginTop: '15%', marginRight: '10%', color:'#4697c4' }} 
                
                />}
            
            </a>
            </Tooltip>
            
        );
    }

    return (<div></div>)
}


export function nameCellTemplate(node, project, setSelectedItem, restartRule, dragRef) {
    

    
    //if node.data.type == dvd we need a button to clear database kpis
    if(node.data.type === 'dvd'){
    }
    return (
        <div style={{ display: 'inline-flex', width:'70%'}}>
            {(node.data.type === 'Quality Point') && <FontAwesomeIcon icon={faProjectDiagram} style={{ color: '#76b3d6', fontSize: 'calc(9px + 0.3vw)',  marginRight: '10%' }} />}
            {(node.data.type === 'Datasource' && node.data.datasourceType!=='REAL_TIME_LAMBDA') && <FontAwesomeIcon icon={faDatabase} style={{ color: '#76b3d6', fontSize: 'calc(9px + 0.3vw)',  marginRight: '10%' }} />}
            {(node.data.type === 'Datasource' && node.data.datasourceType==='REAL_TIME_LAMBDA') && <FontAwesomeIcon icon={faHubspot} style={{ color: '#76b3d6', fontSize: 'calc(9px + 0.3vw)',  marginRight: '10%' }} />}
            
            {(node.data.nodeType=== 'Rule') && <FontAwesomeIcon ref={dragRef} icon={faFileAlt} style={{ color: '#03ad39', fontSize: 'calc(9px + 0.3vw)', marginRight: '10%' }} />}
            {(node.data.type === 'Folder') && <FontAwesomeIcon icon={faFolderOpen} style={{ color: '#cc9c33', fontSize: 'calc(9px + 0.3vw)', marginRight: '10%' }} />}

            {(node.data.type === 'Quality Point') &&
                <Typography style={{ cursor: 'pointer', width:'100%', color: '#76b3d6',fontSize: 'calc(8px + 0.3vw)' }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project)
                        setSelectedItem(item, itemType)
                    }}
                >
                    {node.data.name} </Typography>
            }
            {(node.data.type === 'Datasource') &&
                

                <div>
                    <Typography style={{ cursor: 'pointer', width:'100%', color: '#404040',fontSize: 'calc(8px + 0.3vw)' }} variant='subtitle2'
                        onDoubleClick={e => {
                            const { item, itemType } = helper.obtainEditableItem(node.key, project)
                            setSelectedItem(item, itemType)
                        }}
                    >
                        {node.data.dataset ? `${node.data.name} (${node.data.dataset.name})` : node.data.name}  </Typography>
                </div>
            }

            {(node.data.type === 'Folder') &&
                <Typography style={{ cursor: 'pointer',width:'100%', color: '#404040',fontSize: 'calc(7px + 0.3vw)'  }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project)
                        setSelectedItem(item, itemType)
                    }}
                >
                    {node.data.name} </Typography>
            }
            
            {(node.data.nodeType === 'Rule') && (node.data.type === 'dvd') &&
                 <div style={{ display: 'inline-flex'}} >
                     
                    <Typography  style={{ cursor: 'pointer', color: '#404040', width:'100%',fontSize: 'calc(7px + 0.3vw)' }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project)
                        setSelectedItem(item, itemType)
                    }}
                    >
                        {node.data.name} 
                    </Typography>
                    <Tooltip title={'Reset KPIs to restart rule'} >
                        <div>
                            <FontAwesomeIcon icon={faSyncAlt} style={{ marginLeft:'10px',color: '#4697c4', fontSize: 16, }}
                            onClick={event=>restartRule(node.data._id)} />
                        </div>
                        
                    </Tooltip>
                </div>
                
            }

            {(node.data.nodeType === 'Rule') && (node.data.type !== 'dvd') &&
                <Typography style={{ cursor: 'pointer', color: '#404040', width:'100%',fontSize: 'calc(7px + 0.3vw)' }} variant='subtitle2'
                    onDoubleClick={e => {
                        const { item, itemType } = helper.obtainEditableItem(node.key, project)
                        setSelectedItem(item, itemType)
                    }}
                >
                    {node.data.name} </Typography>
            }
            
        </div>
    );
}



export function outputControllCellTemplate(node) {
    if (!node.data.controlModeType || node.data.controlModeType === null) {
        return (<div></div>);
    }

    return (
        <div>
            {node.data.controlModeType.toLowerCase() === 'warning' &&
                <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#d99221', fontSize: 16, }} />}
            {node.data.controlModeType.toLowerCase() === 'info' &&
                <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#3dad5b', fontSize: 16, }} />}
            {node.data.controlModeType.toLowerCase() === 'error' &&
                <FontAwesomeIcon icon={faStopCircle} style={{ color: '#d93621', fontSize: 16, }} />}
        </div>
    );



}

export function wrongActionCellTemplate(node) {
    if (!node.data.wrongRegisterActionType || node.data.wrongRegisterActionType === null) {
        return (<div></div>);
    }

    return (
        <div>
            {node.data.wrongRegisterActionType.toLowerCase() === 'include_action' &&
                <TrendingFlatIcon style={{ color: '#545454', height: '20px', width: '20px', marginBottom: '-5px' }} />}
            {node.data.wrongRegisterActionType.toLowerCase() === 'discard_action' &&
                <DeleteIcon style={{ color: '#545454', height: '15px', width: '20px', marginBottom: '-2px' }} />}
            {node.data.wrongRegisterActionType.toLowerCase().substring(0, 7)}
        </div>
    );


};

export function dimensionCellTemplate(node,theme) {
    if (!node.data.dimension || node.data.dimension === null) {
        return (<div></div>);
    }

    return (
        <div>
            {node.data.dimension.toLowerCase() === 'unicity' &&
                <AllOutIcon style={{ color: '#545454', height: '20px', width: '20px', marginBottom: '-5px' }} />}
            {node.data.dimension.toLowerCase() === 'timeliness' &&
                <AvTimerIcon style={{ color: '#545454', height: '20px', width: '20px', marginBottom: '-5px' }} />}
            {node.data.dimension.toLowerCase() === 'integrity' &&
                <CompareArrowsIcon style={{ color: '#545454', height: '20px', width: '20px', marginBottom: '-5px' }} />}
            {node.data.dimension.toLowerCase() === 'veracity' &&
                <CheckCircleOutlineIcon style={{ color: '#545454', height: '20px', width: '20px', marginBottom: '-5px' }} />}
            {node.data.dimension.toLowerCase() === 'timeliness' &&
                <AvTimerIcon style={{ color: '#545454', height: '20px', width: '20px', marginBottom: '-5px' }} />}
            {node.data.dimension.toLowerCase()}

        </div>
    );
}

function getConditionString(condition) {
        
    if (condition.operator === 'in') {
        return `${condition.column.name} in ${condition.listValues ? condition.listValues.join(','): 'None'}`
    }
    else if (condition.operator === 'between') {
        return `${condition.column.name} between ${condition.lowerValue} and ${condition.greaterValue}`
    }
    else {
        return `${condition.column.name} ${condition.operator} than ${condition.lowerValue ? condition.lowerValue : condition.greaterValue}`
    }
}


function getCompleteConditionString(conditions){
    var conditionIndex;
    var conditionString = ""
    if(conditions.length>0){
        for (conditionIndex = 0; conditionIndex < conditions.length-1; conditionIndex++) {
        // Se ejecuta 5 veces, con valores desde paso desde 0 hasta 4.
        conditionString = conditionString+" ( "+getConditionString(conditions[conditionIndex])+" ) "+conditions[conditionIndex].selectedJoin
        };
        conditionString = conditionString+" ( "+getConditionString(conditions[conditions.length-1])+" ) "
    }
    return conditionString
}

export function conditionalTemplate(node,theme){
    
    
    if (!node.data.conditions || node.data.conditions.length ===0) {
        return (<div></div>);
    }

    return (
        <Button icon="fas fa-filter"
                tooltip={getCompleteConditionString(node.data.conditions)}
                tooltipOptions={{position: 'bottom'}}
                style={theme.icons.iconAqtivaTablePrime}
            />
    );
}

export function thErrorTemplate(node){
    return helper.getThFromObject(node.data.errorTh)

}

export function thWarningTemplate(node){
    return helper.getThFromObject(node.data.warningTh)
}

export function typeTemplate(node){
    if(node.data.type!=='Quality Point' && node.data.type!=='Datasource' && node.data.type!=='Folder'){
        return 'Rule'
    }
    return node.data.type
}

export function ruleTypeTemplate(node){
    if(node.data.type!=='Quality Point' && node.data.type!=='Datasource' && node.data.type!=='Folder'){
        return node.data.type
    }
    return ''
}