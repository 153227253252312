import React from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { useTheme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RightClickMenu } from "../EntityDataRepository/RightClickMenu";
import { CellTemplate } from "./CellTemplate/index";

import { OptionNew } from "../EntityDataRepository/Options/OptionNew";

import * as treeTableDataHelper from "../EntityDataRepository/Helpers/treeTableDataHelper";

import { getDTOIdFromFolder } from "../EntityDataRepository/Helpers/getterDTOs";
/* import { getEntityIdFromFileInRepositoryShared } from "../EntityDataRepository/Helpers/getterDTOs";
import { getEntityIdFromFileInRepository } from "../EntityDataRepository/Helpers/getterDTOs"; */
import { findAuthorNameFromId } from "../EntityDataRepository/Helpers/getterDTOs";
import {OptionDelete} from "../EntityDataRepository/Options/OptionDelete"
import Moment from "react-moment";
import { /* useDispatch, */ useSelector } from "react-redux";
import {
  faUserLock,
  faGlobe,
  faShareAlt,
  faLock,
  faTrashAlt,
  faDownload,
  faUpload,
  faFolderPlus,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import Typography from "@material-ui/core/Typography";

import trackEvent from './../../../../trackEvent'

const initialMouseState = {
  mouseX: null,
  mouseY: null,
};


export function DataRepositoryTreeTable({
  enableContextMenu = false,
  ...props
}) {
  //const dispatch = useDispatch();
  const theme = useTheme();

  const license = useSelector((store) => store.license);

  const [mouseState, setMouseState] = React.useState(initialMouseState);


  function setSelectedItemAndType(value, treeData, setSelectedItem) {
    const node = treeTableDataHelper.findValueInTreeDataAndOptionalCallback(
      treeData,
      value
    );
    const selectedElementType = node ? node.data.type : undefined;
    setSelectedItem({ itemId: value, type: selectedElementType });
  }
  

  function handleContextMenu(event) {
    setSelectedItemAndType(event.value, props.treeData, props.setSelectedItem);
    event.originalEvent.preventDefault();
    setMouseState({
      mouseX: event.originalEvent.clientX - 2,
      mouseY: event.originalEvent.clientY - 4,
    });
  }



  function handleClose() {
    setMouseState(initialMouseState);
  }

  function getOptionsBasedOnTypeAndSelection() {
    const options = [
      {
        name: "New folder",
        onClick: () => {
          props.setShowDialogs.new(true);
          trackEvent('Data Repository','Tree Table New Folder');
          handleClose();
        },
        icon: faFolderPlus,
        types: [
          { elementType: "Folder", type: "private" },
          { elementType: "Folder", type: "shared" },
        ],
        //elementType: "Folder",
        //type: "All",
      },
      {
        name: "Delete",
        onClick: () => {
          trackEvent('Data Repository','Tree Table Delete');
          props.setShowDialogs.delete(true);
          handleClose();
        },
        icon: faTrashAlt,
        types: [
          { elementType: "Files", type: "private" },
          { elementType: "Files", type: "shared" },
          { elementType: "Folder", type: "private" },
          { elementType: "Folder", type: "shared" },
        ],
        //elementType: "All",
        //type: "All",
      },
      {
        name: "Export",
        onClick: () => {
          trackEvent('Data Repository','Tree Table Export');
          props.setShowDialogs.export("rightClick");
          handleClose();
        },
        icon: faUpload,
        types: [
          { elementType: "Files", type: "private" },
          { elementType: "Folder", type: "shared" },
        ],
        //elementType: "Files",
        //type: "private",
      },
      {
        name: "Import",
        onClick: () => {
          trackEvent('Data Repository','Tree Table Import');
          props.setShowDialogs.import("rightClick");
          handleClose();
        },
        icon: faDownload,
        types: [
          { elementType: "Files", type: "shared" },
          { elementType: "Folder", type: "private" },
        ],
        //elementType: "Files",
        //type: "shared",
      },
    ];

    const filteredOptions = options.filter((oneType) => {
      return (
        oneType.types.filter(
          (type) =>
            type.type === props.type &&
            type.elementType === props.selectedItem.type
        ).length > 0
      );
    });
    return filteredOptions;
  }

  function findPermissionIcon(type) {
    var typeVar = type;
    if (!type) {
      typeVar = "Private";
    }
    //console.log(type);
    if (typeVar === "Public") {
      return (
        <FontAwesomeIcon
          icon={faGlobe}
          style={theme.dataRepository.iconPublic}
          fontSize="small"
        />
      );
    }
    return (
      <FontAwesomeIcon
        icon={faUserLock}
        style={theme.dataRepository.iconPrivate}
        fontSize="small"
      />
    );
  }

  return (
    <>
      {enableContextMenu && (
        <>
          <RightClickMenu
            options={getOptionsBasedOnTypeAndSelection()}
            mouseState={mouseState}
            handleClose={handleClose}
          />
          <OptionNew
            actions={props.actions}
            showDialog={props.showDialogs.new}
            setShowDialog={props.setShowDialogs.new}
            selectedItem={props.selectedItem}
            type={props.type}
            parent={false}
            dtoId={() =>
              getDTOIdFromFolder(props.dtoData, props.selectedItem.itemId)
            }
          />
          <OptionDelete
            actions={props.actions}
            showDialog={props.showDialogs.delete}
            setShowDialog={props.setShowDialogs.delete}
            selectedItem={props.selectedItem}
            dtoData={props.dtoData}
            type={props.type}
            user={props.user}
            treeData={props.treeData}
            defaultFolderId={props.defaultFolderId}
          />
        </>
      )}
      {(props.showTitle === true || props.showTitle === undefined) && (
        <div
          style={{
            ...theme.dataRepository.elementAdjacent,
            marginRight: "25px",
            marginTop: "15px",
            marginBottom: "35px",
          }}
        >
          <FontAwesomeIcon
            icon={props.iconTitle}
            style={{
              fontSize: "calc(8px + 0.4vw)",
              marginLeft: "5px",
              marginRight: "5px",
              color: "#4697c4",
            }}
            fontSize="small"
          />
          <Typography variant="subtitle2">{props.title}</Typography>
        </div>
      )}
      <TreeTable
        defaultSortOrder={-1}
        value={props.treeData.node}
        style={{
          marginTop: "-35px",
          borderBottom: "3px solid lightblue",
          maxHeight: "13vw",
          minHeight: "10vw",
          marginLeft: "5px",
          marginRight: "5px",
          overflowY: "scroll",
        }}
        onContextMenuSelectionChange={(event) => {
          handleContextMenu(event);
        }}
        selectionMode="single"
        selectionKeys={props.selectedItem.itemId}
        onSelectionChange={(e) => {
          setSelectedItemAndType(
            e.value,
            props.treeData,
            props.setSelectedItem
          );
        }}
        expandedKeys={props.expandedKeys}
        onToggle={(e) => props.setExpandedKeys(e.value)}
      >
        <Column
          style={{
            ...theme.dataRepository.backgroundColumnsAndText,
            width: "20%",
            wordBreak: "break-all",
          }}
          field="name"
          header="Name"
          expander
          body={(data) =>
            CellTemplate(
              data,
              props.iconType1,
              props.iconType2,
              props.onClickOpenDialogSimulation,
              props.fullProjectView
            )
          }
        ></Column>
        <Column
          style={{
            ...theme.dataRepository.backgroundColumnsAndText,
            textAlign: "center",
            width: "10%",
          }}
          field="date"
          header="Date"
          body={(node) => <Moment format="DD/MM/YYYY">{node.data.date}</Moment>}
        ></Column>
        <Column
          style={{
            ...theme.dataRepository.backgroundColumnsAndText,
            textAlign: "center",
            width: "20%",
            //whiteSpace: 'unset' //soluciones para wrap text
            tableLayout: "fixed",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
            whiteSpace: "unset",
          }}
          field="author"
          header="Owner"
          body={(node) =>
            findAuthorNameFromId(
              license,
              node.data ? node.data.author : undefined
            )
          }
        ></Column>
        <Column
          style={{
            ...theme.dataRepository.backgroundColumnsAndText,
            textAlign: "center",
            width: "10%",
          }}
          field="private"
          body={(node) => findPermissionIcon(node.data.private)}
          header={
            <FontAwesomeIcon
              icon={faLock}
              style={{ color: "#696969", margin: 5 }}
              fontSize="small"
            />
          }
        ></Column>
        <Column
          style={{
            ...theme.dataRepository.backgroundColumnsAndText,
            textAlign: "center",
            width: "10%",
          }}
          body={(node) =>
            node.data.type !== "Folder" && props.type === "shared" ? (
              <FontAwesomeIcon
                onClick={() => {props.setShowDialogs.graph(true); trackEvent('Data Repository','Tree Table Show Graph');}}
                icon={faExternalLinkAlt}
                style={theme.dataRepository.iconPublic}
                fontSize="small"
              />
            ) : null
          }
          field="shared"
          header={
            <div>
              <FontAwesomeIcon
                icon={faShareAlt}
                style={{ color: "#696969", margin: 5 }}
                fontSize="small"
              />
            </div>
          }
        ></Column>
      </TreeTable>
    </>
  );
}
