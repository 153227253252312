
import quality from '../apis/quality';
import qualityLocalHost from '../apis/qualityLocalHost';

import {
    UPDATE_SIMULATION,
    FETCH_SIMULATIONS,
    DELETE_SIMULATIONS
    } from './types'
    import {axiosHeader, dispatchError} from '../components/common/axios/axiosHelper';


    
export const getDeploymentForProject = (apiKey, projectId) => async (dispatch,getState) => {
    
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/deploy/key/${apiKey}/project/${projectId}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
          });
    
    
   return returnValue
};

export const getDeploymentForProjectAndEnvironment = (apiKey, projectId,environment) => async (dispatch,getState) => {
    
    var returnValue = {result:'undefined'}
    
         
    await quality.get(`/deploy/key/${apiKey}/project/${projectId}/env/${environment}`,{
        headers: axiosHeader()
      }).then(function (response) {
            
            returnValue = {result:'ok', data:response.data}
        })
        .catch(function (error) {
            dispatchError(dispatch,error)
            
          });
    
    
   return returnValue
};